import { employeesPath } from '@/features/constants'
import { axios } from '@/lib/helpers'

export const headerService = () => {
  return {
    signOut: async (path: string) => {
      try {
        await axios.delete(path, {})
      } finally {
        window.location.reload()
      }
    },
    changeLanguage: async (employeeID: number, language: PlatformLanguage) => {
      try {
        await axios.patch(`${employeesPath}/${employeeID}`, {
          employee: { language },
        })
      } finally {
        window.location.reload()
      }
    },
  }
}
