export enum CovidScreeningType {
  not_require = 'not_require',
  form = 'form',
  atk = 'atk',
  either = 'either',
  both = 'both',
}

export type InvitationSetting = {
  required_vaccine_record: boolean
  covid_screening_type: CovidScreeningType
  effective_date_screening: number
  email_language: PlatformLanguage
  email_color: string
  effective_hour: number
  show_personal_data: boolean
  show_vaccine_record: boolean
  show_covid_screening: boolean
}

export type WalkInSetting = {
  covid_screening_type: CovidScreeningType
  effective_date_screening: number
  token: string
}

export type Company = {
  id: number
  slug: string
  name: string
  image_url: string
  latitude: number
  longitude: number
  location_name: string
  address: string
  logo_url: string
  locations: Location[]
  open_hour: number
  open_minutes: number
  close_hour: number
  close_minutes: number
  open_hour_weekend: number
  open_minutes_weekend: number
  close_hour_weekend: number
  close_minutes_weekend: number
  open_on_weekend: boolean
  terms_url: string
  policy_url: string
  invitation_setting: InvitationSetting
  walk_in_setting: WalkInSetting
  visitor_policy_url: string
}

export type Location = {
  id: number
  name: string
  status: string
  default_location?: boolean
}

export enum EmployeeStatus {
  pending = 'pending',
  enable = 'enable',
  disable = 'disable',
}

export enum EmployeeType {
  staff = 'Staff',
  admin = 'Admin',
  owner = 'Owner',
}

export type Employee = {
  id: number
  email: string
  first_name: string
  last_name?: string
  status: EmployeeStatus
  type: string
  image?: Image
  company_slug: string
  google_email: string
  microsoft_email: string
  slack_email: string
  zoom_email: string
  language: PlatformLanguage
  confirmation_expired: boolean
}

export type Invite = {
  id: number
  title: string
  comment: string
  location_id?: string
  location?: string
  start_time: string
  end_time: string
  host_employee_name: string
  host_employee_id: string
  list_schedule_time: InviteScheduleTime[]
  online_link?: string
  category: InviteCategory
}

export enum InvitationStatus {
  not_confirm = 'not_confirm',
  pending = 'pending',
  approved = 'approved',
  rejected = 'rejected',
}

export type Visitor = {
  id: number
  email: string
  first_name: string
  last_name: string
  image: Image
  telephone: string
  slug: string
  invitation_status?: InvitationStatus
  vaccine_slot_verified?: number
}

export type Invitation = {
  id: string | number
  asset: string
  slug: string
  status: InvitationStatus
  start_datetime: string
  finish_datetime: string
  check_in_at?: Date
  check_out_at?: Date
  visitor_image_url?: string
  visitor_name?: string
  visitor_email?: string
  visitor_telephone?: string
  invite: Invite
  host_employee_name?: string
  area_to_access?: string
  employee_email: string
  visitor_slug: string
  covid_screening_form_status?: CovidScreeningFormStatus
  covid_screening_atk_status?: CovidScreeningATKStatus
  covid_screening_form_expired_at?: string
  covid_screening_atk_expired_at?: string
  covid_screening_image_url?: string
  logo_url?: string
  online_link?: string
  internal_note?: string
  company_slug: string
  visitor_note?: string
  check_in_employee_name?: string
  check_out_employee_name?: string
  covid_screening_type: CovidScreeningType
  vaccine_slot_verified: number
  show_personal_data: boolean
  show_vaccine_record: boolean
  show_covid_screening: boolean
  company_visitor_policy_url?: string
}

export type Image = {
  url: string
}

export type DateTimeObject = {
  fromDate: string
  toDate: string
  fromTime: string
  toTime: string
}

export type CovidScreening = {
  q1: boolean
  q2: boolean
  q3: boolean
  q4: boolean
  q5: boolean
  q6: boolean
  symptoms: string[]
  slug: string
  logo_url?: string
  atk_image: Image
  test_atk_at: string
}

export type YesNoQuestionCovidScreening = {
  q1: boolean
  q2: boolean
  q3: boolean
  q4: boolean
  q5: boolean
  q6: boolean
}

export enum RiskAssessmentStatus {
  not_vaccinated = 'not_vaccinated',
  not_confirm = 'not_confirm',
  pending = 'pending',
  rejected = 'rejected',
  verified = 'verified',
}

export type Vaccine = {
  name: string
  date: Date | string
  status: RiskAssessmentStatus
}

export type RiskAssessment = {
  id: number
  image: Image
  visitor_id: number
  rejected_reason?: string
  requested_at: Date
  verified_at: Date
  visitor_slug: string
  visitor_name: string
  visitor_email: string
  visitor_image_url: string
  visitor_telephone: string
  vaccines: Vaccine[]
  request_status: RiskAssessmentStatus
  slot_verified: number
}

export enum CovidScreeningFormStatus {
  not_screening = 'not_screening',
  low_risk = 'low_risk',
  moderate_risk = 'moderate_risk',
  high_risk = 'high_risk',
}

export type VisitorFormData = {
  firstName: string
  lastName: string
  invitationSlug: string
  email: string
  telephone: string
  asset: string
  image?: File
  visitor_note: string
}

export type VaccineFormData = {
  vaccines: Vaccine[]
  image?: File | ''
  invitationSlug?: string
}

export enum SystemAdminStatus {
  pending = 'pending',
  enable = 'enable',
  disable = 'disable',
}

export type SystemAdmin = {
  id: number
  email: string
  first_name: string
  last_name?: string
  status: EmployeeStatus
  image?: Image
}

export enum LocationStatus {
  enable = 'enable',
  disable = 'disable',
}

export type IntegratedEvent = {
  id: number
  title: string
  description: string
  location: string
  start_time: string
  end_time: string
  organizer: string
  event_type: ConnectionsType
}

export type ConnectionsType = 'google' | 'microsoft' | 'zoom' | 'slack'

export type InviteScheduleTime = {
  start_time: string
  end_time: string
  selected?: boolean
}

export type VaccineLog = {
  action: string
  note: string
  created_at: Date
  updated_at: Date
}

export type Delivery = {
  id: number
  status: 'pending' | 'picked_up' | 'rejected'
  created_at: Date
  updated_at: Date
  owner_name: string
  image: Image
  mark_as_received_employee_name?: string
  host_employee_id: number
  sender?: string
}

export type WalkInVisitorData = {
  first_name: string
  last_name: string
  email: string
  asset: string
  image: File
  telephone: string
  host_employee_id: string
}

export type WalkInDeliveryData = {
  company_slug: string
  image: File
  host_employee_id: string
  sender: string
}

export type EventDetailModalType = 'visitor' | 'walk_in'
export type LogsPageTab = 'invites' | 'walk_in' | 'delivery' | 'atk'
export type ATKReportTab = 'request' | 'log'

export type SearchParams = {
  sort?: string
  direction?: string
  tab?: string
}

export type AtkReport = {
  slug: string
  visitor_image_url: string
  visitor_name: string
  visitor_email: string
  visitor_telephone: string
  submit_atk_at: Date
  atk_image_url: string
  test_atk_at: Date
  atk_status: CovidScreeningATKStatus
  company_slug: string
  invitation_slug: string
}

export type AtkLog = {
  status: CovidScreeningATKStatus
  submit_at: Date
  verify_at: Date
  visitor_name: string
  verified_employee: string
}

export enum CovidScreeningATKStatus {
  not_screening = 'not_screening',
  not_verify = 'not_verify',
  rejected = 'rejected',
  negative = 'negative',
  positive = 'positive',
  expired = 'expired',
}
export type WebcamFacingMode = 'user' | 'environment'
export type ActionLog = {
  id: string
  email: string
  action: string
  description: string
  created_at: Date
}

export type InviteCategory = 'local' | 'online' | 'walk_in'
export type AtkReportWithDate = { [key: string]: AtkReport[] }
export type AtkReportActionType = 'none' | 'risk' | 'no_risk' | 'reject'
export type CompanySettingNumberField =
  | 'invitation.effective_date_screening'
  | 'invitation.effective_hour'
  | 'walk_in.effective_date_screening'
export type InvitationWalkInType = 'invitation' | 'walk_in'
export type CovidScreeningStatus =
  | CovidScreeningFormStatus
  | CovidScreeningATKStatus
export type VisitorWithCovidScreeningStatus = Visitor & {
  covid_screening_status: CovidScreeningStatus
}
export type CovidScreeningSettingFields = {
  required_covid_screening: CovidScreeningType.either | CovidScreeningType.both
  required_screening_form: boolean
  required_atk: boolean
}
export type InvitationSettingFields = InvitationSetting &
  CovidScreeningSettingFields
export type WalkInSettingFields = WalkInSetting & CovidScreeningSettingFields
