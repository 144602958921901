import React, { useState } from 'react'

import classnames from 'classnames'

import styles from './Invitations.module.scss'
import { Button, showToast, VisitorDetail } from '@/components/shared'

import { invitationService } from '../../services'
import {
  CovidScreeningFormStatus,
  CovidScreeningStatus,
  Invitation as InvitationType,
  Visitor,
} from '@/features/types'
import { getLocaleTimeString, t } from '@/lib/helpers'

type Props = {
  invitation: InvitationType
  onClick: (invitationID: string) => void
  onCheckOut?: () => void
  covidScreeningStatus?: CovidScreeningStatus
}

export const WalkInInvitation = (props: Props) => {
  const {
    invitation,
    onClick,
    onCheckOut,
    covidScreeningStatus = CovidScreeningFormStatus.not_screening,
  } = props
  const [checkOutAt, setCheckOutAt] = useState<Date>()

  const {
    id,
    asset,
    check_in_at,
    check_out_at,
    visitor_image_url,
    visitor_name,
    visitor_email,
    visitor_slug,
    host_employee_name,
    vaccine_slot_verified,
  } = invitation

  const handleCheckOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    invitationService()
      .checkOut(id.toString())
      .then(({ data: { check_out_at } }) => {
        setCheckOutAt(check_out_at)
        showToast({
          type: 'success',
          message: t(
            'views.features.invitations.dashboard.successfully_checked_out',
          ),
        })
      })
    onCheckOut && onCheckOut()
  }

  const checkOutAtOrButton = () => {
    const time = check_out_at || checkOutAt
    if (time)
      return (
        <div className={styles.checkInOut}>
          <img src="/assets/icons/exit.svg" height={20} />
          <div className={styles.checkInOutTime}>
            {getLocaleTimeString(time)}
          </div>
        </div>
      )
    return (
      <Button
        text={t('views.misc.check_out')}
        style={'Secondary'}
        className={styles.checkInOutButton}
        onClick={handleCheckOut}
      />
    )
  }

  const visitor: Visitor = {
    id: 0,
    first_name: visitor_name || '',
    last_name: '',
    email: visitor_email || '',
    image: {
      url: visitor_image_url || '',
    },
    telephone: '',
    slug: visitor_slug || '',
    vaccine_slot_verified: vaccine_slot_verified,
  }

  return (
    <tr onClick={() => onClick(id.toString())} className={styles.invitation}>
      <td className={styles.visitorInfo}>
        <VisitorDetail
          visitor={visitor}
          covidScreeningStatus={covidScreeningStatus}
          imageSize={60}
          showTelephone={false}
          borderRadius={6}
          className={styles.visitorDetail}
        />
      </td>
      <td className={classnames(styles.textEllipsis, styles.walkInHostText)}>
        {host_employee_name || '-'}
      </td>
      <td className={classnames(styles.textEllipsis, styles.walkInAssetText)}>
        {asset || '-'}
      </td>
      <td>
        <div className={styles.checkInOut}>
          <img src="/assets/icons/location.svg" height={20} />
          <div className={styles.checkInOutTime}>
            {check_in_at && getLocaleTimeString(check_in_at)}
          </div>
        </div>
      </td>
      <td className={styles.centerColumn}>{checkOutAtOrButton()}</td>
    </tr>
  )
}
