// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ExportCSV-module__downloadIcon___1kN4Y{color:#3873e4;margin-right:8px}", "",{"version":3,"sources":["/usr/src/app/app/javascript/components/shared/ExportCSV/ExportCSV.module.scss"],"names":[],"mappings":"AAEA,wCACE,aAAA,CACA,gBAAA","file":"ExportCSV.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.downloadIcon) {\n  color: $anyvisit-primary;\n  margin-right: 8px;\n}\n"]}]);
// Exports
exports.locals = {
	"downloadIcon": "ExportCSV-module__downloadIcon___1kN4Y"
};
module.exports = exports;
