import React, { useState, useEffect } from 'react'

import { ScreeningSelection, ScreeningForm, AtkForm } from '../../components'
import { VisitorLayout } from '../../layout'

import { CovidScreening, CovidScreeningType } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  covidScreening: CovidScreening
  invitationCovidScreeningType: CovidScreeningType
  atkTestDays: string[]
}

const CovidScreeningPage = (props: Props) => {
  const { covidScreening, invitationCovidScreeningType } = props
  const [screeningType, setScreeningType] = useState<CovidScreeningType>(
    invitationCovidScreeningType,
  )

  useEffect(() => {
    if (invitationCovidScreeningType === CovidScreeningType.both) {
      const url = new URL(window.location.href)
      const type = url.searchParams.get('type') || CovidScreeningType.form
      setScreeningType(type as CovidScreeningType)
    }
  }, [])

  if (!covidScreening) {
    return (
      <VisitorLayout>
        {t('views.features.visitor.covid_screening.not_available')}
      </VisitorLayout>
    )
  }

  switch (screeningType) {
    case CovidScreeningType.either:
      return (
        <ScreeningSelection {...props} setScreeningType={setScreeningType} />
      )
    case CovidScreeningType.form:
      return <ScreeningForm {...props} />
    case CovidScreeningType.atk:
      return <AtkForm {...props} />
    default:
      return (
        <VisitorLayout>
          {t('views.features.visitor.covid_screening.not_available')}
        </VisitorLayout>
      )
  }
}

export default CovidScreeningPage
