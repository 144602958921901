import React from 'react'

import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { animated, useTransition } from 'react-spring'

import styles from './WalkInScreeningForm.module.scss'
import {
  Button,
  CheckboxButtons,
  RadioButtons,
  RadioButtonsOnChangeOption,
} from '@/components/shared'

import { walkInServices } from '../../services'
import { covidScreeningChoiceLabels } from '@/features/constants'
import { CovidScreening, CovidScreeningType } from '@/features/types'

import {
  useWalkInContext,
  WalkInScreeningFormStateType,
  WalkInStateType,
} from '../../context'

export const WalkInScreeningForm = () => {
  const {
    state,
    setState,
    setType,
    t,
    visitor,
    setShowConfirmModal,
    company,
    covidScreening,
    setCovidScreening,
    setIsLoading,
  } = useWalkInContext()

  const { symptoms, q1, q2, q3, q4, q5, q6 } = covidScreening as CovidScreening
  const { handleSubmit, register, setValue, watch } = useForm({
    defaultValues: { q1, q2, q3, q4, q5, q6, symptoms },
  })

  const transitions = useTransition(state, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const choices = covidScreeningChoiceLabels.map((choice) => ({
    title: t(`views.features.visitor.covid_screening.screening_form.${choice}`),
    value: choice.replaceAll('_', ' '),
  }))

  const opacityTo = () => {
    return state === 1
      ? { range: [0.0, 1.0], output: [0, 1] }
      : { range: [1.0, 0.0], output: [1, 0] }
  }

  const isLastStep =
    company?.walk_in_setting.covid_screening_type !== CovidScreeningType.both

  const _handleSubmit = async (data: any) => {
    if (!visitor) return

    if (isLastStep) {
      setIsLoading(true)
      const res = await walkInServices().submitVisitor(visitor)
      await walkInServices().submitScreeningForm(
        res?.covidScreeningSlug,
        data,
        res?.invitationSlug,
      )
      setShowConfirmModal(false)
      setIsLoading(false)
      setState(0)
      setType('visitor-success')
    } else {
      setCovidScreening({
        ...(covidScreening as CovidScreening),
        ...data,
      })
      setState(1)
      setType('atk')
    }
  }

  const onSelectYesNo = (answer: boolean) => {
    setValue(`q${state as WalkInScreeningFormStateType}`, answer)
    setState((state + 1) as WalkInStateType)
    setCovidScreening({
      ...(covidScreening as CovidScreening),
      [`q${state as WalkInScreeningFormStateType}`]: answer,
    })
  }

  const getRadioButtonChangeOptions = (
    state: WalkInStateType,
  ): RadioButtonsOnChangeOption[] => {
    return [
      {
        label: t('views.features.visitor.components.choice_yes'),
        checked: watch(`q${state as WalkInScreeningFormStateType}`),
        onClick: () => onSelectYesNo(true),
      },
      {
        label: t('views.features.visitor.components.choice_no'),
        checked: watch(`q${state as WalkInScreeningFormStateType}`) === false,
        onClick: () => onSelectYesNo(false),
      },
    ]
  }

  return (
    <div className={styles.formArea}>
      {transitions(({ opacity }, state) => {
        return (
          <animated.div
            style={{
              position: 'absolute',
              width: '100%',
              opacity: opacity.to(opacityTo()),
            }}
          >
            <form
              id={`walkInState${state}`}
              onSubmit={handleSubmit(_handleSubmit)}
            >
              {state <= 6 ? (
                <RadioButtons
                  key={`q${state}`}
                  title={t(
                    `views.features.visitor.covid_screening.screening_form.q${state}`,
                  )}
                  name={`q${state}`}
                  horizontal
                  onChangeOptions={getRadioButtonChangeOptions(state)}
                  className={styles.question}
                />
              ) : (
                <div className={styles.question}>
                  <span className={styles.label}>
                    {t(
                      'views.features.visitor.covid_screening.screening_form.q7',
                    )}
                  </span>
                  <div className={styles.checkboxes}>
                    <CheckboxButtons
                      choices={choices.slice(0, choices.length / 2)}
                      name={'symptoms'}
                      register={register}
                      className={classnames(styles.checkbox, styles.mr16)}
                    />
                    <CheckboxButtons
                      choices={choices.slice(choices.length / 2)}
                      name={'symptoms'}
                      register={register}
                      className={styles.checkbox}
                    />
                  </div>
                  <Button
                    text={t('views.misc.confirm')}
                    type={isLastStep ? 'button' : 'submit'}
                    onClick={
                      isLastStep ? () => setShowConfirmModal(true) : undefined
                    }
                    size="L"
                    className={styles.submitButton}
                  />
                </div>
              )}
            </form>
          </animated.div>
        )
      })}
    </div>
  )
}
