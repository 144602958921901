import React from 'react'

import { UseFormHandleSubmit, UseFormRegister } from 'react-hook-form'

import styles from './AssetsCard.module.scss'
import { Button, TextField } from '@/components/shared'

import { t } from '@/lib/helpers'

type Props = {
  assets: string
  isEdit?: boolean
  register: UseFormRegister<any>
  handleSubmit: UseFormHandleSubmit<any>
  onCancelEditAsset: () => void
  onSaveAsset: (data: any) => void
  className?: string
}

export const AssetsCard = (props: Props) => {
  const {
    assets,
    isEdit = false,
    register,
    handleSubmit,
    onCancelEditAsset,
    onSaveAsset,
    className,
  } = props

  const showAssetComponent = (
    <div className={styles.card}>
      <div className={styles.header}>
        {t('views.features.app.confirm_check_in.asset')}
      </div>
      {assets}
    </div>
  )

  const editAssetComponent = (
    <form onSubmit={handleSubmit(onSaveAsset)}>
      <div className={styles.card}>
        <TextField
          type="textarea"
          name="asset"
          register={register}
          required
          className={styles.textarea}
        />
      </div>
      <div className={styles.editAssetButtons}>
        <Button
          text={t('views.misc.cancel')}
          style={'Secondary'}
          onClick={onCancelEditAsset}
        />
        <Button text="Save" type="submit" />
      </div>
    </form>
  )

  return (
    <div className={className}>
      {isEdit ? editAssetComponent : showAssetComponent}
    </div>
  )
}
