import { deliveriesPath, errorsNotFoundPath } from '@/features/constants'
import { axios } from '@/lib/helpers'

export const deliveryService = () => {
  return {
    pickedUp: async (id: number) => {
      try {
        await axios.patch(`${deliveriesPath}/${id}`, {})
      } catch (err) {
        if (axios.isAxiosError(err) && err?.response?.status === 404) {
          window.location.href = errorsNotFoundPath
        } else {
          window.location.reload()
        }
      }
    },
  }
}
