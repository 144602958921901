import React, { useState, useEffect, createRef } from 'react'

import classnames from 'classnames'
import { useTransition } from 'react-spring'
import Webcam from 'react-webcam'

import { WalkInDelivery, WalkInVisitor } from '..'
import styles from './WalkInForm.module.scss'
import { Camera, RotateCcw } from '@/components/icons'
import { Button, ISelectOption } from '@/components/shared'

import { walkInServices } from '../../services'
import { CovidScreeningType, Employee } from '@/features/types'

import { useWalkInContext } from '../../context'

export const WalkInForm = () => {
  const {
    state,
    type,
    company,
    t,
    setShowConfirmModal,
    host,
    setHost,
    imgSrc,
    setImgSrc,
  } = useWalkInContext()
  const [showCountdown, setShowCountdown] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(5)
  const [image, setImage] = useState<File>()
  const [listHost, setListHost] = useState<ISelectOption[] | null>(null)
  const [isCapture, setIsCapture] = useState<boolean>(false)

  const transitions = useTransition(state, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const isVisitorLastState = type === 'visitor' && state === 4
  const isLastState = isVisitorLastState || (type === 'delivery' && state === 2)

  const handleRecapture = () => {
    setImgSrc(undefined)
    setImage(undefined)
    setCountdown(5)
  }

  const searchHost = (val: string) => {
    if (val.length >= 3 && company) {
      walkInServices()
        .searchHost(company.slug, val)
        .then((res: Employee[]) => {
          setListHost(
            res.map((employee) => ({
              label: `${employee.first_name} ${employee.last_name}`,
              value: employee.id.toString(),
            })),
          )
        })
    } else {
      setListHost(null)
    }
  }

  const webcamRef = createRef<Webcam>()
  const manageImage = (dataUrl: string | null | undefined) => {
    if (dataUrl) {
      setImgSrc(dataUrl)
      fetch(dataUrl)
        .then(function (res) {
          return res.arrayBuffer()
        })
        .then(function (buf) {
          setImage(new File([buf], 'image.jpg', { type: 'image/jpeg' }))
        })
    }
  }

  let timeout: NodeJS.Timeout
  useEffect(() => {
    if (showCountdown) {
      if (countdown > 0) {
        timeout = setTimeout(() => setCountdown(countdown - 1), 1000)
      } else {
        setShowCountdown(false)
        setIsCapture(true)
        setTimeout(() => setIsCapture(false), 500)
        manageImage(webcamRef.current?.getScreenshot())
      }
      return () => clearTimeout(timeout)
    }
  }, [showCountdown, countdown, state, webcamRef])

  const capture = () => {
    clearTimeout(timeout)
    setCountdown(0)
    setShowCountdown(false)
    setIsCapture(true)
    setTimeout(() => setIsCapture(false), 500)
    manageImage(webcamRef.current?.getScreenshot())
  }

  const isVisitorLastStep =
    isVisitorLastState &&
    company?.walk_in_setting.covid_screening_type ===
      CovidScreeningType.not_require

  return (
    <>
      <div
        className={classnames({
          [styles.formArea]: type !== 'delivery',
          [styles.deliveryFormArea]: type === 'delivery',
          [styles.formImageArea]: isLastState,
        })}
      >
        {transitions(({ opacity }) => {
          switch (type) {
            case 'visitor':
              return (
                <WalkInVisitor
                  opacity={opacity}
                  listHost={listHost}
                  host={host}
                  searchHost={searchHost}
                  setHost={setHost}
                  isCapture={isCapture}
                  imgSrc={imgSrc}
                  state={state}
                  ref={webcamRef}
                  showCountdown={showCountdown}
                  setShowCountdown={setShowCountdown}
                  countdown={countdown}
                  image={image}
                />
              )
            case 'delivery':
              return (
                <WalkInDelivery
                  opacity={opacity}
                  listHost={listHost}
                  host={host}
                  searchHost={searchHost}
                  setHost={setHost}
                  isCapture={isCapture}
                  imgSrc={imgSrc}
                  state={state}
                  ref={webcamRef}
                  showCountdown={showCountdown}
                  setShowCountdown={setShowCountdown}
                  countdown={countdown}
                  image={image}
                />
              )
          }
        })}
      </div>
      <div
        className={classnames(styles.btns, {
          [styles.webcamBtns]: isLastState,
        })}
      >
        {isLastState && showCountdown && (
          <Button
            type={'button'}
            text={t('views.features.walk_in.form.capture')}
            iconComponent={<Camera className={styles.camera} />}
            size={'L'}
            onClick={capture}
            className={styles.btn}
          />
        )}
        {(!isLastState || (isLastState && imgSrc)) && (
          <>
            {isLastState && (
              <Button
                type={'button'}
                text={t('views.features.walk_in.form.recapture')}
                size={'L'}
                onClick={handleRecapture}
                iconComponent={<RotateCcw className={styles.mr8} />}
                style={'Secondary'}
                className={classnames(styles.btn, styles.mr16)}
              />
            )}
            <Button
              form={`walkInState${state}`}
              type={isVisitorLastStep ? 'button' : 'submit'}
              text={
                isLastState ? t('views.misc.confirm') : t('views.misc.next')
              }
              size={'L'}
              onClick={
                isVisitorLastStep ? () => setShowConfirmModal(true) : undefined
              }
              className={styles.btn}
            />
          </>
        )}
      </div>
    </>
  )
}
