// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CreateModal-module__modal___P9OPA>div{width:90%;max-width:465px}.CreateModal-module__imageWrapper___3_1Qm{margin-top:24px;display:flex;justify-content:center}.CreateModal-module__image___3JU9n{width:-moz-fit-content;width:fit-content;position:relative}.CreateModal-module__pointer___3mEcu{cursor:pointer}.CreateModal-module__form___23UsL{margin-top:32px}.CreateModal-module__email___244fg,.CreateModal-module__role___6zGey{margin-top:16px}.CreateModal-module__buttons___7qd1C{margin-top:24px;display:flex;justify-content:flex-end}.CreateModal-module__createButton___2_i6_{margin-left:8px;width:200px;max-width:50%}.CreateModal-module__hiddenInput___xkiMG{display:none}", "",{"version":3,"sources":["/usr/src/app/app/javascript/components/shared/User/components/CreateModal/CreateModal.module.scss"],"names":[],"mappings":"AAGE,uCACE,SAAA,CACA,eAAA,CAIJ,0CACE,eAAA,CACA,YAAA,CACA,sBAAA,CAGF,mCACE,sBAAA,CAAA,iBAAA,CACA,iBAAA,CAGF,qCACE,cAAA,CAGF,kCACE,eAAA,CAGF,qEAEE,eAAA,CAGF,qCACE,eAAA,CACA,YAAA,CACA,wBAAA,CAGF,0CACE,eAAA,CACA,WAAA,CACA,aAAA,CAGF,yCACE,YAAA","file":"CreateModal.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.modal) {\n  > div {\n    width: 90%;\n    max-width: 465px;\n  }\n}\n\n:local(.imageWrapper) {\n  margin-top: 24px;\n  display: flex;\n  justify-content: center;\n}\n\n:local(.image) {\n  width: fit-content;\n  position: relative;\n}\n\n:local(.pointer) {\n  cursor: pointer;\n}\n\n:local(.form) {\n  margin-top: 32px;\n}\n\n:local(.email),\n:local(.role) {\n  margin-top: 16px;\n}\n\n:local(.buttons) {\n  margin-top: 24px;\n  display: flex;\n  justify-content: flex-end;\n}\n\n:local(.createButton) {\n  margin-left: 8px;\n  width: 200px;\n  max-width: 50%;\n}\n\n:local(.hiddenInput) {\n  display: none;\n}\n"]}]);
// Exports
exports.locals = {
	"modal": "CreateModal-module__modal___P9OPA",
	"imageWrapper": "CreateModal-module__imageWrapper___3_1Qm",
	"image": "CreateModal-module__image___3JU9n",
	"pointer": "CreateModal-module__pointer___3mEcu",
	"form": "CreateModal-module__form___23UsL",
	"email": "CreateModal-module__email___244fg",
	"role": "CreateModal-module__role___6zGey",
	"buttons": "CreateModal-module__buttons___7qd1C",
	"createButton": "CreateModal-module__createButton___2_i6_",
	"hiddenInput": "CreateModal-module__hiddenInput___xkiMG"
};
module.exports = exports;
