import React, { useRef, forwardRef, MutableRefObject } from 'react'

import classnames from 'classnames'

import { Spinner } from '..'
import styles from './TextField.module.scss'

type TextFieldProps = {
  title?: string
  legendTitle?: string
  placeholder?: string
  defaultValue?: string
  type?: string
  required?: boolean
  readOnly?: boolean
  pattern?: string
  className?: string
  name: string
  register: any
  autoComplete?: string
  autoFocus?: boolean
  onBlur?: () => void
  postDecoration?: React.ReactNode
  maxLength?: number
  disabled?: boolean
  loading?: boolean
  onFocus?: () => void
}

export const TextField = forwardRef<any, TextFieldProps>((props, customRef) => {
  const {
    title,
    legendTitle,
    placeholder,
    defaultValue,
    type = 'text',
    required,
    readOnly,
    pattern,
    className,
    name,
    register,
    autoComplete,
    autoFocus,
    onBlur,
    postDecoration,
    maxLength,
    disabled = false,
    loading = false,
    onFocus,
  } = props

  const inputRef = useRef<HTMLInputElement | null>(null)
  const { ref, ...rest } = register(name)
  const handleAddDash = (tel: string) => {
    let val = tel.split('-').join('')
    return (
      (val.substring(0, 7).match(/[0-9]{1,3}/g) || []).join('-') +
      (val.substring(7).match(/[0-9]{1,4}/) || '')
    )
  }

  const handleEnter = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      inputRef.current?.blur()
    }
  }

  const inputComponent =
    type == 'textarea' ? (
      <textarea
        className={classnames(styles.input, styles.textarea)}
        defaultValue={defaultValue}
        {...register(name, { required })}
        required={required}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    ) : (
      <input
        className={classnames(styles.input, {
          [styles.readOnly]: readOnly,
          [styles.withLegendTitle]: legendTitle,
        })}
        type={type}
        placeholder={placeholder || ' '}
        readOnly={readOnly}
        defaultValue={
          type === 'tel'
            ? defaultValue && handleAddDash(defaultValue)
            : defaultValue
        }
        {...rest}
        ref={(e) => {
          ref(e)
          inputRef.current = e
          if (customRef)
            (customRef as MutableRefObject<HTMLInputElement | null>).current = e
        }}
        required={required}
        pattern={pattern}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        onKeyUp={
          type === 'tel'
            ? (e: React.ChangeEvent<HTMLInputElement>) =>
                (e.target.value = handleAddDash(e.target?.value))
            : onBlur && handleEnter
        }
        maxLength={type === 'tel' ? 12 : maxLength}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
      />
    )
  return (
    <div className={classnames(styles.background, className)}>
      {title && (
        <label className={styles.title}>
          {title}
          {required && title && <span className={styles.requiredTitle}>*</span>}
        </label>
      )}
      {inputComponent}
      {legendTitle && (
        <label className={styles.legendTitle}>{legendTitle}</label>
      )}
      {legendTitle && (
        <fieldset className={styles.fieldset}>
          <legend className={styles.legend}>
            <span>{legendTitle}</span>
          </legend>
        </fieldset>
      )}
      {postDecoration && (
        <div className={styles.postDecoration}>{postDecoration}</div>
      )}
      {loading && (
        <div className={styles.spinner}>
          <Spinner height={40} width={40} />
        </div>
      )}
    </div>
  )
})
