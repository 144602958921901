import React, { useEffect, useRef, useState } from 'react'

/* eslint-disable */
import FullCalendar, { EventInput, EventMountArg } from '@fullcalendar/react'
import thLocale from '@fullcalendar/core/locales/th'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentPlugin from '@fullcalendar/moment'
import './Calendar.scss'
/* eslint-disable */

import moment from 'moment'
import { UseFormSetValue } from 'react-hook-form'

import styles from './InviteCalendar.module.scss'
import { CaretLeft, CaretRight } from '@/components/icons'
import { Button, ConfirmModal } from '@/components/shared'
import {
  compareHourAndMinutes,
  eventOrder,
  formatDate,
  formatHourAndMinutes,
  getLocaleDateString,
  getLocaleTimeString,
  t,
} from '@/lib/helpers'
import { Company } from '@/features/types'

type Props = {
  events: EventInput[]
  newEvents: EventInput[]
  setNewEvents: (events: EventInput[]) => void
  scheduleMode?: boolean
  watch: any
  company: Company
  setValue: UseFormSetValue<any>

  // slot duration (minutes)
  duration: number | string
}

const outsideCompanyTime = (
  date: Date,
  company: Company,
  duration: number | string,
) => {
  if (duration === 'allDay') return false
  duration = duration as number
  const openHour =
    date.getDay() === 0 || date.getDay() === 6
      ? company.open_hour_weekend
      : company.open_hour
  const openMinutes =
    date.getDay() === 0 || date.getDay() === 6
      ? company.open_minutes_weekend
      : company.open_minutes

  if (
    date.getHours() < openHour ||
    (date.getHours() === openHour && date.getMinutes() < openMinutes)
  )
    return true

  const dateEnd = moment(date).add(duration, 'minutes').toDate()
  const closeHour =
    date.getDay() === 0 || date.getDay() === 6
      ? company.close_hour_weekend
      : company.close_hour
  const closeMinutes =
    date.getDay() === 0 || date.getDay() === 6
      ? company.close_minutes_weekend
      : company.close_minutes
  if (
    dateEnd.getHours() > closeHour ||
    (dateEnd.getHours() === closeHour && dateEnd.getMinutes() > closeMinutes)
  )
    return true
  return false
}

export const InviteCalendar = (props: Props) => {
  const {
    events,
    newEvents,
    setNewEvents,
    scheduleMode = false,
    duration,
    watch,
    company,
    setValue,
  } = props

  const [title, setTitle] = useState<string>(
    getLocaleDateString(new Date(), {
      month: 'long',
      year: 'numeric',
    }),
  )
  const [newEventID, setNewEventID] = useState<number>(0)

  // confirm for create new invite outside company's work hour
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [newEventStart, setNewEventStart] = useState<Date>()
  const [newEventEnd, setNewEventEnd] = useState<Date>()
  const [isRemoveEvent, setIsRemoveEvent] = useState<boolean>(false)

  const calendarRef: any = useRef()
  const openTime =
    compareHourAndMinutes(
      company.open_hour,
      company.open_minutes,
      company.open_hour_weekend,
      company.open_minutes_weekend,
    ) < 0
      ? formatHourAndMinutes(company.open_hour, company.open_minutes)
      : formatHourAndMinutes(
          company.open_hour_weekend,
          company.open_minutes_weekend,
        )
  const closeTime =
    compareHourAndMinutes(
      company.close_hour,
      company.close_minutes,
      company.close_hour_weekend,
      company.close_minutes_weekend,
    ) > 0
      ? formatHourAndMinutes(company.close_hour, company.close_minutes)
      : formatHourAndMinutes(
          company.close_hour_weekend,
          company.close_minutes_weekend,
        )

  const _setTitle = () => {
    if (!calendarRef.current) return
    const calendarApi = calendarRef.current.getApi()
    setTitle(calendarApi.view.title)
  }
  const prev = () => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.prev()
  }

  const next = () => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.next()
  }

  const getNewEvent = (date: any, duration: number | string) => {
    const start =
      duration === 'allDay'
        ? moment(`${moment(date).format('L')} ${openTime}`).toDate()
        : date
    const end =
      duration === 'allDay'
        ? moment(`${moment(date).format('L')} ${closeTime}`).toDate()
        : moment(date).add(duration, 'minutes').toDate()
    return {
      start,
      end,
    }
  }

  const addNewEvent = (start: Date, end: Date) => {
    setHoverEvent(undefined)
    if (!scheduleMode) {
      return setValue('dateTime', {
        fromTime: getLocaleTimeString(start),
        toTime: getLocaleTimeString(end),
        fromDate: formatDate(start, 'D MMM YYYY'),
        toDate: formatDate(end, 'D MMM YYYY'),
      })
    }
    setNewEvents([
      ...newEvents,
      {
        id: newEventID.toString(),
        start,
        end,
        className: `new-invite select-slot ${duration < 30 && 'slot-15'}`,
      },
    ])
    setNewEventID(newEventID + 1)
  }

  const handleDateClick = (info: any) => {
    const { date } = info
    let companyStartTime
    let companyEndTime
    if (date.getDay() === 0 || date.getDay() === 6) {
      companyStartTime = formatHourAndMinutes(
        company.open_hour_weekend,
        company.open_minutes_weekend,
      )
      companyEndTime = formatHourAndMinutes(
        company.close_hour_weekend,
        company.close_minutes_weekend,
      )
    } else {
      companyStartTime = formatHourAndMinutes(
        company.open_hour,
        company.open_minutes,
      )
      companyEndTime = formatHourAndMinutes(
        company.close_hour,
        company.close_minutes,
      )
    }

    const start =
      duration === 'allDay'
        ? moment(`${moment(date).format('L')} ${companyStartTime}`).toDate()
        : date
    const end =
      duration === 'allDay'
        ? moment(`${moment(date).format('L')} ${companyEndTime}`).toDate()
        : moment(date).add(duration, 'minutes').toDate()

    if (isRemoveEvent || isSelectedSlot(start, end)) {
      setIsRemoveEvent(false)
      return
    }
    if (outsideCompanyTime(date, company, duration)) {
      setShowConfirmModal(true)
      setNewEventStart(start)
      setNewEventEnd(end)
    } else {
      addNewEvent(start, end)
    }
  }

  const onConfirmConfirmModal = () => {
    setShowConfirmModal(false)
    newEventStart && newEventEnd && addNewEvent(newEventStart, newEventEnd)
  }

  const normalDateTime = watch('dateTime')
  const normalModeEvent = {
    start: moment(
      `${normalDateTime.fromDate} ${normalDateTime.fromTime}`,
    ).toDate(),
    end: watch('moreOneDay')
      ? moment(`${normalDateTime.toDate} ${normalDateTime.toTime}`).toDate()
      : moment(`${normalDateTime.fromDate} ${normalDateTime.toTime}`).toDate(),
    className: `new-invite ${duration < 30 && 'slot-15'}`,
  }

  const [hoverEvent, setHoverEvent] = useState<any>()
  let displayEvents = [...events, ...newEvents]
  if (!scheduleMode) displayEvents = [...displayEvents, normalModeEvent]
  if (hoverEvent) displayEvents = [...displayEvents, hoverEvent]

  const findDateByOffsetX = (offsetX: number) => {
    const dateElementWithOffsetX: any[] = []
    document
      .querySelectorAll('td.fc-timegrid-col.fc-day')
      .forEach((dateItem: any) =>
        dateElementWithOffsetX.push({
          offsetLeft: dateItem?.offsetLeft,
          date: dateItem?.dataset?.date,
        }),
      )

    let date = dateElementWithOffsetX[0]?.date
    for (let dateItem of dateElementWithOffsetX) {
      if (offsetX <= dateItem?.offsetLeft) {
        break
      }
      date = dateItem.date
    }
    return date
  }

  const isSelectedSlot = (start: Date, end: Date) => {
    if (!scheduleMode) {
      return (
        normalDateTime.fromDate === formatDate(start, 'D MMM YYYY') &&
        normalDateTime.fromTime === formatDate(start, 'hh:mm A') &&
        normalDateTime.toDate === formatDate(end, 'D MMM YYYY') &&
        normalDateTime.toTime === formatDate(end, 'hh:mm A')
      )
    }

    if (newEvents.length === 0) return false
    return newEvents
      .map(
        (event) =>
          event.start?.toString() === start.toString() &&
          event.end?.toString() === end.toString(),
      )
      .reduce((a, b) => a || b)
  }

  const handleMouseOverSlot = (event: any) => {
    const time = event?.srcElement?.dataset?.time
    const date = findDateByOffsetX(event?.layerX)
    const newEvent = getNewEvent(
      moment(`${moment(date).format('L')} ${time}`).toDate(),
      duration,
    )
    if (!isSelectedSlot(newEvent.start, newEvent.end))
      setHoverEvent({
        ...newEvent,
        id: 'hover-invite',
        className: `new-invite hover-invite ${duration < 30 && 'slot-15'}`,
      })
  }

  const handleMouseOutSlot = (_event: any) => {
    setHoverEvent(undefined)
  }

  const addCloseButtonToNewSlot = (arg: EventMountArg) => {
    const elm = arg.el
    if (elm.className.includes('select-slot') && elm.parentElement) {
      const parentElm = elm.parentElement
      parentElm.style.pointerEvents = 'unset'
      const closeBtn = document.createElement('img')
      closeBtn.src = '/assets/icons/x-circle.svg'
      closeBtn.className = 'close-button'
      closeBtn.onclick = () => {
        setIsRemoveEvent(true)
        setNewEvents(newEvents.filter((event) => event.id !== arg.event.id))
      }
      parentElm.append(closeBtn)
    }
  }

  useEffect(() => {
    document.querySelectorAll('.fc-timegrid-slot-lane').forEach((item) => {
      item.addEventListener('mouseover', handleMouseOverSlot)
      item.addEventListener('mouseout', handleMouseOutSlot)
    })

    return () => {
      document.querySelectorAll('.fc-timegrid-slot-lane').forEach((item) => {
        item.removeEventListener('mouseover', handleMouseOverSlot)
        item.removeEventListener('mouseout', handleMouseOutSlot)
      })
    }
  }, [calendarRef.current, duration, scheduleMode, newEvents, normalDateTime])

  return (
    <div className={styles.background}>
      <div className={styles.header}>
        <Button
          iconComponent={<CaretLeft />}
          className={styles.previousButton}
          onClick={prev}
        />
        <Button
          iconComponent={<CaretRight />}
          className={styles.nextButton}
          onClick={next}
        />
        <div className={styles.todayText}>{title}</div>
      </div>
      <FullCalendar
        ref={calendarRef}
        plugins={[timeGridPlugin, interactionPlugin, momentPlugin]}
        events={displayEvents}
        datesSet={_setTitle}
        dateClick={handleDateClick}
        slotDuration="00:15:00"
        dayHeaderFormat="ddd D"
        titleFormat={{ year: 'numeric', month: 'long' }}
        headerToolbar={{
          left: '',
          center: '',
          right: '',
        }}
        allDaySlot={false}
        scrollTime={'08:00:00'}
        eventMinHeight={5}
        eventMaxStack={2}
        weekends={company.open_on_weekend}
        eventOrder={eventOrder}
        eventDidMount={addCloseButtonToNewSlot}
        locale={gon.currentLocale === 'th' ? thLocale : undefined}
        slotLabelFormat={{
          hour: '2-digit',
          minute: '2-digit',
          meridiem: 'short',
        }}
      />
      <ConfirmModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onConfirmConfirmModal}
        title={t('views.features.invite.invite.confirm_title')}
        description={t('views.features.invite.invite.confirm_description')}
        confirmText={t('views.misc.confirm')}
      />
    </div>
  )
}
