import {
  CovidScreeningATKStatus,
  CovidScreeningFormStatus,
  CovidScreeningType,
} from '@/features/types'

export const getCovidScreeningStatus = (
  type: CovidScreeningType,
  formStatus: CovidScreeningFormStatus,
  atkStatus: CovidScreeningATKStatus,
) => {
  switch (type) {
    case 'form':
      return formStatus
    case 'atk':
      return atkStatus
    case 'either':
      return [
        CovidScreeningATKStatus.expired,
        CovidScreeningATKStatus.not_screening,
      ].includes(atkStatus)
        ? formStatus
        : formStatus
    case 'both':
      return atkStatus === CovidScreeningATKStatus.positive
        ? CovidScreeningATKStatus.positive
        : formStatus === CovidScreeningFormStatus.not_screening
        ? atkStatus
        : formStatus
    default:
      return CovidScreeningFormStatus.not_screening
  }
}
