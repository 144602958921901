import React from 'react'

import loadingData from '@/jsons/loading.json'
import Lottie from 'react-lottie'

import styles from './Loading.module.scss'

type Props = {}

export const Loading = (props: Props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return (
    <div className={styles.bg}>
      <Lottie options={defaultOptions} height={150} width={150} speed={2} />
    </div>
  )
}
