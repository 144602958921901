import React, { useState } from 'react'

import { Invitations } from '../../components'
import { DashboardLayout } from '../../layout'
import { SortOptions } from '@/components/shared'

import {
  exportInvitationsPath,
  invitationsPath,
  requestsPath,
} from '@/features/constants'
import { Employee, Invitation } from '@/features/types'
import { axios } from '@/lib/helpers'

type Props = {
  defaultSearch: string
  defaultSort: string
  defaultSortDirection: string
  totalVisitor: number
  totalCheckIn: number
  totalCheckOut: number
  totalPending: number
  invitations: Invitation[]
  employee: Employee
}

const RequestsPage = (props: Props) => {
  // eslint-disable-next-line
  const { defaultSearch, defaultSort, defaultSortDirection, invitations } = props
  const [search, setSearch] = useState<string>(defaultSearch || '')
  const [showEventDetailModal, setShowEventDetailModal] =
    useState<boolean>(false)
  const [invitation, setInvitation] = useState<Invitation>()
  const sortOptions: SortOptions = {
    sort: defaultSort || '',
    direction: defaultSortDirection || '',
  }

  const getSearchParams = (newSort?: string, newDirection?: string) => {
    const sort = newSort || sortOptions.sort
    const direction = newDirection || sortOptions.direction
    return `search=${search}&sort=${sort}&sort_direction=${direction}`
  }

  const handleQuery = (newSort?: string, newDirection?: string) => {
    window.location.href = `${requestsPath}?${getSearchParams(
      newSort,
      newDirection,
    )}`
  }

  const openEventDetailModal = (invitationID: string) => {
    axios
      .get(`${invitationsPath}/${invitationID}`, {})
      .then(({ data }) => setInvitation({ ...data }))
    setShowEventDetailModal(true)
  }

  // eslint-disable-next-line
  const getCSVPath = () => `${exportInvitationsPath}?${getSearchParams()}&path=request`

  return (
    <DashboardLayout
      exportCSVPath={getCSVPath()}
      sortOptions={sortOptions}
      search={search}
      setSearch={setSearch}
      showEventDetailModal={showEventDetailModal}
      onCloseEventDetailModal={() => setShowEventDetailModal(false)}
      invitation={invitation}
      queryPath={requestsPath}
      {...props}
    >
      <Invitations
        invitations={invitations}
        sortOptions={sortOptions}
        handleSort={handleQuery}
        showPending
        onClickInvitation={(invitationID: string) =>
          openEventDetailModal(invitationID)
        }
      />
    </DashboardLayout>
  )
}

export default RequestsPage
