import React from 'react'

import ReactSwitch from 'react-switch'

type Props = {
  checked: boolean
  onChange: (checked: boolean) => void
  height: number
  width: number
  disabled?: boolean
  className?: string
}

export const Switch = (props: Props) => {
  const {
    checked,
    width,
    height,
    onChange,
    disabled = false,
    className,
  } = props
  return (
    <ReactSwitch
      onChange={onChange}
      checked={checked}
      offColor={'#e9e9ea'}
      onColor={'#03cc6b'}
      checkedIcon={false}
      uncheckedIcon={false}
      width={width}
      height={height}
      disabled={disabled}
      className={className}
    />
  )
}
