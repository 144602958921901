import React from 'react'

import { VisitorLayout } from '../../layout'
import { Success } from '@/components/shared'

import { t } from '@/lib/helpers'

const SuccessPage = () => {
  return (
    <VisitorLayout showLocaleSwitch={false}>
      <Success
        text={t('views.features.visitor.show.success.title', {}, 'Well done!')}
      />
    </VisitorLayout>
  )
}

export default SuccessPage
