import React, { useState } from 'react'

import classnames from 'classnames'
import moment from 'moment'
import {
  FieldValues,
  RefCallBack,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'
import Creatable from 'react-select/creatable'

import { CheckboxButtons, ISelectOption } from '..'
import styles from './DateTimeRangeField.module.scss'
import { DateField } from '@/components/shared'

import { getTimeOptions } from '@/features/constants'
import { DateTimeObject } from '@/features/types'
import { formatDate, getDuration, getNewTime, t } from '@/lib/helpers'

type Props = {
  value: any
  watch: UseFormWatch<FieldValues>
  onChange: (value: any) => void
  dateTimeRef: RefCallBack
  register: UseFormRegister<FieldValues>
  setDuration: (duration: number | string) => void
}

const timeStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '100%',
    border: '1px solid #e8e8e8',
    borderRadius: '8px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    justifyContent: 'center',
    transform: 'translateY(1px)',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    padding: '8px',
    textAlign: 'center',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingLeft: '6px',
    paddingRight: '6px',
  }),
}

const getFormatDate = (date: Date) => {
  return formatDate(date, 'D MMM YYYY')
}

export const NormalMode = (props: Props) => {
  const { value, watch, onChange, dateTimeRef, register, setDuration } = props

  const timePickerOption: ISelectOption[] = getTimeOptions()
  const moreOneDay = watch('moreOneDay')
  const timePickerOptionTexts = timePickerOption.map((option) => option.value)
  const fromTimeIndex = timePickerOptionTexts.indexOf(value.fromTime)
  const toTimeIndex = timePickerOptionTexts.indexOf(value.toTime)

  const [selectFromTime, setSelectFromTime] = useState(false)
  const [selectToTime, setSelectToTime] = useState(false)
  const _onChange = (newValue: DateTimeObject) => {
    const { fromDate, toDate } = newValue
    setDuration(
      getDuration({ ...newValue, toDate: moreOneDay ? toDate : fromDate }),
    )
    onChange(newValue)
  }

  const disabledDate = (current: any) =>
    current && current < moment().subtract(1, 'days').endOf('day')

  return (
    <>
      <div className={styles.inputs}>
        <div className={styles.dateRow}>
          {moreOneDay && (
            <span className={styles.startEndText}>
              {t('views.components.date_time_range_field.start')}
            </span>
          )}
          <DateField
            value={moment(value.fromDate)}
            onChange={(dateMoment) => {
              if (!dateMoment) return

              const date = dateMoment.toDate()
              const tomorrow = dateMoment.toDate()
              tomorrow.setDate(tomorrow.getDate() + 1)
              if (
                !moreOneDay ||
                (moreOneDay &&
                  date.getTime() >=
                    new Date(watch('dateTime.toDate')).getTime())
              ) {
                _onChange({
                  ...value,
                  fromDate: getFormatDate(date),
                  toDate: getFormatDate(tomorrow),
                })
              } else {
                _onChange({ ...value, fromDate: getFormatDate(date) })
              }
            }}
            ref={dateTimeRef}
            disabledDate={disabledDate}
            allowClear={false}
            className={styles.datePicker}
          />
          <Creatable
            options={timePickerOption}
            name="dateTime.fromTime"
            menuPlacement="top"
            formatCreateLabel={getNewTime}
            value={
              fromTimeIndex === -1
                ? { label: value.fromTime, value: value.fromTime }
                : timePickerOption[fromTimeIndex]
            }
            onCreateOption={(inputValue: string) => {
              const time = getNewTime(inputValue)
              _onChange({ ...value, fromTime: time })
            }}
            onChange={(newOption: any) => {
              const newIndex = timePickerOption.indexOf(newOption)
              if (newIndex >= toTimeIndex) {
                const newToTimeIndex = (newIndex + 1) % (24 * 4)
                _onChange({
                  ...value,
                  toTime: timePickerOption[newToTimeIndex].value,
                  fromTime: newOption.value,
                })
              } else {
                _onChange({ ...value, fromTime: newOption.value })
              }
            }}
            onFocus={() => setSelectFromTime(true)}
            onBlur={() => setSelectFromTime(false)}
            styles={timeStyles}
            className={classnames(styles.timePicker, styles.ml8, {
              [styles.wrapText]: selectFromTime,
            })}
          />
          {!moreOneDay && (
            <>
              <span className={styles.dashText}>-</span>
              <Creatable
                options={timePickerOption}
                name="dateTime.toTime"
                menuPlacement="top"
                formatCreateLabel={getNewTime}
                value={
                  toTimeIndex === -1
                    ? { label: value.toTime, value: value.toTime }
                    : timePickerOption[toTimeIndex]
                }
                onCreateOption={(inputValue: string) => {
                  const time = getNewTime(inputValue)
                  _onChange({ ...value, toTime: time })
                }}
                onChange={(newOption: any) => {
                  const newIndex = timePickerOption.indexOf(newOption)
                  if (newIndex <= fromTimeIndex) {
                    const newFromTimeIndex = (newIndex - 1 + 24 * 4) % (24 * 4)
                    _onChange({
                      ...value,
                      fromTime: timePickerOption[newFromTimeIndex].value,
                      toTime: newOption.value,
                    })
                  } else {
                    _onChange({ ...value, toTime: newOption.value })
                  }
                }}
                onFocus={() => setSelectToTime(true)}
                onBlur={() => setSelectToTime(false)}
                styles={timeStyles}
                className={classnames(styles.timePicker, {
                  [styles.wrapText]: selectToTime,
                })}
              />
            </>
          )}
        </div>
        {moreOneDay && (
          <div className={styles.dateRow}>
            <span className={styles.startEndText}>
              {t('views.components.date_time_range_field.end')}
            </span>
            <DateField
              value={moment(value.toDate)}
              onChange={(dateMoment) => {
                if (!dateMoment) return

                const date = dateMoment.toDate()
                if (
                  date.getTime() <=
                  new Date(watch('dateTime.fromDate')).getTime()
                ) {
                  _onChange({
                    ...value,
                    fromDate: getFormatDate(date),
                    toDate: getFormatDate(date),
                  })
                } else {
                  _onChange({ ...value, toDate: getFormatDate(date) })
                }
              }}
              ref={dateTimeRef}
              allowClear={false}
              disabledDate={disabledDate}
              className={styles.datePicker}
            />
            <Creatable
              options={timePickerOption}
              name="dateTime.toTime"
              menuPlacement="top"
              formatCreateLabel={getNewTime}
              value={
                toTimeIndex === -1
                  ? { label: value.toTime, value: value.toTime }
                  : timePickerOption[toTimeIndex]
              }
              onCreateOption={(inputValue: string) => {
                const time = getNewTime(inputValue)
                _onChange({ ...value, toTime: time })
              }}
              onChange={(newOption: any) =>
                _onChange({ ...value, toTime: newOption.value })
              }
              onFocus={() => setSelectToTime(true)}
              onBlur={() => setSelectToTime(false)}
              styles={timeStyles}
              className={classnames(styles.timePicker, styles.ml8, {
                [styles.wrapText]: selectToTime,
              })}
            />
          </div>
        )}
      </div>
      <CheckboxButtons
        choices={[
          {
            title: t('views.components.date_time_range_field.more_one_day'),
            value: 'moreOneDay',
          },
        ]}
        register={register}
        name="moreOneDay"
        className={styles.moreOneDay}
      />
    </>
  )
}
