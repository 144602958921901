import React from 'react'

import classnames from 'classnames'
import { Control, Controller, UseFormRegister } from 'react-hook-form'

import { CovidScreeningSettingSection, Label } from '..'
import styles from './Settings.module.scss'
import {
  ColorPicker,
  Switch,
  LocaleButtons,
  NumberField,
  NumberFieldDirection,
} from '@/components/shared'

import {
  CompanySettingNumberField,
  InvitationSettingFields,
} from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  register: UseFormRegister<any>
  control: Control<any>
  handleNumberField: (
    field: CompanySettingNumberField,
    direction?: NumberFieldDirection,
  ) => void
  handleUpdateFields: (value: InvitationSettingFields) => void
}

export const InvitationSetting = (props: Props) => {
  const { register, control, handleNumberField, handleUpdateFields } = props

  return (
    <Controller
      control={control}
      name="invitation"
      render={({ field: { onChange, value } }) => {
        const _handleUpdateFields = (newValue: any) => {
          onChange(newValue)
          handleUpdateFields(newValue)
        }

        const onClickLocale = (email_language: PlatformLanguage) => {
          _handleUpdateFields({ ...value, email_language })
        }

        return (
          <div className={styles.wrapper}>
            <div className={styles.section}>
              <span className={styles.title}>
                {t(
                  'views.features.company_setting.invitation.invitation_request',
                )}
              </span>
              <div className={styles.halfWidth}>
                <div className={styles.box}>
                  <Label
                    title={t(
                      'views.features.company_setting.invitation.vaccine',
                    )}
                    description={t(
                      'views.features.company_setting.invitation.vaccine_description',
                    )}
                  />
                  <Switch
                    checked={value.required_vaccine_record}
                    onChange={() => {
                      _handleUpdateFields({
                        ...value,
                        required_vaccine_record: !value.required_vaccine_record,
                      })
                    }}
                    width={50}
                    height={30}
                    className={styles.floatRight}
                  />
                </div>
              </div>
              <hr className={styles.line} />
              <CovidScreeningSettingSection
                register={register}
                control={control}
                value={value}
                onChange={(k, v) => _handleUpdateFields({ ...value, [k]: v })}
                handleNumberField={handleNumberField}
                type={'invitation'}
              />
            </div>
            <div className={classnames(styles.section, styles.mt16)}>
              <span className={styles.title}>
                {t('views.features.company_setting.invitation.email_setting')}
              </span>
              <div className={styles.halfWidth}>
                <div className={styles.emailSection}>
                  <Label
                    title={t(
                      'views.features.company_setting.invitation.language',
                    )}
                  />
                  <LocaleButtons
                    locale={value.email_language}
                    onClickLocale={onClickLocale}
                  />
                </div>
                <div className={styles.box}>
                  <Label
                    title={t('views.features.company_setting.invitation.color')}
                    description={t(
                      'views.features.company_setting.invitation.color_description',
                    )}
                  />
                  <div className={styles.colorPicker}>
                    <ColorPicker
                      defaultColor={value.email_color}
                      handleChange={(colorCode: string) => {
                        _handleUpdateFields({
                          ...value,
                          email_color: colorCode,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={classnames(styles.section, styles.mt16)}>
              <span className={styles.title}>
                {t('views.features.company_setting.invitation.ticket')}
              </span>
              <div className={styles.halfWidth}>
                <div className={styles.box}>
                  <Label
                    title={t(
                      'views.features.company_setting.invitation.effective_hour',
                    )}
                    description={t(
                      'views.features.company_setting.invitation.effective_hour_description',
                    )}
                  />
                  <NumberField
                    name={'invitation.effective_hour'}
                    register={register}
                    onChange={(direction?: NumberFieldDirection) =>
                      handleNumberField('invitation.effective_hour', direction)
                    }
                    unit={t('views.misc.hour')}
                  />
                </div>
                <div className={styles.box}>
                  <Label
                    title={t(
                      'views.features.company_setting.invitation.information_show',
                    )}
                    description={t(
                      'views.features.company_setting.invitation.information_show_description',
                    )}
                  />
                </div>
                <div className={styles.box}>
                  <span>
                    {t(
                      'views.features.company_setting.invitation.show_personal_data',
                    )}
                  </span>
                  <Switch
                    checked={value.show_personal_data}
                    onChange={() => {
                      _handleUpdateFields({
                        ...value,
                        show_personal_data: !value.show_personal_data,
                      })
                    }}
                    width={50}
                    height={30}
                    className={styles.floatRight}
                  />
                </div>
                <div className={styles.box}>
                  <span>
                    {t(
                      'views.features.company_setting.invitation.show_vaccine_record',
                    )}
                  </span>
                  <Switch
                    checked={value.show_vaccine_record}
                    onChange={() => {
                      _handleUpdateFields({
                        ...value,
                        show_vaccine_record: !value.show_vaccine_record,
                      })
                    }}
                    width={50}
                    height={30}
                    className={styles.floatRight}
                  />
                </div>
                <div className={styles.box}>
                  <span>
                    {t(
                      'views.features.company_setting.invitation.show_covid_screening',
                    )}
                  </span>
                  <Switch
                    checked={value.show_covid_screening}
                    onChange={() => {
                      _handleUpdateFields({
                        ...value,
                        show_covid_screening: !value.show_covid_screening,
                      })
                    }}
                    width={50}
                    height={30}
                    className={styles.floatRight}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
