// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".VerifyVaccinePage-module__vaccination___3oL0W{margin-top:16px;padding-bottom:32px}.VerifyVaccinePage-module__vaccineRecord___nBGkO{font-weight:600;line-height:24px;margin-bottom:8px}.VerifyVaccinePage-module__vaccineInformation___3n9ll{margin-top:16px}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/verifyVaccine/pages/VerifyVaccinePage/VerifyVaccinePage.module.scss"],"names":[],"mappings":"AAEA,+CACE,eAAA,CACA,mBAAA,CAGF,iDACE,eAAA,CACA,gBAAA,CACA,iBAAA,CAGF,sDACE,eAAA","file":"VerifyVaccinePage.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.vaccination) {\n  margin-top: 16px;\n  padding-bottom: 32px;\n}\n\n:local(.vaccineRecord) {\n  font-weight: 600;\n  line-height: 24px;\n  margin-bottom: 8px;\n}\n\n:local(.vaccineInformation) {\n  margin-top: 16px;\n}\n"]}]);
// Exports
exports.locals = {
	"vaccination": "VerifyVaccinePage-module__vaccination___3oL0W",
	"vaccineRecord": "VerifyVaccinePage-module__vaccineRecord___nBGkO",
	"vaccineInformation": "VerifyVaccinePage-module__vaccineInformation___3n9ll"
};
module.exports = exports;
