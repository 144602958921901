import React, { ReactNode } from 'react'

import { Control } from 'react-hook-form'

import styles from './LogsLayout.module.scss'
import {
  ApplicationLayout,
  Search,
  ExportCSV,
  Spinner,
} from '@/components/shared'

import { Employee, LogsPageTab, SearchParams } from '@/features/types'
import { t } from '@/lib/helpers'

type TabProps = {
  name: string
  isHighlight?: boolean
  onClick: () => void
}

const Tab = (props: TabProps) => {
  const { name, isHighlight = false, onClick } = props
  return (
    <div
      className={isHighlight ? styles.highlightTab : styles.tab}
      onClick={onClick}
    >
      {t(`views.features.log.${name}_log.title`)}
    </div>
  )
}

const logTabs: LogsPageTab[] = ['invites', 'walk_in', 'delivery', 'atk']

type Props = {
  tab?: LogsPageTab
  employee: Employee
  onSetLogTab?: (logTab: LogsPageTab) => void
  control: Control<any>
  search: string
  setSearch: (search: string) => void
  getSearchParams: (options: SearchParams) => void
  handleQuery: (newSort?: string, newDirection?: string) => void
  isLoading?: boolean
  children: ReactNode
}

const LogsLayout = (props: Props) => {
  const {
    tab,
    employee,
    onSetLogTab,
    control,
    search,
    setSearch,
    getSearchParams,
    handleQuery,
    isLoading = false,
    children,
  } = props

  const getCSVPath = () => {
    return `/logs/export_csv?${getSearchParams({})}`
  }

  return (
    <ApplicationLayout employee={employee}>
      <div className={styles.title}>{t('views.features.log.title')}</div>
      <Search
        search={search}
        setSearch={setSearch}
        date
        control={control}
        handleQuery={handleQuery}
      />
      <div className={styles.tabWrapper}>
        {tab &&
          logTabs.map((logTab) => (
            <Tab
              key={logTab}
              name={logTab}
              isHighlight={tab === logTab}
              onClick={() => onSetLogTab && onSetLogTab(logTab)}
            />
          ))}
        <ExportCSV
          path={getCSVPath()}
          buttonStyle={'Ghost'}
          className={styles.exportBtn}
        />
      </div>
      {isLoading ? (
        <div className={styles.loading}>
          <Spinner height={160} width={160} />
        </div>
      ) : (
        children
      )}
    </ApplicationLayout>
  )
}

export default LogsLayout
