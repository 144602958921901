import React, { useEffect, useState } from 'react'

import styles from './WalkInSuccess.module.scss'
import { Button } from '@/components/shared'

import { useWalkInContext } from '../../context'

export const WalkInSuccess = () => {
  const { setType, setState, type, t } = useWalkInContext()
  const VISITOR_SUCCESS_IMAGE_PATH =
    '/assets/images/walk-in-visitor-success.svg'
  const DELIVERY_SUCCESS_IMAGE_PATH =
    '/assets/images/walk-in-delivery-success.svg'
  const [imgSrc, setImgSrc] = useState<string>(VISITOR_SUCCESS_IMAGE_PATH)

  const redirectToWelcome = () => {
    setType('welcome')
    setState(0)
  }

  useEffect(() => {
    const timeout = setTimeout(() => redirectToWelcome(), 5000)
    switch (type) {
      case 'visitor-success':
        setImgSrc(VISITOR_SUCCESS_IMAGE_PATH)
        break
      case 'delivery-success':
        setImgSrc(DELIVERY_SUCCESS_IMAGE_PATH)
    }
    return () => clearTimeout(timeout)
  }, [type])

  return (
    <div className={styles.wrapper}>
      <img src={imgSrc} height={360} className={styles.img} />
      <Button
        text={t('views.misc.confirm')}
        size={'L'}
        onClick={redirectToWelcome}
        className={styles.btn}
      />
    </div>
  )
}
