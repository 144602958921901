import React, { useState } from 'react'

import { FieldValues, UseFormWatch } from 'react-hook-form'

import styles from './VaccinatedForm.module.scss'
import { Button, ImageField, RadioButtons } from '@/components/shared'

import { RiskAssessment, RiskAssessmentStatus } from '@/features/types'
import { t } from '@/lib/helpers'

import { useVaccineContext } from '../../context/VaccineContext'
import { VaccinatedSlot } from '../VaccinatedSlot'

type VaccinatedFormProps = {
  onSubmit: () => void
}

export const VaccinatedForm = (props: VaccinatedFormProps) => {
  const { riskAssessment, vaccinated, setVaccinated, handleClickNoVaccine } =
    useVaccineContext()
  const { onSubmit } = props
  const { slot_verified } = riskAssessment as RiskAssessment

  const showRadioButton = slot_verified === 0
  const _handleClickNoVaccine = () => {
    setVaccinated(false)
    handleClickNoVaccine()
  }

  const onChangeOptions = [
    {
      label: t('views.features.visitor.edit.vaccinated.choice_yes'),
      checked: vaccinated === true,
      onChange: () => setVaccinated(true),
    },
    {
      label: t('views.features.visitor.edit.vaccinated.choice_no'),
      checked: vaccinated === false,
      onChange: _handleClickNoVaccine,
    },
  ]

  return (
    <>
      {showRadioButton && (
        <span className={styles.title}>
          {t('views.features.visitor.edit.vaccinated.title')}
        </span>
      )}
      <form
        id={'vaccinatedForm'}
        onSubmit={onSubmit}
        className={styles.formSection}
      >
        {showRadioButton && (
          <div className={styles.mb24}>
            <RadioButtons onChangeOptions={onChangeOptions} />
          </div>
        )}
        {vaccinated && <VaccinatedRecordForm />}
      </form>
    </>
  )
}

const VaccinatedRecordForm = () => {
  const {
    riskAssessment: riskAssessmentContext,
    watch,
    vaccineImagePath,
    existingVaccines,
    handleUploadImage,
    handleCancelUploadImage,
    handleClickClearVaccine,
  } = useVaccineContext()
  const riskAssessment = riskAssessmentContext as RiskAssessment
  const [slot, setSlot] = useState<number>(
    Math.max(1, riskAssessment.vaccines.length),
  )

  const getVaccinatedSlot = (
    slotNumber: number,
    valueVaccine?: string,
    valueDate?: Date,
    disabled?: boolean,
  ) => {
    return (
      <VaccinatedSlot
        key={slotNumber}
        dose={slotNumber}
        required={
          slotNumber === 1 ||
          (watch as UseFormWatch<FieldValues>)(`dose_${slotNumber}_date`)
        }
        valueVaccine={valueVaccine}
        valueDate={valueDate}
        disabled={disabled}
        isRemoveable={slotNumber > 1 && !disabled}
        onRemove={() => {
          handleClickClearVaccine(slotNumber)
          if (riskAssessment.slot_verified === slot - 1) {
            handleCancelUploadImage()
          }
          setSlot(slot - 1)
        }}
      />
    )
  }

  return (
    <div>
      <div className={styles.recordTitle}>
        {t('views.features.visitor.edit.vaccinated.vaccination_record')}
      </div>
      {existingVaccines.map((vaccine, idx) =>
        getVaccinatedSlot(
          idx + 1,
          vaccine['name'],
          vaccine['date'] as Date,
          vaccine['status'] === RiskAssessmentStatus.verified,
        ),
      )}
      {Array(slot - existingVaccines.length)
        .fill(null)
        .map((_, idx) => getVaccinatedSlot(existingVaccines.length + idx + 1))}
      <Button
        text={t('views.features.visitor.edit.vaccinated.add_more_vaccine')}
        icon={'/assets/icons/plus.svg'}
        style={'Tertiary'}
        onClick={() => setSlot(slot + 1)}
        className={styles.addMoreVaccineBtn}
      />
      {slot !== riskAssessment.slot_verified && (
        <ImageField
          title={t('views.features.visitor.edit.vaccinated.image_title')}
          placeholder={t('views.features.visitor.edit.vaccinated.image_upload')}
          required
          className={styles.image}
          onUpload={handleUploadImage}
          onCancel={handleCancelUploadImage}
          defaultImagePath={vaccineImagePath}
        />
      )}
    </div>
  )
}
