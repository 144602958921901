// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Loading-module__bg___1OiCC{position:fixed;width:100vw;height:100vh;left:0;top:0;background-color:rgba(0,0,0,.7);display:flex;justify-content:center;align-items:center;flex-direction:column;z-index:10}", "",{"version":3,"sources":["/usr/src/app/app/javascript/components/shared/Loading/Loading.module.scss"],"names":[],"mappings":"AAEA,4BACE,cAAA,CACA,WAAA,CACA,YAAA,CACA,MAAA,CACA,KAAA,CACA,+BAAA,CACA,YAAA,CACA,sBAAA,CACA,kBAAA,CACA,qBAAA,CACA,UAAA","file":"Loading.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.bg) {\n  position: fixed;\n  width: 100vw;\n  height: 100vh;\n  left: 0;\n  top: 0;\n  background-color: rgba(0, 0, 0, 0.7);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  z-index: 10;\n}\n"]}]);
// Exports
exports.locals = {
	"bg": "Loading-module__bg___1OiCC"
};
module.exports = exports;
