// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".RejectModal-module__wrapper___sBEyO{margin:24px 0px}.RejectModal-module__buttons___2Vmoi{margin-top:24px;display:flex;justify-content:flex-end}", "",{"version":3,"sources":["/usr/src/app/app/javascript/components/shared/RejectModal/RejectModal.module.scss"],"names":[],"mappings":"AAEA,qCACE,eAAA,CAGF,qCACE,eAAA,CACA,YAAA,CACA,wBAAA","file":"RejectModal.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.wrapper) {\n  margin: 24px 0px;\n}\n\n:local(.buttons) {\n  margin-top: 24px;\n  display: flex;\n  justify-content: flex-end;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "RejectModal-module__wrapper___sBEyO",
	"buttons": "RejectModal-module__buttons___2Vmoi"
};
module.exports = exports;
