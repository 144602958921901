import React from 'react'

import { useForm } from 'react-hook-form'

import { PasswordWithConfirm } from '../../components'
import {
  UserLayout,
  TextField,
  userLayoutStyles,
  showToast,
  validatePassword,
} from '@/components/shared'

import { userServices } from '../../services'
import { Employee, SystemAdmin } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  user: Employee | SystemAdmin
  isSystemAdmin?: boolean
}

const USER_SETUP_FORM = 'userSetUpForm'

export const UserSetUpPage = (props: Props) => {
  const { user, isSystemAdmin = false } = props
  const { email, first_name, last_name } = user

  const { handleSubmit, register, watch } = useForm({
    defaultValues: {
      email,
      firstname: first_name,
      lastname: last_name,
      password: '',
      confirmPassword: '',
    },
  })

  const _handleSubmit = (data: any) => {
    if (!validatePassword(data.password)) {
      return showToast({
        type: 'alert',
        message: t('views.components.password_complexity.invalid'),
      })
    } else if (data.password !== data.confirmPassword) {
      return showToast({
        type: 'alert',
        message: t('views.components.password_complexity.password_not_match'),
      })
    }

    const url = new URL(window.location.href)
    const token = url.searchParams.get('confirmation_token')
    userServices().setUpAccount({ ...user, ...data, token, isSystemAdmin })
  }

  const mode = isSystemAdmin ? 'SystemAdmin' : 'AnyVisit'

  return (
    <UserLayout
      form={USER_SETUP_FORM}
      isSystemAdmin={isSystemAdmin}
      textBtn={t('views.features.devise.set_up.set_up_account')}
    >
      <div className={userLayoutStyles.title}>
        {t('views.features.devise.set_up.welcome', { mode })}
      </div>
      <form
        id={USER_SETUP_FORM}
        onSubmit={handleSubmit(_handleSubmit)}
        className={userLayoutStyles.formSection}
      >
        <TextField
          legendTitle={t('views.features.devise.forgot_password.email_title')}
          type={'email'}
          name={'email'}
          register={register}
          required
          autoComplete={'email'}
          readOnly
          className={userLayoutStyles.field}
        />
        <TextField
          legendTitle={t('views.misc.firstname')}
          name={'firstname'}
          register={register}
          required
          autoFocus
          className={userLayoutStyles.field}
        />
        <TextField
          legendTitle={t('views.misc.lastname')}
          name={'lastname'}
          register={register}
          required
          className={userLayoutStyles.field}
        />
        <PasswordWithConfirm register={register} watch={watch} />
      </form>
    </UserLayout>
  )
}
