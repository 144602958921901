import React from 'react'

import { Control, FieldValues } from 'react-hook-form'

import { CreatableSelect, ISelectOption } from '..'

import { getTimeOptions } from '@/features/constants'
import { getNewTime } from '@/lib/helpers'

interface Props {
  defaultValue?: string
  className?: string
  control: Control<FieldValues>
  name: `${string}`
  required?: boolean
  allowClear?: boolean
  disabledDate?: (currentDate: any) => boolean
  disabled?: boolean
  onChange?: (time: string) => void
}

export const TimeField = (props: Props) => {
  const { className, defaultValue, control, name } = props

  const timePickerOption: ISelectOption[] = getTimeOptions()
  const timePickerOptionTexts = timePickerOption.map((option) => option.value)
  const timeIndex = defaultValue
    ? timePickerOptionTexts.indexOf(defaultValue)
    : -1
  const _defaultValue = defaultValue
    ? timeIndex === -1
      ? { label: defaultValue, value: defaultValue }
      : timePickerOption[timeIndex]
    : null

  return (
    <CreatableSelect
      options={timePickerOption}
      formatCreateLabel={getNewTime}
      formatCreateValue={getNewTime}
      control={control}
      name={name}
      defaultValue={_defaultValue}
      className={className}
    />
  )
}
