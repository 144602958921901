import React, { createRef, useEffect, useState } from 'react'

import classnames from 'classnames'
import { default as WebcamType } from 'react-webcam'

import {
  Button,
  ImageField,
  RadioButtons,
  RadioButtonsOnChangeOption,
  Webcam,
} from '..'
import styles from './ImageFieldWithWebcam.module.scss'
import { Camera, PaperClip, RotateCcw } from '@/components/icons'

import { t as translateFunction } from '@/lib/helpers'

type Props = {
  title?: string
  image?: File
  defaultImagePath?: string
  setImage: (image?: File) => void
  setDisabled?: (disabled: boolean) => void
  required?: boolean
  className?: string
  withCountdown?: boolean
  withSubmitButton?: boolean
  t?: any
  handleSubmit?: () => void
}

export const ImageFieldWithWebcam = (props: Props) => {
  const {
    title,
    image,
    defaultImagePath,
    setImage,
    setDisabled = (_disabled: boolean) => {},
    required,
    className,
    withCountdown,
    withSubmitButton,
    t = translateFunction,
    handleSubmit,
  } = props
  const [type, setType] = useState<'webcam' | 'upload'>(
    defaultImagePath ? 'upload' : 'webcam',
  )

  const onChangeOptions: RadioButtonsOnChangeOption[] = [
    {
      iconComponent: <Camera className={styles.icon} />,
      label: t('views.components.image_field_with_webcam.take_photo'),
      checked: type === 'webcam',
      onChange: () => {
        setType('webcam')
        setDisabled(!imgSrc)
      },
    },
    {
      iconComponent: <PaperClip className={styles.icon} />,
      label: t('views.components.image_field_with_webcam.upload_photo'),
      checked: type === 'upload',
      onChange: () => {
        setType('upload')
        setDisabled(!image)
      },
    },
  ]

  const [imgSrc, setImgSrc] = useState<string>()
  const [isCapture, setIsCapture] = useState<boolean>(false)
  const webcamRef = createRef<WebcamType>()
  const [showCountdown, setShowCountdown] = useState<boolean>(false)
  const [countdown, setCountdown] = useState<number>(5)

  const handleUpload = (file: File) => {
    setImage(file)
    setDisabled(false)
  }

  const handleCancelUpload = () => {
    setImage(undefined)
    setDisabled(true)
  }

  const manageImage = (dataUrl: string | null | undefined) => {
    if (dataUrl) {
      setImgSrc(dataUrl)
      fetch(dataUrl)
        .then(function (res) {
          return res.arrayBuffer()
        })
        .then(function (buf) {
          setImage(new File([buf], 'image.jpg', { type: 'image/jpeg' }))
          setDisabled(false)
        })
    }
  }

  let timeout: NodeJS.Timeout
  useEffect(() => {
    if (isCapture) {
      manageImage(webcamRef.current?.getScreenshot())
      setIsCapture(false)
    }
    if (withCountdown && showCountdown) {
      if (countdown > 0) {
        timeout = setTimeout(() => setCountdown(countdown - 1), 1000)
      } else {
        setShowCountdown(false)
        setIsCapture(true)
        setTimeout(() => setIsCapture(false), 500)
        manageImage(webcamRef.current?.getScreenshot())
      }
      return () => clearTimeout(timeout)
    }
  }, [showCountdown, countdown, isCapture])

  let field

  const handleRecapture = () => {
    setImgSrc(undefined)
    setDisabled(true)
    setCountdown(5)
  }

  switch (type) {
    case 'webcam':
      field = (
        <div className={styles.webcamButtons}>
          <div
            className={classnames(styles.webcam, {
              [styles.capture]: isCapture,
            })}
          >
            <Webcam
              imgSrc={imgSrc}
              ref={webcamRef}
              showSwitchFacingMode
              facingMode={'environment'}
              showCountdown={withCountdown && showCountdown}
              setShowCountdown={setShowCountdown}
              countdown={countdown}
            />
          </div>
          <div className={styles.buttons}>
            {imgSrc && (
              <>
                <Button
                  text={t('views.components.image_field_with_webcam.recapture')}
                  iconComponent={<RotateCcw className={styles.mr8} />}
                  onClick={handleRecapture}
                  style={'Secondary'}
                  className={styles.button}
                />
                {withSubmitButton && (
                  <Button
                    type={handleSubmit ? 'button' : 'submit'}
                    onClick={handleSubmit}
                    text={t('views.misc.confirm')}
                    className={classnames(
                      styles.recaptureSubmit,
                      styles.button,
                    )}
                  />
                )}
              </>
            )}
            {!imgSrc && (
              <Button
                text={t('views.components.image_field_with_webcam.capture')}
                onClick={() => setIsCapture(true)}
                className={styles.button}
              />
            )}
          </div>
        </div>
      )
      break
    case 'upload':
      field = (
        <>
          <ImageField
            required={required}
            onUpload={handleUpload}
            onCancel={handleCancelUpload}
            defaultImagePath={defaultImagePath}
            placeholder={''}
          />
          {(image || defaultImagePath) && withSubmitButton && (
            <Button
              type={handleSubmit ? 'button' : 'submit'}
              onClick={handleSubmit}
              text={t('views.misc.confirm')}
              className={classnames(styles.webcamSubmit, styles.button)}
            />
          )}
        </>
      )
      break
  }

  return (
    <div className={className}>
      <div className={styles.title}>
        {title}
        {required && <span className={styles.requiredTitle}>*</span>}
      </div>
      <RadioButtons
        onChangeOptions={onChangeOptions}
        horizontal
        className={styles.radioButtons}
      />
      <div className={styles.imageZone}>{field}</div>
    </div>
  )
}
