import React from 'react'

type Props = {
  useCurrentColor?: boolean
  className?: string
}

export const UserIcon = (props: Props) => {
  const { useCurrentColor = true, className } = props
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={useCurrentColor ? 'currentColor' || '#3873e4' : ''}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M10 9.16667C11.841 9.16667 13.3334 7.67428 13.3334 5.83333C13.3334 3.99238 11.841 2.5 10 2.5C8.15907 2.5 6.66669 3.99238 6.66669 5.83333C6.66669 7.67428 8.15907 9.16667 10 9.16667Z" />
      <path d="M15.8334 14.1667V15C15.8314 15.6624 15.5673 16.2972 15.0989 16.7656C14.6305 17.234 13.9958 17.498 13.3334 17.5H6.66669C6.00425 17.498 5.36952 17.234 4.90111 16.7656C4.43269 16.2972 4.16867 15.6624 4.16669 15V14.1667C4.16669 13.0616 4.60567 12.0018 5.38708 11.2204C6.16848 10.439 7.22829 10 8.33335 10H11.6667C12.7718 10 13.8316 10.439 14.613 11.2204C15.3944 12.0018 15.8334 13.0616 15.8334 14.1667Z" />
    </svg>
  )
}
