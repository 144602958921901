import React from 'react'

type Props = {
  useCurrentColor?: boolean
  className?: string
}

export const AnyVisitIcon = (props: Props) => {
  const { useCurrentColor = true, className } = props
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={useCurrentColor ? 'currentColor' || '#3873e4' : ''}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.882 2.95827L8.04114 2.18384V16.8428L14.882 16.0679V2.95827ZM8.69619 9.06924C8.60846 9.20065 8.56168 9.35513 8.56176 9.51314C8.56176 9.72487 8.64587 9.92792 8.79558 10.0776C8.94529 10.2273 9.14834 10.3114 9.36007 10.3114C9.51808 10.3115 9.67256 10.2647 9.80397 10.177C9.93539 10.0893 10.0378 9.96454 10.0983 9.81858C10.1588 9.67261 10.1747 9.51198 10.1439 9.357C10.1131 9.20203 10.037 9.05967 9.92527 8.94794C9.81354 8.83621 9.67118 8.76013 9.51621 8.72932C9.36123 8.69852 9.2006 8.71437 9.05463 8.77488C8.90867 8.83538 8.78393 8.93782 8.69619 9.06924ZM9.14228 9.65892C9.11352 9.61575 9.09821 9.56502 9.09829 9.51314C9.09829 9.47873 9.10508 9.44466 9.11826 9.41287C9.13144 9.38108 9.15076 9.3522 9.17511 9.32789C9.19946 9.30358 9.22837 9.2843 9.26018 9.27117C9.29199 9.25804 9.32607 9.25131 9.36049 9.25137C9.41236 9.25137 9.46307 9.26676 9.5062 9.29558C9.54932 9.32441 9.58293 9.36539 9.60276 9.41332C9.62259 9.46125 9.62776 9.51399 9.61761 9.56487C9.60746 9.61574 9.58245 9.66246 9.54574 9.69911C9.50902 9.73576 9.46227 9.7607 9.41138 9.77077C9.36049 9.78084 9.30776 9.77558 9.25986 9.75568C9.21196 9.73577 9.17104 9.7021 9.14228 9.65892Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2557 15.9922H17.3457C17.3818 15.9907 17.4179 15.9966 17.4517 16.0094C17.4854 16.0223 17.5163 16.0418 17.5424 16.0669C17.5684 16.0919 17.5892 16.122 17.6033 16.1552C17.6175 16.1885 17.6248 16.2243 17.6248 16.2604C17.6248 16.2966 17.6175 16.3323 17.6033 16.3656C17.5892 16.3989 17.5684 16.4289 17.5424 16.454C17.5163 16.479 17.4854 16.4986 17.4517 16.5114C17.4179 16.5242 17.3818 16.5301 17.3457 16.5287H15.0027L7.96623 17.3245C7.95591 17.3251 7.94556 17.3251 7.93523 17.3245C7.8641 17.3244 7.79592 17.296 7.74566 17.2457C7.6954 17.1954 7.66718 17.1271 7.66718 17.056V16.5287H2.25787C2.18856 16.5259 2.123 16.4965 2.07492 16.4465C2.02685 16.3965 2 16.3298 2 16.2604C2 16.1911 2.02685 16.1244 2.07492 16.0744C2.123 16.0244 2.18856 15.9949 2.25787 15.9922H4.3462V2.76565C4.34631 2.6948 4.37442 2.62688 4.42439 2.57667C4.47437 2.52645 4.54216 2.49803 4.613 2.49759H7.66592V1.97027C7.66566 1.93221 7.67349 1.89453 7.68891 1.85972C7.70432 1.82492 7.72695 1.79379 7.75531 1.7684C7.78367 1.74301 7.81711 1.72394 7.8534 1.71246C7.88969 1.70098 7.92801 1.69734 7.96581 1.7018L15.0174 2.49759C15.0829 2.50499 15.1434 2.53623 15.1873 2.58536C15.2313 2.63448 15.2556 2.69806 15.2557 2.76397V15.9922ZM7.6676 3.03412H4.88274V15.9922H5.57256V3.99201C5.57267 3.92095 5.60095 3.85283 5.6512 3.80258C5.70144 3.75234 5.76956 3.72406 5.84062 3.72395H7.6676V3.03412ZM6.1091 15.9922V4.26048H7.6676V15.9922H6.1091ZM14.7192 3.00815L8.20413 2.27016V16.7565L14.7192 16.0211V3.00815Z"
      />
    </svg>
  )
}
