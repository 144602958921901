import React, { useState } from 'react'

import classnames from 'classnames'
import { useForm } from 'react-hook-form'

import { CheckboxTermsPolicy } from '../../components'
import { VisitorLayout } from '../../layout'
import styles from './ConfirmationPage.module.scss'
import { Vaccinated } from '@/components/icons'
import {
  Button,
  ScreeningShield,
  VisitorDetail,
  Link,
  InfoSection,
  EyeSwitch,
} from '@/components/shared'

import { vaccinesPath } from '@/features/constants'
import {
  CovidScreening,
  CovidScreeningType,
  Invitation,
  InvitationStatus,
  RiskAssessment,
  Visitor,
} from '@/features/types'
import {
  axios,
  formatDate,
  getLocaleDateString,
  getLocaleTimeString,
  t,
} from '@/lib/helpers'

import SuccessPage from '../SuccessPage'

type Props = {
  invitation: Invitation
  risk_assessment: RiskAssessment
  covid_screening: CovidScreening
  covid_screening_available_at: Date
  disabled_covid_screening: boolean
  qr_code: any
  required_vaccine_record: boolean
}

const visitorFormatDate = (start_date: string, end_date: string) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  }
  if (formatDate(start_date) === formatDate(end_date)) {
    return `${getLocaleDateString(
      start_date,
      dateOptions,
    )} - ${getLocaleTimeString(end_date, dateOptions)}`
  } else {
    return `${getLocaleDateString(
      start_date,
      dateOptions,
    )} - ${getLocaleDateString(end_date, dateOptions)}`
  }
}

const ConfirmationPage = (props: Props) => {
  const {
    invitation,
    risk_assessment,
    covid_screening,
    covid_screening_available_at,
    disabled_covid_screening,
    qr_code,
    required_vaccine_record = true,
  } = props
  const {
    slug,
    invite,
    visitor_slug,
    status,
    company_slug,
    show_personal_data,
    show_covid_screening,
    show_vaccine_record,
    company_visitor_policy_url,
  } = invitation

  const isConfirmInvitation = invitation.status !== InvitationStatus.not_confirm
  const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false)
  const [masking, setMasking] = useState<boolean>(false)
  const { vaccines } = risk_assessment
  const { register, watch } = useForm()

  if (showSuccessPage) {
    return <SuccessPage />
  }

  const handleConfirm = async () => {
    const response = await axios.patch(
      `/visitors/confirm?invitation_id=${slug}`,
      {},
    )
    if (response.status == 200) {
      setShowSuccessPage(true)
    }
  }

  const handleEdit = () => {
    if (status === InvitationStatus.not_confirm) {
      window.location.href = `/visitors/${visitor_slug}/schedule?invitation_id=${slug}`
    } else {
      window.location.href = `/visitors/${visitor_slug}/edit?invitation_id=${slug}`
    }
  }

  const handleEditVaccine = () => {
    window.location.href = `${vaccinesPath}/${visitor_slug}?invitation_id=${slug}`
  }

  const handleEditCovidScreening = () => {
    if (!covid_screening) return null

    window.location.href = `/covid_screenings/${covid_screening.slug}/edit?invitation_id=${slug}`
  }

  const covidButton = () => {
    if (invitation.covid_screening_type === CovidScreeningType.not_require)
      return null

    return (
      <div className={styles.covidButtonWrapper}>
        <Button
          text={t('views.features.visitor.covid_screening.selection.both')}
          style={'Secondary'}
          size={'L'}
          iconComponent={
            <ScreeningShield
              screeningStatus={covid_screening ? 'primary50' : 'disabled'}
            />
          }
          onClick={handleEditCovidScreening}
          disabled={!covid_screening || disabled_covid_screening}
          className={classnames(styles.button, styles.covidButton)}
        />
        {!covid_screening && (
          <div className={styles.covidScreeningDisabledText}>
            {new Date() <= new Date(covid_screening_available_at) && (
              <>
                {`${t(
                  'views.features.visitor.show.available_on',
                )} ${getLocaleDateString(covid_screening_available_at)}`}
              </>
            )}
          </div>
        )}
      </div>
    )
  }

  const vaccineAndCovidButton = () => (
    <div className={styles.vaccineAndCovidButton}>
      {required_vaccine_record && (
        <Button
          text={t('views.features.visitor.show.vaccination_record')}
          style={'Secondary'}
          size={'L'}
          iconComponent={
            <div className={styles.vaccinatedWrapper}>
              <Vaccinated className={styles.smallVaccinated} />
            </div>
          }
          onClick={handleEditVaccine}
          className={styles.button}
        />
      )}
      {covidButton()}
    </div>
  )

  const vaccineInformation = () => {
    let vaccinatedComponents = []

    if (vaccines.length > 0) {
      vaccinatedComponents = vaccines.map((vaccine, idx) =>
        vaccinatedComponent(idx + 1, vaccine.name, vaccine.date as Date),
      )
    } else {
      vaccinatedComponents = [
        <div key="1" className={styles.noVaccinationRecord}>
          {t('views.features.visitor.show.no_vaccination_record')}
        </div>,
      ]
    }
    return (
      <div>
        <div className={styles.vaccineRecord}>
          <Vaccinated className={styles.largeVaccinated} />
          <div className={styles.vaccineRecordText}>
            {t('views.features.visitor.show.vaccination_record')}
          </div>
        </div>
        {vaccinatedComponents}
      </div>
    )
  }

  const visitor: Visitor = {
    id: 0,
    first_name: invitation?.visitor_name || '',
    last_name: '',
    email: invitation?.visitor_email || '',
    image: {
      url: invitation?.visitor_image_url || '',
    },
    telephone: invitation?.visitor_telephone || '',
    slug: invitation?.visitor_slug || '',
  }

  const onlineLink = invitation.online_link && (
    <Link url={invitation.online_link} />
  )

  const showVaccineAndATK =
    isConfirmInvitation &&
    (risk_assessment?.image?.url || covid_screening?.atk_image?.url)

  return (
    <VisitorLayout
      textNextBtn={t('views.components.layout.visitor.confirm')}
      onClickNextBtn={isConfirmInvitation ? undefined : handleConfirm}
      showEditBtn
      onClickEditBtn={handleEdit}
      showBannerImage={false}
      logoUrl={invitation.logo_url}
      disabledNextButton={
        !isConfirmInvitation &&
        (watch('terms_policy') !== 'agree' ||
          (!!company_visitor_policy_url && watch('visitor_policy') !== 'agree'))
      }
      className={styles.visitorLayout}
    >
      <div className={styles.title}>{invite.title}</div>
      {!invitation.online_link && isConfirmInvitation && (
        <div className={styles.qrCodeWrapper}>
          <img
            src={`data:image/jpeg;base64,${qr_code}`}
            className={styles.qrCode}
          />
        </div>
      )}
      <div className={styles.inviteSection}>
        <div className={styles.information}>
          <span className={styles.informationText}>
            {t('views.features.visitor.show.information')}
          </span>
          <EyeSwitch checked={!masking} onClick={() => setMasking(!masking)} />
        </div>
        <InfoSection
          iconName={'pin'}
          masked={masking}
          value={invitation.area_to_access || onlineLink}
        />
        <InfoSection
          iconName={'calendar-fill'}
          value={visitorFormatDate(
            invitation.start_datetime,
            invitation.finish_datetime,
          )}
          masked={masking}
        />
        <InfoSection
          iconName={'user'}
          value={invitation.host_employee_name || '-'}
          masked={masking}
        />
        <div className={styles.comment}>{invite.comment}</div>
      </div>
      <div
        className={classnames(styles.visitorSection, {
          [styles.pb24]: showVaccineAndATK,
        })}
      >
        {show_personal_data && (
          <VisitorDetail
            visitor={visitor}
            imageSize={80}
            masked={masking}
            className={styles.visitorDetail}
          />
        )}
        {!invitation.online_link && (
          <>
            {isConfirmInvitation
              ? vaccineAndCovidButton()
              : required_vaccine_record && vaccineInformation()}
            <InfoSection
              iconName="suitcase"
              value={invitation.asset}
              masked={masking}
              className={styles.asset}
            />
          </>
        )}
        <InfoSection
          iconName="note"
          value={invitation.visitor_note}
          masked={masking}
          className={styles.asset}
        />
      </div>
      {!isConfirmInvitation && (
        <CheckboxTermsPolicy
          company_slug={company_slug}
          register={register}
          visitor_policy_url={company_visitor_policy_url}
        />
      )}
      {showVaccineAndATK && (
        <div className={styles.vaccineATKSection}>
          {show_vaccine_record && risk_assessment?.image?.url && (
            <div className={styles.vaccineATK}>
              <InfoSection
                iconName="vaccination-receive-slot"
                value={t('views.features.visitor.show.vaccination_record')}
              />
              <img
                src={risk_assessment.image.url}
                className={styles.vaccineATKImage}
              />
            </div>
          )}
          {show_covid_screening && covid_screening?.atk_image?.url && (
            <div className={styles.vaccineATK}>
              <InfoSection
                value={
                  <div className={styles.atkSection}>
                    <ScreeningShield
                      screeningStatus="primary"
                      className={styles.atkIcon}
                    />
                    {t('views.features.visitor.covid_screening.selection.atk')}
                  </div>
                }
              />
              <img
                src={covid_screening.atk_image.url}
                className={styles.vaccineATKImage}
              />
            </div>
          )}
        </div>
      )}
    </VisitorLayout>
  )
}

const vaccinatedComponent = (dose: number, name: string, date: Date) => {
  return (
    <div className={styles.vaccine} key={dose}>
      <div className={styles.doseText}>
        {t('views.features.visitor.edit.vaccinated.dose_order', {
          order: t('ordinalize', { count: dose }),
        })}
        :
      </div>
      <div className={styles.vaccineName}>{name}</div>
      <div className={styles.vaccineDate}>
        {getLocaleDateString(date, {
          day: '2-digit',
          month: '2-digit',
          year: '2-digit',
        })}
      </div>
    </div>
  )
}

export default ConfirmationPage
