import React from 'react'

import { useForm } from 'react-hook-form'

import {
  PasswordWithConfirm,
  UserLayout,
  validatePassword,
  showToast,
  userLayoutStyles,
} from '@/components/shared'

import { employeesServices } from '../../services'
import { t } from '@/lib/helpers'

const EMPLOYEE_CHANGE_PASSWORD_FORM = 'employee_change_password_form'

const ChangePasswordPage = () => {
  const { register, watch, handleSubmit } = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  })

  const _handleSubmit = (data: any) => {
    if (!validatePassword(data.password)) {
      return showToast({
        type: 'alert',
        message: t('views.components.password_complexity.invalid'),
      })
    } else if (data.password !== data.confirmPassword) {
      return showToast({
        type: 'alert',
        message: t('views.components.password_complexity.password_not_match'),
      })
    }

    const url = new URL(window.location.href)
    const token = url.searchParams.get('reset_password_token') || ''
    employeesServices().changePassword(data.password, token)
  }

  return (
    <UserLayout
      form={EMPLOYEE_CHANGE_PASSWORD_FORM}
      textBtn={t('views.features.devise.forgot_password.set_new_password')}
      showBackToSignInPath
    >
      <div className={userLayoutStyles.title}>
        {t('views.features.devise.forgot_password.enter_new_password')}
      </div>
      <form
        id={EMPLOYEE_CHANGE_PASSWORD_FORM}
        onSubmit={handleSubmit(_handleSubmit)}
        className={userLayoutStyles.formSection}
      >
        <PasswordWithConfirm register={register} watch={watch} />
      </form>
    </UserLayout>
  )
}

export default ChangePasswordPage
