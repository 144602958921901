import React from 'react'

import {
  ApplicationLayout,
  ConfirmModal,
  IntegrationConnection,
  IntegrationConnectionProps,
} from '..'
import styles from './IntegrationLayout.module.scss'

import { Employee } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  employee: Employee
  title: string
  sectionHeader: string
  sectionDescription: string
  titleModal: string
  descriptionModal: string
  showModal: boolean
  onCloseModal: () => void
  onConfirmModal: () => void
  integrationConnections: IntegrationConnectionProps[]
  className?: string
}

export const IntegrationLayout = (props: Props) => {
  const {
    employee,
    title,
    sectionHeader,
    sectionDescription,
    titleModal,
    descriptionModal,
    showModal,
    onCloseModal,
    onConfirmModal,
    integrationConnections,
    className,
  } = props

  return (
    <ApplicationLayout employee={employee} className={className}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{title}</div>
        <div className={styles.sectionBox}>
          <div className={styles.section}>
            <div className={styles.sectionHeader}>{sectionHeader}</div>
            <div className={styles.sectionDescription}>
              {sectionDescription}
            </div>
            <div className={styles.connectionBox}>
              <div className={styles.connection}>
                {integrationConnections.map((connection, idx) => (
                  <div key={connection.logoName}>
                    <IntegrationConnection
                      logoName={connection.logoName}
                      title={connection.title}
                      description={connection.description}
                      email={connection.email}
                      onConnect={connection.onConnect}
                      onDisconnect={connection.onDisconnect}
                    />
                    {idx !== integrationConnections.length - 1 && (
                      <hr className={styles.line} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmModal
        show={showModal}
        onClose={onCloseModal}
        onConfirm={onConfirmModal}
        title={titleModal}
        description={descriptionModal}
        confirmText={t('views.misc.disconnect')}
        type={'Delete'}
      />
    </ApplicationLayout>
  )
}
