import React from 'react'

import { EventInput } from '@fullcalendar/core'
import moment from 'moment'
import Select from 'react-select'

import { Button, ISelectOption } from '..'
import styles from './DateTimeRangeField.module.scss'
import { Plus } from '@/components/icons'

import { getTimeOptions, MINIMUM_SCHEDULE_SLOTS } from '@/features/constants'
import { Company } from '@/features/types'
import {
  addTime,
  formatDate,
  formatHourAndMinutes,
  getTimeIndex,
  t,
} from '@/lib/helpers'

import { DateWithTimeRange } from './DateWithTimeRange'

type Props = {
  duration: number | string
  setDuration: (duration: number | string) => void
  events: EventInput[]
  setEvents: (events: EventInput[]) => void
  company: Company
}

const sameDate = (d1: any, d2: any) => {
  return moment(d1).isSame(moment(d2), 'day')
}

const selectStyle = {
  placeholder: (provided: any) => ({
    ...provided,
    color: '#d1d1d1',
    marginLeft: '2px',
    marginRight: '2px',
  }),
  indicatorSeparator: () => ({
    backgroundColor: 'unset',
  }),
  control: (provided: any) => ({
    ...provided,
    borderColor: '#e8e8e8',
    borderRadius: '8px',
    height: '48px',
  }),
  option: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    textAlign: 'left',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingLeft: '8px',
    paddingRight: '8px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    transform: 'translateY(1px)',
  }),
}

export const ScheduleMode = (props: Props) => {
  const { duration, setDuration, events, setEvents, company } = props

  const durationOptions = [
    {
      label: `15 ${t('views.misc.mins')}`,
      value: 15,
    },
    {
      label: `30 ${t('views.misc.mins')}`,
      value: 30,
    },
    {
      label: `45 ${t('views.misc.mins')}`,
      value: 45,
    },
    {
      label: `1 ${t('views.misc.hour')}`,
      value: 60,
    },
    {
      label: `1 ${t('views.misc.hour')} 30 ${t('views.misc.mins')}`,
      value: 90,
    },
    {
      label: `2 ${t('views.misc.hours')}`,
      value: 120,
    },
    {
      label: `2 ${t('views.misc.hours')} 30 ${t('views.misc.mins')}`,
      value: 150,
    },
    {
      label: `3 ${t('views.misc.hours')}`,
      value: 180,
    },
    {
      label: `3 ${t('views.misc.hours')} 30 ${t('views.misc.mins')}`,
      value: 210,
    },
    {
      label: `4 ${t('views.misc.hours')}`,
      value: 240,
    },
    {
      label: `5 ${t('views.misc.hours')}`,
      value: 300,
    },
    {
      label: t('views.misc.all_day'),
      value: 'allDay',
    },
  ]

  const defaultDurationIndex = durationOptions.findIndex(
    (option) => option.value === duration,
  )

  const onChangeEvent = (event: EventInput, oldDate?: string) => {
    if (!oldDate) {
      const eventIndex = events.findIndex(
        (object: EventInput) => object.id === event.id,
      )
      const newEvents = [...events]
      newEvents[eventIndex] = event
      setEvents(newEvents)
    } else {
      const newDate = moment(event.start).format('L')
      const newEvents = events.reduce((prev: EventInput[], cur: EventInput) => {
        if (sameDate(cur.start, oldDate)) {
          cur.start = moment(
            `${newDate} ${moment(cur.start).format('LT')}`,
          ).toDate()
          cur.end = moment(
            `${newDate} ${moment(cur.end).format('LT')}`,
          ).toDate()
        }
        prev.push(cur)
        return prev
      }, [])
      setEvents(newEvents)
    }
  }

  const onDelete = (event: EventInput) => {
    const newEvents = events.filter(
      (object: EventInput) => object.id !== event.id,
    )
    setEvents(newEvents)
  }

  const formatCompanyStartTime = (date: Date) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return formatHourAndMinutes(
        company.open_hour_weekend,
        company.open_minutes_weekend,
      )
    }
    return formatHourAndMinutes(company.open_hour, company.open_minutes)
  }

  const formatCompanyEndTime = (date: Date) => {
    if (date.getDay() === 0 || date.getDay() === 6) {
      return formatHourAndMinutes(
        company.close_hour_weekend,
        company.close_minutes_weekend,
      )
    }
    return formatHourAndMinutes(company.close_hour, company.close_minutes)
  }

  const onChangeDuration = (newOption: any) => {
    const newDuration = newOption.value
    let newEvents
    if (newDuration === 'allDay') {
      newEvents = events.map((event: EventInput) => {
        const startDate = new Date(event.start as string)
        const companyStartTime = formatCompanyStartTime(startDate)
        const companyEndTime = formatCompanyEndTime(startDate)
        return {
          ...event,
          start: moment(
            `${moment(event.start).format('L')} ${companyStartTime}`,
          ).toDate(),
          end: moment(
            `${moment(event.start).format('L')} ${companyEndTime}`,
          ).toDate(),
          className: event.className,
        }
      })
    } else {
      newEvents = events.map((event: EventInput) => ({
        ...event,
        end: moment(event.start).add(newDuration, 'minutes').toDate(),
        className: `${
          newDuration < 30
            ? `${event.className} slot-15`
            : event.className?.toString().replaceAll('slot-15', '')
        }`,
      }))
    }
    setEvents(newEvents)
    setDuration(newDuration)
  }

  const nextDate = (date: Date) => {
    /// if Friday and close weekend -> return Monday
    if (date.getDay() === 5 && !company.open_on_weekend) {
      return date.getDate() + 3
    }
    return date.getDate() + 1
  }

  const onAddSlot = () => {
    let lastEvent = events[events.length - 1]
    if (!lastEvent || !lastEvent.end) {
      lastEvent = {
        end: new Date(),
      }
    }
    const timeOptions: ISelectOption[] = getTimeOptions()
    let newFromTime = timeOptions[getTimeIndex(lastEvent.end as Date)]?.value
    const addedTime = addTime(lastEvent.end as Date, duration as number)
    let newToTime = timeOptions[getTimeIndex(addedTime)]?.value
    let newDate = new Date(lastEvent.end as string)
    if (duration === 'allDay') {
      newDate.setDate(nextDate(newDate))
      newFromTime = formatCompanyStartTime(newDate)
      newToTime = formatCompanyEndTime(newDate)
    } else if (!sameDate(lastEvent.end, addedTime)) {
      newDate.setDate(nextDate(newDate))
      newFromTime = timeOptions[0].value
      newToTime = timeOptions[(duration as number) / 15].value
    }
    const newEvent: EventInput = {
      id: `addedEvent${formatDate(newDate, 'D MMM YYYY')} ${newFromTime}`,
      start: new Date(`${formatDate(newDate, 'D MMM YYYY')} ${newFromTime}`),
      end: new Date(`${formatDate(newDate, 'D MMM YYYY')} ${newToTime}`),
      className: 'new-invite select-slot',
    }
    setEvents([...events, newEvent])
  }

  const disabledDate = (current: any) =>
    current && current < moment().subtract(1, 'days').endOf('day')

  return (
    <>
      <div>
        <div className={styles.durationText}>
          {t('views.components.date_time_range_field.duration_of_event')}
        </div>
        <Select
          options={durationOptions}
          defaultValue={durationOptions[defaultDurationIndex]}
          name="duration"
          menuPlacement="top"
          styles={selectStyle}
          onChange={onChangeDuration}
        />
      </div>
      <div className={styles.timeSlots}>
        <div>{t('views.components.date_time_range_field.time_slots')}</div>
        <div className={styles.timeSlotsDescription}>
          {t(
            'views.components.date_time_range_field.minimum_slots_description',
            { minimum_slot: MINIMUM_SCHEDULE_SLOTS },
          )}
        </div>
        <div>
          {events.map((event: EventInput, idx: number) => {
            const showDate =
              idx === 0 ? true : !sameDate(events[idx - 1].start, event.start)
            return (
              <DateWithTimeRange
                key={event.id}
                event={event}
                onChange={onChangeEvent}
                onDelete={onDelete}
                showDate={showDate}
                duration={duration}
                disabledDate={disabledDate}
              />
            )
          })}
        </div>
        <Button
          text={t('views.components.date_time_range_field.add_time_slots')}
          onClick={onAddSlot}
          iconComponent={<Plus />}
          style={'Tertiary'}
          className={styles.addSlotButton}
        />
      </div>
    </>
  )
}
