import React from 'react'

import classnames from 'classnames'
import { FieldValues, UseFormRegister, UseFormWatch } from 'react-hook-form'

import styles from './SettingSections.module.scss'
import { showToast, TextField } from '@/components/shared'

import { t } from '@/lib/helpers'

type Props = {
  handleUpdateFields: () => void
  watch: UseFormWatch<FieldValues>
  register: UseFormRegister<FieldValues>
}

export const TermsPolicySettingSection = (props: Props) => {
  const { handleUpdateFields, watch, register } = props

  const validateUrls = (url: string) => {
    const regExUrl = /^https?:\/\/.*$/
    if (url.match(regExUrl) === null) return false
    else return true
  }

  const handleChangeUrls = (
    name:
      | 'general.terms_url'
      | 'general.policy_url'
      | 'general.visitor_policy_url',
  ) => {
    const url = watch(name)
    if (validateUrls(url)) {
      handleUpdateFields()
    } else {
      showToast({
        type: 'alert',
        message: t('views.features.company_setting.invalid_url'),
      })
    }
  }

  return (
    <div className={classnames(styles.section, styles.mt16)}>
      <span className={styles.title}>
        {t('views.features.company_setting.general.terms_and_policy')}
      </span>
      <div className={styles.formSection}>
        <div className={styles.form}>
          <TextField
            type={'url'}
            title={t('views.features.company_setting.general.terms_url')}
            placeholder={t(
              'views.features.company_setting.general.terms_url_placeholder',
            )}
            name={'general.terms_url'}
            register={register}
            onBlur={() => handleChangeUrls('general.terms_url')}
            className={styles.mt16}
          />
          <TextField
            type={'url'}
            title={t('views.features.company_setting.general.policy_url')}
            placeholder={t(
              'views.features.company_setting.general.policy_url_placeholder',
            )}
            name={'general.policy_url'}
            register={register}
            onBlur={() => handleChangeUrls('general.policy_url')}
            className={styles.mt16}
          />
          <TextField
            type={'url'}
            title={t(
              'views.features.company_setting.general.visitor_policy_url',
            )}
            placeholder={t(
              'views.features.company_setting.general.visitor_policy_url_placeholder',
            )}
            name={'general.visitor_policy_url'}
            register={register}
            onBlur={() => handleChangeUrls('general.visitor_policy_url')}
            className={styles.mt16}
          />
        </div>
      </div>
    </div>
  )
}
