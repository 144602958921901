import React from 'react'

import styles from './ErrorsPage.module.scss'
import { ApplicationLayout, Button } from '@/components/shared'

type ErrorType =
  | 'expired'
  | 'invalid'
  | 'internal_server_error'
  | 'unprocessable_entity'

type Props = {
  company_logo_url?: string
  type?: ErrorType
  showBackToMainBtn?: boolean
}

const getTitle = (type: ErrorType) => {
  switch (type) {
    case 'expired':
      return 'Your link has been expired.'
    case 'invalid':
      return 'Your link is invalid.'
    case 'unprocessable_entity':
      return (
        <>
          The change you wanted
          <br />
          was rejected.
        </>
      )
    default:
      return (
        <>
          We&apos;re sorry,
          <br />
          but something went wrong.
        </>
      )
  }
}

const getDescription = (type: ErrorType) => {
  switch (type) {
    case 'expired':
      return 'เนื่องจากลิงก์นี้มีการกำหนดอายุ'
    case 'invalid':
      return 'เนื่องจากลิงก์นี้ไม่ถูกต้อง'
    default:
      return 'เนื่องจากขณะนี้เกิดปัญหาขัดข้อง'
  }
}

const ErrorsPage = (props: Props) => {
  const {
    company_logo_url,
    type = 'invalid',
    showBackToMainBtn = false,
  } = props

  return (
    <ApplicationLayout walkIn logoUrl={company_logo_url}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{getTitle(type)}</div>
        <div className={styles.description}>
          {getDescription(type)} กรุณาติดต่อผู้ดูแลของคุณ
        </div>
        <img src={'/assets/images/empty-state.svg'} />
        {showBackToMainBtn && (
          <Button
            text={'กลับสู่หน้าหลัก'}
            size={'L'}
            onClick={() => (window.location.href = '/')}
            className={styles.btn}
          />
        )}
      </div>
    </ApplicationLayout>
  )
}

export default ErrorsPage
