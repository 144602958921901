import React, { ReactNode } from 'react'

import classnames from 'classnames'

import styles from './Users.module.scss'
import { Pencil, Trash } from '@/components/icons'
import { ImagePreview } from '@/components/shared'

import { Employee, EmployeeStatus, SystemAdmin } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  user: Employee | SystemAdmin
  onClickEdit?: (
    id: string,
    name: string,
    email: string,
    role: string,
    status: EmployeeStatus,
    imageURL?: string,
  ) => void
  onClickDelete?: (id: string, name: string) => void
  isUser?: boolean
  statusComponent?: ReactNode
  isSystemAdmin?: boolean
}

export const User = (props: Props) => {
  const {
    user,
    isUser = false,
    onClickEdit,
    onClickDelete,
    statusComponent,
    isSystemAdmin = false,
  } = props

  const { id, image, first_name, last_name, email, status } = user
  const name = `${first_name} ${last_name}`
  const type = (user as Employee).type || ''

  const handleClickEdit = () => {
    return onClickEdit?.(id.toString(), name, email, type, status, image?.url)
  }

  const handleClickDelete = () => {
    return onClickDelete?.(id.toString(), name)
  }

  return (
    <tr key={id} className={styles.hoverAndShowIcon}>
      <td className={styles.employee}>
        <ImagePreview
          src={image?.url || '/assets/icons/empty-display-image.svg'}
          width={60}
          height={60}
        />
        <div className={styles.employeeName}>
          {name}
          {isUser && <div className={styles.textBlue}>(You)</div>}
        </div>
      </td>
      <td className={classnames(styles.textEllipsis, styles.email)}>{email}</td>
      {!isSystemAdmin && (
        <td className={classnames(styles.textEllipsis, styles.role)}>
          {t(`views.misc.${type.toLowerCase()}`)}
        </td>
      )}
      <td className={classnames(styles.textEllipsis, styles.status)}>
        {statusComponent}
      </td>
      <td className={styles.editAndDelete}>
        <div className={styles.toolsBox}>
          <div
            className={classnames(styles.iconWrapper, styles.pencil, {
              [styles.disabled]: !onClickEdit,
            })}
            onClick={handleClickEdit}
          >
            <Pencil />
          </div>
          <div
            className={classnames(styles.iconWrapper, styles.trash, {
              [styles.disabled]: !onClickDelete,
            })}
            onClick={handleClickDelete}
          >
            <Trash />
          </div>
        </div>
      </td>
    </tr>
  )
}
