import {
  errorsNotFoundPath,
  errorsUnprocessableEntityPath,
  passwordPath,
  rootPath,
} from '@/features/constants'
import { axios } from '@/lib/helpers'

export const employeesServices = () => {
  return {
    changePassword: async (password: string, reset_password_token: string) => {
      try {
        await axios.patch(passwordPath, {
          employee: {
            reset_password_token,
            password,
          },
        })
        window.location.href = rootPath
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          switch (err.response.status) {
            case 404:
              return (window.location.href = errorsNotFoundPath)
            case 422:
              return (window.location.href = errorsUnprocessableEntityPath)
            default:
              return window.location.reload()
          }
        }
      }
    },
  }
}
