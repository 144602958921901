import React from 'react'

import classnames from 'classnames'

import { Button } from '..'
import styles from './LocaleButtons.module.scss'

type LocaleButtonsSize = 'S' | 'L'

type Props = {
  locale: PlatformLanguage
  onClickLocale: (PlatformLanguage: PlatformLanguage) => void
  size?: LocaleButtonsSize
}

export const LocaleButtons = (props: Props) => {
  const { locale, onClickLocale, size = 'L' } = props
  return (
    <div className={styles.langBtns}>
      <div className={styles.thLangBtn}>
        <Button
          text={size === 'L' ? 'Thai' : 'TH'}
          icon={'/assets/icons/thailand.svg'}
          size={'L'}
          onClick={() => onClickLocale('th')}
          className={classnames(styles.langBtn, {
            [styles.smallBtn]: size === 'S',
            [styles.selectedLangBtn]: locale === 'th',
          })}
        />
      </div>
      <div className={styles.enLangBtn}>
        <Button
          text={size === 'L' ? 'English' : 'EN'}
          icon={'/assets/icons/united-kingdom.svg'}
          size={'L'}
          onClick={() => onClickLocale('en')}
          className={classnames(styles.langBtn, {
            [styles.smallBtn]: size === 'S',
            [styles.selectedLangBtn]: locale === 'en',
          })}
        />
      </div>
    </div>
  )
}
