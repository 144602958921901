import React from 'react'

import { useForm } from 'react-hook-form'

import { TextField, UserLayout, userLayoutStyles } from '@/components/shared'

import { passwordPath, checkMailPath } from '@/features/constants'
import { axios, t } from '@/lib/helpers'

type Props = {}

const employeeForgotPasswordForm = 'employeeForgotPasswordForm'

const ForgotPasswordPage = (props: Props) => {
  const { handleSubmit, register } = useForm()
  const _handleSubmit = async (data: any) => {
    const { email } = data
    axios
      .post(passwordPath, { employee: { email } })
      .then((res) => {
        if (res.status === 200)
          window.location.href = `${checkMailPath}?email=${encodeURIComponent(
            email,
          )}`
      })
      .catch((err) => {
        if (err.response.status === 400) window.location.reload()
      })
  }

  return (
    <UserLayout
      form={employeeForgotPasswordForm}
      textBtn={t('views.features.devise.forgot_password.recover_password')}
      showSignInPath
    >
      <div className={userLayoutStyles.title}>
        <p>{t('views.features.devise.forgot_password.oops')}</p>
        <p>{t('views.features.devise.forgot_password.are_you_forgot')}</p>
      </div>
      <div className={userLayoutStyles.detail}>
        {t('views.features.devise.forgot_password.form_detail')}
      </div>
      <form
        id={employeeForgotPasswordForm}
        onSubmit={handleSubmit(_handleSubmit)}
      >
        <TextField
          legendTitle={t('views.features.devise.forgot_password.email_title')}
          type={'email'}
          name={'email'}
          register={register}
          required
          autoComplete={'email'}
          className={userLayoutStyles.field}
        />
      </form>
    </UserLayout>
  )
}

export default ForgotPasswordPage
