import React from 'react'

import classnames from 'classnames'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { MenuPlacement, StylesConfig } from 'react-select'
import Creatable from 'react-select/creatable'

import { ISelectOption } from '..'
import styles from './CreatableSelectTime.module.scss'

import { getTimeOptions } from '@/features/constants'
import { formatDate, getLocaleTimeString, getNewTime } from '@/lib/helpers'

dayjs.extend(customParseFormat)

type Props = {
  menuPlacement?: MenuPlacement
  option: ISelectOption
  onChange: (newOption: ISelectOption) => void
  className?: string
}

const timeStyles: StylesConfig<ISelectOption, false> = {
  control: (provided) => ({
    ...provided,
    height: '100%',
    border: '1px solid #e8e8e8',
    borderRadius: '8px',
  }),
  valueContainer: (provided) => ({
    ...provided,
    justifyContent: 'center',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided) => ({
    ...provided,
    textAlign: 'center',
  }),
}

export const CreatableSelectTime = (props: Props) => {
  const { menuPlacement, option, onChange, className } = props
  const timePickerOption: ISelectOption[] = getTimeOptions()
  const timePickerOptionTexts = timePickerOption.map((option) => option.value)
  const valueIndex = timePickerOptionTexts.indexOf(option.value)
  const optionDate = dayjs(option.value, 'hh:mm A').toDate()
  const value =
    valueIndex === -1
      ? { label: getLocaleTimeString(optionDate), value: option.value }
      : timePickerOption[valueIndex]
  return (
    <Creatable
      options={timePickerOption}
      formatCreateLabel={getNewTime}
      value={value}
      onCreateOption={(inputValue: string) => {
        const newTimeString = getNewTime(inputValue)
        let newTime = dayjs(newTimeString, 'hh:mm A')
        if (!newTime.isValid()) {
          newTime = dayjs(newTimeString, 'HH:mm')
        }
        onChange({ label: newTime, value: formatDate(newTime, 'hh:mm A') })
      }}
      onChange={(newOption: any) => onChange(newOption)}
      styles={timeStyles}
      menuPlacement={menuPlacement}
      className={classnames(styles.timePicker, className)}
    />
  )
}
