// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Toaster-module__toast___1F6jA{background-color:unset;box-shadow:unset;height:56px}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/toast/Toaster/Toaster.module.scss"],"names":[],"mappings":"AAAA,+BACE,sBAAA,CACA,gBAAA,CACA,WAAA","file":"Toaster.module.scss","sourcesContent":[":local(.toast) {\n  background-color: unset;\n  box-shadow: unset;\n  height: 56px;\n}\n"]}]);
// Exports
exports.locals = {
	"toast": "Toaster-module__toast___1F6jA"
};
module.exports = exports;
