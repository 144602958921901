import React from 'react'

import classnames from 'classnames'

import styles from './ConfirmModal.module.scss'
import { Button, ButtonStyle, Modal } from '@/components/shared'

import { t } from '@/lib/helpers'

export type ConFirmModalBtnType = 'button' | 'submit' | 'reset'

type Props = {
  show: boolean
  onClose: () => void
  onConfirm?: () => void
  form?: string
  title?: string
  description?: React.ReactNode
  confirmText?: string
  cancelText?: string
  type?: ButtonStyle
  confirmBtnType?: ConFirmModalBtnType
  children?: React.ReactNode
  className?: string
}

export const ConfirmModal = (props: Props) => {
  const {
    show,
    onClose,
    onConfirm,
    form,
    title,
    description,
    confirmText,
    cancelText = t('views.misc.cancel', {}, 'cancel'),
    type = 'Primary',
    confirmBtnType = 'button',
    children,
    className,
  } = props

  return (
    <Modal
      show={show}
      title={title}
      onClose={onClose}
      className={classnames(styles.modal, className)}
    >
      {description && <span className={styles.description}>{description}</span>}
      {children}
      <div className={styles.actionBtns}>
        <Button
          type={confirmBtnType}
          text={confirmText}
          size={'L'}
          style={type}
          className={styles.deleteBtn}
          onClick={onConfirm}
          form={form}
        />
        <Button
          text={cancelText}
          size={'L'}
          style={'Secondary'}
          className={styles.cancelBtn}
          onClick={onClose}
        />
      </div>
    </Modal>
  )
}
