import React from 'react'

import { useForm } from 'react-hook-form'

import { VisitorLayout } from '../../layout'
import styles from './ScreeningSelection.module.scss'
import { RadioButtons, RadioButtonsRegisterOption } from '@/components/shared'

import { CovidScreeningType, CovidScreening } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  covidScreening: CovidScreening
  setScreeningType: (type: CovidScreeningType) => void
}

const screeningSelectionForm = 'screeningSelectionForm'

export const ScreeningSelection = (props: Props) => {
  const { covidScreening, setScreeningType } = props
  const { register, watch, handleSubmit } = useForm()

  const registerOptions: RadioButtonsRegisterOption[] = [
    {
      icon: 'diagnosis',
      label: t('views.features.visitor.covid_screening.selection.form'),
      value: 'form',
    },
    {
      icon: 'rapid-test',
      label: t('views.features.visitor.covid_screening.selection.atk'),
      value: 'atk',
    },
  ]

  const _handleSubmit = (data: any) => {
    setScreeningType(data.screening)
  }

  return (
    <VisitorLayout
      form={screeningSelectionForm}
      textNextBtn={t('views.components.layout.visitor.next')}
      disabledNextButton={!watch('screening')}
      logoUrl={covidScreening.logo_url}
    >
      <span className={styles.title}>
        {t('views.features.visitor.covid_screening.selection.title')}
      </span>
      <form id={screeningSelectionForm} onSubmit={handleSubmit(_handleSubmit)}>
        <RadioButtons
          name={'screening'}
          register={register}
          registerOptions={registerOptions}
          horizontal
          className={styles.radioButtons}
        />
      </form>
    </VisitorLayout>
  )
}
