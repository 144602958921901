import React, { useEffect, useState } from 'react'

import classnames from 'classnames'

import styles from './Header.module.scss'

import { headerService } from '../services'
import { AtkReportsChannel, DeliveriesChannel } from '@/channels'
import {
  invitationsPath,
  requestsPath,
  rootPath,
  logsPath,
  calendarsPath,
  employeesPath,
  devisePaths,
  allVisitorPaths,
  companiesPath,
  connectionsPath,
  deliveriesPath,
  atkReportsPath,
  actionLogsPath,
  signOutPath,
} from '@/features/constants'
import { allSystemAdminPath } from '@/features/constants/systemAdmin'
import {
  AtkReport,
  Delivery,
  Employee,
  EmployeeType,
  SystemAdmin,
} from '@/features/types'
import { t } from '@/lib/helpers'

import { Dropdown, DropdownMenu, ImagePreview, LocaleButtons } from '../..'
import { SystemAdminHeader } from './SystemAdminHeader'

type MenuProps = {
  label: string
  href: string
  isHighlight: boolean
  notify?: boolean
}

const Menu = (props: MenuProps) => {
  const { label, href, isHighlight = false, notify = false } = props
  return (
    <a
      href={href}
      className={classnames(styles.navbarButton, {
        [styles.highlightNavbarButton]: isHighlight,
      })}
    >
      {label}
      {notify && <div className={styles.notify} />}
    </a>
  )
}

type Props = {
  employee?: Employee
  systemAdmin?: SystemAdmin
  logoUrl?: string
  walkIn?: boolean
}

export const Header = (props: Props) => {
  const { employee, systemAdmin, logoUrl, walkIn } = props
  let navbar
  let rightSection
  let menus: DropdownMenu[]
  const current_path = window.location.pathname
  const [notifyDelivery, setNotifyDelivery] = useState<boolean>(false)
  const [notifyAtkReport, setNotifyAtkReport] = useState<boolean>(false)

  useEffect(() => {
    if (employee) {
      DeliveriesChannel.received = (delivery: Delivery) => {
        if (
          employee.id !== delivery.host_employee_id &&
          employee.type === EmployeeType.staff
        ) {
          return
        }
        setNotifyDelivery(true)
      }
      AtkReportsChannel.received = (_atkReport: AtkReport) => {
        setNotifyAtkReport(true)
      }
    }
  }, [])

  if (allSystemAdminPath.some((path) => current_path.includes(path))) {
    return <SystemAdminHeader systemAdmin={systemAdmin} />
  }

  if (employee) {
    menus = [
      {
        label: t('views.components.header.connections'),
        onClick: () => (window.location.href = connectionsPath),
      },
      {
        label: (
          <div className={styles.langWrapper}>
            <span>{t('views.components.header.language')}</span>
            <LocaleButtons
              locale={employee.language}
              onClickLocale={(locale: PlatformLanguage) =>
                headerService().changeLanguage(employee.id, locale)
              }
              size={'S'}
            />
          </div>
        ),
        className: styles.langMenu,
      },
      {
        label: t('views.components.header.sign_out'),
        onClick: () => headerService().signOut(signOutPath),
        className: styles.signOut,
      },
    ]
    rightSection = (
      <Dropdown menus={menus} className={styles.userDropdown}>
        <div className={classnames(styles.navbarUser)}>
          <div className={styles.profile}>
            <span className={styles.employeeName}>
              {`${employee.first_name} ${employee.last_name}`}
            </span>
            <span className={styles.role}>
              {t(`views.misc.${employee.type.toLowerCase()}`)}
            </span>
          </div>
          <ImagePreview
            src={employee.image?.url || '/assets/icons/empty-display-image.svg'}
            width={40}
            height={40}
            className={styles.image}
          />
        </div>
      </Dropdown>
    )
  }

  if (walkIn) {
    return (
      <div className={classnames(styles.bg, styles.walkInBackground)}>
        <div
          onClick={() => window.location.reload()}
          className={classnames(
            styles.logo,
            styles.visitorLogo,
            styles.pointer,
          )}
        >
          <img src={logoUrl || '/assets/icons/logo-color.svg'} height={36} />
        </div>
      </div>
    )
  } else if (allVisitorPaths.some((path) => current_path.includes(path))) {
    return (
      <div className={classnames(styles.bg, styles.visitorBackground)}>
        <div className={classnames(styles.logo, styles.visitorLogo)}>
          <img src={logoUrl || '/assets/icons/logo-color.svg'} height={36} />
        </div>
      </div>
    )
  } else if (
    !devisePaths.some((path) => current_path.includes(path)) &&
    employee
  ) {
    if (
      employee.type === EmployeeType.admin ||
      employee.type === EmployeeType.owner
    ) {
      menus = [
        {
          label: t('views.components.header.company_management'),
          onClick: () =>
            (window.location.href = `${companiesPath}/${employee.company_slug}`),
        },
        {
          label: t('views.components.header.user_management'),
          onClick: () => (window.location.href = employeesPath),
        },
        {
          label: t('views.components.header.action_log'),
          onClick: () => (window.location.href = actionLogsPath),
        },
        {
          label: t('views.components.header.log'),
          onClick: () => (window.location.href = logsPath),
        },
      ]

      navbar = (
        <div className={styles.navbar}>
          <Menu
            label={t('views.components.header.home')}
            href={invitationsPath}
            isHighlight={[rootPath, invitationsPath, requestsPath].some(
              (path) => current_path === path,
            )}
          />
          <Menu
            label={t('views.components.header.calendar')}
            href={calendarsPath}
            isHighlight={current_path === calendarsPath}
          />
          <Menu
            label={t('views.components.header.delivery')}
            href={deliveriesPath}
            isHighlight={current_path === deliveriesPath}
            notify={notifyDelivery}
          />
          <Menu
            label={t('views.components.header.atk')}
            href={atkReportsPath}
            isHighlight={current_path === atkReportsPath}
            notify={notifyAtkReport}
          />
          <Dropdown
            menus={menus}
            className={classnames(styles.navbarButton, styles.settings)}
          >
            {t('views.components.header.settings')}
          </Dropdown>
        </div>
      )
    } else {
      navbar = (
        <div className={styles.navbar}>
          <Menu
            label={t('views.components.header.calendar')}
            href={calendarsPath}
            isHighlight={current_path === calendarsPath}
          />
          <Menu
            label={t('views.components.header.delivery')}
            href={deliveriesPath}
            isHighlight={current_path === deliveriesPath}
            notify={notifyDelivery}
          />
        </div>
      )
    }
  }

  return (
    <div className={styles.bg}>
      <div className={styles.wrapper}>
        <div
          className={styles.logo}
          onClick={() => (window.location.href = rootPath)}
        >
          <img src="/assets/icons/logo.svg" />
        </div>
        {navbar}
        <div className={styles.rightSection}>{rightSection}</div>
      </div>
    </div>
  )
}
