import React from 'react'

import classnames from 'classnames'
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'

import styles from './VaccinatedSlot.module.scss'
import { Trash } from '@/components/icons'
import { DateField, Select, TextField } from '@/components/shared'

import { vaccineOptions } from '@/features/constants'
import { t } from '@/lib/helpers'

import { useVaccineContext } from '../../context/VaccineContext'

type Props = {
  dose: number
  required?: boolean
  disabled?: boolean
  valueVaccine?: string
  valueDate?: Date
  isRemoveable?: boolean
  onRemove?: () => void
}

export const VaccinatedSlot = (props: Props) => {
  const {
    register: registerContext,
    watch: watchContext,
    control: controlContext,
  } = useVaccineContext()
  const {
    dose,
    required = false,
    disabled = false,
    valueVaccine = '',
    valueDate,
    isRemoveable = false,
    onRemove,
  } = props
  const register = registerContext as UseFormRegister<FieldValues>
  const watch = watchContext as UseFormWatch<FieldValues>
  const control = controlContext as Control<FieldValues>

  const otherText = vaccineOptions.slice(-1).pop()?.value
  const disabledVaccineDate = (current: any) => {
    // Can not select days after today
    return current && new Date(current).getTime() > new Date().getTime()
  }
  const name = `dose_${dose}_name` as `${string}`

  return (
    <>
      <div className={styles.doseTitleWrapper}>
        {t('views.features.visitor.edit.vaccinated.dose_order', {
          order: t('ordinalize', { count: dose }),
        })}
        {dose === 1 && <div className={styles.requiredStar}>*</div>}
        {isRemoveable && (
          <Trash
            showToolTip={false}
            onClick={onRemove}
            className={styles.trash}
          />
        )}
      </div>
      <Select
        title={t('views.features.visitor.edit.vaccinated.vaccine_name')}
        placeholder={t('views.features.visitor.edit.vaccinated.placeholder')}
        name={name}
        control={control}
        required={required}
        disabled={disabled}
        options={vaccineOptions}
        defaultValue={valueVaccine}
        className={classnames(styles.field, {
          [styles.selectPlaceholder]: watch(name) === '',
        })}
      />
      {watch(name) === otherText && (
        <TextField
          required
          placeholder={t('views.features.visitor.edit.vaccinated.placeholder')}
          name={`dose_${dose}_other`}
          disabled={disabled}
          register={register}
        />
      )}
      <div className={styles.dateWrapper}>
        <label>
          {t('views.features.visitor.edit.vaccinated.vaccine_date')}
          <DateField
            defaultValue={valueDate}
            control={control}
            name={`dose_${dose}_date` as `${string}`}
            required={required}
            disabledDate={disabledVaccineDate}
            disabled={disabled}
          />
        </label>
      </div>
    </>
  )
}
