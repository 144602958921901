import React from 'react'

import classnames from 'classnames'
import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'

import { ISelectOption, Select, TextField } from '..'
import styles from './RejectForm.module.scss'

import { t } from '@/lib/helpers'

export const REJECT_FORM = 'REJECT_FORM'

type Props = {
  onSubmit: () => void
  control: Control<FieldValues>
  register: UseFormRegister<FieldValues>
  watch: UseFormWatch<FieldValues>
  options: ISelectOption[]
  className?: string
}

export const RejectForm = (props: Props) => {
  const { onSubmit, control, register, watch, options, className } = props
  const otherText = options.slice(-1).pop()?.value

  return (
    <form
      id={REJECT_FORM}
      onSubmit={onSubmit}
      className={classnames(styles.wrapper, className)}
    >
      <Select
        title={t('views.misc.reason')}
        name="reason"
        control={control}
        required
        options={options}
      />
      {watch('reason') === otherText && (
        <TextField
          required
          placeholder={t('views.misc.description')}
          name="reason_other"
          register={register}
          type="textarea"
          className={styles.textArea}
        />
      )}
    </form>
  )
}
