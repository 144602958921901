import React from 'react'

import classnames from 'classnames'

import styles from './VaccineLogs.module.scss'
import { SortOptions, Table, Column } from '@/components/shared'

import { VaccineLog } from '@/features/types'
import { getLocaleDateString, t } from '@/lib/helpers'

type Props = {
  vaccineLogs: VaccineLog[]
  sortOptions: SortOptions
  handleSort: (newSort?: string, newDirection?: string) => void
  className?: string
}

const columns: Column[] = [
  {
    column: 'action',
    title: t('views.misc.action'),
    className: styles.action,
  },
  {
    column: 'request_datetime',
    title: t('views.features.risk_assessments.vaccine_logs.request_time'),
    className: styles.requestDateTime,
  },
  {
    column: 'action_datetime',
    title: t('views.features.risk_assessments.vaccine_logs.action_time'),
    className: styles.actionDateTime,
  },
  {
    column: 'note',
    title: t('views.misc.note'),
    className: styles.note,
  },
]

export const VaccineLogs = (props: Props) => {
  const { vaccineLogs, sortOptions, handleSort, className } = props
  const data = vaccineLogs.map((vaccineLog: VaccineLog, idx: number) => {
    const { action, created_at, updated_at } = vaccineLog
    return (
      <tr key={idx}>
        <td>
          <div className={classnames(styles.textEllipsis, styles.actionText)}>
            {action}
          </div>
        </td>
        <td>
          <div className={styles.textEllipsis}>
            {getLocaleDateString(created_at, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
            })}
          </div>
        </td>
        <td className={styles.textEllipsis}>
          {updated_at == created_at ? '-' : getLocaleDateString(updated_at)}
        </td>
        <td className={classnames(styles.textEllipsis, styles.noteText)}>
          <div className={classnames(styles.textEllipsis, styles.noteText)}>
            {vaccineLog.note}
          </div>
        </td>
      </tr>
    )
  })

  return (
    <div className={classnames(styles.table, className)}>
      <Table
        columns={columns}
        data={data}
        sortOptions={sortOptions}
        handleSort={handleSort}
        emptyState={t('views.features.risk_assessments.vaccine_logs.no_logs')}
      />
    </div>
  )
}
