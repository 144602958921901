// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SignUpPage-module__layout___2isiB>div>button{margin-top:0px !important}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/employees/pages/SignUpPage/SignUpPage.module.scss"],"names":[],"mappings":"AACE,8CACE,yBAAA","file":"SignUpPage.module.scss","sourcesContent":[":local(.layout) {\n  > div > button {\n    margin-top: 0px !important;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"layout": "SignUpPage-module__layout___2isiB"
};
module.exports = exports;
