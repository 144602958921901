import React, { ChangeEvent } from 'react'

import classnames from 'classnames'

import { Button } from '..'
import styles from './ImportFileButton.module.scss'

type Props = {
  text: string
  onUpload: (event: ChangeEvent<HTMLInputElement>) => void
  accept: string
  className?: string
}

export const ImportFileButton = (props: Props) => {
  const { text, onUpload, accept, className } = props
  return (
    <div className={classnames(styles.wrapper, className)}>
      <label htmlFor={'importFile'}>
        <Button text={text} className={styles.btn} />
      </label>
      <input type="file" id="importFile" onChange={onUpload} accept={accept} />
    </div>
  )
}
