import { axios } from '@/lib/helpers'

export const verifyVaccineServices = () => {
  return {
    reject: async (id: string, reason: string) => {
      return axios.patch(`/risk_assessments/${id}`, {
        status: 'rejected',
        reason: reason,
      })
    },
    approve: async (id: string) => {
      return axios.patch(`/risk_assessments/${id}`, {
        status: 'approved',
      })
    },
    skip: async (id: string) => {
      return axios.get(`/risk_assessments/${id}/skip`, {})
    },
  }
}
