import React, { forwardRef } from 'react'

import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { animated, SpringValue } from 'react-spring'

import { ISelectOption, Select, TextField, Webcam } from '@/components/shared'

import { walkInServices } from '../../services'

import { useWalkInContext, WalkInStateType } from '../../context'
import styles from '../WalkInForm/WalkInForm.module.scss'

type Props = {
  opacity: SpringValue<number>
  listHost?: ISelectOption[] | null
  host?: ISelectOption | null
  searchHost: (val: string) => void
  setHost: (host: ISelectOption | null) => void
  isCapture: boolean
  imgSrc?: string
  state: WalkInStateType
  showCountdown: boolean
  setShowCountdown: (showCountDown: boolean) => void
  countdown: number
  image?: File
}

export const WalkInDelivery = forwardRef<any, Props>((props, webcamRef) => {
  const {
    type,
    state,
    setState,
    setType,
    company,
    t,
    setIsLoading,
    delivery,
    setDelivery,
  } = useWalkInContext()
  const {
    opacity,
    listHost,
    host,
    searchHost,
    setHost,
    isCapture,
    imgSrc,
    showCountdown,
    setShowCountdown,
    countdown,
    image,
  } = props
  const { handleSubmit, control, register } = useForm({
    defaultValues: {
      host: delivery?.host_employee_id || null,
      sender: delivery?.sender || null,
    },
  })

  const _handleSubmit = async (data: any) => {
    if (state < 2) {
      setDelivery({
        ...data,
        host_employee_id: data.host,
      })
      setState((state + 1) as WalkInStateType)
    } else if (image && company) {
      setIsLoading(true)
      await walkInServices().submitDelivery({
        company_slug: company.slug,
        host_employee_id: data.host,
        sender: data.sender,
        image,
      })
      setIsLoading(false)
      setState(0)
      setType('delivery-success')
    }
    return false
  }

  switch (state) {
    case 1:
      return (
        <animated.div
          style={{
            position: 'absolute',
            width: '100%',
            opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
          }}
        >
          <form id={'walkInState1'} onSubmit={handleSubmit(_handleSubmit)}>
            <Select
              autoFocus
              title={t('views.features.walk_in.delivery.parcels_owner')}
              required
              options={listHost || (host && [host]) || []}
              placeholder={t(
                'views.features.walk_in.delivery.parcels_owner_name',
              )}
              name={'host'}
              control={control}
              onInputChange={searchHost}
              onChange={(val) =>
                setHost(listHost?.find((host) => host.value === val) || null)
              }
              menuIsOpen={listHost !== null}
              hideDropdownIcon
              className={styles.selectField}
            />
            <TextField
              title={t('views.features.walk_in.delivery.parcels_sender')}
              register={register}
              name={'sender'}
              placeholder={t(
                'views.features.walk_in.delivery.parcels_sender_name',
              )}
              className={styles.textField}
            />
          </form>
        </animated.div>
      )
    default:
      return (
        <animated.div
          style={{
            position: 'absolute',
            opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
          }}
        >
          {type === 'delivery' && (
            <form
              id={'walkInState2'}
              onSubmit={handleSubmit(_handleSubmit)}
              className={classnames(styles.webcamArea, {
                [styles.capture]: isCapture,
              })}
            >
              <Webcam
                imgSrc={imgSrc}
                ref={webcamRef}
                showCountdown={showCountdown}
                setShowCountdown={setShowCountdown}
                countdown={countdown}
                showSwitchFacingMode
              />
            </form>
          )}
        </animated.div>
      )
  }
})
