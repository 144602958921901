import React from 'react'

import classnames from 'classnames'
import { UseFormRegister } from 'react-hook-form'

import { RadioButton } from '..'
import styles from './RadioButtons.module.scss'

export type RadioButtonsRegisterOption = {
  icon?: string
  label: string
  value: string
}

export type RadioButtonsOnChangeOption = {
  icon?: string
  iconComponent?: React.ReactNode
  label: string
  checked: boolean
  onChange?: () => void
  onClick?: () => void
}

type Props = {
  title?: string
  name?: string
  registerOptions?: RadioButtonsRegisterOption[]
  register?: UseFormRegister<any>
  onChangeOptions?: RadioButtonsOnChangeOption[]
  horizontal?: boolean
  required?: boolean
  className?: string
}

export const RadioButtons = (props: Props) => {
  const {
    title,
    registerOptions,
    register,
    name,
    onChangeOptions,
    horizontal = false,
    required = false,
    className,
  } = props

  const radioOptions =
    registerOptions?.map((option) => (
      <RadioButton
        key={option.label}
        title={option.label}
        register={register}
        name={name}
        value={option.value}
        icon={option.icon}
        required={required}
        className={styles.choice}
      />
    )) ||
    onChangeOptions?.map((option) => (
      <RadioButton
        key={option.label}
        title={option.label}
        checked={option.checked}
        onChange={option.onChange}
        icon={option.icon}
        iconComponent={option.iconComponent}
        required={required}
        onClick={option.onClick}
        className={styles.choice}
      />
    ))

  return (
    <div className={className}>
      <label className={styles.title}>
        {title}
        {required && title && <span className={styles.requiredTitle}>*</span>}
      </label>
      <div
        className={classnames(styles.choices, {
          [styles.horizontal]: horizontal,
        })}
      >
        {radioOptions}
      </div>
    </div>
  )
}
