import React, { useEffect, useRef, useState } from 'react'

import { Controller, useForm } from 'react-hook-form'

import { VisitorLayout } from '../../layout'
import styles from './SchedulePage.module.scss'
import { RadioButton, InfoSection } from '@/components/shared'

/* eslint-disable */
import FullCalendar, {
  DayCellContentArg,
  EventSourceInput,
  MountArg,
} from '@fullcalendar/react'
import thLocale from '@fullcalendar/core/locales/th'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import './SchedulePage.scss'
/* eslint-disable */

import { Invitation } from '@/features/types'
import { t, formatDate, getLocaleTimeString } from '@/lib/helpers'
import { visitorService } from '../../services'

type Props = {
  invitation: Invitation
}

const selectedDateId = 'selectedDateCell'

const SchedulePage = (props: Props) => {
  const { invitation } = props
  const { invite } = invitation
  const [date, setDate] = useState<string>(
    formatDate(invitation.start_datetime),
  )
  const defaultSchedule =
    invitation.start_datetime &&
    invitation.finish_datetime &&
    `${formatDate(
      invitation.start_datetime,
      'YYYY-MM-DD hh:mm A',
    )} - ${formatDate(invitation.finish_datetime, 'YYYY-MM-DD hh:mm A')}`
  const { handleSubmit, reset, control } = useForm({
    defaultValues: {
      schedule: defaultSchedule,
    },
  })
  const calendarRef: any = useRef()
  const events: EventSourceInput = invite.list_schedule_time.map((schedule) => {
    return {
      start: schedule.start_time,
      end: schedule.end_time,
    }
  })

  const _handleSubmit = (data: any) => {
    const { schedule } = data
    visitorService().submitScheduleDateForm(
      invitation.visitor_slug,
      invitation.slug,
      schedule,
    )
  }

  const choices = (onChange: (schedule: string) => void, value: string) => {
    if (date) {
      return events
        .filter((event) => event.start?.toString().includes(date))
        .map((event) => {
          const timeText = `${getLocaleTimeString(
            event.start?.toString() as string,
          )} - ${getLocaleTimeString(event.end?.toString() as string)}`
          const dateTimeText = `${formatDate(
            event.start,
            'YYYY-MM-DD hh:mm A',
          )} - ${formatDate(event.end, 'YYYY-MM-DD hh:mm A')}`
          return (
            event.start &&
            event.end && (
              <RadioButton
                key={event.start.toString()}
                required
                title={timeText}
                value={dateTimeText}
                onChange={() => onChange(dateTimeText)}
                checked={value === dateTimeText}
                className={styles.radioButton}
              />
            )
          )
        })
    }
  }

  useEffect(() => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.gotoDate(
      invitation.start_datetime || invite.list_schedule_time[0].start_time,
    )
  }, [])

  return (
    <Controller
      control={control}
      name="schedule"
      defaultValue={defaultSchedule}
      render={({ field: { onChange, value } }) => (
        <VisitorLayout
          showBannerImage={false}
          textNextBtn={t('views.components.layout.visitor.next')}
          onClickNextBtn={handleSubmit(_handleSubmit)}
          className={styles.visitorLayout}
          disabledNextButton={!value}
          logoUrl={invitation.logo_url}
        >
          <span className={styles.title}>{invite.title}</span>
          <div className={styles.description}>
            <InfoSection
              iconName={'pin'}
              value={invitation.area_to_access || invitation.online_link}
            />
            <InfoSection
              iconName={'user'}
              value={invitation.host_employee_name || '-'}
            />
            <span className={styles.comment}>{invite.comment}</span>
          </div>
          <hr className={styles.line} />
          <span className={styles.dateTimeTitle}>
            {t('views.features.visitor.schedule.title')}
          </span>
          <div className={styles.calendarBox}>
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, interactionPlugin]}
              headerToolbar={{
                left: 'prev',
                center: 'title',
                right: 'next',
              }}
              events={events}
              dayCellDidMount={(info: MountArg<DayCellContentArg>) => {
                const lastElement =
                  info.el.firstElementChild?.children[1]?.lastElementChild
                if (lastElement) {
                  lastElement.textContent =
                    info.el.firstElementChild?.firstElementChild?.textContent ??
                    ''
                }
                if (formatDate(info.date) === date) {
                  const dateCell = info.el.firstElementChild as HTMLElement
                  dateCell.classList.add('dateCell')
                  dateCell.id = selectedDateId
                }
              }}
              dateClick={(info: DateClickArg) => {
                if (
                  !info.dayEl.className.includes('fc-day-other') &&
                  info.dayEl.firstElementChild?.children[1].firstElementChild?.className.includes(
                    'fc-daygrid-event-harness',
                  )
                ) {
                  const previousDateCell =
                    document.getElementById(selectedDateId)
                  if (previousDateCell) {
                    previousDateCell.classList.remove('dateCell')
                    previousDateCell.id = ''
                  }
                  const dateCell = info.dayEl.firstElementChild as HTMLElement
                  dateCell.classList.add('dateCell')
                  dateCell.id = selectedDateId
                  setDate(info.dateStr)
                  reset({ schedule: '' })
                }
              }}
              locale={gon.currentLocale === 'th' ? thLocale : undefined}
            />
            <div className={styles.buttons}>{choices(onChange, value)}</div>
          </div>
        </VisitorLayout>
      )}
    />
  )
}

export default SchedulePage
