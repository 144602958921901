import { createContext, Dispatch, SetStateAction, useContext } from 'react'

import { SortOptions } from '@/components/shared'

import { Delivery } from '@/features/types'

type DeliveryContextProps = {
  deliveries: Delivery[]
  setDeliveries: Dispatch<SetStateAction<Delivery[]>>
  lastDeleteID?: number
  setLastDeleteID: Dispatch<SetStateAction<number | undefined>>
  sortOptions: SortOptions
  handleQuery: (newSort?: string, newDirection?: string) => void
  onRemoveDelivery: () => void
}

export const DeliveryContext = createContext<DeliveryContextProps>({
  deliveries: [],
  setDeliveries: () => {},
  lastDeleteID: undefined,
  setLastDeleteID: () => {},
  sortOptions: { sort: '', direction: '' },
  handleQuery: (_newSort?: string, _newDirection?: string) => {},
  onRemoveDelivery: () => {},
})

export const useDeliveryContext = () => {
  const context = useContext(DeliveryContext)
  if (context === undefined) {
    throw new Error('useDeliveryContext must be used within a Provider')
  }
  return context
}
