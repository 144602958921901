import React, { useRef, useState } from 'react'

import classnames from 'classnames'
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'

import { TermsPolicySettingSection, Label } from '..'
import styles from './Settings.module.scss'
import {
  CreatableSelectTime,
  ImagePreview,
  showToast,
  Spinner,
  Switch,
  TextField,
} from '@/components/shared'

import { acceptedImageFile, MAXIMUM_IMAGE_SIZE } from '@/features/constants'
import { blobToFile, t } from '@/lib/helpers'

import {
  googleMapURLToLatLng,
  validateLatLng,
  getGoogleMapEmbeddedLink,
  GoogleMapIframe,
} from '.'

type Props = {
  logo_url: string
  register: UseFormRegister<any>
  control: Control<any>
  watch: UseFormWatch<any>
  handleUploadLogo: (logo: File) => void
  handleUpdateFields: () => void
  handleUpdateAddress: (
    address: string,
    latitude: number,
    longitude: number,
  ) => void
  handleUpdateLatLng: (latitude: number, longitude: number) => void
}

const compareTime = (from: string, to: string) => {
  let fromHour = parseInt(from.split(':')[0]) % 12
  if (from.includes('PM')) fromHour += 12
  let toHour = parseInt(to.split(':')[0]) % 12
  if (to.includes('PM')) toHour += 12

  if (fromHour < toHour) return -1
  if (fromHour > toHour) return 1

  let fromMinute = parseInt(from.split(':')[1])
  let toMinute = parseInt(to.split(':')[1])
  if (fromMinute < toMinute) return -1
  if (fromMinute > toMinute) return 1
  return 0
}

export const GeneralSetting = (props: Props) => {
  const {
    logo_url,
    register,
    control,
    watch,
    handleUploadLogo,
    handleUpdateFields,
    handleUpdateAddress,
    handleUpdateLatLng,
  } = props
  const [companyLogoPath, setCompanyLogoPath] = useState<string>(logo_url)
  const weekendTimeRef = useRef<any>(null)
  const [imageLoading, setImageLoading] = useState<boolean>(false)

  const handleUploadCompanyLogo = async (event: any) => {
    if (event.target.files[0].size >= MAXIMUM_IMAGE_SIZE) {
      showToast({
        type: 'alert',
        message: t('views.components.image_field.large_size'),
      })
    } else {
      setImageLoading(true)
      const file = event.target.files[0]
      switch (file.type) {
        case 'image/heic': {
          const jpegFile = await blobToFile(file)
          setCompanyLogoPath(URL.createObjectURL(jpegFile))
          handleUploadLogo(jpegFile)
          break
        }
        default:
          setCompanyLogoPath(URL.createObjectURL(file))
          handleUploadLogo(file)
      }
      setImageLoading(false)
    }
  }

  const handleChangeAddress = () => {
    const address = watch('general.address')
    const latitudeAndLongitude = googleMapURLToLatLng(address)
    if (
      latitudeAndLongitude &&
      validateLatLng(latitudeAndLongitude[0], latitudeAndLongitude[1])
    ) {
      handleUpdateAddress(
        address,
        latitudeAndLongitude[0],
        latitudeAndLongitude[1],
      )
    } else {
      showToast({
        type: 'alert',
        message: t('views.features.company_setting.invalid_address'),
      })
    }
  }

  const handleChangeLatLng = () => {
    const latitude = parseFloat(watch('general.latitude'))
    const longitude = parseFloat(watch('general.longitude'))
    if (validateLatLng(latitude, longitude)) {
      handleUpdateLatLng(latitude, longitude)
    } else {
      showToast({
        type: 'alert',
        message: t('views.features.company_setting.invalid_latitude_longitude'),
      })
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.section}>
        <span className={styles.title}>
          {t('views.features.company_setting.general.logo')}
        </span>
        <span className={styles.description}>
          {t('views.features.company_setting.general.logo_description')}
        </span>
        <div className={styles.uploadSection}>
          {imageLoading ? (
            <div className={styles.imageLoading}>
              <Spinner width={140} height={140} />
            </div>
          ) : (
            <ImagePreview
              src={companyLogoPath || '/assets/icons/empty-logo.svg'}
              width={140}
              height={140}
              className={styles.logo}
            />
          )}
          <div className={styles.uploadWrapper}>
            {t('views.features.company_setting.general.upload_description')}
            <div className={styles.upload}>
              <label htmlFor={'companyLogo'} className={styles.uploadBtn}>
                <div className={styles.btn}>
                  {t('views.features.company_setting.general.upload_file')}
                </div>
              </label>
              <div className={styles.uploadInput}>
                <input
                  id={'companyLogo'}
                  type={'file'}
                  onChange={handleUploadCompanyLogo}
                  accept={acceptedImageFile}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={classnames(styles.section, styles.mt16)}>
        <span className={styles.title}>
          {t('views.features.company_setting.general.account')}
        </span>
        <div className={styles.formSection}>
          <div className={styles.form}>
            <TextField
              title={t('views.features.company_setting.general.company_name')}
              placeholder={t(
                'views.features.company_setting.general.company_name_placeholder',
              )}
              name={'general.name'}
              register={register}
              required
              onBlur={() => handleUpdateFields()}
              className={styles.field}
            />
            <TextField
              title={t('views.features.company_setting.general.location_name')}
              placeholder={t(
                'views.features.company_setting.general.location_name_placeholder',
              )}
              name={'general.location_name'}
              register={register}
              onBlur={() => handleUpdateFields()}
              className={styles.field}
            />
            <TextField
              title={t('views.features.company_setting.general.google_map_url')}
              placeholder={t(
                'https://maps.google.com/?q=13.7141057,100.5921371',
              )}
              name={'general.address'}
              register={register}
              onBlur={() => handleChangeAddress()}
              className={styles.field}
            />
            <div className={styles.latAndLng}>
              <TextField
                title={t('views.features.company_setting.general.latitude')}
                placeholder={'0.000000'}
                name={'general.latitude'}
                register={register}
                onBlur={() => handleChangeLatLng()}
                className={classnames(styles.field, styles.latitude)}
              />
              <TextField
                title={t('views.features.company_setting.general.longitude')}
                placeholder={'0.000000'}
                name={'general.longitude'}
                register={register}
                onBlur={() => handleChangeLatLng()}
                className={classnames(styles.field, styles.longitude)}
              />
            </div>
          </div>
          <div className={styles.map}>
            <GoogleMapIframe
              src={getGoogleMapEmbeddedLink(
                watch('general.latitude'),
                watch('general.longitude'),
              )}
              className={styles.googleMap}
            />
          </div>
        </div>
      </div>
      <div className={classnames(styles.section, styles.mt16)}>
        <span className={styles.title}>
          {t('views.features.company_setting.general.time')}
        </span>
        <div className={styles.formSection}>
          <div className={styles.form}>
            <div className={styles.companyTimeSection}>
              <Label
                title={t(
                  'views.features.company_setting.general.working_hours',
                )}
                description={t(
                  'views.features.company_setting.general.working_hours_description',
                )}
              />
              <div className={styles.timeRangePicker}>
                <Controller
                  control={control}
                  name="general.weekday"
                  render={({ field: { onChange, value } }) => {
                    return (
                      <>
                        <CreatableSelectTime
                          menuPlacement="top"
                          onChange={(newOption: any) => {
                            const newOpenTime = newOption.value
                            let newCloseTime = value.close_time
                            if (compareTime(newOpenTime, newCloseTime) > 0) {
                              newCloseTime = newOpenTime
                            }
                            onChange({
                              open_time: newOpenTime,
                              close_time: newCloseTime,
                            })
                            if (newCloseTime !== value.close_time) {
                              handleUpdateFields()
                            } else {
                              handleUpdateFields()
                            }
                          }}
                          option={{
                            label: value.open_time,
                            value: value.open_time,
                          }}
                        />
                        <span className={styles.timeSeparator}>-</span>
                        <CreatableSelectTime
                          menuPlacement="top"
                          onChange={(newOption: any) => {
                            let newOpenTime = value.open_time
                            const newCloseTime = newOption.value
                            if (compareTime(newOpenTime, newCloseTime) > 0) {
                              newOpenTime = newCloseTime
                            }
                            onChange({
                              open_time: newOpenTime,
                              close_time: newCloseTime,
                            })
                            if (newOpenTime !== value.open_time) {
                              handleUpdateFields()
                            } else {
                              handleUpdateFields()
                            }
                          }}
                          option={{
                            label: value.close_time,
                            value: value.close_time,
                          }}
                        />
                      </>
                    )
                  }}
                />
              </div>
            </div>
            <div>
              <Controller
                control={control}
                name="general.weekend"
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <div className={styles.companyTimeSection}>
                        <Label
                          title={t(
                            'views.features.company_setting.general.open_on_weekend',
                          )}
                          description={t(
                            'views.features.company_setting.general.open_on_weekend_description',
                          )}
                        />
                        <Switch
                          checked={value.open_on_weekend}
                          onChange={() => {
                            const newValue = !value.open_on_weekend
                            if (newValue) {
                              onChange({
                                open_on_weekend: newValue,
                                open_time_weekend: watch(
                                  'general.weekday.open_time',
                                ),
                                close_time_weekend: watch(
                                  'general.weekday.close_time',
                                ),
                              })
                              handleUpdateFields()
                            } else {
                              onChange({
                                ...value,
                                open_on_weekend: newValue,
                              })
                              handleUpdateFields()
                            }
                            weekendTimeRef.current &&
                              weekendTimeRef.current.scrollIntoView({
                                behavior: 'smooth',
                              })
                          }}
                          width={50}
                          height={30}
                          className={styles.floatRight}
                        />
                      </div>
                      <div
                        className={classnames(styles.weekEndTimeSection, {
                          [styles.switchActive]: value.open_on_weekend,
                        })}
                        ref={weekendTimeRef}
                      >
                        <Label
                          title={t(
                            'views.features.company_setting.general.weekend_working_hours',
                          )}
                          description={t(
                            'views.features.company_setting.general.weekend_working_hours_description',
                          )}
                        />
                        <div className={styles.timeRangePicker}>
                          <CreatableSelectTime
                            menuPlacement="top"
                            onChange={(newOption: any) => {
                              const newOpenTime = newOption.value
                              let newCloseTime = value.close_time_weekend
                              if (compareTime(newOpenTime, newCloseTime) > 0) {
                                newCloseTime = newOpenTime
                              }
                              onChange({
                                ...value,
                                open_time_weekend: newOpenTime,
                                close_time_weekend: newCloseTime,
                              })
                              if (newCloseTime !== value.close_time_weekend) {
                                handleUpdateFields()
                              } else {
                                handleUpdateFields()
                              }
                            }}
                            option={{
                              label: value.open_time_weekend,
                              value: value.open_time_weekend,
                            }}
                          />
                          <span className={styles.timeSeparator}>-</span>
                          <CreatableSelectTime
                            menuPlacement="top"
                            onChange={(newOption: any) => {
                              let newOpenTime = value.open_time_weekend
                              const newCloseTime = newOption.value
                              if (compareTime(newOpenTime, newCloseTime) > 0) {
                                newOpenTime = newCloseTime
                              }
                              onChange({
                                ...value,
                                open_time_weekend: newOpenTime,
                                close_time_weekend: newCloseTime,
                              })
                              if (newOpenTime !== value.open_time_weekend) {
                                handleUpdateFields()
                              } else {
                                handleUpdateFields()
                              }
                            }}
                            option={{
                              label: value.close_time_weekend,
                              value: value.close_time_weekend,
                            }}
                          />
                        </div>
                      </div>
                    </>
                  )
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <TermsPolicySettingSection {...props} />
    </div>
  )
}
