import { showToast } from '@/components/shared'

import { companiesPath, locationsPath } from '@/features/constants'
import { axios, axiosFormData } from '@/lib/helpers'

export const companyServices = () => {
  return {
    uploadLogo: async (id: string, logo: File) => {
      try {
        const form = new FormData()
        form.append('company[logo]', logo)
        const res = await axiosFormData.patch(`${companiesPath}/${id}`, form)
        showToast({ type: 'success', message: res.data?.response })
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          showToast({ type: 'alert', message: err.response.data?.response })
        }
      }
    },
    addArea: async (name: string) => {
      try {
        const res = await axios.post(`${locationsPath}`, {
          location: { name },
        })
        showToast({ type: 'success', message: res.data?.response })
        return res.data?.id
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          showToast({ type: 'alert', message: err.response.data?.response })
        }
      }
    },
    updateArea: async (id: number, field: string, value: string) => {
      try {
        const res = await axios.patch(`${locationsPath}/${id}`, {
          location: { [field]: value },
        })
        showToast({ type: 'success', message: res.data?.response })
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          showToast({ type: 'alert', message: err.response.data?.response })
        }
      }
    },
    update: async (
      id: string,
      type: 'company' | 'invitation_setting' | 'walk_in_setting',
      option: any,
    ) => {
      try {
        const res = await axios.patch(`${companiesPath}/${id}?type=${type}`, {
          [type]: option,
        })
        showToast({ type: 'success', message: res.data?.response })
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          showToast({ type: 'alert', message: err.response.data?.response })
        }
      }
    },
    deleteArea: async (id: number) => {
      try {
        const res = await axios.delete(`${locationsPath}/${id}`, {})
        showToast({ type: 'success', message: res.data?.response })
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          showToast({ type: 'alert', message: err.response.data?.response })
        }
      }
    },
    regenerateWalkInToken: async (id: string) => {
      try {
        const res = await axios.patch(
          `${companiesPath}/${id}/regenerate_walk_in_token`,
          {},
        )
        showToast({ type: 'success', message: res.data?.response })
        return res.data
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          showToast({ type: 'alert', message: err.response.data?.response })
        }
      }
    },
  }
}
