import React from 'react'

import classnames from 'classnames'
import { toast } from 'react-hot-toast'

import styles from './Toast.module.scss'

export type ToastProps = {
  type: 'success' | 'alert'
  message: string
}

export const Toast = (props: ToastProps) => {
  const { type, message } = props
  const typeStyle = type === 'success' ? styles.success : styles.error

  return (
    <span className={classnames(styles.messages, typeStyle)}>{message}</span>
  )
}

export const showToast = (props: ToastProps) => toast(<Toast {...props} />)
