import React, { useState } from 'react'

import { useForm } from 'react-hook-form'

import styles from './Settings.module.scss'
import { Plus } from '@/components/icons'
import { Button, Table, Column, SortOptions } from '@/components/shared'

import { Location } from '@/features/types'
import { t } from '@/lib/helpers'

import { Area } from '../Area'
import { AreaModalType, AreaModal } from '../AreaModal'

const columns: Column[] = [
  {
    column: 'name',
    title: t('views.features.company_setting.area.area_name'),
  },
  {
    column: 'status',
    title: t('views.misc.status'),
  },
  {
    title: '',
  },
]

type AreaColumn = 'name' | 'status'
type Props = {
  accessAreas: Location[]
  handleAddArea: (name: string) => Promise<number>
  handleUpdateArea: (id: number, field: string, value: string) => void
  handleDeleteArea: (id: number) => void
}

export const AreaSetting = (props: Props) => {
  const { accessAreas, handleAddArea, handleDeleteArea, handleUpdateArea } =
    props
  const [areas, setAreas] = useState<Location[]>(() =>
    accessAreas.sort((areaA, _) => (areaA.default_location ? -1 : 1)),
  )
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    sort: '',
    direction: '',
  })
  const [showTypeModal, setShowTypeModal] = useState<AreaModalType>(
    AreaModalType.none,
  )
  const [selectedAreaId, setSelectedAreaId] = useState<number>(0)
  const [areaIdx, setAreaIdx] = useState<number>(-1)
  const { register, handleSubmit, reset } = useForm()

  const handleSort = (newSort?: string, newDirection?: string) => {
    if (newSort && newDirection) {
      const sortedAreas = areas.sort((areaA: Location, areaB: Location) => {
        const dataAreaA = areaA[newSort as AreaColumn].toLowerCase()
        const dataAreaB = areaB[newSort as AreaColumn].toLowerCase()

        if (dataAreaA < dataAreaB) {
          return newDirection === 'asc' ? -1 : 1
        } else if (dataAreaA > dataAreaB) {
          return newDirection === 'asc' ? 1 : -1
        } else {
          return 0
        }
      })
      setAreas(sortedAreas)
      setSortOptions({
        sort: newSort,
        direction: newDirection,
      })
    }
  }

  const handleCloseModal = () => {
    setShowTypeModal(AreaModalType.none)
    setSelectedAreaId(0)
    setAreaIdx(-1)
    reset({ name: '' })
  }

  const _handleSubmit = async (data: any) => {
    const { name } = data
    switch (showTypeModal) {
      case AreaModalType.add:
        {
          const id = await handleAddArea(name)
          const newArea: Location = { id, name, status: 'enable' }
          setAreas([newArea, ...areas])
        }
        break
      case AreaModalType.edit:
        {
          handleUpdateArea(selectedAreaId, 'name', name)
          setAreas([
            ...areas.slice(0, areaIdx),
            {
              id: areas[areaIdx]?.id,
              status: areas[areaIdx]?.status,
              name,
            },
            ...areas.slice(areaIdx + 1),
          ])
        }
        break
      case AreaModalType.delete:
        handleDeleteArea(selectedAreaId)
        setAreas([...areas.slice(0, areaIdx), ...areas.slice(areaIdx + 1)])
        break
    }
    handleCloseModal()
  }

  const handleUpdateStatus = (
    id: number,
    status: string,
    areaIndex: number,
  ) => {
    handleUpdateArea(id, 'status', status)
    setAreas([
      ...areas.slice(0, areaIndex),
      {
        id,
        status,
        name: areas[areaIndex].name,
        default_location: areas[areaIndex].default_location,
      },
      ...areas.slice(areaIndex + 1),
    ])
  }

  const handleEdit = (id: number, name: string, areaIndex: number) => {
    setSelectedAreaId(id)
    setAreaIdx(areaIndex)
    reset({ name })
    setShowTypeModal(AreaModalType.edit)
  }

  const handleDelete = (id: number, areaIndex: number) => {
    setSelectedAreaId(id)
    setAreaIdx(areaIndex)
    setShowTypeModal(AreaModalType.delete)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.headerSection}>
        <div className={styles.header}>
          <span className={styles.title}>
            {t('views.features.company_setting.area.access_area')}
          </span>
          <span className={styles.description}>
            {t('views.features.company_setting.area.description')}
          </span>
        </div>
        <Button
          text={t('views.features.company_setting.area.add_area')}
          iconComponent={<Plus />}
          onClick={() => setShowTypeModal(AreaModalType.add)}
          className={styles.addAreaBtn}
        />
      </div>
      <div className={styles.tableSection}>
        <Table
          columns={columns}
          data={areas.map((area, idx) => (
            <Area
              key={idx}
              idx={idx}
              area={area}
              handleUpdateStatus={handleUpdateStatus}
              onEdit={handleEdit}
              onDelete={handleDelete}
            />
          ))}
          handleSort={handleSort}
          sortOptions={sortOptions}
          className={styles.table}
        />
      </div>
      <AreaModal
        show={showTypeModal !== AreaModalType.none}
        type={showTypeModal}
        areaName={areas[areaIdx]?.name}
        onClose={() => handleCloseModal()}
        register={register}
        onSubmit={handleSubmit(_handleSubmit)}
      />
    </div>
  )
}
