import React from 'react'

import classnames from 'classnames'

import styles from './ScreeningShield.module.scss'
import { Shield } from '@/components/icons'

import {
  CovidScreeningATKStatus,
  CovidScreeningFormStatus,
} from '@/features/types'

type Props = {
  screeningStatus?:
    | CovidScreeningFormStatus
    | CovidScreeningATKStatus
    | 'primary'
    | 'primary50'
    | 'disabled'
  className?: string
}

export const ScreeningShield = (props: Props) => {
  const { screeningStatus, className } = props

  let shieldClassName = styles.disappear
  switch (screeningStatus) {
    case CovidScreeningATKStatus.positive:
    case CovidScreeningFormStatus.high_risk:
      shieldClassName = styles.highRisk
      break
    case CovidScreeningFormStatus.moderate_risk:
      shieldClassName = styles.moderateRisk
      break
    case CovidScreeningATKStatus.negative:
    case CovidScreeningFormStatus.low_risk:
      shieldClassName = styles.lowRisk
      break
    case 'primary':
      shieldClassName = styles.primary
      break
    case 'primary50':
      shieldClassName = styles.primary50
      break
    case 'disabled':
      shieldClassName = styles.disabled
  }

  return (
    <div className={classnames(styles.shieldIcon, shieldClassName)}>
      <Shield className={className} />
    </div>
  )
}
