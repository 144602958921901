// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CheckMailPage-module__mt20___1w2ia{margin-top:20px}.CheckMailPage-module__mb40___29TOY{margin-bottom:40px}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/employees/pages/CheckMailPage/CheckMailPage.module.scss"],"names":[],"mappings":"AAAA,oCACE,eAAA,CAGF,oCACE,kBAAA","file":"CheckMailPage.module.scss","sourcesContent":[":local(.mt20) {\n  margin-top: 20px;\n}\n\n:local(.mb40) {\n  margin-bottom: 40px;\n}\n"]}]);
// Exports
exports.locals = {
	"mt20": "CheckMailPage-module__mt20___1w2ia",
	"mb40": "CheckMailPage-module__mb40___29TOY"
};
module.exports = exports;
