import React from 'react'

import styles from './AreaModal.module.scss'
import { TextField } from '@/components/shared'
import { ConfirmModal } from '@/components/shared/ConfirmModal'

import { t } from '@/lib/helpers'

export enum AreaModalType {
  add = 'add',
  edit = 'edit',
  delete = 'delete',
  none = 'none',
}

type Props = {
  show: boolean
  type: AreaModalType
  areaName?: string
  onClose: () => void
  register: any
  onSubmit: () => void
}

const areaModalForm = 'areaModalForm'

export const AreaModal = (props: Props) => {
  const { show, type, areaName, onClose, register, onSubmit } = props
  let title, description, textBtn
  switch (type) {
    case AreaModalType.add:
      title = t('views.features.company_setting.area.add_new_area')
      textBtn = t('views.misc.add')
      break
    case AreaModalType.edit:
      title = t('views.features.company_setting.area.edit_area')
      textBtn = t('views.misc.save')
      break
    case AreaModalType.delete:
      title = t('views.features.company_setting.area.delete_area')
      description = t(
        'views.features.company_setting.area.confirm_delete_area',
        { area_name: areaName },
      )
      textBtn = t('views.misc.delete')
      break
  }
  const isDeleteType = type === AreaModalType.delete
  return (
    <ConfirmModal
      show={show}
      title={title}
      description={description}
      confirmText={textBtn}
      onClose={onClose}
      form={areaModalForm}
      confirmBtnType={'submit'}
      type={isDeleteType ? 'Delete' : 'Primary'}
    >
      <form id={areaModalForm} onSubmit={onSubmit} className={styles.form}>
        {!isDeleteType && (
          <TextField
            title={t('views.features.company_setting.area.area_name')}
            name={'name'}
            register={register}
            placeholder={t('views.features.company_setting.area.area_name')}
            required
          />
        )}
      </form>
    </ConfirmModal>
  )
}
