import React from 'react'

type Props = {
  className?: string
}

export const RotateCcw = (props: Props) => {
  const { className } = props
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={'currentColor' || '#808080'}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M2.5 4V10H8.5"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.01 14.9999C5.65839 16.8403 6.88734 18.4201 8.51166 19.5013C10.136 20.5825 12.0677 21.1065 14.0157 20.9944C15.9637 20.8823 17.8226 20.1401 19.3121 18.8797C20.8017 17.6193 21.8413 15.9089 22.2742 14.0063C22.7072 12.1037 22.5101 10.1119 21.7126 8.33105C20.9152 6.55019 19.5605 5.07674 17.8528 4.13271C16.1451 3.18868 14.1769 2.82521 12.2447 3.09707C10.3125 3.36892 8.52091 4.26137 7.14 5.63995L2.5 9.99995"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
