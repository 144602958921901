// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ErrorsPage-module__wrapper___2DOaA{display:flex;flex-direction:column;width:496px;margin:auto;padding:8px}.ErrorsPage-module__title___3g3VK{text-align:center;font-weight:600;font-size:32px;line-height:48px}.ErrorsPage-module__description___1XH06{font-weight:500;font-size:20px;text-align:center;line-height:32px;margin-top:4px;margin-bottom:16px;word-break:break-word}.ErrorsPage-module__btn___147Jr{width:100% !important}@media screen and (max-width: 480px){.ErrorsPage-module__wrapper___2DOaA{width:unset !important;max-width:480px}}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/errors/pages/ErrorsPage/ErrorsPage.module.scss"],"names":[],"mappings":"AAAA,oCACE,YAAA,CACA,qBAAA,CACA,WAAA,CACA,WAAA,CACA,WAAA,CAGF,kCACE,iBAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGF,wCACE,eAAA,CACA,cAAA,CACA,iBAAA,CACA,gBAAA,CACA,cAAA,CACA,kBAAA,CACA,qBAAA,CAGF,gCACE,qBAAA,CAGF,qCACE,oCACE,sBAAA,CACA,eAAA,CAAA","file":"ErrorsPage.module.scss","sourcesContent":[":local(.wrapper) {\n  display: flex;\n  flex-direction: column;\n  width: 496px;\n  margin: auto;\n  padding: 8px;\n}\n\n:local(.title) {\n  text-align: center;\n  font-weight: 600;\n  font-size: 32px;\n  line-height: 48px;\n}\n\n:local(.description) {\n  font-weight: 500;\n  font-size: 20px;\n  text-align: center;\n  line-height: 32px;\n  margin-top: 4px;\n  margin-bottom: 16px;\n  word-break: break-word;\n}\n\n:local(.btn) {\n  width: 100% !important;\n}\n\n@media screen and (max-width: 480px) {\n  :local(.wrapper) {\n    width: unset !important;\n    max-width: 480px;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "ErrorsPage-module__wrapper___2DOaA",
	"title": "ErrorsPage-module__title___3g3VK",
	"description": "ErrorsPage-module__description___1XH06",
	"btn": "ErrorsPage-module__btn___147Jr"
};
module.exports = exports;
