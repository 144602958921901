import React from 'react'

import styles from './SuccessPage.module.scss'
import { ApplicationLayout, Success } from '@/components/shared'

import { Employee } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  employee: Employee
}

const SuccessPage = (props: Props) => {
  const { employee } = props

  return (
    <ApplicationLayout employee={employee} className={styles.layout}>
      <Success
        text={t('views.features.delivery.successfully_picked_up')}
        className={styles.success}
      />
    </ApplicationLayout>
  )
}

export default SuccessPage
