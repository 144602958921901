import React from 'react'

import styles from './IntegrationConnection.module.scss'
import { Button } from '@/components/shared'

import { t } from '@/lib/helpers'

export type IntegrationConnectionProps = {
  logoName: string
  title: string
  description: string
  email?: string
  onDisconnect: () => void
  onConnect: () => void
}

export const IntegrationConnection = (props: IntegrationConnectionProps) => {
  const { logoName, title, description, email, onConnect, onDisconnect } = props
  const isConnect = email !== null
  return (
    <div className={styles.wrapper}>
      <div className={styles.infoIcon}>
        <img src={`/assets/icons/${logoName}.svg`} />
        <div className={styles.info}>
          <span className={styles.title}>{title}</span>
          <span className={styles.description}>{description}</span>
        </div>
      </div>
      <div className={styles.rightSection}>
        <span className={styles.email}>{isConnect && email}</span>
        <div className={styles.actionBtn}>
          {isConnect ? (
            <Button
              text={t('views.misc.disconnect')}
              icon={'/assets/icons/link-break.svg'}
              style={'Tertiary'}
              onClick={onDisconnect}
            />
          ) : (
            <Button
              text={t('views.misc.connect')}
              icon={'/assets/icons/link-simple.svg'}
              onClick={onConnect}
            />
          )}
        </div>
      </div>
    </div>
  )
}
