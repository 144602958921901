import React, { useState } from 'react'

import { useForm } from 'react-hook-form'

import { VisitorLayout } from '../../layout'
import styles from './AtkForm.module.scss'
import {
  RadioButtons,
  RadioButtonsRegisterOption,
  ImageFieldWithWebcam,
} from '@/components/shared'

import { visitorService } from '../../services'
import { CovidScreening } from '@/features/types'
import { formatDate, getLocaleDateString, t } from '@/lib/helpers'

type Props = {
  covidScreening: CovidScreening
  atkTestDays: string[]
}

const atkForm = 'atkForm'
const valueDateFormat = 'D MMMM YYYY'
const labelDateFormat: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

const getLabelDate = (idx: number) => {
  switch (idx) {
    case 0:
      return t('views.misc.today')
    case 1:
      return t('views.misc.yesterday')
  }
}

export const AtkForm = (props: Props) => {
  const { covidScreening, atkTestDays } = props
  const { handleSubmit, register } = useForm({
    defaultValues: {
      test_atk_at: formatDate(covidScreening.test_atk_at, valueDateFormat),
    },
  })
  const image_url = covidScreening.atk_image?.url
  const [image, setImage] = useState<File>()
  const [disabledNextButton, setDisabledNextButton] = useState<boolean>(
    !image_url,
  )

  const registerOptions: RadioButtonsRegisterOption[] = atkTestDays.map(
    (day, idx) => ({
      label: `${getLabelDate(idx)} (${getLocaleDateString(
        day,
        labelDateFormat,
      )})`,
      value: formatDate(day, valueDateFormat),
    }),
  )

  const _handleSubmit = async (data: any) => {
    const url = new URL(window.location.href)
    const invitationSlug = url.searchParams.get('invitation_id') || ''
    await visitorService().submitAtkForm(
      covidScreening.slug,
      {
        ...data,
        atk_image: image,
      },
      invitationSlug,
    )
  }

  return (
    <VisitorLayout
      form={atkForm}
      textNextBtn={t('views.components.layout.visitor.next')}
      logoUrl={covidScreening.logo_url}
      disabledNextButton={disabledNextButton}
    >
      <span className={styles.title}>
        {t('views.features.visitor.covid_screening.atk_form.title')}
      </span>
      <form
        id={atkForm}
        onSubmit={handleSubmit(_handleSubmit)}
        className={styles.form}
      >
        <RadioButtons
          title={t(
            'views.features.visitor.covid_screening.atk_form.atk_test_at',
          )}
          name={'test_atk_at'}
          register={register}
          registerOptions={registerOptions}
          required
          className={styles.radioButtons}
        />
        <ImageFieldWithWebcam
          title={t('views.features.visitor.covid_screening.atk_form.atk_image')}
          defaultImagePath={image_url}
          image={image}
          setImage={setImage}
          setDisabled={setDisabledNextButton}
          required
          className={styles.imageFieldWithWebcam}
        />
      </form>
    </VisitorLayout>
  )
}
