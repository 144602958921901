import React from 'react'

type Props = {
  className?: string
}

export const Download = (props: Props) => {
  const { className } = props

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M4.53223 7.84387C4.46258 7.77424 4.40733 7.69157 4.36963 7.60058C4.33193 7.50959 4.31252 7.41206 4.31252 7.31357C4.31251 7.21509 4.3319 7.11756 4.36958 7.02656C4.40726 6.93557 4.4625 6.85288 4.53214 6.78324C4.67277 6.64257 4.86353 6.56354 5.06244 6.56353C5.16092 6.56352 5.25845 6.58291 5.34945 6.62059C5.44044 6.65827 5.52313 6.71351 5.59277 6.78315L8.25 9.43966V0.75C8.25 0.551088 8.32902 0.360322 8.46967 0.21967C8.61032 0.0790178 8.80109 0 9 0C9.19891 0 9.38968 0.0790178 9.53033 0.21967C9.67098 0.360322 9.75 0.551088 9.75 0.75V9.43966L12.4072 6.78314C12.4769 6.71351 12.5596 6.65827 12.6506 6.62059C12.7415 6.5829 12.8391 6.56351 12.9376 6.56352C13.0361 6.56353 13.1336 6.58294 13.2246 6.62064C13.3156 6.65833 13.3982 6.71359 13.4679 6.78323C13.5375 6.85288 13.5927 6.93556 13.6304 7.02656C13.6681 7.11756 13.6875 7.21508 13.6875 7.31357C13.6875 7.41206 13.6681 7.50958 13.6304 7.60057C13.5927 7.69156 13.5374 7.77424 13.4678 7.84387L9.53027 11.7804C9.38963 11.921 9.19889 12 9 12C8.80111 12 8.61037 11.921 8.46973 11.7804L4.53223 7.84387ZM17.25 10.5C17.0511 10.5 16.8603 10.579 16.7197 10.7197C16.579 10.8603 16.5 11.0511 16.5 11.25V16.5H1.5V11.25C1.5 11.0511 1.42098 10.8603 1.28033 10.7197C1.13968 10.579 0.948912 10.5 0.75 10.5C0.551088 10.5 0.360322 10.579 0.21967 10.7197C0.0790178 10.8603 0 11.0511 0 11.25V16.5C0.000454817 16.8977 0.158636 17.279 0.439842 17.5602C0.721048 17.8414 1.10231 17.9995 1.5 18H16.5C16.8977 17.9995 17.279 17.8414 17.5602 17.5602C17.8414 17.279 17.9995 16.8977 18 16.5V11.25C18 11.0511 17.921 10.8603 17.7803 10.7197C17.6397 10.579 17.4489 10.5 17.25 10.5Z" />
    </svg>
  )
}
