import React from 'react'

import classnames from 'classnames'

import styles from './RadioButton.module.scss'

type Props = {
  checked?: boolean
  title?: string
  onChange?: () => void
  register?: any
  name?: any
  value?: string | boolean
  required?: boolean
  disabled?: boolean
  icon?: string
  iconComponent?: React.ReactNode
  onClick?: () => void
  className?: string
}

export const RadioButton = (props: Props) => {
  const {
    checked,
    title,
    onChange,
    register,
    name,
    value,
    required = false,
    disabled = false,
    icon,
    iconComponent,
    onClick,
    className,
  } = props

  let inputComponent
  if (register && name) {
    inputComponent = (
      <input
        type="radio"
        className={styles.radio}
        {...register(name, { required })}
        value={value}
        required={required}
        disabled={disabled}
      />
    )
  } else {
    inputComponent = (
      <input
        type="radio"
        checked={checked}
        className={styles.radio}
        onChange={onChange}
        required={required}
        disabled={disabled}
        onClick={onClick}
      />
    )
  }
  let prefixIcon: React.ReactNode = <span className={styles.radioLook} />
  if (icon)
    prefixIcon = (
      <img src={`/assets/icons/${icon}.svg`} className={styles.icon} />
    )
  else if (iconComponent) {
    prefixIcon = <div className={styles.icon}>{iconComponent}</div>
  }
  return (
    <label
      className={classnames(styles.radioButton, className, {
        [styles.disabled]: disabled,
        [styles.iconBox]: icon,
      })}
    >
      {inputComponent}
      {prefixIcon}
      <span
        className={classnames(styles.title, { [styles.titleWithIcon]: icon })}
      >
        {title}
      </span>
      <div className={styles.bg}></div>
    </label>
  )
}
