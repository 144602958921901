import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import { Invitations } from '../../components'
import { DashboardLayout } from '../../layout'
import { SortOptions } from '@/components/shared'

import { invitationService, walkInService } from '../../services'
import { InvitationsChannel } from '@/channels'
import { invitationsPath } from '@/features/constants'
import {
  Invitation,
  Employee,
  EventDetailModalType,
  EmployeeType,
} from '@/features/types'
import { axios, getNewTime } from '@/lib/helpers'

type Props = {
  defaultSearch: string
  defaultSort: string
  defaultSortDirection: string
  totalVisitor: number
  totalCheckIn: number
  totalCheckOut: number
  totalPending: number
  defaultInvitations: Invitation[]
  defaultTab: EventDetailModalType
  defaultFromTime: string
  defaultToTime: string
  employee: Employee
  company_token: string
}

const DashboardPage = (props: Props) => {
  // eslint-disable-next-line
  const {
    defaultSearch,
    defaultSort,
    defaultSortDirection,
    defaultInvitations,
    defaultTab,
    defaultFromTime,
    defaultToTime,
    employee,
    totalVisitor,
    totalCheckIn: defaultTotalCheckIn,
    totalCheckOut: defaultTotalCheckout,
  } = props

  const { control, watch } = useForm({
    defaultValues: {
      fromTime:
        (defaultFromTime && {
          label: getNewTime(defaultFromTime),
          value: defaultFromTime,
        }) ||
        undefined,
      toTime:
        (defaultToTime && {
          label: getNewTime(defaultToTime),
          value: defaultToTime,
        }) ||
        undefined,
    },
  })

  const [search, setSearch] = useState<string>(defaultSearch || '')
  const [showEventDetailModal, setShowEventDetailModal] =
    useState<boolean>(false)
  const [invitation, setInvitation] = useState<Invitation>()
  const [invitations, setInvitations] =
    useState<Invitation[]>(defaultInvitations)
  const [tab, setTab] = useState<EventDetailModalType>(defaultTab || 'visitor')
  const sortOptions: SortOptions = {
    sort: defaultSort || '',
    direction: defaultSortDirection || '',
  }
  const [totalCheckIn, setTotalCheckIn] = useState<number>(defaultTotalCheckIn)
  const [totalCheckOut, setTotalCheckOut] =
    useState<number>(defaultTotalCheckout)

  const getSearchParams = (newSort?: string, newDirection?: string) => {
    const sort = newSort || sortOptions.sort
    const direction = newDirection || sortOptions.direction
    const fromTime = watch('fromTime')?.value
    const toTime = watch('toTime')?.value
    const dateQuery =
      fromTime && toTime && `&from_time=${fromTime}&to_time=${toTime}`
    const path = `search=${search}&sort=${sort}&sort_direction=${direction}&tab=${tab}`
    return dateQuery ? `${path}${dateQuery}` : path
  }

  const handleQuery = (newSort?: string, newDirection?: string) => {
    window.location.href = `${invitationsPath}?${getSearchParams(
      newSort,
      newDirection,
    )}`
  }

  const openEventDetailModal = (invitationID: string) => {
    axios
      .get(`${invitationsPath}/${invitationID}`, {})
      .then(({ data }) => setInvitation({ ...data }))
    setShowEventDetailModal(true)
  }

  const onSetVisitorTab = async () => {
    if (tab === 'visitor') return
    setTab('visitor')
    setInvitations([])
    try {
      const invitations = (await invitationService().getInvitations()).data
      setInvitations(invitations)
    } catch {
      window.location.reload()
    }
  }

  const onSetWalkInTab = async () => {
    if (tab === 'walk_in') return
    setTab('walk_in')
    setInvitations([])
    try {
      const walkInInvitations = (await walkInService().walkInInvitations()).data
      setInvitations(walkInInvitations)
    } catch {
      window.location.reload()
    }
  }

  useEffect(() => {
    if (!employee || employee.type === EmployeeType.staff) {
      return
    }
    InvitationsChannel.received = (invitation: Invitation) => {
      const { category } = invitation.invite
      if ((tab === 'visitor' && category === 'local') || tab === category) {
        setInvitations([...invitations, invitation])
      }
    }
  }, [invitations])

  const onCheckIn = () => {
    setTotalCheckIn((prevTotalCheckIn) => prevTotalCheckIn + 1)
  }

  const onCheckOut = () => {
    setTotalCheckOut((prevTotalCheckOut) => prevTotalCheckOut + 1)
  }

  return (
    <DashboardLayout
      {...props}
      sortOptions={sortOptions}
      search={search}
      setSearch={setSearch}
      queryPath={invitationsPath}
      showEventDetailModal={showEventDetailModal}
      onCloseEventDetailModal={() => setShowEventDetailModal(false)}
      invitation={invitation}
      tab={tab}
      onSetVisitorTab={onSetVisitorTab}
      onSetWalkInTab={onSetWalkInTab}
      handleQuery={handleQuery}
      totalVisitor={totalVisitor}
      totalCheckIn={totalCheckIn}
      totalCheckOut={totalCheckOut}
      control={control}
      defaultFromTime={defaultFromTime}
      defaultToTime={defaultToTime}
    >
      <Invitations
        invitations={invitations}
        sortOptions={sortOptions}
        handleSort={handleQuery}
        onClickInvitation={(invitationID: string) =>
          openEventDetailModal(invitationID)
        }
        tab={tab}
        onCheckIn={onCheckIn}
        onCheckOut={onCheckOut}
      />
    </DashboardLayout>
  )
}

export default DashboardPage
