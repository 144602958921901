import React from 'react'

import { InfoSection } from '..'
import styles from './InvitationDetail.module.scss'

import { Invitation } from '@/features/types'
import { getLocaleDateString } from '@/lib/helpers'

type Props = {
  title?: string
  showArea?: boolean
  showDate?: boolean
  showNote?: boolean
  invitation?: Invitation
}

export const InvitationDetail = (props: Props) => {
  const {
    title,
    showArea = false,
    showDate = false,
    showNote = false,
    invitation,
  } = props
  return (
    <>
      <div className={styles.eventNameLabel}>{title}</div>
      {showArea && (
        <InfoSection
          iconName={'pin'}
          value={invitation?.area_to_access}
          className={styles.contentWrapper}
        />
      )}
      {showDate && (
        <InfoSection
          iconName={'calendar-fill'}
          value={
            invitation?.start_datetime &&
            getLocaleDateString(invitation?.start_datetime, {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
              hour: 'numeric',
              minute: '2-digit',
            })
          }
          className={styles.contentWrapper}
        />
      )}
      <InfoSection
        iconName={'user'}
        value={invitation?.host_employee_name || '-'}
        className={styles.contentWrapper}
      />
      <InfoSection
        iconName={'suitcase'}
        value={invitation?.asset}
        className={styles.contentWrapper}
      />
      {showNote && (
        <InfoSection
          iconName={'note'}
          value={invitation?.visitor_note}
          className={styles.contentWrapper}
        />
      )}
    </>
  )
}
