import React from 'react'

import classnames from 'classnames'

import styles from './Button.module.scss'

export type ButtonStyle =
  | 'Primary'
  | 'Secondary'
  | 'Tertiary'
  | 'Delete'
  | 'DeleteSecondary'
  | 'Link'
  | 'Ghost'
  | 'GhostGray'
  | 'Rounded'
  | 'Emerald'
export type ButtonSize = 'XS' | 'S' | 'M' | 'L' | 'XL'
export type ButtonType = 'button' | 'submit' | 'reset'
interface IButton {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  icon?: string
  iconComponent?: React.ReactNode
  text?: string
  style?: ButtonStyle
  type?: ButtonType
  form?: string
  size?: ButtonSize
  disabled?: boolean
  className?: string
}

export const Button = (props: IButton) => {
  const {
    onClick,
    icon,
    iconComponent,
    text,
    style = 'Primary',
    size = 'M',
    type = 'button',
    form,
    disabled = false,
    className,
  } = props

  const buttonStyle = (buttonStyle: ButtonStyle) => {
    switch (buttonStyle) {
      case 'Secondary':
        return styles.secondary
      case 'Tertiary':
        return styles.tertiary
      case 'Delete':
        return styles.delete
      case 'DeleteSecondary':
        return styles.deleteSecondary
      case 'Link':
        return styles.link
      case 'Ghost':
        return styles.ghost
      case 'GhostGray':
        return styles.ghostGray
      case 'Rounded':
        return styles.rounded
      case 'Emerald':
        return styles.emerald
      default:
        return styles.primary
    }
  }
  const sizeStyle = (size: ButtonSize) => {
    switch (size) {
      case 'XS':
        return styles.xs
      case 'S':
        return styles.s
      case 'L':
        return styles.l
      case 'XL':
        return styles.xl
      default:
        return styles.m
    }
  }
  const iconHeight = (size: ButtonSize) => {
    switch (size) {
      case 'XS':
        return 14
      case 'S':
        return 18
      case 'L':
        return 24
      case 'XL':
        return 24
      default:
        return 20
    }
  }

  return (
    <button
      type={type}
      onClick={onClick}
      form={form}
      disabled={disabled}
      className={classnames(
        styles.button,
        buttonStyle(style),
        sizeStyle(size),
        className,
      )}
    >
      <div className={styles.content}>
        {icon && (
          <img
            src={icon}
            className={text && styles.marginRightIcon}
            height={iconHeight(size)}
          />
        )}
        {iconComponent}
        <div className={styles.text}>{text}</div>
      </div>
    </button>
  )
}
