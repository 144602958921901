import React from 'react'

import { UserSetUpPage } from '@/components/shared'

import { SystemAdmin } from '@/features/types'

type Props = {
  systemAdmin: SystemAdmin
}

const SystemAdminSetUpPage = (props: Props) => {
  const { systemAdmin } = props

  return <UserSetUpPage user={systemAdmin} isSystemAdmin />
}

export default SystemAdminSetUpPage
