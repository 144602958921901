// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ActionLogsPage-module__logs___1XE-d{padding-bottom:32px;margin-top:8px}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/logs/pages/ActionLogsPage/ActionLogsPage.module.scss"],"names":[],"mappings":"AAAA,qCACE,mBAAA,CACA,cAAA","file":"ActionLogsPage.module.scss","sourcesContent":[":local(.logs) {\n  padding-bottom: 32px;\n  margin-top: 8px;\n}\n"]}]);
// Exports
exports.locals = {
	"logs": "ActionLogsPage-module__logs___1XE-d"
};
module.exports = exports;
