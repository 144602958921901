import React from 'react'

import { FieldValues, UseFormRegister } from 'react-hook-form'

import styles from './VisitorForm.module.scss'
import { ImageField, TextField } from '@/components/shared'

import { Invitation, Visitor } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  onSubmit: (event?: any) => void
  register: UseFormRegister<FieldValues>
  visitor: Visitor
  invitation: Invitation
  handleUpload: (event: any) => void
  handleCancelUpload: () => void
}

export const VisitorForm = (props: Props) => {
  const {
    onSubmit,
    register,
    visitor,
    invitation,
    handleUpload,
    handleCancelUpload,
  } = props

  return (
    <>
      <span className={styles.title}>
        {t('views.features.visitor.edit.title')}
      </span>
      <form
        id={'visitorRegistrationForm'}
        onSubmit={onSubmit}
        className={styles.formSection}
      >
        <TextField
          title={t('views.features.visitor.edit.first_name')}
          placeholder={t('views.features.visitor.edit.first_name')}
          required
          defaultValue={visitor.first_name}
          name="first_name"
          register={register}
          className={styles.field}
        />
        <TextField
          title={t('views.features.visitor.edit.last_name')}
          placeholder={t('views.features.visitor.edit.last_name')}
          required
          defaultValue={visitor.last_name}
          name="last_name"
          register={register}
          className={styles.field}
        />
        <TextField
          title={t('views.misc.email')}
          placeholder={'name@email.com'}
          required
          type="email"
          defaultValue={visitor.email}
          name="email"
          register={register}
          readOnly
          className={styles.field}
        />
        <TextField
          type={'tel'}
          title={t('views.misc.telephone')}
          placeholder={'xxx-xxx-xxxx'}
          pattern={'[0-9]{3}-[0-9]{3}-[0-9]{4}'}
          defaultValue={visitor.telephone}
          name="telephone"
          required
          register={register}
          className={styles.field}
        />
        {!invitation.online_link && (
          <TextField
            title={t('views.features.visitor.edit.asset')}
            defaultValue={invitation.asset}
            name="asset"
            register={register}
            className={styles.field}
          />
        )}
        <ImageField
          title={t('views.features.visitor.edit.image')}
          required
          className={styles.field}
          onUpload={handleUpload}
          onCancel={handleCancelUpload}
          defaultImagePath={visitor.image.url}
        />
        <div className={styles.suggestion}>
          {t('views.features.visitor.edit.image_suggestion')}
        </div>
        <TextField
          type={'textarea'}
          title={t('views.features.visitor.edit.visitor_note')}
          defaultValue={invitation.visitor_note}
          name="visitor_note"
          register={register}
          className={styles.field}
        />
      </form>
    </>
  )
}
