import { logsPath } from '@/features/constants'
import { axios } from '@/lib/helpers'

export const logService = () => {
  return {
    getLogs: async (searchParams?: string) => {
      try {
        const res = await axios.get(`${logsPath}?${searchParams}`, {})
        return res.data
      } catch (_err) {
        window.location.reload()
      }
    },
  }
}
