import React from 'react'

type Props = {
  useCurrentColor?: boolean
  className?: string
}

export const UsersIcon = (props: Props) => {
  const { useCurrentColor = true, className } = props
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={useCurrentColor ? 'currentColor' || '#3873e4' : ''}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path d="M3.33331 7.5C4.71402 7.5 5.83331 6.38071 5.83331 5C5.83331 3.61929 4.71402 2.5 3.33331 2.5C1.9526 2.5 0.833313 3.61929 0.833313 5C0.833313 6.38071 1.9526 7.5 3.33331 7.5Z" />
        <path d="M6.075 9.22504C5.23625 9.74843 4.54449 10.4767 4.06485 11.3412C3.58521 12.2057 3.33347 13.1781 3.33333 14.1667H1.66667C1.22504 14.1654 0.801888 13.9894 0.489612 13.6771C0.177337 13.3648 0.00131894 12.9417 0 12.5L0 10.8334C0.00197841 10.1709 0.266006 9.53621 0.734419 9.06779C1.20283 8.59938 1.83757 8.33535 2.5 8.33337H4.16667C4.53048 8.33419 4.88972 8.41441 5.21933 8.56842C5.54893 8.72242 5.84095 8.94651 6.075 9.22504Z" />
        <path d="M16.6667 7.5C18.0474 7.5 19.1667 6.38071 19.1667 5C19.1667 3.61929 18.0474 2.5 16.6667 2.5C15.286 2.5 14.1667 3.61929 14.1667 5C14.1667 6.38071 15.286 7.5 16.6667 7.5Z" />
        <path d="M20 10.8334V12.5C19.9987 12.9417 19.8227 13.3648 19.5104 13.6771C19.1982 13.9894 18.775 14.1654 18.3334 14.1667H16.6667C16.6666 13.1781 16.4148 12.2057 15.9352 11.3412C15.4556 10.4767 14.7638 9.74843 13.925 9.22504C14.1591 8.94651 14.4511 8.72242 14.7807 8.56842C15.1103 8.41441 15.4696 8.33419 15.8334 8.33337H17.5C18.1625 8.33535 18.7972 8.59938 19.2656 9.06779C19.734 9.53621 19.9981 10.1709 20 10.8334Z" />
        <path d="M10 9.16667C11.841 9.16667 13.3334 7.67428 13.3334 5.83333C13.3334 3.99238 11.841 2.5 10 2.5C8.15907 2.5 6.66669 3.99238 6.66669 5.83333C6.66669 7.67428 8.15907 9.16667 10 9.16667Z" />
        <path d="M15 14.1667V15C14.998 15.6624 14.734 16.2972 14.2656 16.7656C13.7972 17.234 13.1624 17.498 12.5 17.5H7.5C6.83757 17.498 6.20283 17.234 5.73442 16.7656C5.26601 16.2972 5.00198 15.6624 5 15V14.1667C5 13.0616 5.43899 12.0018 6.22039 11.2204C7.00179 10.439 8.0616 10 9.16667 10H10.8333C11.9384 10 12.9982 10.439 13.7796 11.2204C14.561 12.0018 15 13.0616 15 14.1667Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="20" height="20" />
        </clipPath>
      </defs>
    </svg>
  )
}
