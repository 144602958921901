import React from 'react'

import { Invitation, WalkInInvitation } from '..'
import styles from './Invitations.module.scss'
import { Column, SortOptions, Table } from '@/components/shared'

import {
  CovidScreeningATKStatus,
  CovidScreeningFormStatus,
  DashboardTab,
  Invitation as InvitationType,
} from '@/features/types'
import { getCovidScreeningStatus } from '@/lib/covidScreeningHelpers'
import { t } from '@/lib/helpers'

type Props = {
  invitations: InvitationType[]
  sortOptions?: SortOptions
  handleSort: (newSort?: string, newDirection?: string) => void
  onClickInvitation: (invitationID: string) => void
  showPending?: boolean
  tab: DashboardTab
  onCheckIn?: () => void
  onCheckOut?: () => void
}

export const Invitations = (props: Props) => {
  const {
    invitations,
    sortOptions,
    handleSort,
    onClickInvitation,
    showPending,
    tab,
    onCheckIn,
    onCheckOut,
  } = props

  const visitorCol: Column = {
    column: 'visitors.first_name',
    title: t('views.features.invitations.dashboard.visitor'),
    className: styles.visitor,
  }

  const titleCol: Column = {
    column: 'invites.title',
    title: t('views.features.invitations.dashboard.event_name'),
    className: styles.event,
  }

  const hostCol: Column = {
    column: 'employees.first_name',
    title: t('views.features.invitations.dashboard.host'),
    className: tab === 'visitor' ? styles.host : styles.walkInHost,
  }

  const timeCol: Column = {
    column: 'start_datetime',
    title: t('views.features.invitations.dashboard.time'),
    className: styles.time,
  }

  const locationCol: Column = {
    column: 'locations.name',
    title: t('views.features.invitations.dashboard.area_and_asset'),
    className: styles.area,
  }

  const assetCol: Column = {
    column: 'asset',
    title: t('views.features.invitations.dashboard.asset'),
    className: tab === 'visitor' ? styles.asset : styles.walkInAsset,
  }

  const checkInCols: Column = {
    column: 'time_check_in_at',
    title: t('views.misc.check_in'),
    className: styles.check,
  }

  const noTitleCol: Column = {
    column: '',
    title: '',
    className: styles.noTitle,
  }

  const tableColumns = () => {
    switch (tab) {
      case 'visitor':
        return [
          visitorCol,
          titleCol,
          timeCol,
          locationCol,
          noTitleCol,
          noTitleCol,
        ]
      case 'walk_in':
        return [visitorCol, hostCol, assetCol, checkInCols]
    }
  }

  const getEmptyState = () => {
    switch (tab) {
      case 'visitor':
        return t('views.features.invitations.dashboard.no_invited_visitors')
      case 'walk_in':
        return t('views.features.invitations.dashboard.no_walk_in_visitors')
    }
  }

  const data = invitations.map((invitation: InvitationType, idx: number) => {
    const {
      covid_screening_form_status,
      covid_screening_atk_status,
      covid_screening_type,
    } = invitation
    const covidScreeningStatus = getCovidScreeningStatus(
      covid_screening_type,
      covid_screening_form_status as CovidScreeningFormStatus,
      covid_screening_atk_status as CovidScreeningATKStatus,
    )

    if (tab === 'visitor') {
      return (
        <Invitation
          key={idx}
          invitation={invitation}
          onClick={onClickInvitation}
          showPending={showPending}
          onCheckIn={onCheckIn}
          onCheckOut={onCheckOut}
          covidScreeningStatus={covidScreeningStatus}
        />
      )
    }
    return (
      <WalkInInvitation
        key={idx}
        invitation={invitation}
        onClick={onClickInvitation}
        onCheckOut={onCheckOut}
        covidScreeningStatus={covidScreeningStatus}
      />
    )
  })

  return (
    <div className={styles.background}>
      <div className={styles.tableWrapper}>
        <Table
          columns={tableColumns()}
          data={data}
          sortOptions={sortOptions}
          handleSort={handleSort}
          emptyState={getEmptyState()}
          className={styles.table}
        />
      </div>
    </div>
  )
}
