import React from 'react'

import { useForm } from 'react-hook-form'

import styles from './SignUpPage.module.scss'
import {
  showToast,
  TextField,
  UserLayout,
  userLayoutStyles,
  validatePassword,
  PasswordWithConfirm,
} from '@/components/shared'

import { checkMailPath, employeesPath } from '@/features/constants'
import { axios, t } from '@/lib/helpers'

const employeeSignUpForm = 'employeeSignUpForm'

const SignUpPage = () => {
  const { handleSubmit, register, watch } = useForm()
  const _handleSubmit = async (data: any) => {
    const { company, name, email, password, confirmPassword } = data
    if (!validatePassword(password)) {
      return showToast({
        type: 'alert',
        message: t('views.components.password_complexity.invalid'),
      })
    } else if (password !== confirmPassword) {
      return showToast({
        type: 'alert',
        message: t('views.components.password_complexity.password_not_match'),
      })
    }

    axios
      .post(employeesPath, {
        employee: {
          company,
          name,
          email,
          password,
        },
        type: 'self_signup',
      })
      .then((res) => {
        window.location.href = `${checkMailPath}?email=${encodeURIComponent(
          email,
        )}&type=create_account`
      })
      .catch((err) => {
        window.location.reload()
      })
  }

  return (
    <UserLayout
      form={employeeSignUpForm}
      textBtn={t('views.features.devise.sign_up.sign_up_button')}
      showSignInPath
      className={styles.layout}
    >
      <div className={userLayoutStyles.title}>
        {t('views.features.devise.sign_up.sign_up_header')}
      </div>
      <form
        id={employeeSignUpForm}
        onSubmit={handleSubmit(_handleSubmit)}
        className={userLayoutStyles.formSection}
      >
        <TextField
          legendTitle={t('views.features.devise.sign_up.company_title')}
          register={register}
          name={'company'}
          required
          autoFocus
          className={userLayoutStyles.field}
        />
        <TextField
          legendTitle={t('views.features.devise.sign_up.name_title')}
          name={'name'}
          register={register}
          required
          className={userLayoutStyles.field}
        />
        <TextField
          legendTitle={t('views.features.devise.sign_up.email_title')}
          type={'email'}
          name={'email'}
          register={register}
          required
          autoComplete={'email'}
          className={userLayoutStyles.field}
        />
        <PasswordWithConfirm register={register} watch={watch} />
      </form>
    </UserLayout>
  )
}

export default SignUpPage
