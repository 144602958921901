import React from 'react'

import { useForm } from 'react-hook-form'

import { VisitorLayout } from '../../layout'
import styles from './ScreeningForm.module.scss'
import { CheckboxButtons, RadioButtons } from '@/components/shared'

import { visitorService } from '../../services'
import { covidScreeningChoiceLabels } from '@/features/constants'
import { CovidScreening, CovidScreeningType } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  covidScreening: CovidScreening
  invitationCovidScreeningType: CovidScreeningType
}

const screeningForm = 'screeningForm'

export const ScreeningForm = (props: Props) => {
  const { covidScreening, invitationCovidScreeningType } = props

  const { symptoms, q1, q2, q3, q4, q5, q6 } = covidScreening
  const { handleSubmit, register } = useForm({
    defaultValues: {
      q1: q1?.toString(),
      q2: q2?.toString(),
      q3: q3?.toString(),
      q4: q4?.toString(),
      q5: q5?.toString(),
      q6: q6?.toString(),
      symptoms,
    },
  })
  const choices = covidScreeningChoiceLabels.map((choice) => ({
    title: t(`views.features.visitor.covid_screening.screening_form.${choice}`),
    value: choice.replaceAll('_', ' '),
  }))

  const _handleSubmit = async (data: any) => {
    const url = new URL(window.location.href)
    const invitationSlug = url.searchParams.get('invitation_id') || ''
    await visitorService().submitScreeningForm(
      covidScreening.slug,
      data,
      invitationSlug,
      invitationCovidScreeningType === CovidScreeningType.both,
    )
  }

  const registerOptions = [
    {
      label: t('views.features.visitor.components.choice_yes'),
      value: 'true',
    },
    {
      label: t('views.features.visitor.components.choice_no'),
      value: 'false',
    },
  ]

  const yesNoQuestions = [...Array(6).keys()].map((idx) => {
    const question = `q${idx + 1}`
    return (
      <RadioButtons
        key={question}
        registerOptions={registerOptions}
        title={t(
          `views.features.visitor.covid_screening.screening_form.${question}`,
        )}
        name={question}
        horizontal
        register={register}
        className={styles.question}
      />
    )
  })
  return (
    <VisitorLayout
      form={screeningForm}
      textNextBtn={t('views.components.layout.visitor.next')}
      logoUrl={covidScreening.logo_url}
    >
      <span className={styles.title}>
        {t('views.features.visitor.covid_screening.screening_form.title')}
      </span>
      <form id={screeningForm} onSubmit={handleSubmit(_handleSubmit)}>
        {yesNoQuestions}
        <div className={styles.question}>
          <span className={styles.label}>
            {t('views.features.visitor.covid_screening.screening_form.q7')}
          </span>
          <CheckboxButtons
            choices={choices}
            name={'symptoms'}
            register={register}
          />
        </div>
      </form>
    </VisitorLayout>
  )
}
