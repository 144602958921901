import React from 'react'

import classnames from 'classnames'

import styles from './Logs.module.scss'

import { Delivery } from '@/features/types'
import { getLocaleDateString, getLocaleTimeString } from '@/lib/helpers'

type Props = {
  delivery: Delivery
}

export const DeliveryLog = (props: Props) => {
  const { delivery } = props

  const {
    created_at,
    updated_at,
    owner_name,
    image,
    mark_as_received_employee_name,
    sender,
  } = delivery
  return (
    <tr className={styles.deliveryLogText}>
      <td>
        <img src={image.url} height={60} className={styles.parcelImage} />
      </td>
      <td>
        <div className={classnames(styles.textEllipsis, styles.ownerText)}>
          {owner_name || '-'}
        </div>
      </td>
      <td>
        <div className={classnames(styles.textEllipsis, styles.senderText)}>
          {sender}
        </div>
      </td>
      <td>
        <div>{getLocaleDateString(created_at)}</div>
        <div className={classnames(styles.timeText)}>
          {getLocaleTimeString(created_at)}
        </div>
      </td>
      <td>
        <div>{getLocaleDateString(updated_at)}</div>
        <div className={styles.timeText}>{getLocaleTimeString(updated_at)}</div>
      </td>
      <td className={classnames(styles.textEllipsis)}>
        {mark_as_received_employee_name || '-'}
      </td>
    </tr>
  )
}
