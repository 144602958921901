import React from 'react'

import classnames from 'classnames'

import styles from './InfoSection.module.scss'
import { UserIcon } from '@/components/icons'

import { maskText } from '@/lib/helpers'

type InfoSectionProps = {
  label?: string
  iconName?: string
  value?: React.ReactNode | string
  masked?: boolean
  className?: string
}

export const InfoSection = (props: InfoSectionProps) => {
  const { label, iconName, value = '', masked, className } = props
  let labelAndIcon
  if (label) {
    labelAndIcon = <div className={styles.label}>{label}</div>
  } else if (iconName) {
    labelAndIcon = (
      <div className={styles.icon}>
        {iconName === 'user' ? (
          <UserIcon className={styles.userIcon} />
        ) : (
          <img src={`/assets/icons/${iconName}.svg`} />
        )}
      </div>
    )
  }

  let valueComponent = value || '-'
  if (typeof valueComponent === 'string') {
    valueComponent = masked ? (
      <span className={styles.masked}>
        {maskText(valueComponent as string)}
      </span>
    ) : (
      valueComponent
    )
  }

  return (
    <div className={classnames(styles.infoSection, className)}>
      {labelAndIcon}
      <div className={styles.value}>
        {label && ':'} {valueComponent}
      </div>
    </div>
  )
}
