import React, { useState } from 'react'

import { useForm } from 'react-hook-form'

import { VisitorLayout } from '../../layout'
import { showToast } from '@/components/shared'

import { visitorService } from '../../services'
import {
  errorsNotFoundPath,
  vaccineOptions,
  visitorsPath,
} from '@/features/constants'
import {
  Invitation,
  RiskAssessment,
  RiskAssessmentStatus,
  Vaccine,
  VaccineFormData,
} from '@/features/types'
import { axios, formatDate, t } from '@/lib/helpers'

import { VaccinatedForm } from '../../components/VaccinatedForm'
import { VaccineContext } from '../../context/VaccineContext'
import SuccessPage from '../SuccessPage'

type Props = {
  invitation?: Invitation
  risk_assessment: RiskAssessment
}

const RegistrationPage = (props: Props) => {
  const { invitation, risk_assessment } = props

  const { image: default_image, vaccines, id, request_status } = risk_assessment
  const [vaccineImage, setVaccineImage] = useState<File>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [vaccinated, setVaccinated] = useState<boolean | undefined>(
    id ? vaccines.length > 0 : undefined,
  )
  const [existingVaccines, setExistingVaccines] = useState<Vaccine[]>(vaccines)
  const {
    handleSubmit,
    register,
    watch,
    reset,
    getValues,
    control,
    unregister,
  } = useForm()

  const [showSuccessPage, setShowSuccessPage] = useState<boolean>(false)
  if (showSuccessPage) {
    return <SuccessPage />
  }

  const handleUploadImage = (file: File) => setVaccineImage(file)
  const handleCancelUploadImage = () => setVaccineImage(undefined)

  const getVaccineName = (selectedOption?: string, otherField?: string) => {
    const otherText = vaccineOptions.slice(-1).pop()?.value
    if (selectedOption === otherText && otherField) return otherField

    return selectedOption || ''
  }

  const _handleSubmit = async (data: any) => {
    const dataKeys = Object.keys(data)
    const numberSlots = dataKeys.length / 2
    if (dataKeys.filter((keyA) => !data[keyA]).length > 0) {
      return showToast({
        type: 'alert',
        message: t(
          'views.features.visitor.edit.vaccinated.fill_remove_vaccines',
        ),
      })
    }
    setIsLoading(true)
    const vaccines: Vaccine[] = []
    for (let idx = 1; idx <= numberSlots; idx++) {
      const name = getVaccineName(
        data[`dose_${idx}_name`],
        data[`dose_${idx}_other`],
      )
      const date = formatDate(data[`dose_${idx}_date`])
      if (name === '' || date === '') break

      vaccines.push({
        name,
        date,
        status:
          risk_assessment.vaccines && risk_assessment.vaccines.length > idx
            ? risk_assessment.vaccines[idx].status
            : RiskAssessmentStatus.not_confirm,
      })
    }

    const vaccineFormData: VaccineFormData = {
      vaccines,
      image: vaccinated ? vaccineImage : '',
      invitationSlug: invitation?.slug,
    }
    try {
      const response = await visitorService().submitVaccinationForm(
        risk_assessment.visitor_slug,
        vaccineFormData,
      )
      if (response.status == 200) {
        setIsLoading(false)
        if (invitation) {
          window.location.href = `${visitorsPath}/${risk_assessment.visitor_slug}?invitation_id=${invitation.slug}`
        } else {
          setShowSuccessPage(true)
        }
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err?.response?.status === 404) {
        window.location.href = errorsNotFoundPath
      } else {
        window.location.reload()
      }
    }
  }

  const handleClickNoVaccine = () => {
    reset({})
    handleCancelUploadImage()
  }

  const handleClickClearVaccine = (slotNumber: number) => {
    if (slotNumber <= existingVaccines.length) {
      const newExistingVaccines = existingVaccines
      newExistingVaccines.splice(slotNumber - 1, 1)
      setExistingVaccines(newExistingVaccines)
    }
    let data = getValues()
    const allSlotNumber = Object.keys(data).length / 2
    for (let slot = slotNumber + 1; slot <= allSlotNumber; slot++) {
      data[`dose_${slot - 1}_name`] = data[`dose_${slot}_name`]
      data[`dose_${slot - 1}_date`] = data[`dose_${slot}_date`]
    }
    unregister(`dose_${allSlotNumber}_name`)
    unregister(`dose_${allSlotNumber}_date`)
    delete data[`dose_${allSlotNumber}_name`]
    delete data[`dose_${allSlotNumber}_date`]
    reset(data)
  }

  return (
    <VaccineContext.Provider
      value={{
        riskAssessment: risk_assessment,
        vaccineImagePath:
          request_status !== RiskAssessmentStatus.verified
            ? default_image.url
            : '',
        isLoading,
        setIsLoading,
        vaccinated,
        setVaccinated,
        existingVaccines,
        setExistingVaccines,
        register,
        watch,
        control,
        handleUploadImage,
        handleCancelUploadImage,
        handleClickClearVaccine,
        handleClickNoVaccine,
      }}
    >
      <VisitorLayout
        textNextBtn={t('views.components.layout.visitor.next')}
        isLoading={isLoading}
        form={'vaccinatedForm'}
        logoUrl={invitation?.logo_url}
      >
        <VaccinatedForm onSubmit={handleSubmit(_handleSubmit)} />
      </VisitorLayout>
    </VaccineContext.Provider>
  )
}

export default RegistrationPage
