import consumer from './consumer'

export const AtkReportsChannel = consumer.subscriptions.create(
  'AtkReportsChannel',
  {
    connected() {},
    disconnected() {},
    received(_data) {},
  },
)
