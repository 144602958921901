import React, { ChangeEvent, useState } from 'react'

import {
  ISelectOption,
  SortOptions,
  UserManagementPage,
} from '@/components/shared'

import { adminService } from '../services'
import { Employee, EmployeeStatus } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  employees: Employee[]
  employee: Employee
  defaultSearch?: string
  defaultSort?: string
  defaultSortDirection?: string
  defaultType?: string
}

const StaffPage = (props: Props) => {
  const {
    employees,
    employee,
    defaultSearch,
    defaultSort,
    defaultSortDirection,
    defaultType,
  } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const sortOptions: SortOptions = {
    sort: defaultSort || '',
    direction: defaultSortDirection || '',
  }

  const getSearchParams = (params: any) => {
    const { newSort, newDirection, newSearch, newType } = params
    const search =
      newSearch === null || newSearch === undefined
        ? defaultSearch || ''
        : newSearch
    const sort = newSort || sortOptions.sort
    const direction = newDirection || sortOptions.direction
    const type = newType || defaultType || 'All'

    return `search=${search}&sort=${sort}&sort_direction=${direction}&type=${type}`
  }

  const handleQuery = (params: any) => {
    window.location.href = `/employees?${getSearchParams(params)}`
  }

  const handleSelectRole = (type: string) => {
    const params = {
      newSort: sortOptions.sort,
      newDirection: sortOptions.direction,
      newType: type,
    }
    window.location.href = `/employees?${getSearchParams(params)}`
  }

  const onCreateEmployee = (data: any) => {
    adminService().createEmployee(data.name, data.email, data.role, data.image)
  }

  const onChangeStatus = async (checked: boolean, id: string) => {
    const newStatus = checked ? EmployeeStatus.enable : EmployeeStatus.disable
    await adminService().updateEmployeeStatus(id, newStatus)
    window.location.reload()
  }

  const onEdit = async (id: string, data: any) => {
    await adminService().editEmployee(
      id,
      data.name,
      data.email,
      data.role,
      data.image,
    )
  }

  const onDelete = async (id: string) => {
    await adminService().deleteEmployee(id)
    window.location.reload()
  }

  const roleOptions: ISelectOption[] = [
    {
      label: t('views.misc.all'),
      value: 'All',
    },
    {
      label: t('views.misc.staff'),
      value: 'Staff',
    },
    {
      label: t('views.misc.admin'),
      value: 'Admin',
    },
    {
      label: t('views.misc.owner'),
      value: 'Owner',
    },
  ]

  const handleImportAccount = (event: ChangeEvent<HTMLInputElement>) => {
    setIsLoading(true)
    const files = event.target.files
    files && adminService().importAccount(files[0])
  }

  const resendConfirmation = (id: number) => {
    adminService().resendConfirmation(id)
  }

  return (
    <UserManagementPage
      isLoading={isLoading}
      users={employees}
      user={employee}
      defaultSearch={defaultSearch}
      defaultSort={defaultSort}
      defaultSortDirection={defaultSortDirection}
      defaultType={defaultType}
      onCreate={onCreateEmployee}
      onChangeStatus={onChangeStatus}
      onEdit={onEdit}
      onDelete={onDelete}
      handleQuery={handleQuery}
      handleSelectRole={handleSelectRole}
      roleOptions={roleOptions}
      isSystemAdmin={false}
      handleImportAccount={handleImportAccount}
      resendConfirmation={resendConfirmation}
    />
  )
}

export default StaffPage
