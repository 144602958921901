import React from 'react'

import styles from './TermsPage.module.scss'
import { ApplicationLayout, Link } from '@/components/shared'

const WEBSITE_NAME = window.location.hostname
const LAST_UPDATED_DATE = '7 December 2021'

type Props = {
  vendorTermsUrl?: string
}

const TermsPage = (props: Props) => {
  const { vendorTermsUrl } = props
  return (
    <ApplicationLayout>
      <div className={styles.background}>
        <h1 className={styles.title}>Terms and conditions</h1>
        <div className={styles.termsWrapper}>
          <div className={styles.fadeTextTop}></div>
          <div className={styles.terms}>
            <div>
              These terms and conditions (&quot;Terms&quot;,
              &quot;Agreement&quot;) are an agreement between nDataThoth Co.,
              Ltd. (&quot;nDataThoth Co., Ltd.&quot;, &quot;us&quot;,
              &quot;we&quot; or &quot;our&quot;) and you (&quot;User&quot;,
              &quot;you&quot; or &quot;your&quot;). This Agreement sets forth
              the general terms and conditions of your use of the service called
              &quot;AnyVisit&quot; and any of its related website, products and
              services (collectively, &quot;AnyVisit&quot;).
            </div>
            <div>
              <h2 className={styles.label}>1. Accounts and membership</h2>
              When you create an account on the AnyVisit or Website (&quot;User
              Account&quot;), you are responsible for maintaining the security
              of your User Account and you are fully responsible for all
              activities that occur under such User Account and any other
              actions taken in connection with it. We may, but have no
              obligation to, monitor and review new accounts before you may sign
              in and use of any AnyVisit&apos;s services on behalf of your User
              Account. Providing false contact information of any kind may
              result in the termination of your User Account. You must
              immediately notify us of any unauthorized uses of your User
              Account or any other breaches of security. We will not be liable
              for any acts or omissions by you, including any damages of any
              kind incurred as a result of such acts or omissions. We may
              suspend, disable, or delete your User Account (or any part
              thereof) if we determine that you have violated any provision of
              this Agreement or that your conduct or content would tend to
              damage our reputation and goodwill. If we delete your User Account
              for the foregoing reasons, you may not re-register for the use of
              the AnyVisit. We may block your email address and Internet
              protocol address to prevent further registration on the AnyVisit.
            </div>
            <div>
              <h2 className={styles.label}>2. User content</h2>
              We do not own any data, information or material
              (&quot;Content&quot;) that you submit on the AnyVisit or Website
              in the course of using its services. You shall have sole
              responsibility for the accuracy, quality, integrity, legality,
              reliability, appropriateness, and intellectual property ownership
              or right to use of all submitted Content. We may monitor and
              review Content on the AnyVisit or Website submitted or created
              using by you. Unless specifically permitted by you, your use of
              the AnyVisit or Website does not grant us the license to use,
              reproduce, adapt, modify, publish or distribute the Content
              created by you or stored in your user account for commercial,
              marketing or any similar purpose. Still, you grant us permission
              to access, copy, distribute, store, transmit, reformat, display,
              and perform the Content of your user account solely as required
              for the purpose of providing the AnyVisit&apos;s services to you.
              Without limiting any of those representations or warranties, we
              have the right, though not the obligation, to, in our own sole
              discretion, refuse or remove any Content that, in our reasonable
              opinion, violates any of our policies or is in any way harmful or
              objectionable.
            </div>
            <div>
              <h2 className={styles.label}>3. Supported browsers</h2>
              The AnyVisit is available to be use through the website
              {` ${WEBSITE_NAME}`} (the &quot;Website&quot;). However, please
              note that the AnyVisit is incompatible with Internet Explorer
              browser. If you experience difficulty in using the AnyVisit,
              please check on the Website for more detail or you contact us via
              the provided channel in &quot;Contacting Us&quot;.
            </div>
            <h2 className={styles.label}>4. Uses of the AnyVisit</h2>
            <ol>
              <li>
                <span>4.1</span> The AnyVisit is the visitor management software
                that shall be use for the purpose of internal business
                assistance in form of monitors and records information about
                visitors that visit the areas or premises.
              </li>
              <li>
                <span>4.2</span> You may only use the AnyVisit for the purpose
                specified in Clause 4.1 on the premises or area you designated
                during the registration process.
              </li>
              <li>
                <span>4.3</span> Subject to the terms and conditions of this
                Agreement, we grant you a limited, non-exclusive,
                non-transferable right (without a right to sub-license) to
                access and use the AnyVisit for your internal business purposes
                in a manner consistent with the purpose of visitor management as
                set forth in Clause 4.1 and in compliance with applicable laws.
              </li>
              <li>
                <span>4.4</span> You acknowledge that you shall be fully
                responsible for all use of the AnyVisit, including a use by your
                personnel or authorized persons, and you shall be liable for any
                breach of this Agreement that may occur by your personnel for
                the use of the AnyVisit.
              </li>
              <li>
                <span>4.5</span> You acknowledge that during your use of the
                AnyVisit, you will be acting as the data controller under
                Personal Data Protection Act B.E. 2562 (2019) (&quot;PDPA&quot;)
                and nDataThoth Co., Ltd. will be acting as your data processor.
                In addition, you acknowledge that you will entering into any
                agreements that required under the PDPA with nDataThoth Co.,
                Ltd. (if any)
              </li>
              <li>
                <span>4.6</span> Throughout the service period of the AnyVisit,
                including the free-trial period (if any), you shall:
                <ol>
                  <li>
                    (a) not allow any other person other than your relevant
                    personnel or authorized person to use the AnyVisit;
                  </li>
                  <li>
                    (b) not use the AnyVisit in a manner that may impair,
                    interfere with or damage or cause harm or distress to any
                    person;
                  </li>
                  <li>
                    (c) comply with all our instructions, manuals, guideline and
                    policies from time to time in respect of the use of the
                    AnyVisit;
                  </li>
                  <li>
                    (d) comply with all requirements under PDPA including any
                    regulations, notification, and orders in relation to the
                    PDPA for the processing of personal information during the
                    use of the AnyVisit.
                  </li>
                  <li>
                    (e) use the information made available to you while using
                    the AnyVisit at your own risk;
                  </li>
                  <li>
                    (f) be liable for all access to and use of the AnyVisit
                    whether by you, your personnel or your authorized persons;
                  </li>
                  <li>
                    (g) promptly notify us in the event of a breach of security
                    or any unauthorised use of the AnyVisit after you are aware
                    of such breach or unauthorized uses;
                  </li>
                  <li>
                    (h) ensure that your equipment or devices are compatible to
                    use the AnyVisit at your own risk and responsibilities; and
                  </li>
                  <li>
                    (i) ensure that the visitors or other persons that you use
                    the AnyVisit for are aware that their personal information,
                    especially the vaccination information, will be collected
                    via the AnyVisit.
                  </li>
                </ol>
              </li>
              <li>
                <span>4.7</span> You acknowledge that you will not be able to
                access and use certain functionalities of the AnyVisit unless
                you have internet connection. All traffic charges or access
                charges incurred due to the use of the internet to operate the
                AnyVisit are subject to your agreed terms with your mobile
                network provider, and we shall not be liable for data traffic
                charges incurred by you, either in full or in part.
              </li>
            </ol>
            <h2 className={styles.label}>5. Prohibited uses</h2>
            <ol>
              <li>
                <span>5.1</span> In addition to other terms as set forth in the
                Agreement, you shall use the AnyVisit solely for your internal
                business purposes as specified in Clause 4, in compliance with
                applicable law and you are prohibited from using the AnyVisit or
                its Content for the following actions and purposes:
                <ol>
                  <li>
                    <span>(a)</span> for any unlawful purpose;
                  </li>
                  <li>
                    <span>(b)</span> to solicit others to perform or participate
                    in any unlawful act;
                  </li>
                  <li>
                    <span>(c)</span> to violate any international, federal,
                    provincial or state regulations, rules, laws, or local
                    ordinances;
                  </li>
                  <li>
                    <span>(d)</span> to infringe upon or violate our
                    intellectual property rights or the intellectual property
                    rights of others;
                  </li>
                  <li>
                    <span>(e)</span> to harass, abuse, insult, harm, defame,
                    slander, disparage, intimidate, or discriminate based on
                    gender, sexual orientation, religion, ethnicity, race, age,
                    national origin, or disability;
                  </li>
                  <li>
                    <span>(f)</span> to submit false or misleading information
                    during User Account&apos;s registration and throughout the
                    service period;
                  </li>
                  <li>
                    <span>(g)</span> to upload or transmit viruses or any other
                    type of malicious code that will or may be used in any way
                    that will affect the functionality or operation of AnyVisit
                    or of any related website, other websites, or the Internet;
                  </li>
                  <li>
                    <span>(h)</span> to collect or track the personal
                    information of others for your own purpose that not related
                    to Clause 4 of this Agreement;
                  </li>
                  <li>
                    <span>(i)</span> to spam, phish, pharm, pretext, spider,
                    crawl, or scrape;
                  </li>
                  <li>
                    <span>(j)</span> for any obscene or immoral purpose; or
                  </li>
                  <li>
                    <span>(k)</span> to interfere with or circumvent the
                    security features of the AnyVisit or any related website,
                    other websites, or the Internet. We reserve the right to
                    terminate your use of the AnyVisit or any related website
                    for violating any of the prohibited uses.
                  </li>
                </ol>
              </li>
              <li>
                <span>5.2</span> Otherwise specified under this Agreement, you
                shall not:
                <ol>
                  <li>
                    <span>(a)</span> resell, sub-license, lease, share or
                    otherwise makes the AnyVisit available to any third party
                    without obtaining written permission from us;
                  </li>
                  <li>
                    <span>(b)</span> process, send, or store infringing or
                    unlawful information or material by using the AnyVisit;
                  </li>
                  <li>
                    <span>(c)</span> attempt to gain an unauthorized access to,
                    or disrupt the integrity or performance of the AnyVisit that
                    you are not entitled; and
                  </li>
                  <li>
                    <span>(d)</span> modify, copy, forgery, reverse-engineer or
                    create derivative works, based on the AnyVisit.
                  </li>
                </ol>
              </li>
            </ol>
            <h2 className={styles.label}>6. Billing and payments</h2>
            <ol>
              <li>
                <span>6.1</span> The fees for the use of the AnyVisit are
                payable prior to the use of its service and on subscription
                payment basis (&quot;Fees&quot;). You shall pay all Fees or
                charges in accordance with the fees, charges, and billing terms
                in effect at the time a fee or charge is due and payable. If
                auto-renewal is enabled for the use of the AnyVisit, you have
                subscribed for, you will be charged automatically in accordance
                with the term you selected. If, in our judgment, your purchase
                constitutes a high-risk transaction, we will require you to
                provide us with a copy of your valid government-issued photo
                identification, and possibly a copy of a recent bank statement
                for the credit or debit card used for the purchase. The Fees for
                the AnyVisit shall be deemed payable upon the issue of our
                invoice.
              </li>
              <li>
                <span>6.2</span> We reserve the right to change pricing or rate
                of the AnyVisit&apos;s Fees at any time. We also reserve the
                right to refuse any order you place with us. We may, in our sole
                discretion, limit or cancel quantities purchased per person, per
                household or per order. <br />
                These restrictions may include orders placed by or under the
                same customer account, the same credit card, and/or orders that
                use the same billing and/or shipping address. In the event that
                we make a change to or cancel an order, we may attempt to notify
                you by contacting the email and/or billing address/phone number
                provided at the time the order was made.
              </li>
              <li>
                <span>6.3</span> The AnyVisit is offering products and services
                for sale. Once we receive your order and payment has
                successfully passed a fraud review, access will be granted to
                the product or service being purchased as soon as possible. Free
                products or services are provided with limited features (if
                any). All fees are non-refundable.
              </li>
            </ol>
            <div>
              <h2 className={styles.label}>7. Refund policy</h2>
              Since the AnyVisit offers non-tangible and irrevocable goods. We
              do not provide refunds after purchased, which you acknowledge
              prior to purchasing any products or services related to AnyVisit.
              Please make sure that you have carefully read the description of
              AnyVisit&apos;s services before making a purchase.
            </div>
            <div>
              <h2 className={styles.label}>8. Accuracy of information</h2>
              Occasionally there may be information on the AnyVisit or Website
              that contains typographical errors, inaccuracies or omissions that
              may relate to promotions and offers. We reserve the right to
              correct any errors, inaccuracies or omissions, and to change or
              update information or cancel orders if any information on the
              AnyVisit, Website or on any related services is inaccurate at any
              time without prior notice (including after you have submitted your
              order). We undertake no obligation to update, amend or clarify
              information on the AnyVisit and Website including, without
              limitation, pricing information, except as required by law. No
              specified update or refresh date applied on the AnyVisit or
              Website should be taken to indicate that all information on the
              AnyVisit, Website or on any related services has been modified or
              updated.
            </div>
            <h2 className={styles.label}>9. Third-party services</h2>
            <ol>
              <li>
                <span>9.1</span> If you decide to enable, access or use
                third-party services, be advised that your access and use of
                such other services are governed solely by the terms and
                conditions of such other services, and we do not endorse, are
                not responsible or liable for, and make no representations as to
                any aspect of such other services, including, without
                limitation, their content or the manner in which they handle
                data (including your data) or any interaction between you and
                the provider of such other services. You irrevocably waive any
                claim against nDataThoth Co., Ltd. with respect to such other
                services. nDataThoth Co., Ltd. is not liable for any damages or
                loss caused or alleged to be caused by or in connection with
                your enablement, access or use of any such other services, or
                your reliance on the privacy practices, data security processes
                or other policies of such other services. You may be required to
                register for or log into such other services on their respective
                websites. By enabling any other services, you are expressly
                permitting nDataThoth Co., Ltd. to disclose your data as
                necessary to facilitate the use or enablement of such other
                service.
              </li>
              <li>
                <span>9.2</span> In addition, we may require an assistance from
                our third-party service providers in order to provide services
                under the AnyVisit. We will ensure that our service providers
                are, if any, only process any of your information or personal
                information for the purpose of provide the AnyVisit under this
                Agreement.
              </li>
            </ol>
            <div>
              <h2 className={styles.label}>10. Uptime guarantee</h2>
              We offer a service uptime guarantee of 99.95% of available time
              per month for the use of the AnyVisit. If we fail to maintain this
              service uptime guarantee in a particular month (as solely
              determined by us), you may contact us and request a credit off
              your Fees for that month. The credit may be used only for the
              purchase of further products and services from us and is exclusive
              of any applicable taxes. The service uptime guarantee does not
              apply to service interruptions caused by: (1) periodic scheduled
              maintenance or repairs we may undertake from time to time; (2)
              interruptions caused by you or your activities; (3) outages that
              do not affect core Service functionality; (4) causes beyond our
              control or that are not reasonably foreseeable; and (5) outages
              related to the reliability of certain programming environments.
            </div>
            <div>
              <h2 className={styles.label}>11. Backups</h2>
              We perform regular backups of the AnyVisit&apos;s information and
              Content and will do our best to ensure completeness and accuracy
              of these backups. In the event of the hardware failure or data
              loss we will restore backups automatically to minimize the impact
              and downtime.
            </div>
            <div>
              <h2 className={styles.label}>12. Links to other websites</h2>
              Although the Website may link to other websites, we are not,
              directly or indirectly, implying any approval, association,
              sponsorship, endorsement, or affiliation with any linked website,
              unless specifically stated herein. We are not responsible for
              examining or evaluating, and we do not warrant the offerings of,
              any businesses or individuals or the content of their websites. We
              do not assume any responsibility or liability for the actions,
              products, services, and content of any other third parties. You
              should carefully review the legal statements and other conditions
              of use of any website which you access through a link from the
              Website. Your linking to any other off-site websites is at your
              own risk.
            </div>
            <div>
              <h2 className={styles.label}>
                13. Personal information processing
              </h2>
              We will collect, use or disclose your personal information
              provided to us on the AnyVisit with caution and in accordance with
              PDPA and the standards set forth in our &quot;Privacy Policy&quot;
              which is considered part of this Agreement.
            </div>
            <h2 className={styles.label}>
              14. Confidentiality and Data Processing
            </h2>
            <ol>
              <li>
                <span>14.1</span> As used herein, &quot;Confidential
                Information&quot; means all confidential and proprietary
                information of a party (&quot;Disclosing Party&quot;) disclosed
                to the other party (&quot;Receiving Party&quot;), that is
                designated as confidential or that reasonably should be
                understood to be confidential given the nature of the
                information and the circumstances of disclosure such as names,
                email, health data, COVID-19 vaccination status, Picture,
                COVID-19 Risk Assessment Survey of the visitor.
              </li>
              <li>
                <span>14.2</span> Confidential Information shall not include any
                information that:
                <ol>
                  <li>
                    <span>(a)</span> is or becomes generally known to the public
                    without breach of any obligation owed to Disclosing Party;
                  </li>
                  <li>
                    <span>(b)</span> was known to Receiving Party prior to its
                    disclosure by us without breach of any obligation owed to
                    Disclosing Party;
                  </li>
                  <li>
                    <span>(c)</span> was independently developed by Receiving
                    Party without breach of any obligation owed to Disclosing
                    Party; or
                  </li>
                  <li>
                    <span>(d)</span> is received from a third party without
                    breach of any obligation owed to Disclosing Party.
                  </li>
                </ol>
              </li>
              <li>
                <span>14.3</span> Receiving Party, which is you, your personnel
                or authorized persons shall not disclose any Confidential
                Information of Disclosing Party for any purpose outside the
                scope of AnyVisit&apos;s usage or as set forth under this
                Agreement, except with Disclosing Party&apos;s prior written
                consent. Receiving Party shall protect the confidentiality of
                Disclosing Party&apos;s Confidential Information in the same
                manner that it protects the confidentiality of its own
                confidential information of like kind (but in no event using
                less than reasonable care). Notwithstanding the foregoing,
                nDataThoth Co., Ltd. may use, for its business purposes, data
                generated by the use of the AnyVisit&apos;s service. Receiving
                Party shall promptly notify Disclosing Party if it becomes aware
                of any actual or reasonably suspected breach of confidentiality
                of Disclosing Party&apos;s Confidential Information.
              </li>
              <li>
                <span>14.4</span> If Receiving Party is compelled by law to
                disclose Confidential Information of Disclosing Party, it shall
                provide Disclosing Party with prior notice of such compelled
                disclosure (to the extent legally permitted) and reasonable
                assistance, at Disclosing Party&apos;s cost, if Disclosing Party
                wishes to contest the disclosure.
              </li>
              <li>
                <span>14.5</span> Upon any termination of this Agreement, the
                Receiving Party shall continue to maintain the confidentiality
                of the Disclosing Party&apos;s Confidential Information as long
                as it remains confidential and, upon request, return to the
                Disclosing Party or destroy (at the Disclosing Party&apos;s
                election) all materials containing such Confidential
                Information.
              </li>
            </ol>
            <div>
              <h2 className={styles.label}>15. Intellectual property rights</h2>
              This Agreement does not transfer to you any intellectual property
              owned by nDataThoth Co., Ltd. or third parties, and all rights,
              titles, and interests in, including without limitation to copy
              rights, patents, trade secret rights and other intellectual
              property rights and to such property will remain (as between the
              parties) solely with nDataThoth Co., Ltd. All trademarks, service
              marks, graphics and logos used in connection with our Website or
              Services, are trademarks or registered trademarks of AnyVisit,
              nDataThoth Co., Ltd. or nDataThoth Co., Ltd.&apos;s licensors.
              Other trademarks, service marks, graphics and logos used in
              connection with the AnyVisit, Website or its related services may
              be the trademarks of other third parties. Your use of AnyVisit
              grants you no right or license to reproduce or otherwise use any
              nDataThoth Co., Ltd., or third-party trademarks.
            </div>
            <h2 className={styles.label}>16. Disclaimer of warranty</h2>
            <ol>
              <li>
                <span>16.1</span> You agree that your use of the AnyVisit,
                Website or its related services is solely at your own risk. You
                agree that such Website and any related services of the AnyVisit
                is provided on an &quot;as is&quot; and &quot;as available&quot;
                basis. We expressly disclaim all warranties of any kind, whether
                express or implied, including but not limited to the implied
                warranties of merchantability, fitness for a particular purpose
                and non-infringement. We make no warranty that the use of the
                AnyVisit will meet your requirements, expectation or that the
                AnyVisit will be uninterrupted, timely, secure, or error-free;
                nor do we make any warranty as to the results that may be
                obtained from the use of the AnyVisit or as to the accuracy or
                reliability of any information obtained through the AnyVisit or
                that defects on the AnyVisit, Website or its services will be
                corrected. You understand and agree that any material and/or
                data downloaded or otherwise obtained through the use of the
                AnyVisit is done at your own discretion and risk and that you
                will be solely responsible for any damages, cost and expenses to
                your computer system or loss of data that results from the
                download of such material and/or data. We make no warranty
                regarding any products or services purchased or obtained through
                the AnyVisit or any transactions entered through the AnyVisit.
              </li>
              <li>
                <span>16.2</span> No waiver by us in any of the terms and
                conditions on this Agreement shall be effective unless it is
                expressly stated to be a waiver and is communicated to you in
                writing by us.
              </li>
            </ol>
            <div>
              <h2 className={styles.label}>17. Limitation of liability</h2>
              To the fullest extent permitted by applicable law, in no event
              shall nDataThoth Co., Ltd., its affiliates, officers, directors,
              employees, agents, suppliers, contractors or licensors be liable
              to any person for any indirect, incidental, special, punitive,
              cover or consequential damages (including, without limitation,
              damages for lost profits, revenue, sales, goodwill, use of
              content, impact on business, business interruption, loss of
              anticipated savings, loss of business opportunity) however caused,
              under any theory of liability, including, without limitation,
              contract, tort, warranty, breach of statutory duty, negligence or
              otherwise, even if nDataThoth Co., Ltd. has been advised as to the
              possibility of such damages or could have foreseen such damages.
              To the maximum extent permitted by applicable law, the aggregate
              liability of nDataThoth Co., Ltd. and its affiliates, officers,
              employees, agents, suppliers, contractors and licensors, relating
              to the AnyVisit or Website will be limited to the actual amount
              paid by you to nDataThoth Co., Ltd. for the occurrence giving rise
              to such liability. The limitations and exclusions shall apply if
              this remedy does not fully compensate you for any damages, losses
              or fails of its essential purpose.
            </div>
            <div>
              <h2 className={styles.label}>18. Indemnification</h2>
              You agree to indemnify and hold nDataThoth Co., Ltd. and its
              affiliates, directors, officers, employees, and agents harmless
              from and against any liabilities, losses, damages or costs,
              including reasonable attorneys&apos; fees, incurred in connection
              with or arising from any third-party allegations, claims, actions,
              disputes, or demands asserted against any of them as a result of
              or relating to your Content, your use of the AnyVisit, Website or
              its related services or any willful misconduct on your part.
            </div>
            <div>
              <h2 className={styles.label}>19. Severability</h2>
              All rights and restrictions contained in this Agreement may be
              exercised and shall be applicable and binding only to the extent
              that they do not violate any applicable laws and are intended to
              be limited to the extent necessary so that they will not render
              this Agreement illegal, invalid or unenforceable. If any provision
              or portion of any provision of this Agreement shall be held to be
              illegal, invalid or unenforceable by a court of competent
              jurisdiction, it is the intention of the parties that the
              remaining provisions or portions thereof shall constitute their
              agreement with respect to the subject matter hereof, and all such
              remaining provisions or portions thereof shall remain in full
              force and effect.
            </div>
            <h2 className={styles.label}>20. Content and postings</h2>
            <ol>
              <li>
                <span>20.1</span> You may not modify, print or copy any part of
                the AnyVisit&apos;s content or Website. Inclusion of any part of
                the AnyVisit&apos;s content or Website in another work, whether
                in printed or electronic or another form or inclusion of any
                part of the AnyVisit&apos;s content or Website in another
                website by embedding, framing or otherwise without the express
                permission of nDataThoth Co., Ltd. is prohibited.
              </li>
              <li>
                <span>20.2</span> You shall not upload Content that is
                defamatory, fraudulent, obscene, threatening, invasive of
                another person&apos;s privacy rights or that is otherwise
                unlawful. You shall not upload Content that infringes on the
                intellectual property rights of any other person or entity. You
                shall not upload any Content that includes any computer virus or
                other code designed to disrupt, damage, or limit the functioning
                of the AnyVisit, any computer software or hardware. By
                submitting or uploading files on the AnyVisit or Website, you
                grant nDataThoth Co., Ltd. the right to remove any file at any
                time and for any reason.
              </li>
            </ol>
            <div>
              <h2 className={styles.label}>
                21. Governing law and Dispute resolution
              </h2>
              The formation, interpretation, and performance of this Agreement
              and any disputes arising out of it shall be governed by the
              substantive and procedural laws of Thailand without regard to its
              rules on conflicts or choice of law and, to the extent applicable,
              the laws of Thailand. The exclusive jurisdiction and venue for
              legal actions related to the subject matter hereof shall be the
              competent courts located in Thailand, and you hereby submit to the
              competent jurisdiction of such courts.
            </div>
            <div>
              <h2 className={styles.label}>22. Assignment</h2>
              You may not assign, resell, sub-license or otherwise transfer or
              delegate any of your rights or obligations hereunder, in whole or
              in part, without our prior written consent, which consent shall be
              at our own sole discretion and without obligation; any such
              assignment or transfer shall be null and void. We are free to
              assign any of its rights or obligations hereunder, in whole or in
              part, to any third parties as part of the sale of all or
              substantially all its assets or stock or as part of a merger.
            </div>
            <div>
              <h2 className={styles.label}>23. Changes and amendments</h2>
              We reserve the right to modify this Agreement or its policies
              relating to the AnyVisit, Website or its related services at any
              time, effective upon posting of an updated version of this
              Agreement on the Website. When we do, we will revise the updated
              date at the bottom of this page. Continued use of the AnyVisit or
              Website after any such changes shall constitute your consent to
              such changes.
            </div>
            <div>
              <h2 className={styles.label}>24. Acceptance of these terms</h2>
              You accept and acknowledge that you have read this Agreement and
              agree to all its terms and conditions. By using the AnyVisit,
              Website or its related services you agree to be bound by this
              Agreement. If you do not agree to abide by the terms of this
              Agreement, you are not authorized to use or access the AnyVisit,
              Website or its related services.
            </div>
            <div>
              <h2 className={styles.label}>25. Contacting us</h2>
              If you would like to contact us to understand more about this
              Agreement or wish to contact us concerning any matter relating to
              it, you may do so via the contact form, send an email to{' '}
              <Link
                url={'mailto:support@datawow.io'}
                text={'support@datawow.io'}
                noUnderLine
              />{' '}
              or write a letter to nDataThoth Co., Ltd., no. 1778 Summer Hub
              Offices, 6th Floor, Sukhumvit Road., Phra Khanong subdistrict,
              Klong Toey district, Bangkok 10110 THAILAND.
            </div>
            <br />
            <div>
              This Agreement was last updated on {`${LAST_UPDATED_DATE}`}.
            </div>
            {vendorTermsUrl && (
              <>
                <br />
                <div>
                  For vendor, please visit{' '}
                  <Link
                    url={vendorTermsUrl}
                    text={'vendor terms and condition'}
                    noUnderLine
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.fadeTextBottom} />
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default TermsPage
