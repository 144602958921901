// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".VaccineLogs-module__table___3qimZ{width:90%;height:100%;margin:16px 0}.VaccineLogs-module__action___3na-6{width:45%}.VaccineLogs-module__requestDateTime___137Nx{width:15%}.VaccineLogs-module__actionDateTime___1i1qo{width:15%}.VaccineLogs-module__note___uTudP{width:25%}.VaccineLogs-module__textEllipsis___1Kuem{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.VaccineLogs-module__actionText___Fvsm1{max-width:38vw;text-align:left;padding:4px 0}.VaccineLogs-module__noteText___244do{max-width:20vw;margin-left:auto}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/verifyVaccine/components/VaccineLogs/VaccineLogs.module.scss"],"names":[],"mappings":"AAEA,mCACE,SAAA,CACA,WAAA,CACA,aAAA,CAGF,oCACE,SAAA,CAGF,6CACE,SAAA,CAGF,4CACE,SAAA,CAGF,kCACE,SAAA,CAGF,0CACE,kBAAA,CACA,eAAA,CACA,sBAAA,CAGF,wCACE,cAAA,CACA,eAAA,CACA,aAAA,CAGF,sCACE,cAAA,CACA,gBAAA","file":"VaccineLogs.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.table) {\n  width: 90%;\n  height: 100%;\n  margin: 16px 0;\n}\n\n:local(.action) {\n  width: 45%;\n}\n\n:local(.requestDateTime) {\n  width: 15%;\n}\n\n:local(.actionDateTime) {\n  width: 15%;\n}\n\n:local(.note) {\n  width: 25%;\n}\n\n:local(.textEllipsis) {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n\n:local(.actionText) {\n  max-width: 38vw;\n  text-align: left;\n  padding: 4px 0;\n}\n\n:local(.noteText) {\n  max-width: 20vw;\n  margin-left: auto;\n}\n"]}]);
// Exports
exports.locals = {
	"table": "VaccineLogs-module__table___3qimZ",
	"action": "VaccineLogs-module__action___3na-6",
	"requestDateTime": "VaccineLogs-module__requestDateTime___137Nx",
	"actionDateTime": "VaccineLogs-module__actionDateTime___1i1qo",
	"note": "VaccineLogs-module__note___uTudP",
	"textEllipsis": "VaccineLogs-module__textEllipsis___1Kuem",
	"actionText": "VaccineLogs-module__actionText___Fvsm1",
	"noteText": "VaccineLogs-module__noteText___244do"
};
module.exports = exports;
