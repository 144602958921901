// Load all the channels within this directory and all subdirectories.
// Channel files must be named *_channel.js.

const channels = require.context('.', true, /_channel\.js$/)
channels.keys().forEach(channels)

export * from './deliveries_channel'
export * from './atk_reports_channel'
export * from './invitations_channel'
export * from './risk_assessments_channel'
