import { onlineMeetingsNewLinkPath } from '@/features/constants'
import { axios, formatDate } from '@/lib/helpers'

export const inviteService = () => {
  const getDateTime = (date: string | Date, time: string | Date) => {
    const dateText = formatDate(new Date(date), 'DD MMM YYYY')
    const dateTime = new Date(`${dateText} ${time}`)
    return dateTime
  }

  const getOnlineLink = (online_link?: string) => {
    if (online_link?.includes('zoom.us')) {
      return { zoom_link: online_link }
    } else if (online_link?.includes('meet.google.com')) {
      return { google_meet_link: online_link }
    } else {
      return {}
    }
  }

  return {
    createNormalInvite: async (data: any) => {
      return axios.post('/invites', {
        invite: {
          title: data.title,
          comment: data.comment,
          host_employee_id: data.host_employee_id,
          visitor_emails: data.emails,
          start_time: getDateTime(
            data.dateTime.fromDate,
            data.dateTime.fromTime || '',
          ),
          end_time: getDateTime(
            data.dateTime.toDate || data.dateTime.fromDate,
            data.dateTime.toTime || '',
          ),
          location: data.location,
          ...getOnlineLink(data.online_link),
        },
      })
    },
    createScheduleInvite: async (data: any) => {
      return axios.post('/invites', {
        invite: {
          title: data.title,
          comment: data.comment,
          host_employee_id: data.host_employee_id,
          visitor_emails: data.emails,
          schedule: data.events,
          location: data.location,
          ...getOnlineLink(data.online_link),
        },
      })
    },
    editNormalInvite: async (data: any) => {
      return axios.patch(`/invites/${data.id}`, {
        invite: {
          title: data.title,
          comment: data.comment,
          host_employee_id: data.host_employee_id,
          visitor_emails: data.emails,
          start_time: getDateTime(
            data.dateTime.fromDate,
            data.dateTime.fromTime || '',
          ),
          end_time: getDateTime(
            data.dateTime.toDate || data.dateTime.fromDate,
            data.dateTime.toTime || '',
          ),
          location: data.location,
          ...getOnlineLink(data.online_link),
        },
      })
    },
    editScheduleInvite: async (data: any) => {
      return axios.patch(`/invites/${data.id}`, {
        invite: {
          title: data.title,
          comment: data.comment,
          host_employee_id: data.host_employee_id,
          visitor_emails: data.emails,
          schedule: data.events,
          location: data.location,
          ...getOnlineLink(data.online_link),
        },
      })
    },
    getInviteDetailFromID: async (id: string, start: Date, end: Date) => {
      return axios.get(`./calendars/${id}`, {
        params: {
          start,
          end,
        },
      })
    },
    getInvitationDetailFromID: async (id: string) => {
      return axios.get(`./invitations/${id}`, {})
    },
    getIntegratedEventFromID: async (id: string) => {
      return axios.get(`./integrated_events/${id}`, {})
    },
    deleteInvite: async (id: string) => {
      return axios.delete(`./invites/${id}`, {})
    },
    getNewOnlineLink: async (type: 'google' | 'zoom') => {
      try {
        const response = await axios.get(
          `${onlineMeetingsNewLinkPath}?type=${type}`,
          {},
        )
        return response.data['link']
      } catch (err) {
        console.error(err)
      }
    },
  }
}
