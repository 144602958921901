import React, { useState } from 'react'

import classnames from 'classnames'

import styles from './Deliveries.module.scss'
import {
  Table,
  Column,
  Button,
  ConfirmModal,
  showToast,
} from '@/components/shared'

import { deliveryService } from '../../services'
import { Delivery } from '@/features/types'
import { getLocaleDateString, getLocaleTimeString, t } from '@/lib/helpers'

import { useDeliveryContext } from '../../context'

const columns: Column[] = [
  {
    title: t('views.features.delivery.parcels'),
    className: styles.parcels,
  },
  {
    column: 'host_employees.first_name',
    title: t('views.misc.owner'),
    className: styles.owner,
  },
  {
    column: 'sender',
    title: t('views.features.delivery.sender'),
    className: styles.sender,
  },
  {
    column: 'deliveries.created_at',
    title: t('views.misc.date'),
    className: styles.date,
  },
  {
    title: t('views.features.delivery.arrive'),
    className: styles.time,
  },
]

export const Deliveries = () => {
  const {
    deliveries,
    lastDeleteID,
    setLastDeleteID,
    sortOptions,
    handleQuery,
    onRemoveDelivery,
  } = useDeliveryContext()
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const data = deliveries.map((delivery: Delivery) => {
    const { id, image, owner_name, sender, created_at } = delivery
    const date = getLocaleDateString(created_at)
    const time = getLocaleTimeString(created_at)
    const isDeleted = id === lastDeleteID && !showConfirm
    return (
      <tr
        key={delivery.id}
        className={isDeleted ? styles.deletingDelivery : styles.delivery}
        onTransitionEnd={() => isDeleted && onRemoveDelivery()}
      >
        <td className={styles.imageWrapper}>
          <img src={image.url} height={60} className={styles.parcelImage} />
        </td>
        <td>
          <div
            className={classnames(styles.textEllipsis, styles.ownerNameText)}
          >
            {owner_name || '-'}
          </div>
        </td>
        <td>
          <div className={classnames(styles.textEllipsis, styles.senderText)}>
            {sender}
          </div>
        </td>
        <td>
          <div className={classnames(styles.textEllipsis, styles.dateText)}>
            {date}
          </div>
        </td>
        <td>
          <div className={classnames(styles.textEllipsis, styles.timeText)}>
            {time}
          </div>
        </td>
        <td>
          <Button
            text={t('views.features.delivery.picked_up')}
            onClick={() => onClickPickedUp(id)}
            className={styles.pickedUpButton}
          />
        </td>
      </tr>
    )
  })

  const onClickPickedUp = (id: number) => {
    setShowConfirm(true)
    setLastDeleteID(id)
  }

  const onCancelDelete = () => {
    setLastDeleteID(undefined)
    setShowConfirm(false)
  }

  const onConfirmDelete = async () => {
    lastDeleteID && (await deliveryService().pickedUp(lastDeleteID))
    setShowConfirm(false)
    showToast({
      type: 'success',
      message: t('views.features.delivery.successfully_picked_up'),
    })
  }

  return (
    <div className={styles.table}>
      <Table
        columns={columns}
        data={data}
        sortOptions={sortOptions}
        handleSort={handleQuery}
        emptyState={t('views.features.delivery.no_new_deliveries')}
      />
      <ConfirmModal
        show={showConfirm}
        onClose={onCancelDelete}
        onConfirm={onConfirmDelete}
        title={t('views.features.delivery.confirm_delete.title')}
        description={t('views.features.delivery.confirm_delete.description')}
        confirmText={t('views.features.delivery.confirm_delete.confirm_text')}
      />
    </div>
  )
}
