import React, { ReactNode } from 'react'

import classnames from 'classnames'

import styles from './MobileLayout.module.scss'
import { ImagePreview } from '@/components/shared'

import { signOutPath } from '@/features/constants'
import { Company, Employee } from '@/features/types'
import { axios } from '@/lib/helpers'

type Props = {
  company?: Company
  employee?: Employee
  children?: ReactNode
  footer?: ReactNode
  className?: string
}

export const MobileLayout = (props: Props) => {
  const { company, employee, children, footer, className } = props

  const signOut = async () => {
    const response = await axios.delete(signOutPath, {})
    if (response.status === 204) {
      window.location.href = '/'
    }
  }

  return (
    <div className={classnames(styles.background, className)}>
      <div className={styles.header}>
        <img
          src={company?.logo_url || '/assets/icons/logo-color.svg'}
          height={36}
        />
        {employee && (
          <div className={styles.employee}>
            <div>
              <div className={styles.employeeName}>
                {employee?.first_name} {employee?.last_name}
              </div>
              <div className={styles.logOutText} onClick={signOut}>
                Log out
              </div>
            </div>
            <ImagePreview
              src={
                employee?.image?.url || '/assets/icons/empty-display-image.svg'
              }
              width={36}
              height={36}
              className={styles.employeeImage}
            />
          </div>
        )}
      </div>
      <div className={styles.container}>{children}</div>
      <div className={styles.footer}>{footer}</div>
    </div>
  )
}
