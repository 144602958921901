import React, { useEffect, useState } from 'react'

import { get } from 'lodash'
import { useTransition, animated } from 'react-spring'

import {
  WalkInWelcome,
  WalkInSuccess,
  WalkInForm,
  WalkInScreeningForm,
  ScreeningSelection,
  WalkInATK,
  WalkInVisitorPolicy,
} from '../../components'
import { WalkInLayout } from '../../layout'
import styles from './WalkInPage.module.scss'
import { ConfirmModal, ISelectOption } from '@/components/shared'

import {
  Company,
  CovidScreening,
  WalkInDeliveryData,
  WalkInVisitorData,
} from '@/features/types'

import { WalkInContext, WalkInStateType, WalkInType } from '../../context'

type Props = {
  company: Company
}

const WalkInPage = (props: Props) => {
  const { company } = props
  const [type, setType] = useState<WalkInType>('welcome')
  const [state, setState] = useState<WalkInStateType>(0)
  const [locale, setLocale] = useState<PlatformLanguage>('th')
  const [visitor, setVisitor] = useState<WalkInVisitorData | null>(null)
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)
  const [covidScreening, setCovidScreening] = useState<CovidScreening | null>(
    null,
  )
  const [host, setHost] = useState<ISelectOption | null>(null)
  const [imgSrc, setImgSrc] = useState<string>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [delivery, setDelivery] = useState<WalkInDeliveryData | null>(null)
  const t = (key: string) => {
    return get(gon.locales, `${locale}.${key}`, key)
  }

  const transitions = useTransition(type, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  useEffect(() => {
    if (type === 'welcome') {
      setVisitor(null)
      setCovidScreening(null)
      setImgSrc(undefined)
      setHost(null)
      setDelivery(null)
    }
  }, [type])

  return (
    <WalkInContext.Provider
      value={{
        company,
        type,
        setType,
        state,
        setState,
        locale,
        setLocale,
        t,
        visitor,
        setVisitor,
        showConfirmModal,
        setShowConfirmModal,
        covidScreening,
        setCovidScreening,
        host,
        setHost,
        imgSrc,
        setImgSrc,
        isLoading,
        setIsLoading,
        delivery,
        setDelivery,
      }}
    >
      <WalkInLayout>
        {transitions(({ opacity }, type) => {
          switch (type) {
            case 'welcome':
              return (
                <animated.div
                  style={{
                    position: 'absolute',
                    opacity: opacity.to({
                      range: [0.0, 1.0],
                      output: [0, 1],
                    }),
                  }}
                >
                  <WalkInWelcome />
                </animated.div>
              )
            case 'visitor':
            case 'delivery':
              return (
                <animated.div
                  style={{
                    position: 'relative',
                    width: '100%',
                    opacity: opacity.to({
                      range: [1.0, 0.0],
                      output: [1, 0],
                    }),
                  }}
                >
                  <WalkInForm />
                </animated.div>
              )
            case 'visitor-success':
            case 'delivery-success':
              return (
                <animated.div
                  style={{
                    position: 'absolute',
                    opacity: opacity.to({
                      range: [1.0, 0.0],
                      output: [1, 0],
                    }),
                  }}
                >
                  <WalkInSuccess />
                </animated.div>
              )
            case 'atk':
              return (
                <animated.div
                  style={{
                    position: 'relative',
                    width: '100%',
                    opacity: opacity.to({
                      range: [1.0, 0.0],
                      output: [1, 0],
                    }),
                  }}
                >
                  <WalkInATK />
                </animated.div>
              )
            case 'screening-form':
              return (
                <animated.div
                  style={{
                    position: 'relative',
                    width: '100%',
                    opacity: opacity.to({
                      range: [1.0, 0.0],
                      output: [1, 0],
                    }),
                  }}
                >
                  <WalkInScreeningForm />
                </animated.div>
              )
            case 'screening-selection':
              return (
                <animated.div
                  style={{
                    position: 'relative',
                    width: '100%',
                    opacity: opacity.to({
                      range: [1.0, 0.0],
                      output: [1, 0],
                    }),
                  }}
                >
                  <ScreeningSelection />
                </animated.div>
              )
            case 'visitor-policy':
              return (
                <animated.div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    opacity: opacity.to({
                      range: [1.0, 0.0],
                      output: [1, 0],
                    }),
                  }}
                >
                  <WalkInVisitorPolicy />
                </animated.div>
              )
          }
        })}
        {['visitor', 'delivery', 'atk', 'screening-form'].includes(type) && (
          <ConfirmModal
            show={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
            form={`walkInState${state}`}
            confirmBtnType="submit"
            title={t('views.features.walk_in.visitor.confirm_modal_title')}
            description={t(
              'views.features.walk_in.visitor.confirm_modal_description',
            )}
            confirmText={t('views.misc.confirm')}
            cancelText={t('views.misc.cancel')}
            className={styles.confirmModal}
          />
        )}
      </WalkInLayout>
    </WalkInContext.Provider>
  )
}

export default WalkInPage
