import React from 'react'

type Props = {
  useCurrentColor?: boolean
  className?: string
}

export const CalendarIcon = (props: Props) => {
  const { useCurrentColor = true, className } = props
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={useCurrentColor ? 'currentColor' || '#3873e4' : ''}
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M16.25 2.91665H15.5556V1.52776C15.5556 1.14442 15.2445 0.833313 14.8611 0.833313H14.1667C13.7833 0.833313 13.4722 1.14442 13.4722 1.52776V2.91665H6.52778V1.52776C6.52778 1.14442 6.21667 0.833313 5.83334 0.833313H5.13889C4.75556 0.833313 4.44445 1.14442 4.44445 1.52776V2.91665H3.75001C2.60139 2.91665 1.66667 3.85137 1.66667 4.99998V15.4166C1.66667 16.5653 2.60139 17.5 3.75001 17.5H16.25C17.3986 17.5 18.3333 16.5653 18.3333 15.4166V4.99998C18.3333 3.85137 17.3986 2.91665 16.25 2.91665ZM16.9445 15.4166C16.9445 15.7993 16.6326 16.1111 16.25 16.1111H3.75001C3.36737 16.1111 3.05556 15.7993 3.05556 15.4166V7.80554H16.9445V15.4166Z" />
    </svg>
  )
}
