import React from 'react'

import classnames from 'classnames'

import styles from './UserLayout.module.scss'
import { ApplicationLayout, Button } from '@/components/shared'

import {
  forgotPasswordPath,
  signInPath,
  signUpPath,
} from '@/features/constants'
import { t } from '@/lib/helpers'

type LinkSectionProps = {
  text: string
  linkText: string
  linkTo: string
}

const LinkSection = (props: LinkSectionProps) => {
  const { text, linkText, linkTo } = props
  return (
    <div className={styles.link}>
      {text} <a href={linkTo}>{linkText}</a>
    </div>
  )
}

type Props = {
  form?: string
  textBtn?: string
  onClickBtn?: () => void
  showSignInPath?: boolean
  showSignUpPath?: boolean
  showForgotPasswordPath?: boolean
  showBackToSignInPath?: boolean
  isSystemAdmin?: boolean
  children?: React.ReactNode
  className?: string
}

export const UserLayout = (props: Props) => {
  const {
    form = '',
    textBtn,
    onClickBtn,
    showSignInPath,
    showSignUpPath,
    showForgotPasswordPath,
    showBackToSignInPath,
    isSystemAdmin = false,
    children,
    className,
  } = props

  const signInSection = showSignInPath && (
    <LinkSection
      text={t('views.features.devise.user_layout.sign_in_description')}
      linkText={t('views.features.devise.sign_in.sign_in_header')}
      linkTo={signInPath}
    />
  )

  const signUpSection = showSignUpPath && (
    <LinkSection
      text={t('views.features.devise.user_layout.sign_up_description')}
      linkText={t('views.features.devise.sign_up.sign_up_header')}
      linkTo={signUpPath}
    />
  )

  const forgotPasswordSection = showForgotPasswordPath && (
    <LinkSection
      text={t('views.features.devise.user_layout.forgot_password_description')}
      linkText={t('views.features.devise.user_layout.forgot_password_link')}
      linkTo={forgotPasswordPath}
    />
  )

  const backToSignInSection = showBackToSignInPath && (
    <LinkSection
      text={t('views.features.devise.user_layout.back_to')}
      linkText={t('views.features.devise.sign_in.sign_in_header')}
      linkTo={signInPath}
    />
  )

  return (
    <ApplicationLayout
      className={classnames(styles.layout, {
        [styles.systemAdminLayout]: isSystemAdmin,
      })}
    >
      <div className={classnames(styles.container, className)}>
        <div className={styles.box}>
          {children}
          {textBtn && (
            <Button
              type={form === '' ? 'button' : 'submit'}
              text={textBtn}
              form={form}
              onClick={onClickBtn}
              className={styles.btn}
            />
          )}
          {!isSystemAdmin && (
            <div className={styles.linksSection}>
              {signInSection}
              {signUpSection}
              {forgotPasswordSection}
              {backToSignInSection}
            </div>
          )}
        </div>
      </div>
    </ApplicationLayout>
  )
}

export const userLayoutStyles = styles
