import React from 'react'

import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'

import { ConfirmModal, ISelectOption, RejectForm, REJECT_FORM } from '..'
import styles from './RejectModal.module.scss'

import { t } from '@/lib/helpers'

type Props = {
  show: boolean
  onClose: () => void
  onSubmit: () => void
  control: Control<FieldValues>
  register: UseFormRegister<FieldValues>
  watch: UseFormWatch<FieldValues>
  options: ISelectOption[]
}

export const RejectModal = (props: Props) => {
  const { show, onClose } = props

  return (
    <ConfirmModal
      show={show}
      onClose={onClose}
      title={t('views.components.reject_modal.title')}
      type={'Delete'}
      confirmText={t('views.misc.reject')}
      confirmBtnType={'submit'}
      form={REJECT_FORM}
      className={styles.modal}
    >
      <RejectForm {...props} />
    </ConfirmModal>
  )
}
