import React from 'react'

import classnames from 'classnames'
import { Control } from 'react-hook-form'

import styles from './DashboardLayout.module.scss'
import {
  ApplicationLayout,
  Button,
  EventDetailModal,
  Search,
  showToast,
  SortOptions,
} from '@/components/shared'

import { invitationService } from '../services'
import { invitesNewPath, walkInsNewPath } from '@/features/constants'
import { EventDetailModalType, Employee, Invitation } from '@/features/types'
import { getLocaleDateString, t } from '@/lib/helpers'

type Props = {
  employee: Employee
  totalVisitor: number
  totalCheckIn: number
  totalCheckOut: number
  search: string
  setSearch: (search: string) => void
  sortOptions: SortOptions
  queryPath: string
  showEventDetailModal: boolean
  onCloseEventDetailModal: () => void
  invitation?: Invitation
  tab: EventDetailModalType
  onSetVisitorTab: () => void
  onSetWalkInTab: () => void
  handleQuery: (newSort?: string, newDirection?: string) => void
  children?: React.ReactNode
  company_token: string
  control?: Control<any>
  defaultFromTime?: string
  defaultToTime?: string
}

export const DashboardLayout = (props: Props) => {
  const {
    employee,
    totalVisitor,
    totalCheckIn,
    totalCheckOut,
    search,
    setSearch,
    showEventDetailModal,
    onCloseEventDetailModal,
    invitation,
    tab,
    onSetVisitorTab,
    onSetWalkInTab,
    handleQuery,
    children,
    company_token,
    control,
    defaultFromTime,
    defaultToTime,
  } = props
  const today = getLocaleDateString(new Date())
  const onUpdateNote = async (id: string, internal_note: string) => {
    await invitationService().updateInvitation(id, { internal_note })
    showToast({
      type: 'success',
      message: t(
        'views.features.invitations.event_detail_modal.successfully_update_note',
      ),
    })
  }

  return (
    <ApplicationLayout employee={employee}>
      <div className={styles.background}>
        <div className={styles.titleSection}>
          <span className={styles.todayText}>
            {t('views.misc.today')}, {today}
          </span>
          <Button
            onClick={() =>
              (window.location.href = `${walkInsNewPath}?token=${company_token}`)
            }
            icon={'/assets/icons/calendar-plus-in.svg'}
            text={t('views.features.invitations.dashboard.visitor')}
            className={styles.visitorButton}
            size={'L'}
          />
          <Button
            onClick={() => (window.location.href = invitesNewPath)}
            icon={'/assets/icons/calendar-plus-out.svg'}
            text={t('views.features.invitations.dashboard.create_invitation')}
            size={'L'}
          />
        </div>
        <div className={styles.totalNumberSection}>
          <div className={styles.totalCard}>
            <div>{t('views.features.invitations.dashboard.total_visitor')}</div>
            <div
              className={classnames(styles.totalNumber, styles.totalVisitor)}
            >
              {totalVisitor}
            </div>
          </div>
          <div className={styles.totalCard}>
            <div>{t('views.misc.check_in')}</div>
            <div
              className={classnames(styles.totalNumber, styles.totalCheckIn)}
            >
              {totalCheckIn}
            </div>
          </div>
          <div className={styles.totalCard}>
            <div>{t('views.misc.check_out')}</div>
            <div
              className={classnames(styles.totalNumber, styles.totalCheckOut)}
            >
              {totalCheckOut}
            </div>
          </div>
        </div>
        <div>
          <Search
            search={search}
            setSearch={setSearch}
            handleQuery={handleQuery}
            control={control}
            defaultFromTime={defaultFromTime}
            defaultToTime={defaultToTime}
            time
            withClearButton
            className={styles.searchSection}
          />
        </div>
        <div className={styles.tabWrapper}>
          <div
            className={tab === 'visitor' ? styles.highlightTab : styles.tab}
            onClick={onSetVisitorTab}
          >
            {t('views.features.invitations.dashboard.visitor')}
          </div>
          <div
            className={tab === 'walk_in' ? styles.highlightTab : styles.tab}
            onClick={onSetWalkInTab}
          >
            {t('views.features.invitations.dashboard.walk_in')}
          </div>
        </div>
        {children}
        {invitation && (
          <EventDetailModal
            show={showEventDetailModal}
            onClose={onCloseEventDetailModal}
            invitation={invitation}
            type={tab}
            onUpdateNote={onUpdateNote}
          />
        )}
      </div>
    </ApplicationLayout>
  )
}
