import React from 'react'

import classnames from 'classnames'

import styles from './RiskAssessment.module.scss'
import { Button, ImagePreview } from '@/components/shared'

import {
  RiskAssessment as RiskAssessmentType,
  RiskAssessmentStatus,
} from '@/features/types'
import { getLocaleDateString } from '@/lib/helpers'

type RiskAssessmentProps = {
  risk_assessment: RiskAssessmentType
  onClickVerify: (id: number) => void
}

const formatStatus = (status: RiskAssessmentStatus) => {
  switch (status) {
    case RiskAssessmentStatus.pending:
      return <span className={styles.waiting}>Waiting</span>
    case RiskAssessmentStatus.rejected:
      return <span className={styles.rejected}>Rejected</span>
    case RiskAssessmentStatus.not_confirm:
    case RiskAssessmentStatus.not_vaccinated:
      return <span></span>
    default:
      return <span className={styles.approved}>Approved</span>
  }
}

export const RiskAssessment = (props: RiskAssessmentProps) => {
  const { risk_assessment, onClickVerify } = props

  return (
    <tr className={styles.riskAssessment}>
      <td className={styles.visitorInfo}>
        <ImagePreview
          src={
            risk_assessment.visitor_image_url ||
            '/assets/icons/empty-display-image.svg'
          }
          width={60}
          height={60}
          borderRadius={6}
        />
        <div className={styles.visitorDetail}>
          <div className={styles.textEllipsis}>
            {risk_assessment.visitor_name}
          </div>
          <div className={classnames(styles.textEllipsis, styles.visitorEmail)}>
            {risk_assessment.visitor_email}
          </div>
        </div>
      </td>
      <td className={classnames(styles.textEllipsis, styles.requestedDate)}>
        {getLocaleDateString(risk_assessment.requested_at)}
      </td>
      <td className={classnames(styles.textEllipsis, styles.status)}>
        {formatStatus(risk_assessment.request_status)}
      </td>
      <td className={styles.centerColumn}>
        {risk_assessment.request_status === RiskAssessmentStatus.pending && (
          <Button
            text="Verify"
            className={styles.checkInOutButton}
            onClick={() => onClickVerify(risk_assessment.id)}
          />
        )}
      </td>
    </tr>
  )
}
