import React, { useState } from 'react'

import classnames from 'classnames'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'

import {
  ApplicationLayout,
  Button,
  ButtonStyle,
  ISelectOption,
  RejectModal,
  VisitorDetail,
  VisitorInfo,
} from '..'
import styles from './VerifyLayout.module.scss'
import { CaretLeft } from '@/components/icons'

import { Employee, SystemAdmin } from '@/features/types'
import { t } from '@/lib/helpers'

export type VerifyButton = {
  text: string
  onClick?: () => void
  style?: ButtonStyle
  className?: string
}

type Props = {
  systemAdmin?: SystemAdmin
  employee?: Employee
  showSkip?: boolean
  onSkip?: () => void
  onBack?: () => void
  firstVerifyBtn: VerifyButton
  secondVerifyBtn: VerifyButton
  thirdVerifyBtn?: VerifyButton
  rejectOptions: ISelectOption[]
  onReject: SubmitHandler<FieldValues>
  verifyImageUrl: string
} & VerifyComponentProps

export const VerifyLayout = (props: Props) => {
  const {
    onBack,
    showSkip,
    onSkip,
    rejectOptions,
    onReject,
    firstVerifyBtn,
    secondVerifyBtn,
    thirdVerifyBtn,
  } = props
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false)
  const { handleSubmit, register, watch, control } = useForm()

  return (
    <ApplicationLayout {...props}>
      <div className={styles.background}>
        <div className={styles.backAndSkipButton}>
          <Button
            text={t('views.misc.back')}
            style={'Ghost'}
            iconComponent={<CaretLeft />}
            onClick={onBack}
            className={styles.backButton}
          />
          {showSkip && (
            <Button
              text={t('views.misc.skip')}
              style={'Link'}
              onClick={onSkip}
            />
          )}
        </div>
        <VerifyComponent {...props} />
        <div className={styles.buttons}>
          {thirdVerifyBtn && (
            <Button
              text={thirdVerifyBtn.text}
              onClick={() => setShowRejectModal(true)}
              style={thirdVerifyBtn.style || 'DeleteSecondary'}
              size={'L'}
              className={classnames(styles.thirdBtn, thirdVerifyBtn.className)}
            />
          )}
          <Button
            text={secondVerifyBtn.text}
            onClick={
              secondVerifyBtn.onClick
                ? secondVerifyBtn.onClick
                : () => setShowRejectModal(true)
            }
            style={secondVerifyBtn.style || 'DeleteSecondary'}
            size={'L'}
            className={classnames(styles.secondBtn, secondVerifyBtn.className)}
          />
          <Button
            text={firstVerifyBtn.text}
            onClick={firstVerifyBtn.onClick}
            style={firstVerifyBtn.style || 'Primary'}
            size={'L'}
            className={classnames(styles.firstBtn, firstVerifyBtn.className)}
          />
        </div>
      </div>
      <RejectModal
        show={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        onSubmit={handleSubmit(onReject)}
        control={control}
        register={register}
        watch={watch}
        options={rejectOptions}
      />
    </ApplicationLayout>
  )
}

type VerifyComponentProps = {
  visitorInfo: VisitorInfo
  verifyImageUrl: string
  extraInfo?: React.ReactNode
}

const VerifyComponent = (props: VerifyComponentProps) => {
  const { visitorInfo, verifyImageUrl, extraInfo } = props
  return (
    <div className={styles.componentBackground}>
      <div className={styles.leftSection}>
        <div className={styles.visitor}>
          <VisitorDetail
            visitorInfo={visitorInfo}
            imageSize={72}
            showTelephone
          />
        </div>
        {extraInfo}
      </div>
      <div className={styles.rightSection}>
        <div className={styles.imageWrapper}>
          <img src={verifyImageUrl} className={styles.image} />
        </div>
      </div>
    </div>
  )
}
