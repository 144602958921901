import { checkInOutPath, invitationsPath } from '@/features/constants'
import { axios } from '@/lib/helpers'

export const invitationService = () => {
  return {
    updateInvitationStatus: async (id: string, status: string) => {
      return axios.patch(`${invitationsPath}/${id}/${status}`, {})
    },
    checkIn: async (id: string) => {
      return axios.patch(`${checkInOutPath}/${id}/check_in`, {})
    },
    checkOut: async (id: string) => {
      return axios.patch(`${checkInOutPath}/${id}/check_out`, {})
    },
    updateInvitation: async (id: string, params: object) => {
      return axios.patch(`${invitationsPath}/${id}`, {
        invitation: params,
      })
    },
    scanQR: async (slug: string) => {
      return axios.get(`${checkInOutPath}/${slug}`, {})
    },
    getInvitations: async () => {
      return axios.get(`${invitationsPath}`, {})
    },
  }
}
