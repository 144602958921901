import React from 'react'

import classnames from 'classnames'

import styles from './Logs.module.scss'

import { AtkLog as AtkLogType } from '@/features/types'
import { getLocaleDateString, t } from '@/lib/helpers'

type Props = {
  atk: AtkLogType
}

export const AtkLog = (props: Props) => {
  const { atk } = props
  const { submit_at, status, visitor_name, verify_at, verified_employee } = atk

  const dateFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  return (
    <tr className={styles.row}>
      <td>
        <div className={classnames(styles.textEllipsis, styles.visitorName)}>
          {visitor_name}
        </div>
      </td>
      <td>
        <div className={classnames(styles.textEllipsis, styles.dateText)}>
          {getLocaleDateString(submit_at, dateFormat)}
        </div>
      </td>
      <td>
        <div className={classnames(styles.textEllipsis, styles.dateText)}>
          {status === 'expired'
            ? '-'
            : t(`views.features.atk_reports.status.${status}`)}
        </div>
      </td>
      <td>
        <div className={styles.verified}>
          {status === 'expired' ? (
            <div className={styles.expired}>{t('views.misc.expired')}</div>
          ) : (
            <>
              <div className={classnames(styles.textEllipsis, styles.dateText)}>
                {getLocaleDateString(verify_at, dateFormat)}
              </div>
              <div
                className={classnames(styles.textEllipsis, styles.verifiedName)}
              >
                {`${t('views.misc.by')} ${verified_employee || '-'}`}
              </div>
            </>
          )}
        </div>
      </td>
    </tr>
  )
}
