import React from 'react'

import { FieldValues, UseFormRegister } from 'react-hook-form'

import styles from './CheckboxTermsPolicy.module.scss'
import { CheckboxButtons, Link } from '@/components/shared'

import { policyPath, termsPath } from '@/features/constants'
import { t } from '@/lib/helpers'

type Props = {
  company_slug: string
  register: UseFormRegister<FieldValues>
  visitor_policy_url?: string
}

export const CheckboxTermsPolicy = (props: Props) => {
  const { company_slug, register, visitor_policy_url } = props
  return (
    <>
      <CheckboxButtons
        choices={[
          {
            title: (
              <span>
                {t('views.features.visitor.show.agree')}{' '}
                <Link
                  url={`${termsPath}?company_id=${company_slug}`}
                  text={t('views.features.visitor.show.terms_conditions')}
                  noUnderLine
                />{' '}
                {t('views.misc.and')}{' '}
                <Link
                  url={`${policyPath}?company_id=${company_slug}`}
                  text={t('views.features.visitor.show.privacy_policy')}
                  noUnderLine
                />
              </span>
            ),
            value: 'agree',
          },
        ]}
        register={register}
        name={'terms_policy'}
        className={styles.termsPolicy}
      />
      {visitor_policy_url && (
        <CheckboxButtons
          choices={[
            {
              title: (
                <span>
                  {t('views.misc.visitor_policy.text')}{' '}
                  <Link
                    url={visitor_policy_url}
                    text={t('views.misc.visitor_policy.text_url')}
                    noUnderLine
                  />
                </span>
              ),
              value: 'agree',
            },
          ]}
          register={register}
          name={'visitor_policy'}
          className={styles.visitorPolicy}
        />
      )}
    </>
  )
}
