import React from 'react'

import classnames from 'classnames'

import styles from './Logs.module.scss'

import { Invitation } from '@/features/types'
import { getLocaleDateString, getLocaleTimeString, t } from '@/lib/helpers'

type Props = {
  invitation: Invitation
}

const getCheckTimeAndEmployeeName = (
  check_at?: Date,
  employee_name?: string,
) => {
  if (!check_at) return '-'

  return (
    <>
      {getLocaleTimeString(check_at)}
      <br />
      <span className={styles.employeeName}>{`${t('views.misc.by')} ${
        employee_name || '-'
      }`}</span>
    </>
  )
}

export const InvitesLog = (props: Props) => {
  const { invitation } = props

  const {
    visitor_name,
    visitor_email,
    invite,
    host_employee_name,
    area_to_access,
    check_in_at,
    check_out_at,
    start_datetime,
    check_in_employee_name,
    check_out_employee_name,
  } = invitation
  return (
    <tr>
      <td className={styles.log}>
        <div
          className={classnames(styles.textEllipsis, styles.visitorNameText)}
        >
          {visitor_name}
        </div>
        <div className={classnames(styles.textEllipsis, styles.visitorEmail)}>
          {visitor_email}
        </div>
      </td>
      <td>
        <div className={classnames(styles.textEllipsis, styles.eventName)}>
          {invite.title}
        </div>
        <div className={styles.eventDate}>
          {getLocaleDateString(start_datetime)}
        </div>
      </td>
      <td className={classnames(styles.textEllipsis, styles.hostText)}>
        {host_employee_name || '-'}
      </td>
      <td className={classnames(styles.textEllipsis, styles.areaToAccessText)}>
        {area_to_access}
      </td>
      <td className={styles.checkWithEmployeeName}>
        {getCheckTimeAndEmployeeName(check_in_at, check_in_employee_name)}
      </td>
      <td className={styles.checkWithEmployeeName}>
        {getCheckTimeAndEmployeeName(check_out_at, check_out_employee_name)}
      </td>
    </tr>
  )
}
