import React from 'react'

import DatePicker from 'antd/lib/date-picker'
import thTH from 'antd/lib/locale/th_TH'
import classnames from 'classnames'
import moment from 'moment'
import { Control, Controller, FieldValues, RefCallBack } from 'react-hook-form'

import styles from './DateField.module.scss'

import { getLocaleDateString, t } from '@/lib/helpers'

interface Props {
  defaultValue?: string | Date
  className?: string
  control?: Control<FieldValues>
  name?: `${string}`
  required?: boolean
  allowClear?: boolean
  disabledDate?: (currentDate: any) => boolean
  disabled?: boolean
  value?: moment.Moment
  onChange?: (dateMoment: moment.Moment) => void
  ref?: RefCallBack
}

export const DateField = (props: Props) => {
  const {
    defaultValue,
    className,
    control,
    name,
    required = false,
    allowClear = false,
    disabledDate,
    disabled = false,
    value,
    onChange,
    ref,
  } = props

  const dateFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  if (control && name) {
    return (
      <div className={classnames(styles.background, className)}>
        <Controller
          control={control}
          name={name}
          defaultValue={defaultValue}
          render={({ field: { value, onChange, ref } }) => (
            <>
              <DatePicker
                locale={
                  gon.currentLocale === 'th' ? thTH.DatePicker : undefined
                }
                value={value && moment(value)}
                onChange={(date) => onChange(date)}
                ref={ref}
                format={(value: any) =>
                  getLocaleDateString(value.toDate(), dateFormat)
                }
                allowClear={allowClear}
                disabledDate={disabledDate}
                inputReadOnly
                disabled={disabled}
                placeholder={t(
                  'views.components.date_field.placeholder',
                  {},
                  'Select date',
                )}
                className={classnames(styles.datePicker, {
                  [styles.datePickerHover]: !disabled,
                })}
              />
              {required && (
                <input
                  required={required}
                  value={value}
                  className={styles.validateInput}
                />
              )}
            </>
          )}
        />
      </div>
    )
  } else {
    return (
      <div className={classnames(styles.background, className)}>
        <DatePicker
          locale={gon.currentLocale === 'th' ? thTH.DatePicker : undefined}
          value={value}
          onChange={(date) => date && onChange && onChange(date)}
          format={(value: any) =>
            getLocaleDateString(value.toDate(), dateFormat)
          }
          ref={ref}
          allowClear={allowClear}
          disabledDate={disabledDate}
          inputReadOnly
          disabled={disabled}
          placeholder={t(
            'views.components.date_field.placeholder',
            {},
            'Select date',
          )}
          className={classnames(styles.datePicker, {
            [styles.datePickerHover]: !disabled,
          })}
        />
      </div>
    )
  }
}
