import React from 'react'

import { Calendar } from '../components'
import styles from './CalendarPage.module.scss'
import {
  AnyVisitIcon,
  CalendarIcon,
  UserIcon,
  UsersIcon,
} from '@/components/icons'
import { ApplicationLayout, ISelectOption } from '@/components/shared'

import {
  Invite,
  Employee,
  IntegratedEvent,
  Company,
  Invitation,
} from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  invites: Invite[]
  defaultInvite?: Invite
  defaultInvitations?: Invitation[]
  employee: Employee
  integratedEvents: IntegratedEvent[]
  company: Company
}

const typeOptions: ISelectOption[] = [
  {
    label: (
      <div className={styles.label}>
        <CalendarIcon useCurrentColor={false} className={styles.icon} />
        {t('views.features.calendar.calendar.all')}
      </div>
    ),
    value: 'all',
  },
  {
    label: (
      <div className={styles.label}>
        <AnyVisitIcon useCurrentColor={false} className={styles.icon} />
        {t('views.features.calendar.calendar.anyvisit_only')}
      </div>
    ),
    value: 'anyvisit',
  },
]

const personOptions: ISelectOption[] = [
  {
    label: (
      <div className={styles.label}>
        <UsersIcon useCurrentColor={false} className={styles.icon} />
        {t('views.features.calendar.calendar.all')}
      </div>
    ),
    value: 'all',
  },
  {
    label: (
      <div className={styles.label}>
        <UserIcon useCurrentColor={false} className={styles.icon} />
        {t('views.features.calendar.calendar.only_me')}
      </div>
    ),
    value: 'me',
  },
]

const CalendarPage = (props: Props) => {
  const {
    invites,
    defaultInvite,
    defaultInvitations,
    employee,
    integratedEvents,
    company,
  } = props

  return (
    <ApplicationLayout employee={employee}>
      <div className={styles.background}>
        <div className={styles.calendar}>
          <Calendar
            employee={employee}
            integratedEvents={integratedEvents}
            invites={invites}
            defaultInvite={defaultInvite}
            defaultInvitations={defaultInvitations}
            company={company}
            typeOptions={typeOptions}
            personOptions={personOptions}
          />
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default CalendarPage
