// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".EyeSwitch-module__background___17Sbj{cursor:pointer}", "",{"version":3,"sources":["/usr/src/app/app/javascript/components/shared/EyeSwitch/EyeSwitch.module.scss"],"names":[],"mappings":"AAEA,sCACE,cAAA","file":"EyeSwitch.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.background) {\n  cursor: pointer;\n}\n"]}]);
// Exports
exports.locals = {
	"background": "EyeSwitch-module__background___17Sbj"
};
module.exports = exports;
