import React, { useState, forwardRef } from 'react'

import { default as WebcamComponent } from 'react-webcam'

import { ImagePreview } from '..'
import styles from './Webcam.module.scss'

import { WebcamFacingMode } from '@/features/types'

import { RotateCcw } from '../../icons'

type Props = {
  imgSrc?: string
  showCountdown?: boolean
  setShowCountdown?: (showCountdown: boolean) => void
  countdown?: number
  showSwitchFacingMode?: boolean
  facingMode?: WebcamFacingMode
}

const MAX_WIDTH_HEIGHT_WEBCAM = 360

export const Webcam = forwardRef<WebcamComponent, Props>((props, ref) => {
  const {
    imgSrc,
    showCountdown = false,
    setShowCountdown,
    countdown,
    facingMode = 'user',
    showSwitchFacingMode = false,
  } = props

  const [mode, setMode] = useState<WebcamFacingMode>(facingMode)

  const videoConstraints = {
    width: MAX_WIDTH_HEIGHT_WEBCAM,
    height: MAX_WIDTH_HEIGHT_WEBCAM,
    facingMode: mode,
  }

  return (
    <>
      <div className={styles.webcamWrapper}>
        {!imgSrc && (
          <>
            <WebcamComponent
              audio={false}
              height={MAX_WIDTH_HEIGHT_WEBCAM}
              width={MAX_WIDTH_HEIGHT_WEBCAM}
              ref={ref}
              screenshotFormat="image/jpeg"
              videoConstraints={videoConstraints}
              mirrored={mode === 'user'}
              onPlaying={() => setShowCountdown && setShowCountdown(true)}
              className={styles.webcam}
            />
            {showCountdown && <h1 className={styles.countdown}>{countdown}</h1>}
            {showSwitchFacingMode && (
              <div
                onClick={() =>
                  mode === 'user' ? setMode('environment') : setMode('user')
                }
              >
                <RotateCcw className={styles.switchFacingMode} />
              </div>
            )}
          </>
        )}
        {imgSrc && (
          <div className={styles.imagePreview}>
            <ImagePreview
              width={MAX_WIDTH_HEIGHT_WEBCAM}
              height={MAX_WIDTH_HEIGHT_WEBCAM}
              src={imgSrc}
              className={styles.webcam}
            />
          </div>
        )}
      </div>
    </>
  )
})
