import { ISelectOption } from '@/components/shared'

import { t } from '@/lib/helpers'

export const signInPath = '/system_admins/sign_in'
export const signOutPath = '/system_admins/sign_out'
export const passwordPath = '/system_admins/password'
export const rootPath = '/risk_assessments'
export const riskAssessmentsPath = '/risk_assessments'
export const systemAdminsPath = '/system_admins'
export const vaccineLogsPath = '/risk_assessments/vaccine_logs'
export const confirmationPath = '/system_admins/confirmation'

export const allSystemAdminPath = [
  rootPath,
  signInPath,
  signOutPath,
  systemAdminsPath,
]

export const rejectOptions: ISelectOption[] = [
  t('views.features.risk_assessments.edit.reject_modal.insufficient_evidence'),
  t('views.features.risk_assessments.edit.reject_modal.false_proof'),
  t('views.features.risk_assessments.edit.reject_modal.wrong_information'),
  t('views.misc.other'),
].map((reason: string) => ({
  label: reason,
  value: reason,
}))
