import React from 'react'

import classnames from 'classnames'
import parse from 'html-react-parser'

import styles from './PasswordComplexity.module.scss'

import { minimumPasswordLength } from '@/features/constants'
import { t } from '@/lib/helpers'

type Props = {
  password: string
  show: boolean
}

const upperCase = /[A-Z]/g
const lowerCase = /[a-z]/g
const digit = /[0-9]/g
const specialCase = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g

export const validatePassword = (password: string = '') => {
  return (
    password.length >= minimumPasswordLength &&
    password.match(upperCase) !== null &&
    password.match(lowerCase) !== null &&
    password.match(digit) !== null &&
    password.match(specialCase) !== null
  )
}

const rules = [
  {
    text: t('views.components.password_complexity.rule_minimum_length', {
      length: minimumPasswordLength,
    }),
    check: (password: string) => password.length >= minimumPasswordLength,
  },
  {
    text: t('views.components.password_complexity.rule_one_uppercase'),
    check: (password: string) => password.match(upperCase) !== null,
  },
  {
    text: t('views.components.password_complexity.rule_one_lowercase'),
    check: (password: string) => password.match(lowerCase) !== null,
  },
  {
    text: t('views.components.password_complexity.rule_one_digit'),
    check: (password: string) => password.match(digit) !== null,
  },
  {
    text: t('views.components.password_complexity.rule_one_special'),
    check: (password: string) => password.match(specialCase) !== null,
  },
]

export const PasswordComplexity = (props: Props) => {
  const { password, show = false } = props

  return (
    <ul
      className={classnames(styles.passwordComplexity, { [styles.show]: show })}
    >
      {rules.map((rule, idx) => (
        <li
          key={idx}
          className={classnames(styles.listPasswordComplexity, {
            [styles.check]: password && rule.check(password),
          })}
        >
          <div
            className={classnames(styles.checkCircle, {
              [styles.showCheckIcon]: password && rule.check(password),
            })}
          >
            <div className={styles.notCheck} />
            <img src="/assets/icons/check.svg" className={styles.checkIcon} />
          </div>
          {parse(rule.text)}
        </li>
      ))}
    </ul>
  )
}
