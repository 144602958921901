import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import {
  CovidDetail,
  Modal,
  TextField,
  VisitorDetail,
  InvitationDetail,
} from '..'
import styles from './EventDetailModal.module.scss'

import { EventDetailModalType, Invitation, Visitor } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  show: boolean
  onClose: () => void
  invitation: Invitation
  type: EventDetailModalType
  onUpdateNote: (id: string, internal_note: string) => void
}

export const EventDetailModal = (props: Props) => {
  const { show, onClose, invitation, type, onUpdateNote } = props

  const { register, watch, reset } = useForm()

  useEffect(() => {
    if (invitation) {
      reset({
        internal_note: invitation.internal_note,
      })
    }
  }, [invitation])

  const visitor: Visitor = {
    id: 0,
    first_name: invitation.visitor_name || '',
    last_name: '',
    email: invitation.visitor_email || '',
    image: {
      url: invitation.visitor_image_url || '',
    },
    telephone: invitation.visitor_telephone || '',
    slug: invitation.visitor_slug || '',
  }

  const handleUpdateNote = () => {
    const internal_note = watch('internal_note')
    if (!invitation || internal_note === invitation.internal_note) return

    onUpdateNote(invitation.id as string, internal_note)
  }

  return (
    <Modal show={show} onClose={onClose} className={styles.modal}>
      <VisitorDetail
        visitor={visitor}
        imageSize={60}
        borderRadius={6}
        className={styles.visitorDetail}
      />
      <CovidDetail showModal={show} invitation={invitation} />
      <div className={styles.contentSection}>
        <InvitationDetail
          title={type === 'visitor' ? invitation.invite.title : 'Detail'}
          showArea={type !== 'walk_in'}
          showDate={type !== 'walk_in'}
          showNote
          invitation={invitation}
        />
      </div>
      <hr className={styles.noteSeparator} />
      <div className={styles.noteSection}>
        <TextField
          title={t(
            'views.features.invitations.event_detail_modal.internal_note',
          )}
          type="textarea"
          name="internal_note"
          register={register}
          onBlur={handleUpdateNote}
          className={styles.textarea}
        />
      </div>
    </Modal>
  )
}
