import {
  covidScreeningsPath,
  covidScreeningsSearchPath,
  employeesSearchPath,
  walkInsPath,
} from '@/features/constants'
import {
  CovidScreening,
  WalkInDeliveryData,
  WalkInVisitorData,
} from '@/features/types'
import { axios, formatTelephone } from '@/lib/helpers'

export const walkInServices = () => {
  return {
    searchHost: async (slug: string, name: string) => {
      try {
        const res = await axios.get(
          `${employeesSearchPath}?company=${slug}&name=${name}`,
          {},
        )
        return res.data
      } catch (err) {
        return []
      }
    },
    submitVisitor: async (data: WalkInVisitorData) => {
      try {
        const form = new FormData()
        form.append('type', 'visitor')
        form.append('first_name', data.first_name)
        form.append('last_name', data.last_name)
        form.append('email', data.email)
        form.append('telephone', formatTelephone(data.telephone.toString()))
        form.append('image', data.image)
        form.append('asset', data.asset)
        form.append('host_employee_id', data.host_employee_id)
        const res = await axios.post(walkInsPath, form)
        return {
          invitationSlug: res.data.invitation_slug,
          covidScreeningSlug: res.data.covid_screening_slug,
        }
      } catch (err) {
        console.error(err)
      }
    },
    submitDelivery: async (data: WalkInDeliveryData) => {
      try {
        const form = new FormData()
        form.append('type', 'delivery')
        form.append('company_slug', data.company_slug)
        form.append('delivery[image]', data.image)
        form.append('delivery[host_employee_id]', data.host_employee_id)
        form.append('delivery[sender]', data.sender)
        await axios.post(walkInsPath, form)
      } catch (error) {
        console.error(error)
      }
    },
    submitScreeningForm: async (
      covidScreeningSlug: string,
      data: CovidScreening,
      invitationSlug: string,
    ) => {
      try {
        await axios.patch(
          `${covidScreeningsPath}/${covidScreeningSlug}?invitation_id=${invitationSlug}`,
          {
            type: 'form',
            covid_screening: data,
            walk_in: true,
          },
        )
      } catch (error) {
        console.error(error)
      }
    },
    submitAtkForm: async (
      covidScreeningSlug: string,
      data: any,
      invitationSlug: string,
    ) => {
      try {
        const form = new FormData()
        form.append('type', 'atk')
        form.append('walk_in', 'true')
        data.atk_image &&
          form.append('covid_screening[atk_image]', data.atk_image)
        data.test_atk_at &&
          form.append('covid_screening[test_atk_at]', data.test_atk_at)
        await axios.patch(
          `${covidScreeningsPath}/${covidScreeningSlug}?invitation_id=${invitationSlug}`,
          form,
        )
      } catch (error) {
        console.error(error)
      }
    },
    getCovidScreeningByEmail: async (email: string, company_id?: string) => {
      try {
        const res = await axios.get(covidScreeningsSearchPath, {
          params: {
            email,
            company_id,
          },
        })
        return res.data.covid_screening
      } catch (error) {
        console.error(error)
      }
    },
  }
}
