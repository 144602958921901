import React from 'react'

import classnames from 'classnames'

import styles from './Logs.module.scss'

import { Invitation } from '@/features/types'
import { getLocaleDateString, getLocaleTimeString } from '@/lib/helpers'

type Props = {
  invitation: Invitation
}

export const WalkInLog = (props: Props) => {
  const { invitation } = props

  const {
    visitor_name,
    visitor_email,
    host_employee_name,
    asset,
    check_in_at,
    check_out_at,
  } = invitation
  return (
    <tr>
      <td className={styles.log}>
        <div className={styles.visitorName}>
          <div
            className={classnames(styles.textEllipsis, styles.visitorNameText)}
          >
            {visitor_name}
          </div>
          <div className={classnames(styles.textEllipsis, styles.visitorEmail)}>
            {visitor_email}
          </div>
        </div>
      </td>
      <td className={classnames(styles.textEllipsis, styles.hostText)}>
        {host_employee_name || '-'}
      </td>
      <td className={classnames(styles.textEllipsis, styles.assetText)}>
        {asset || '-'}
      </td>
      <td>
        <div>{check_in_at && getLocaleDateString(check_in_at)}</div>
        <div className={classnames(styles.timeText)}>
          {check_in_at && getLocaleTimeString(check_in_at)}
        </div>
      </td>
      <td>
        <div>{check_out_at && getLocaleDateString(check_out_at)}</div>
        <div className={classnames(styles.timeText)}>
          {check_out_at && getLocaleTimeString(check_out_at)}
        </div>
      </td>
    </tr>
  )
}
