import { createContext, Dispatch, SetStateAction, useContext } from 'react'

import {
  Control,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'

import { RiskAssessment, Vaccine } from '@/features/types'

type VaccineContextProps = {
  riskAssessment?: RiskAssessment
  vaccineImagePath: string
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  vaccinated?: boolean
  setVaccinated: Dispatch<SetStateAction<boolean | undefined>>
  existingVaccines: Vaccine[]
  setExistingVaccines: Dispatch<SetStateAction<Vaccine[]>>
  register?: UseFormRegister<FieldValues>
  watch?: UseFormWatch<FieldValues>
  control?: Control<FieldValues>
  handleUploadImage: (file: File) => void
  handleCancelUploadImage: () => void
  handleClickClearVaccine: (slotNumber: number) => void
  handleClickNoVaccine: () => void
}

export const VaccineContext = createContext<VaccineContextProps>({
  riskAssessment: undefined,
  vaccineImagePath: '',
  isLoading: false,
  setIsLoading: () => {},
  vaccinated: undefined,
  setVaccinated: () => {},
  existingVaccines: [],
  setExistingVaccines: () => {},
  register: undefined,
  watch: undefined,
  control: undefined,
  handleUploadImage: (_event: any) => {},
  handleCancelUploadImage: () => {},
  handleClickClearVaccine: (_slotNumber: number) => {},
  handleClickNoVaccine: () => {},
})

export const useVaccineContext = () => {
  const context = useContext(VaccineContext)
  if (context === undefined) {
    throw new Error('useVaccineContext must be used within a Provider')
  }
  return context
}
