import React from 'react'

import classnames from 'classnames'

import styles from './InviteDetail.module.scss'
import { Pencil, Trash } from '@/components/icons'
import { Button, VisitorDetail, Link, InfoSection } from '@/components/shared'

import {
  InvitationStatus,
  Invite,
  VisitorWithCovidScreeningStatus,
} from '@/features/types'
import { getLocaleDateString, t } from '@/lib/helpers'

type InviteDetailProps = {
  invite?: Invite
  visitors?: VisitorWithCovidScreeningStatus[]
  isIntegratedEvent?: boolean
  onEditInvite: () => void
  onDeleteInvite: () => void
}

export const InviteDetail = (props: InviteDetailProps) => {
  const {
    invite,
    visitors,
    isIntegratedEvent = false,
    onEditInvite,
    onDeleteInvite,
  } = props

  if (!invite) {
    return (
      <div className={styles.background}>
        <div className={styles.emptyInvite}>
          <span>
            {t('views.features.calendar.invite_detail.tap_for', {
              create_invitation: t(
                'views.features.calendar.calendar.create_invitation',
              ),
            })}
          </span>
          <span>
            {t('views.features.calendar.invite_detail.create_the_first_one')}
          </span>
        </div>
      </div>
    )
  }

  const onlineLink = invite.online_link && <Link url={invite.online_link} />

  return (
    <>
      <div className={styles.background}>
        <div className={styles.wrapper}>
          <div className={styles.inviteDetail}>
            <div className={styles.title}>{invite.title}</div>
            <div className={styles.description}>{invite.comment}</div>
            <InfoSection
              iconName={'pin'}
              value={invite.location || onlineLink || '-'}
              className={styles.infoSection}
            />
            <InfoSection
              iconName={'calendar-fill'}
              value={
                invite?.start_time ? (
                  getLocaleDateString(new Date(invite?.start_time))
                ) : (
                  <span className={styles.waitingForConfirmation}>
                    {t(
                      'views.features.calendar.invite_detail.waiting_for_confirmation',
                    )}
                  </span>
                )
              }
              className={styles.infoSection}
            />
            <InfoSection
              iconName={'user'}
              value={invite.host_employee_name}
              className={styles.infoSection}
            />
            {!isIntegratedEvent && invite.category !== 'walk_in' && (
              <div className={styles.editAndDeleteSection}>
                <div className={styles.pencil} onClick={onEditInvite}>
                  <Pencil />
                </div>
                <div className={styles.trash} onClick={onDeleteInvite}>
                  <Trash />
                </div>
              </div>
            )}
          </div>
          <div className={styles.visitors}>
            <div className={styles.visitorInformationText}>
              {isIntegratedEvent
                ? t('views.features.calendar.invite_detail.attendees')
                : t(
                    'views.features.calendar.invite_detail.visitor_information',
                  )}
            </div>
            {visitors?.map(
              (visitor: VisitorWithCovidScreeningStatus, idx: number) => (
                <VisitorInformation
                  visitor={visitor}
                  isIntegratedEvent={isIntegratedEvent}
                  key={idx}
                />
              ),
            )}
          </div>
        </div>
      </div>
    </>
  )
}

type VisitorInformationProps = {
  visitor: VisitorWithCovidScreeningStatus
  isIntegratedEvent?: boolean
}

const VisitorInformation = (props: VisitorInformationProps) => {
  const { visitor, isIntegratedEvent = false } = props

  return (
    <div
      className={classnames(styles.visitor, {
        [styles.visitorWithoutName]: !visitor.first_name,
      })}
    >
      <VisitorDetail
        visitor={visitor}
        imageSize={40}
        covidScreeningStatus={visitor.covid_screening_status}
        className={styles.visitorDetail}
      />
      {!isIntegratedEvent && (
        <div className={styles.visitorStatusButtonWrapper}>
          {visitor.invitation_status === InvitationStatus.approved ? (
            <Button
              text={t('views.features.calendar.invite_detail.accepted')}
              className={styles.visitorAcceptButton}
            />
          ) : visitor.invitation_status === InvitationStatus.rejected ? (
            <Button
              disabled
              text={t('views.features.calendar.invite_detail.rejected')}
              className={styles.visitorNotAcceptButton}
            />
          ) : (
            <Button
              disabled
              text={t('views.features.calendar.invite_detail.awaiting')}
              className={styles.visitorNotAcceptButton}
            />
          )}
        </div>
      )}
    </div>
  )
}
