// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".VaccineLogsFilter-module__select___3ptzl{width:35%;margin-right:8px}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/verifyVaccine/components/VaccineLogsFilter/VaccineLogsFilter.module.scss"],"names":[],"mappings":"AAEA,0CACE,SAAA,CACA,gBAAA","file":"VaccineLogsFilter.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.select) {\n  width: 35%;\n  margin-right: 8px;\n}\n"]}]);
// Exports
exports.locals = {
	"select": "VaccineLogsFilter-module__select___3ptzl"
};
module.exports = exports;
