import React, { ChangeEvent } from 'react'

import classnames from 'classnames'
import { Control, Controller } from 'react-hook-form'
import { default as ReactSelect, StylesConfig } from 'react-select'

import styles from './Select.module.scss'

import { t } from '@/lib/helpers'

export interface ISelectOption {
  label: React.ReactNode
  value: string
  isDisabled?: boolean
}

export enum SelectSize {
  Large,
  Small,
}

interface ISelect {
  title?: string
  placeholder?: string
  defaultValue?: string
  name: `${string}`
  control: Control<any>
  required?: boolean
  options: ISelectOption[]
  disabled?: boolean
  menuPlacement?: 'auto' | 'top' | 'bottom'
  onChange?: (value: string) => void
  isClearable?: boolean
  onClear?: () => void
  size?: SelectSize
  isSearchable?: boolean
  onInputChange?: (val: string) => void
  menuIsOpen?: boolean
  hideDropdownIcon?: boolean
  autoFocus?: boolean
  className?: string
}

const customStyles = (
  size: SelectSize,
  hideDropdownIcon: boolean,
): StylesConfig<ISelectOption, false> => {
  return {
    placeholder: (provided) => ({
      ...provided,
      color: '#d1d1d1',
    }),
    indicatorSeparator: () => ({
      backgroundColor: 'unset',
    }),
    control: (provided) => ({
      ...provided,
      height: '100%',
      borderRadius: '8px',
      padding: size === SelectSize.Small ? '0px 8px' : '4px 8px',
      display: 'flex',
      alignItems: 'center',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10,
    }),
    option: (provided, { isSelected }) => ({
      ...provided,
      borderRadius: '4px',
      fill: isSelected ? '#ffffff' : '#3873e4',
    }),
    menuList: (provided: any) => ({
      ...provided,
      paddingLeft: '8px',
      paddingRight: '8px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: hideDropdownIcon ? 'none' : 'flex',
      alignSelf: 'center',
      padding: size === SelectSize.Small ? '0px' : '8px',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: size === SelectSize.Small ? '0px' : '2px 8px',
      fill: '#3873e4',
      transform: 'translateY(1px)',
    }),
  }
}

export const Select = (props: ISelect) => {
  const {
    title,
    placeholder,
    defaultValue,
    name,
    control,
    required,
    options,
    disabled = false,
    menuPlacement = 'auto',
    onChange,
    isClearable = false,
    onClear,
    size = SelectSize.Large,
    isSearchable = true,
    onInputChange,
    menuIsOpen = undefined,
    hideDropdownIcon = false,
    autoFocus = false,
    className,
  } = props

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange: renderOnChange, value, ref } }) => (
        <div
          className={classnames(
            styles.background,
            {
              [styles.disabled]: disabled,
            },
            className,
          )}
        >
          {title && (
            <label className={styles.title}>
              {title}
              {required && title && (
                <span className={styles.requiredTitle}>*</span>
              )}
            </label>
          )}
          <ReactSelect
            inputRef={ref}
            value={
              value
                ? options.find(
                    (c: ISelectOption) => c.value === value.toString(),
                  )
                : null
            }
            onChange={(val: any, triggeredAction) => {
              if (triggeredAction.action === 'clear') {
                onClear && onClear()
              } else {
                renderOnChange(val?.value)
                val && onChange?.(val.value)
              }
            }}
            required={required}
            placeholder={
              placeholder || t('views.components.select.placeholder')
            }
            isDisabled={disabled}
            options={options}
            menuPlacement={menuPlacement}
            isClearable={isClearable}
            className={styles.selectWrapper}
            styles={customStyles(size, hideDropdownIcon)}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: '#e8effc',
                primary: '#3873e4',
              },
            })}
            isSearchable={isSearchable}
            onInputChange={onInputChange}
            menuIsOpen={menuIsOpen}
            autoFocus={autoFocus}
          />
          {required && (
            <input
              required={required}
              value={value}
              onChange={(val: ChangeEvent<HTMLInputElement>) =>
                renderOnChange(val.target.value)
              }
              className={styles.validateInput}
            />
          )}
        </div>
      )}
    />
  )
}
