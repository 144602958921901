import React from 'react'

import classnames from 'classnames'

import styles from './VaccineInformation.module.scss'

import { getLocaleDateString, t } from '@/lib/helpers'

type Props = {
  dose: number
  name?: string
  date?: Date
  approved?: boolean
  className?: string
}

export const VaccineInformation = (props: Props) => {
  const { dose, name, date, approved = false, className } = props

  if (!name) {
    return <></>
  }

  return (
    <div
      className={classnames(
        styles.background,
        { [styles.approved]: approved },
        className,
      )}
    >
      <div className={styles.doseTextWrapper}>
        <div className={styles.doseText}>
          {t('views.features.visitor.edit.vaccinated.dose_order', {
            order: t('ordinalize', { count: dose }),
          })}
        </div>
        {approved && <img src="/assets/icons/circle-wavy-check.svg" />}
      </div>
      <div className={styles.vaccination}>
        <div className={styles.vaccinationDetail}>
          <div className={styles.header}>
            {t('views.features.risk_assessments.edit.vaccine_name')}
          </div>
          <div className={styles.description}>{name}</div>
        </div>
        <div className={styles.vaccinationDetail}>
          <div className={styles.header}>{t('views.misc.date')}</div>
          <div className={styles.description}>
            {getLocaleDateString(date as Date)}
          </div>
        </div>
      </div>
    </div>
  )
}
