import consumer from './consumer'

export const DeliveriesChannel = consumer.subscriptions.create(
  'DeliveriesChannel',
  {
    connected() {},
    disconnected() {},
    received(_data) {},
  },
)
