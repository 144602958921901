import React from 'react'

import classnames from 'classnames'
import { use100vh } from 'react-div-100vh'

import styles from './ApplicationLayout.module.scss'
import { Header, Footer, Loading } from '@/components/shared'

import { Employee, SystemAdmin } from '@/features/types'

type Props = {
  employee?: Employee
  systemAdmin?: SystemAdmin
  isLoading?: boolean
  children?: React.ReactNode
  logoUrl?: string
  walkIn?: boolean
  className?: string
}

export const ApplicationLayout = (props: Props) => {
  const {
    employee,
    systemAdmin,
    isLoading,
    children,
    className,
    logoUrl,
    walkIn = false,
  } = props

  const height = use100vh()
  const containerHeight = height || 'calc(100vh - 112px)'

  return (
    <div
      className={classnames(
        styles.bg,
        { [styles.walkInBg]: walkIn },
        className,
      )}
    >
      {isLoading && <Loading />}
      <Header
        employee={employee}
        systemAdmin={systemAdmin}
        logoUrl={logoUrl}
        walkIn={walkIn}
      />
      <div
        style={{
          height: containerHeight,
          padding: height ? '56px 0px' : 'unset',
        }}
        className={styles.container}
      >
        <div className={styles.content}>{children}</div>
      </div>
      <Footer walkIn={walkIn} />
    </div>
  )
}
