import React from 'react'

import classnames from 'classnames'
import Switch from 'react-switch'

import styles from './Users.module.scss'
import { RotateCcw } from '@/components/icons'
import { Button, Column, SortOptions, Table } from '@/components/shared'

import {
  Employee,
  EmployeeStatus,
  EmployeeType,
  SystemAdmin,
} from '@/features/types'
import { t } from '@/lib/helpers'

import { User } from '.'

type Props = {
  users: Employee[] | SystemAdmin[]
  sortOptions: SortOptions
  handleSort: (newSort?: string, newDirection?: string) => void
  currentUser: Employee | SystemAdmin
  onChangeStatus: (checked: boolean, id: string) => void
  onClickEdit: (
    id: string,
    name: string,
    email: string,
    role: string,
    status: EmployeeStatus,
    imageURL?: string,
  ) => void
  onClickDelete: (id: string, name: string) => void
  className?: string
  isSystemAdmin?: boolean
  resendConfirmation?: (id: number) => void
}

export const Users = (props: Props) => {
  const {
    users,
    sortOptions,
    handleSort,
    currentUser,
    onChangeStatus,
    onClickEdit,
    onClickDelete,
    className,
    isSystemAdmin,
    resendConfirmation,
  } = props

  const disabled = (currentEmployee: Employee, employee: Employee) => {
    if (currentEmployee.type === EmployeeType.owner) return false
    if (currentEmployee.type === EmployeeType.staff)
      return currentEmployee.id != employee.id
    return employee.type === EmployeeType.owner
  }

  const model = isSystemAdmin ? 'system_admins' : 'employees'
  const columns: Column[] = [
    {
      column: `${model}.first_name`,
      title: isSystemAdmin ? t('views.misc.admin') : t('views.misc.staff'),
      className: styles.employee,
    },
    {
      column: `${model}.email`,
      title: t('views.misc.email'),
      className: styles.email,
    },
    {
      column: `${model}.status`,
      title: t('views.misc.status'),
      className: styles.status,
    },
    {
      column: '',
      title: '',
      className: styles.editAndDelete,
    },
  ]
  if (!isSystemAdmin) {
    columns.splice(2, 0, {
      column: `${model}.type`,
      title: t('views.misc.role'),
      className: styles.role,
    })
  }

  const data = users.map((user: Employee | SystemAdmin, idx: number) => {
    const isDisabled = disabled(currentUser as Employee, user as Employee)
    const statusComponent = () => {
      if (!isSystemAdmin && (isDisabled || currentUser.id === user.id)) {
        return (
          <div className={styles.textStatus}>
            <div
              className={classnames(
                styles.statusCircle,
                user.status === 'enable' ? styles.active : styles.inactive,
              )}
            ></div>
            {user.status === 'enable'
              ? t('views.misc.active')
              : t('views.misc.inactive')}
          </div>
        )
      } else if (user.status === 'pending' && resendConfirmation) {
        return (
          <div className={styles.textStatus}>
            {(user as Employee).confirmation_expired ? (
              <Button
                iconComponent={<RotateCcw className={styles.rotateIcon} />}
                text={t('views.misc.resend')}
                style={'Link'}
                onClick={() => resendConfirmation(user.id)}
                className={styles.expiredBtn}
              />
            ) : (
              t('views.misc.awaiting')
            )}
          </div>
        )
      } else {
        return (
          <Switch
            onChange={(checked: boolean) =>
              onChangeStatus(checked, user.id.toString())
            }
            checked={user.status === EmployeeStatus.enable}
            offColor="#e9e9ea"
            onColor="#03cc6b"
            checkedIcon={false}
            uncheckedIcon={false}
            width={40}
            height={24}
          />
        )
      }
    }

    return (
      <User
        key={idx}
        user={user}
        onClickEdit={isDisabled ? undefined : onClickEdit}
        onClickDelete={isDisabled ? undefined : onClickDelete}
        isUser={user.id === currentUser.id}
        statusComponent={statusComponent()}
        isSystemAdmin={isSystemAdmin}
      />
    )
  })

  return (
    <div
      className={classnames(
        styles.background,
        { [styles.systemAdminBg]: isSystemAdmin },
        className,
      )}
    >
      <Table
        columns={columns}
        data={data}
        sortOptions={sortOptions}
        handleSort={handleSort}
        emptyState={
          isSystemAdmin
            ? t('views.features.system_admins.index.no_matched_system_admins')
            : t('views.features.employee.index.no_matched_employees')
        }
      />
    </div>
  )
}
