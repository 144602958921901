import { showToast } from '@/components/shared'

import {
  signInPath,
  signOutPath,
  systemAdminsPath,
} from '@/features/constants/systemAdmin'
import { axios } from '@/lib/helpers'

export const systemAdminService = () => {
  return {
    signOut: async () => {
      return axios.delete(signOutPath, {})
    },
    createSystemAdmin: async (name: string, email: string, image?: File) => {
      try {
        const form = new FormData()
        form.append('system_admin[name]', name)
        form.append('system_admin[email]', email)
        image && form.append('system_admin[image]', image)
        await axios.post(systemAdminsPath, form)
        window.location.reload()
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          showToast({ type: 'alert', message: err.response.data?.response })
        }
      }
    },
    updateEmployeeStatus: async (id: string, status: string) => {
      return axios.patch(`${systemAdminsPath}/${id}`, {
        system_admin: {
          status,
        },
      })
    },
    editSystemAdmin: async (
      id: string,
      name: string,
      email: string,
      image?: File,
    ) => {
      const form = new FormData()
      form.append('system_admin[name]', name)
      form.append('system_admin[email]', email)
      image && form.append('system_admin[image]', image)
      return axios.patch(`${systemAdminsPath}/${id}`, form)
    },
    deleteSystemAdmin: async (id: string) => {
      return axios.delete(`${systemAdminsPath}/${id}`, {})
    },
  }
}
