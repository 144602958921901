import React, { useRef, useState } from 'react'

import classnames from 'classnames'
import { Control, FieldValues, UseFormRegister } from 'react-hook-form'

import styles from './CreateModal.module.scss'
import {
  Button,
  ImagePreview,
  ISelectOption,
  Modal,
  Select,
  showToast,
  Spinner,
  TextField,
} from '@/components/shared'

import { acceptedImageFile, MAXIMUM_IMAGE_SIZE } from '@/features/constants'
import { blobToFile, t } from '@/lib/helpers'

type Props = {
  show: boolean
  onClose: () => void
  onSubmit: (data: any) => void
  handleSubmit: any
  register: UseFormRegister<FieldValues>
  control: Control<FieldValues>
  isEdit?: boolean
  imageURL?: string
  currentRole?: string
  isSystemAdmin?: boolean
  canEditProfile?: boolean
}

export const createEditEmployeeForm = 'createEditEmployeeForm'

export const CreateModal = (props: Props) => {
  const {
    show,
    onClose,
    onSubmit,
    handleSubmit,
    register,
    control,
    isEdit = false,
    imageURL,
    currentRole = '',
    isSystemAdmin = false,
    canEditProfile = false,
  } = props

  const inputRef: any = useRef(null)
  const [image, setImage] = useState<File>()
  const [imagePath, setImagePath] = useState<string>()
  const [imageLoading, setImageLoading] = useState<boolean>(false)
  const onClickUpload = (_event: React.MouseEvent) => {
    if (!inputRef.current) return

    inputRef.current.click()
  }

  const handleUpload = async (event: any) => {
    if (event.target.files[0].size >= MAXIMUM_IMAGE_SIZE) {
      showToast({
        type: 'alert',
        message: t('views.components.image_field.large_size'),
      })
    } else {
      setImageLoading(true)
      const file = event.target.files[0]
      switch (file.type) {
        case 'image/heic': {
          const jpegFile = await blobToFile(file)
          setImage(jpegFile)
          setImagePath(URL.createObjectURL(jpegFile))
          break
        }
        default:
          setImage(file)
          setImagePath(URL.createObjectURL(file))
      }
      setImageLoading(false)
    }
  }

  const _onSubmit = (data: any) => {
    onSubmit({ ...data, image: image })
  }

  let roleOptions: ISelectOption[] = []
  if (!isSystemAdmin) {
    roleOptions.push({
      label: t('views.misc.staff'),
      value: 'Staff',
    })
    if (currentRole === 'Admin') {
      roleOptions.push({
        label: t('views.misc.admin'),
        value: 'Admin',
      })
    }
    if (currentRole === 'Owner') {
      roleOptions.push({
        label: t('views.misc.admin'),
        value: 'Admin',
      })
      roleOptions.push({
        label: t('views.misc.owner'),
        value: 'Owner',
      })
    }
  }

  return (
    <Modal
      show={show}
      onClose={onClose}
      title={
        isEdit
          ? t('views.features.employee.index.edit_title')
          : t('views.features.employee.index.create_title')
      }
      className={styles.modal}
    >
      <div className={styles.imageWrapper}>
        <div
          className={classnames(styles.image, {
            [styles.pointer]: canEditProfile,
          })}
          onClick={canEditProfile ? onClickUpload : undefined}
        >
          {imageLoading ? (
            <Spinner height={80} width={80} />
          ) : (
            <>
              <input
                id="file"
                type="file"
                ref={inputRef}
                onChange={handleUpload}
                className={styles.hiddenInput}
                accept={acceptedImageFile}
              />
              <ImagePreview
                src={
                  imagePath ||
                  imageURL ||
                  '/assets/icons/empty-display-image.svg'
                }
                width={80}
                height={80}
                showNewIcon={canEditProfile}
              />
            </>
          )}
        </div>
      </div>
      <form
        id={createEditEmployeeForm}
        className={styles.form}
        onSubmit={handleSubmit(_onSubmit)}
      >
        <TextField
          title={t('views.features.employee.index.account_name')}
          required
          placeholder={t(
            'views.features.employee.index.account_name_placeholder',
          )}
          name="name"
          register={register}
          disabled={!canEditProfile}
        />
        <TextField
          title={t('views.features.employee.index.account_email')}
          required
          placeholder={t(
            'views.features.employee.index.account_email_placeholder',
          )}
          name="email"
          register={register}
          className={styles.email}
          type="email"
          disabled={!canEditProfile}
        />
        {!isSystemAdmin && (
          <Select
            title={t('views.misc.role')}
            required
            options={roleOptions}
            placeholder={t('views.features.employee.index.role_placeholder')}
            name="role"
            control={control}
            className={styles.role}
          />
        )}
        <div className={styles.buttons}>
          <Button
            text={t('views.misc.cancel')}
            style={'Secondary'}
            size={'L'}
            onClick={onClose}
          />
          <Button
            text={isEdit ? t('views.misc.edit') : t('views.misc.create')}
            style={'Primary'}
            size={'L'}
            className={styles.createButton}
            type="submit"
          />
        </div>
      </form>
    </Modal>
  )
}
