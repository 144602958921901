import { showToast } from '@/components/shared'

import { importAccountPath, resendConfirmationPath } from '@/features/constants'
import { axios } from '@/lib/helpers'

export const adminService = () => {
  return {
    createEmployee: async (
      name: string,
      email: string,
      type: string,
      image?: File,
    ) => {
      try {
        const form = new FormData()
        form.append('employee[name]', name)
        form.append('employee[email]', email)
        form.append('employee[type]', type)
        image && form.append('employee[image]', image)
        await axios.post('./employees', form)
        window.location.reload()
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          showToast({ type: 'alert', message: err.response.data?.response })
        }
      }
    },
    updateEmployeeStatus: async (id: string, status: string) => {
      return axios.patch(`./employees/${id}`, {
        employee: {
          status,
        },
      })
    },
    editEmployee: async (
      id: string,
      name: string,
      email: string,
      type: string,
      image?: File,
    ) => {
      try {
        const form = new FormData()
        form.append('employee[name]', name)
        form.append('employee[email]', email)
        form.append('employee[type]', type)
        image && form.append('employee[image]', image)
        const res = await axios.patch(`./employees/${id}`, form)
        res.data.response &&
          showToast({
            type: 'success',
            message: res.data.response,
          })
        setTimeout(() => window.location.reload(), 1000)
      } catch (_err) {
        window.location.reload()
      }
    },
    deleteEmployee: async (id: string) => {
      return axios.delete(`./employees/${id}`, {})
    },
    importAccount: async (file: File) => {
      try {
        const form = new FormData()
        form.append('employee[file]', file)
        await axios.post(importAccountPath, form)
      } finally {
        window.location.reload()
      }
    },
    resendConfirmation: async (id: number) => {
      try {
        await axios.post(resendConfirmationPath, { id })
      } finally {
        window.location.reload()
      }
    },
  }
}
