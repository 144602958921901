import React from 'react'

import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import styles from './AtkCard.module.scss'
import { CircleClose, UserIcon } from '@/components/icons'
import { Button } from '@/components/shared'

import { AtkReportActionType } from '@/features/types'
import { t } from '@/lib/helpers'

import { useAtkReportContext } from '../../context'

type Props = {
  id: string
  imgUrl: string
  name: string
  date: string
  invitationID: string
}

export const AtkCard = (props: Props) => {
  const { id, imgUrl, name, date, invitationID } = props
  const {
    selectMode,
    ids,
    setIDs,
    setTypeModal,
    lastDeleteIDs,
    onRemoveAtkReport,
    getInvitation,
  } = useAtkReportContext()

  const onSelect = () => {
    if (selectMode) {
      if (ids.has(id)) {
        const newIds = ids
        newIds.delete(id)
        setIDs(new Set(newIds))
      } else {
        setIDs(new Set([...ids, id]))
      }
    } else {
      getInvitation(invitationID)
    }
  }

  const onClickAction = (e: React.MouseEvent, action: AtkReportActionType) => {
    e.stopPropagation()
    setIDs(new Set([id]))
    setTypeModal(action)
  }

  const isDeleted = lastDeleteIDs.includes(id)

  return (
    <div
      onClick={onSelect}
      className={classnames(styles.wrapper, {
        [styles.delete]: isDeleted,
      })}
      onTransitionEnd={() => isDeleted && onRemoveAtkReport()}
    >
      <div className={styles.imgWrapper}>
        <img src={imgUrl} className={styles.img} />
        {selectMode ? (
          <div
            className={classnames(styles.slot, {
              [styles.checkSlot]: ids.has(id),
            })}
          >
            <img src={'/assets/icons/check.svg'} className={styles.check} />
          </div>
        ) : (
          <CircleClose
            tooltip={t('views.misc.reject')}
            onClick={(e) => e && onClickAction(e, 'reject')}
            className={styles.closeIcon}
          />
        )}
        <div
          data-tip={t('views.features.atk_reports.index.taken_date')}
          className={styles.pill}
        >
          {date}
        </div>
      </div>
      <div className={styles.nameWrapper}>
        <UserIcon className={styles.userIcon} />
        {name}
      </div>
      {!selectMode && (
        <div className={styles.btnWrapper}>
          <Button
            text={t('views.misc.risk')}
            size={'S'}
            style={'Delete'}
            onClick={(e) => onClickAction(e, 'risk')}
            className={styles.riskBtn}
          />
          <Button
            text={t('views.misc.no_risk')}
            size={'S'}
            onClick={(e) => onClickAction(e, 'no_risk')}
            className={styles.noRiskBtn}
          />
        </div>
      )}
      <ReactTooltip effect="solid" />
    </div>
  )
}
