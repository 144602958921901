import React, { useState } from 'react'

import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { animated, useTransition } from 'react-spring'

import styles from './WalkInATK.module.scss'
import {
  Button,
  ImageFieldWithWebcam,
  RadioButtons,
  RadioButtonsRegisterOption,
} from '@/components/shared'

import { walkInServices } from '../../services'
import { CovidScreening, CovidScreeningType } from '@/features/types'
import { formatDate, getLocaleDateString } from '@/lib/helpers'

import { useWalkInContext } from '../../context'

const dateFormat = 'D MMMM YYYY'

export const WalkInATK = () => {
  const {
    company,
    state,
    setState,
    setType,
    t,
    visitor,
    setShowConfirmModal,
    covidScreening,
    setCovidScreening,
    setIsLoading,
  } = useWalkInContext()

  const { handleSubmit, register } = useForm({
    defaultValues: {
      test_atk_at: covidScreening?.test_atk_at
        ? formatDate(covidScreening?.test_atk_at, dateFormat)
        : '',
    },
  })
  const [image, setImage] = useState<File>()

  const transitions = useTransition(state, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  })

  const opacityTo = () => {
    return state === 1
      ? { range: [0.0, 1.0], output: [0, 1] }
      : { range: [1.0, 0.0], output: [1, 0] }
  }

  const _handleSubmit = async (data: any) => {
    if (state === 1) {
      setCovidScreening({
        ...(covidScreening as CovidScreening),
        test_atk_at: data.test_atk_at,
      })
      setState(2)
    } else if (visitor) {
      setIsLoading(true)
      setShowConfirmModal(false)
      const res = await walkInServices().submitVisitor(visitor)
      if (
        covidScreening &&
        company?.walk_in_setting.covid_screening_type ===
          CovidScreeningType.both
      ) {
        await walkInServices().submitScreeningForm(
          res?.covidScreeningSlug,
          covidScreening,
          res?.invitationSlug,
        )
      }
      await walkInServices().submitAtkForm(
        res?.covidScreeningSlug,
        {
          atk_image: image,
          test_atk_at: data.test_atk_at,
        },
        res?.invitationSlug,
      )
      setIsLoading(false)
      setState(0)
      setType('visitor-success')
    }
  }

  const today = new Date()
  const yesterday = new Date()
  yesterday.setDate(today.getDate() - 1)
  const registerOptions: RadioButtonsRegisterOption[] = [
    {
      label: `${t('views.misc.today')} (${getLocaleDateString(today)})`,
      value: formatDate(today, dateFormat),
    },
    {
      label: `${t('views.misc.yesterday')} (${getLocaleDateString(yesterday)})`,
      value: formatDate(yesterday, dateFormat),
    },
  ]

  return (
    <div
      className={classnames(styles.formArea, {
        [styles.formImageArea]: state === 2,
      })}
    >
      {transitions(({ opacity }, state) => {
        return (
          <animated.div
            style={{
              position: 'absolute',
              width: '100%',
              opacity: opacity.to(opacityTo()),
            }}
          >
            <form
              id={`walkInState${state}`}
              onSubmit={handleSubmit(_handleSubmit)}
              className={classnames({ [styles.webcamArea]: state === 2 })}
            >
              {state === 1 ? (
                <>
                  <RadioButtons
                    title={t(
                      'views.features.visitor.covid_screening.atk_form.atk_test_at',
                    )}
                    name={'test_atk_at'}
                    register={register}
                    registerOptions={registerOptions}
                    required
                    className={styles.atkRadioButtons}
                  />
                  <Button
                    type={'submit'}
                    text={t('views.misc.next')}
                    size={'L'}
                    className={styles.atkButton}
                  />
                </>
              ) : (
                <ImageFieldWithWebcam
                  title={t(
                    'views.features.visitor.covid_screening.atk_form.atk_image',
                  )}
                  image={image}
                  setImage={setImage}
                  required
                  withCountdown
                  withSubmitButton
                  t={t}
                  defaultImagePath={covidScreening?.atk_image?.url}
                  handleSubmit={() => setShowConfirmModal(true)}
                />
              )}
            </form>
          </animated.div>
        )
      })}
    </div>
  )
}
