import React from 'react'

import classnames from 'classnames'

import styles from './Footer.module.scss'

import { devisePaths, policyPath, termsPath } from '@/features/constants'
import { t } from '@/lib/helpers'

type Props = {
  walkIn?: boolean
}

export const Footer = (props: Props) => {
  const { walkIn } = props
  const current_path = window.location.pathname
  let footer = (
    <>
      <a href={termsPath} className={styles.footerLink}>
        {t('views.components.footer.terms')}
      </a>
      <span className={styles.andText}>{t('views.misc.and')}</span>
      <a href={policyPath} className={styles.footerLink}>
        {t('views.components.footer.policy')}
      </a>
    </>
  )

  if (!devisePaths.some((path) => current_path.includes(path)) || walkIn) {
    footer = (
      <div className={styles.footer}>
        <a
          href={termsPath}
          className={classnames(styles.footerContent, styles.footerLinkGrey)}
        >
          {t('views.components.footer.terms')}
        </a>
        <a
          href={policyPath}
          className={classnames(styles.footerContent, styles.footerLinkGrey)}
        >
          {t('views.components.footer.policy')}
        </a>
        <div className={styles.footerContent}>
          {t('views.components.footer.made_with')}
          <img src="/assets/icons/heart.png" className={styles.heartIcon} />
          {t('views.components.footer.in_bangkok')}
        </div>
      </div>
    )
  }

  return <div className={styles.footerWrapper}>{footer}</div>
}
