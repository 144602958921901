import React from 'react'

import styles from './Label.module.scss'

type Props = {
  title: string
  description?: string
}

export const Label = (props: Props) => {
  const { title, description } = props
  return (
    <div className={styles.label}>
      <span className={styles.title}>{title}</span>
      <span className={styles.description}>{description}</span>
    </div>
  )
}
