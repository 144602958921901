import React from 'react'

import {
  ISelectOption,
  SortOptions,
  UserManagementPage,
} from '@/components/shared'

import { systemAdminService } from '../../services'
import { systemAdminsPath } from '@/features/constants/systemAdmin'
import { SystemAdmin, SystemAdminStatus } from '@/features/types'

type Props = {
  systemAdmins: SystemAdmin[]
  systemAdmin: SystemAdmin
  defaultSearch?: string
  defaultSort?: string
  defaultSortDirection?: string
}

const SystemAdminPage = (props: Props) => {
  const {
    systemAdmins,
    systemAdmin,
    defaultSearch,
    defaultSort,
    defaultSortDirection,
  } = props

  const sortOptions: SortOptions = {
    sort: defaultSort || '',
    direction: defaultSortDirection || '',
  }
  const getSearchParams = (params: any) => {
    const { newSort, newDirection, newSearch } = params
    const search =
      newSearch === null || newSearch === undefined
        ? defaultSearch || ''
        : newSearch
    const sort = newSort || sortOptions.sort
    const direction = newDirection || sortOptions.direction

    return `search=${search}&sort=${sort}&sort_direction=${direction}`
  }

  const handleQuery = (params: any) => {
    window.location.href = `${systemAdminsPath}?${getSearchParams(params)}`
  }

  const onCreateEmployee = async (data: any) => {
    await systemAdminService().createSystemAdmin(
      data.name,
      data.email,
      data.image,
    )
  }

  const onChangeStatus = async (checked: boolean, id: string) => {
    const newStatus = checked
      ? SystemAdminStatus.enable
      : SystemAdminStatus.disable
    await systemAdminService().updateEmployeeStatus(id, newStatus)
    window.location.reload()
  }

  const onEdit = async (id: string, data: any) => {
    await systemAdminService().editSystemAdmin(
      id,
      data.name,
      data.email,
      data.image,
    )
    window.location.reload()
  }

  const onDelete = async (id: string) => {
    await systemAdminService().deleteSystemAdmin(id)
    window.location.reload()
  }

  const roleOptions: ISelectOption[] = [
    {
      label: 'All',
      value: 'All',
    },
    {
      label: 'Staff',
      value: 'Staff',
    },
    {
      label: 'Admin',
      value: 'Admin',
    },
    {
      label: 'Owner',
      value: 'Owner',
    },
  ]

  return (
    <UserManagementPage
      users={systemAdmins}
      user={systemAdmin}
      defaultSearch={defaultSearch}
      defaultSort={defaultSort}
      defaultSortDirection={defaultSortDirection}
      onCreate={onCreateEmployee}
      onChangeStatus={onChangeStatus}
      onEdit={onEdit}
      onDelete={onDelete}
      handleQuery={handleQuery}
      roleOptions={roleOptions}
      isSystemAdmin
    />
  )
}

export default SystemAdminPage
