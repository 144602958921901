import React, { useState } from 'react'

import classnames from 'classnames'
import { useForm } from 'react-hook-form'

import {
  AreaSetting,
  GeneralSetting,
  InvitationSetting,
  WalkInSetting,
} from '../../components'
import styles from './CompanySettingPage.module.scss'
import { ApplicationLayout, NumberFieldDirection } from '@/components/shared'

import { companyServices } from '../../services'
import {
  maxInvitationEffectiveHour,
  maxScreeningEffectiveDate,
  minInvitationEffectiveHour,
  minScreeningEffectiveDate,
} from '@/features/constants'
import {
  Company,
  CompanySettingNumberField,
  CovidScreeningSettingFields,
  CovidScreeningType,
  Employee,
  InvitationSetting as InvitationSettingType,
  InvitationSettingFields,
  InvitationWalkInType,
  WalkInSetting as WalkInSettingType,
  WalkInSettingFields,
} from '@/features/types'
import { formatDate, t, useDebounce } from '@/lib/helpers'

type SelectableMenu = 0 | 1 | 2 | 3

type Props = {
  company: Company
  employee: Employee
  walk_in_qr_code: string
}

const getHourFromTimeValue = (value: string) => {
  let hour = parseInt(value.split(':')[0]) % 12
  if (value.includes('PM')) hour += 12
  return hour
}

const getMinutesFromTimeValue = (value: string) => {
  let minutes = value.split(':')[1]
  minutes = minutes.split(' ')[0]
  return parseInt(minutes)
}

const CompanySettingPage = (props: Props) => {
  const { company, employee, walk_in_qr_code } = props
  const { invitation_setting, walk_in_setting } = company
  const [selectedMenu, setSelectedMenu] = useState<SelectableMenu>(0)
  const defaultOpenTime = new Date()
  defaultOpenTime.setHours(company.open_hour, company.open_minutes)
  const defaultOpenTimeWeekend = new Date()
  defaultOpenTimeWeekend.setHours(
    company.open_hour_weekend,
    company.open_minutes_weekend,
  )
  const defaultCloseTime = new Date()
  defaultCloseTime.setHours(company.close_hour, company.close_minutes)
  const defaultCloseTimeWeekend = new Date()
  defaultCloseTimeWeekend.setHours(
    company.close_hour_weekend,
    company.close_minutes_weekend,
  )
  const { register, watch, reset, getValues, control } = useForm({
    defaultValues: {
      general: {
        name: company.name,
        address: company.address,
        latitude: company.latitude,
        longitude: company.longitude,
        location_name: company.location_name,
        logo: company.logo_url,
        weekday: {
          open_time: formatDate(defaultOpenTime, 'hh:mm A'),
          close_time: formatDate(defaultCloseTime, 'hh:mm A'),
        },
        weekend: {
          open_on_weekend: company.open_on_weekend,
          open_time_weekend: formatDate(defaultOpenTimeWeekend, 'hh:mm A'),
          close_time_weekend: formatDate(defaultCloseTimeWeekend, 'hh:mm A'),
        },
        terms_url: company.terms_url,
        policy_url: company.policy_url,
        visitor_policy_url: company.visitor_policy_url,
      },
      invitation: {
        required_vaccine_record: invitation_setting.required_vaccine_record,
        required_screening_form: [
          CovidScreeningType.form,
          CovidScreeningType.either,
          CovidScreeningType.both,
        ].includes(invitation_setting.covid_screening_type),
        required_atk: [
          CovidScreeningType.atk,
          CovidScreeningType.either,
          CovidScreeningType.both,
        ].includes(invitation_setting.covid_screening_type),
        required_covid_screening:
          invitation_setting.covid_screening_type === CovidScreeningType.both
            ? CovidScreeningType.both
            : CovidScreeningType.either,
        effective_date_screening: invitation_setting.effective_date_screening,
        email_language: invitation_setting.email_language,
        email_color: invitation_setting.email_color,
        effective_hour: invitation_setting.effective_hour,
        show_personal_data: invitation_setting.show_personal_data,
        show_vaccine_record: invitation_setting.show_vaccine_record,
        show_covid_screening: invitation_setting.show_covid_screening,
      },
      walk_in: {
        required_screening_form: [
          CovidScreeningType.form,
          CovidScreeningType.either,
          CovidScreeningType.both,
        ].includes(walk_in_setting.covid_screening_type),
        required_atk: [
          CovidScreeningType.atk,
          CovidScreeningType.either,
          CovidScreeningType.both,
        ].includes(walk_in_setting.covid_screening_type),
        effective_date_screening: walk_in_setting.effective_date_screening,
        required_covid_screening:
          walk_in_setting.covid_screening_type === CovidScreeningType.both
            ? CovidScreeningType.both
            : CovidScreeningType.either,
      },
    },
  })

  const handleUploadLogo = (logo: File) => {
    companyServices().uploadLogo(company.slug, logo)
  }

  const handleUpdateGeneralFields = useDebounce(async () => {
    const options: any = {
      name: watch('general.name'),
      location_name: watch('general.location_name'),
      open_hour: getHourFromTimeValue(watch('general.weekday.open_time')),
      open_minutes: getMinutesFromTimeValue(watch('general.weekday.open_time')),
      close_hour: getHourFromTimeValue(watch('general.weekday.close_time')),
      close_minutes: getMinutesFromTimeValue(
        watch('general.weekday.close_time'),
      ),
      open_on_weekend: watch('general.weekend.open_on_weekend'),
      open_hour_weekend: getHourFromTimeValue(
        watch('general.weekend.open_time_weekend'),
      ),
      open_minutes_weekend: getMinutesFromTimeValue(
        watch('general.weekend.open_time_weekend'),
      ),
      close_hour_weekend: getHourFromTimeValue(
        watch('general.weekend.close_time_weekend'),
      ),
      close_minutes_weekend: getMinutesFromTimeValue(
        watch('general.weekend.close_time_weekend'),
      ),
      terms_url: watch('general.terms_url'),
      policy_url: watch('general.policy_url'),
      visitor_policy_url: watch('general.visitor_policy_url'),
    }
    await companyServices().update(company.slug, 'company', options)
  }, 2000)

  const setValueRequiredCovidScreening = (
    type: InvitationWalkInType,
    value: CovidScreeningType,
  ) => {
    const values = getValues()
    values[type]['required_covid_screening'] = value
    reset(values)
  }

  const getCovidScreeningType = (
    type: InvitationWalkInType,
    value: CovidScreeningSettingFields,
  ) => {
    const required_covid_screening = value[
      'required_covid_screening'
    ] as CovidScreeningType
    const required_screening_form = value['required_screening_form']
    const required_atk = value['required_atk']

    switch (true) {
      case required_screening_form && required_atk:
        setValueRequiredCovidScreening(type, required_covid_screening)
        return CovidScreeningType[required_covid_screening]
      case required_screening_form && !required_atk:
        setValueRequiredCovidScreening(type, CovidScreeningType.either)
        return CovidScreeningType.form
      case !required_screening_form && required_atk:
        setValueRequiredCovidScreening(type, CovidScreeningType.either)
        return CovidScreeningType.atk
      default:
        setValueRequiredCovidScreening(type, CovidScreeningType.not_require)
        return CovidScreeningType.not_require
    }
  }

  const handleUpdateInvitationFields = useDebounce(
    async (value: InvitationSettingFields) => {
      const options: InvitationSettingType = {
        ...value,
        covid_screening_type: getCovidScreeningType('invitation', value),
      }
      await companyServices().update(
        company.slug,
        'invitation_setting',
        options,
      )
    },
    2000,
  )

  const handleUpdateWalkInFields = useDebounce(
    async (value: WalkInSettingFields) => {
      const options: WalkInSettingType = {
        ...value,
        covid_screening_type: getCovidScreeningType('walk_in', value),
      }
      await companyServices().update(company.slug, 'walk_in_setting', options)
    },
    2000,
  )

  const handleUpdateAddress = async (
    address: string,
    latitude: number,
    longitude: number,
  ) => {
    const options = {
      latitude: latitude,
      longitude: longitude,
      address,
    }

    if (address !== company.address) {
      await companyServices().update(company.slug, 'company', options)
      const values = getValues()
      values['general']['latitude'] = latitude
      values['general']['longitude'] = longitude
      reset(values)
    }
  }

  const handleUpdateLatLng = useDebounce(
    async (latitude: number, longitude: number) => {
      const address = `https://maps.google.com/?q=${latitude},${longitude}`
      const options = {
        latitude,
        longitude,
        address,
      }
      if (latitude !== company.latitude || longitude !== company.longitude) {
        await companyServices().update(company.slug, 'company', options)
        const values = getValues()
        values['general']['address'] = address
        reset(values)
      }
    },
    2000,
  )

  const handleUpdateNumberField = useDebounce(
    async (
      type: 'invitation_setting' | 'walk_in_setting',
      key: string,
      value: number,
    ) => {
      await companyServices().update(company.slug, type, {
        [key]: value,
      })
    },
    2000,
  )

  const getNumberEffective = (
    field: CompanySettingNumberField,
    direction?: NumberFieldDirection,
  ) => {
    let numberEffective = watch(field)
    switch (direction) {
      case 'up':
        return numberEffective + 1
      case 'down':
        return numberEffective - 1
      default:
        return numberEffective
    }
  }

  const getMinMaxEffective = (field: CompanySettingNumberField) => {
    let maxEffective, minEffective
    switch (field) {
      case 'invitation.effective_date_screening':
      case 'walk_in.effective_date_screening':
        maxEffective = maxScreeningEffectiveDate
        minEffective = minScreeningEffectiveDate
        break
      case 'invitation.effective_hour':
        maxEffective = maxInvitationEffectiveHour
        minEffective = minInvitationEffectiveHour
        break
    }
    return { minEffective, maxEffective }
  }

  const handleNumberField = async (
    field: CompanySettingNumberField,
    direction?: NumberFieldDirection,
  ) => {
    let numberEffective = getNumberEffective(field, direction)
    const { minEffective, maxEffective } = getMinMaxEffective(field)

    if (numberEffective > maxEffective) numberEffective = maxEffective
    else if (numberEffective < minEffective) numberEffective = minEffective
    const values = getValues()
    const [type, key] = field.split('.')
    switch (key) {
      case 'effective_date_screening':
        values[type as InvitationWalkInType][key] = numberEffective
        break
      case 'effective_hour':
        values['invitation'][key] = numberEffective
        break
    }
    reset(values)
    await handleUpdateNumberField(`${type}_setting`, key, numberEffective)
  }

  return (
    <ApplicationLayout employee={employee}>
      <div className={styles.wrapper}>
        <span className={styles.title}>
          {t('views.features.company_setting.title')}
        </span>
        <div className={styles.menus}>
          <div
            onClick={() => setSelectedMenu(0)}
            className={classnames(styles.menu, {
              [styles.selectedMenu]: selectedMenu === 0,
            })}
          >
            {t('views.features.company_setting.general_menu')}
          </div>
          <div
            onClick={() => setSelectedMenu(1)}
            className={classnames(styles.menu, {
              [styles.selectedMenu]: selectedMenu === 1,
            })}
          >
            {t('views.features.company_setting.invitation_menu')}
          </div>
          <div
            onClick={() => setSelectedMenu(2)}
            className={classnames(styles.menu, {
              [styles.selectedMenu]: selectedMenu === 2,
            })}
          >
            {t('views.features.company_setting.area_menu')}
          </div>
          <div
            onClick={() => setSelectedMenu(3)}
            className={classnames(styles.menu, {
              [styles.selectedMenu]: selectedMenu === 3,
            })}
          >
            {t('views.features.company_setting.walk_in_menu')}
          </div>
        </div>
        <div className={styles.setting}>
          {selectedMenu === 0 && (
            <GeneralSetting
              {...company}
              register={register}
              control={control}
              watch={watch}
              handleUploadLogo={handleUploadLogo}
              handleUpdateFields={handleUpdateGeneralFields}
              handleUpdateAddress={handleUpdateAddress}
              handleUpdateLatLng={handleUpdateLatLng}
            />
          )}
          {selectedMenu === 1 && (
            <InvitationSetting
              register={register}
              control={control}
              handleNumberField={handleNumberField}
              handleUpdateFields={handleUpdateInvitationFields}
            />
          )}
          {selectedMenu == 2 && (
            <AreaSetting
              accessAreas={company.locations}
              handleAddArea={companyServices().addArea}
              handleUpdateArea={companyServices().updateArea}
              handleDeleteArea={companyServices().deleteArea}
            />
          )}
          {selectedMenu == 3 && (
            <WalkInSetting
              register={register}
              defaultQRCode={walk_in_qr_code}
              company={company}
              control={control}
              handleUpdateFields={handleUpdateWalkInFields}
              handleNumberField={handleNumberField}
            />
          )}
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default CompanySettingPage
