import React, { useEffect, forwardRef, useState } from 'react'

import classnames from 'classnames'
import { useForm } from 'react-hook-form'
import { animated, SpringValue } from 'react-spring'

import { ISelectOption, Select, TextField, Webcam } from '@/components/shared'

import { walkInServices } from '../../services'
import { CovidScreening, CovidScreeningType } from '@/features/types'

import { useWalkInContext, WalkInStateType } from '../../context'
import styles from '../WalkInForm/WalkInForm.module.scss'

type Props = {
  opacity: SpringValue<number>
  listHost?: ISelectOption[] | null
  host?: ISelectOption | null
  searchHost: (val: string) => void
  setHost: (host: ISelectOption | null) => void
  isCapture: boolean
  imgSrc?: string
  state: WalkInStateType
  showCountdown: boolean
  setShowCountdown: (showCountDown: boolean) => void
  countdown: number
  image?: File
}

export const WalkInVisitor = forwardRef<any, Props>((props, webcamRef) => {
  const {
    type,
    state,
    setState,
    setType,
    company,
    t,
    visitor,
    setVisitor,
    setShowConfirmModal,
    covidScreening,
    setCovidScreening,
    setIsLoading,
  } = useWalkInContext()
  const {
    opacity,
    listHost,
    host,
    searchHost,
    setHost,
    isCapture,
    imgSrc,
    showCountdown,
    setShowCountdown,
    countdown,
    image,
  } = props
  const [changeState, setChangeState] = useState<boolean>(false)
  const { handleSubmit, register, control } = useForm({
    defaultValues: {
      first_name: visitor?.first_name || '',
      last_name: visitor?.last_name || '',
      email: visitor?.email || '',
      telephone: visitor?.telephone || '',
      asset: visitor?.asset || '',
      host: visitor?.host_employee_id || '',
    },
  })

  const getNextWalkInType = () => {
    switch (company?.walk_in_setting.covid_screening_type) {
      case CovidScreeningType.either:
        return 'screening-selection'
      case CovidScreeningType.both:
      case CovidScreeningType.form:
        return 'screening-form'
      case CovidScreeningType.atk:
        return 'atk'
      default:
        return 'visitor-success'
    }
  }

  const manageVisitor = (data: any) => {
    setVisitor({
      ...data,
      host_employee_id: data.host,
      image: image || visitor?.image,
    })
  }

  const _handleSubmit = async (data: any) => {
    if (state < 4) {
      manageVisitor(data)
      setChangeState(true)
    } else if (image || visitor?.image) {
      const nextWalkInType = getNextWalkInType()
      if (nextWalkInType === 'visitor-success') {
        setIsLoading(true)
        setShowConfirmModal(false)
        await walkInServices().submitVisitor({
          ...data,
          host_employee_id: data.host,
          image,
        })
        setIsLoading(false)
      } else {
        if (!covidScreening) {
          const existCovidScreening =
            await walkInServices().getCovidScreeningByEmail(
              data.email,
              company?.slug,
            )
          setCovidScreening(existCovidScreening as CovidScreening)
        }
        manageVisitor(data)
      }
      setState(['screening-form', 'atk'].includes(nextWalkInType) ? 1 : 0)
      setType(nextWalkInType)
    }
    return false
  }

  useEffect(() => {
    if (state < 4 && changeState) {
      setState((state + 1) as WalkInStateType)
      setChangeState(false)
    }
  }, [changeState])

  switch (state) {
    case 1:
      return (
        <animated.div
          style={{
            position: 'absolute',
            width: '100%',
            opacity: opacity.to({ range: [0.0, 1.0], output: [0, 1] }),
          }}
        >
          <form id={'walkInState1'} onSubmit={handleSubmit(_handleSubmit)}>
            <TextField
              autoFocus
              title={t('views.misc.firstname')}
              name={'first_name'}
              placeholder={t('views.misc.firstname')}
              required
              register={register}
              className={styles.textField}
            />
            <TextField
              title={t('views.misc.lastname')}
              name={'last_name'}
              placeholder={t('views.misc.lastname')}
              required
              register={register}
              className={styles.textField}
            />
          </form>
        </animated.div>
      )
    case 2:
      return (
        <animated.div
          style={{
            position: 'absolute',
            width: '100%',
            opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
          }}
        >
          <form id={'walkInState2'} onSubmit={handleSubmit(_handleSubmit)}>
            <TextField
              autoFocus
              type={'email'}
              title={t('views.misc.email')}
              name={'email'}
              placeholder={'name@email.com'}
              required
              register={register}
              className={styles.textField}
            />
            <TextField
              type={'tel'}
              title={t('views.misc.phone_number')}
              name={'telephone'}
              placeholder={'012-345-6789'}
              pattern={'[0-9]{3}-[0-9]{3}-[0-9]{4}'}
              required
              register={register}
              className={styles.textField}
            />
          </form>
        </animated.div>
      )
    case 3:
      return (
        <animated.div
          style={{
            position: 'absolute',
            width: '100%',
            opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
          }}
        >
          <form id={'walkInState3'} onSubmit={handleSubmit(_handleSubmit)}>
            <Select
              autoFocus
              title={t('views.features.walk_in.visitor.staff_name')}
              required
              options={listHost || (host && [host]) || []}
              placeholder={t(
                'views.features.walk_in.visitor.staff_name_placeholder',
              )}
              name={'host'}
              control={control}
              onInputChange={searchHost}
              onChange={(val) =>
                setHost(listHost?.find((host) => host.value === val) || null)
              }
              menuIsOpen={listHost !== null}
              hideDropdownIcon
              defaultValue={visitor?.host_employee_id}
              className={styles.selectField}
            />
            <TextField
              title={t('views.features.walk_in.visitor.asset')}
              name={'asset'}
              placeholder={t(
                'views.features.walk_in.visitor.asset_placeholder',
              )}
              register={register}
              className={styles.textField}
            />
          </form>
        </animated.div>
      )
    default:
      return (
        <animated.div
          style={{
            position: 'absolute',
            opacity: opacity.to({ range: [1.0, 0.0], output: [1, 0] }),
          }}
        >
          {type === 'visitor' && (
            <form
              id={'walkInState4'}
              onSubmit={handleSubmit(_handleSubmit)}
              className={classnames(styles.webcamArea, {
                [styles.capture]: isCapture,
              })}
            >
              <Webcam
                imgSrc={imgSrc}
                ref={webcamRef}
                showCountdown={showCountdown}
                setShowCountdown={setShowCountdown}
                countdown={countdown}
              />
            </form>
          )}
        </animated.div>
      )
  }
})
