import React, { ChangeEvent, useState } from 'react'

import classnames from 'classnames'
import { Control, Controller, FieldValues } from 'react-hook-form'
import { InputActionMeta, MenuPlacement, StylesConfig } from 'react-select'
import { default as CreatableSelectComponent } from 'react-select/creatable'

import { ISelectOption } from '..'
import styles from './CreatableSelect.module.scss'

type Props = {
  control: Control<FieldValues>
  name: `${string}`
  options: ISelectOption[]
  title?: string
  placeholder?: string
  required?: boolean
  menuPlacement?: MenuPlacement
  isMulti?: boolean
  defaultValue?: any
  validate?: (input: string) => boolean
  hideDropdownIcon?: boolean
  formatCreateLabel?: (inputValue: string) => string
  formatCreateValue?: (inputValue: string) => string
  className?: string
}

export const CreatableSelect = (props: Props) => {
  const {
    control,
    name,
    options,
    title,
    placeholder,
    required = false,
    menuPlacement = 'bottom',
    isMulti = false,
    defaultValue,
    validate = (_input: string) => true,
    hideDropdownIcon = false,
    formatCreateLabel,
    formatCreateValue,
    className,
  } = props
  const [newOptions, setNewOptions] = useState<ISelectOption[]>(options)

  const customStyles: StylesConfig<ISelectOption, true> = {
    placeholder: (provided) => ({
      ...provided,
      color: '#d1d1d1',
      marginLeft: '2px',
      marginRight: '2px',
    }),
    indicatorSeparator: () => ({
      backgroundColor: 'unset',
    }),
    control: (provided) => ({
      ...provided,
      padding: '4px 8px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: hideDropdownIcon ? 'none' : 'inherit',
    }),
    option: (provided: any) => ({
      ...provided,
      borderRadius: '4px',
    }),
    menuList: (provided: any) => ({
      ...provided,
      paddingLeft: '8px',
      paddingRight: '8px',
    }),
  }

  const [currentInput, setCurrentInput] = useState<string>('')

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { onChange, value } }) => {
        const handleInputChange = (
          inputValue: string,
          actionMeta: InputActionMeta | { action: string },
        ) => {
          if (actionMeta.action === 'menu-close' && validate(currentInput)) {
            onChange([...value, { label: currentInput, value: currentInput }])
            setCurrentInput('')
          } else {
            setCurrentInput(inputValue)
          }
        }

        return (
          <div className={classnames(styles.background, className)}>
            <label className={styles.title}>
              {title}
              {required && title && (
                <span className={styles.requiredTitle}>*</span>
              )}
            </label>
            <CreatableSelectComponent
              value={value}
              placeholder={placeholder}
              options={newOptions}
              menuPlacement={menuPlacement}
              isMulti={isMulti}
              styles={customStyles}
              onCreateOption={(_inputValue: string) => {
                if (validate(_inputValue)) {
                  const formatInputValue = formatCreateValue
                    ? formatCreateValue(_inputValue)
                    : _inputValue
                  const newOption = {
                    label: formatInputValue,
                    value: formatInputValue,
                  }
                  setNewOptions([...newOptions, newOption])
                  isMulti
                    ? handleInputChange(formatInputValue, {
                        action: 'menu-close',
                      })
                    : onChange(newOption)
                }
              }}
              onChange={onChange}
              inputValue={isMulti ? currentInput : undefined}
              onInputChange={isMulti ? handleInputChange : undefined}
              className={styles.creatableSelect}
              formatCreateLabel={formatCreateLabel}
            />
            {required && (
              <input
                required={required}
                value={value}
                onChange={(val: ChangeEvent<HTMLInputElement>) =>
                  onChange(val.target.value)
                }
                className={styles.validateInput}
              />
            )}
          </div>
        )
      }}
    />
  )
}
