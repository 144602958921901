import React from 'react'

import classnames from 'classnames'

import styles from './Link.module.scss'

type Props = {
  url: string
  text?: string
  noUnderLine?: boolean
  className?: string
}

export const Link = (props: Props) => {
  const { url, text, noUnderLine = false, className } = props

  return (
    <a
      href={url}
      target={'_blank'}
      rel={'noreferrer'}
      className={classnames(
        styles.link,
        { [styles.noUnderLine]: noUnderLine },
        className,
      )}
    >
      {text || url}
    </a>
  )
}
