import React, { useState } from 'react'

import { useForm } from 'react-hook-form'

import { Logs } from '../../components'
import styles from './ActionLogsPage.module.scss'
import { SortOptions } from '@/components/shared'
import LogsLayout from '@/components/shared/LogsLayout/LogsLayout'

import { actionLogsPath } from '@/features/constants'
import { ActionLog, Employee, SearchParams } from '@/features/types'
import { formatDate } from '@/lib/helpers'

type Props = {
  logs: ActionLog[]
  defaultSearch: string
  defaultSort: string
  defaultSortDirection: string
  defaultFromDate?: string
  defaultToDate?: string
  employee: Employee
}

const ActionLogsPage = (props: Props) => {
  const {
    logs,
    defaultSearch,
    defaultSort,
    defaultSortDirection,
    defaultFromDate,
    defaultToDate,
    employee,
  } = props
  const [search, setSearch] = useState<string>(defaultSearch || '')
  const sortOptions: SortOptions = {
    sort: defaultSort || '',
    direction: defaultSortDirection || '',
  }
  const { control, watch } = useForm({
    defaultValues: {
      fromDate: defaultFromDate || undefined,
      toDate: defaultToDate || undefined,
    },
  })

  const getSearchParams = (options: SearchParams) => {
    const sort = options.sort || sortOptions.sort
    const direction = options.direction || sortOptions.direction
    const fromDate = formatDate(watch('fromDate'))
    const toDate = formatDate(watch('toDate'))
    const dateQuery =
      fromDate !== '' &&
      toDate !== '' &&
      `&search_date=${fromDate} to ${toDate}`
    let path = `search=${search}&sort=${sort}&sort_direction=${direction}&tab=action`
    return dateQuery ? path + dateQuery : path
  }

  const handleQuery = (newSort?: string, newDirection?: string) => {
    window.location.href = `${actionLogsPath}?${getSearchParams({
      sort: newSort,
      direction: newDirection,
    })}`
  }

  return (
    <LogsLayout
      employee={employee}
      control={control}
      search={search}
      setSearch={setSearch}
      getSearchParams={getSearchParams}
      handleQuery={handleQuery}
    >
      <Logs
        logs={logs}
        sortOptions={sortOptions}
        handleSort={handleQuery}
        className={styles.logs}
      />
    </LogsLayout>
  )
}

export default ActionLogsPage
