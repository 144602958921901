// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".SuccessPage-module__layout___rSm7t{background-color:#fff !important}.SuccessPage-module__success___2T8IB{margin-top:10vh}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/delivery/pages/SuccessPage/SuccessPage.module.scss"],"names":[],"mappings":"AAEA,oCACE,gCAAA,CAGF,qCACE,eAAA","file":"SuccessPage.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.layout) {\n  background-color: $white !important;\n}\n\n:local(.success) {\n  margin-top: 10vh;\n}\n"]}]);
// Exports
exports.locals = {
	"layout": "SuccessPage-module__layout___rSm7t",
	"success": "SuccessPage-module__success___2T8IB"
};
module.exports = exports;
