import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import { Deliveries } from '../../components'
import styles from './DeliveryPage.module.scss'
import { ApplicationLayout, Search, SortOptions } from '@/components/shared'

import { DeliveriesChannel } from '@/channels'
import { deliveriesPath } from '@/features/constants'
import { Employee, Delivery, EmployeeType } from '@/features/types'
import { t } from '@/lib/helpers'

import { DeliveryContext } from '../../context'

type Props = {
  defaultDeliveries: Delivery[]
  defaultSearch: string
  defaultSort: string
  defaultSortDirection: string
  employee: Employee
}

const DeliveryPage = (props: Props) => {
  const {
    defaultDeliveries,
    defaultSearch,
    defaultSort,
    defaultSortDirection,
    employee,
  } = props
  const [search, setSearch] = useState<string>(defaultSearch || '')
  const [deliveries, setDeliveries] = useState<Delivery[]>(defaultDeliveries)
  const [lastDeleteID, setLastDeleteID] = useState<number>()
  const sortOptions: SortOptions = {
    sort: defaultSort || '',
    direction: defaultSortDirection || '',
  }
  const { control } = useForm()

  const getSearchParams = (newSort?: string, newDirection?: string) => {
    const sort = newSort || sortOptions.sort
    const direction = newDirection || sortOptions.direction
    return `search=${search}&sort=${sort}&sort_direction=${direction}`
  }

  const handleQuery = (newSort?: string, newDirection?: string) => {
    window.location.href = `${deliveriesPath}?${getSearchParams(
      newSort,
      newDirection,
    )}`
  }

  const onRemoveDelivery = () => {
    setDeliveries(
      deliveries.filter((delivery: Delivery) => delivery.id !== lastDeleteID),
    )
  }

  useEffect(() => {
    DeliveriesChannel.received = (newDelivery: Delivery) => {
      if (
        employee.id !== newDelivery.host_employee_id &&
        employee.type === EmployeeType.staff
      )
        return

      switch (newDelivery.status) {
        case 'pending':
          setDeliveries([...deliveries, newDelivery])
          break
        case 'picked_up':
          setLastDeleteID(newDelivery.id)
          onRemoveDelivery()
          break
      }
    }
  }, [deliveries])

  return (
    <DeliveryContext.Provider
      value={{
        deliveries,
        setDeliveries,
        lastDeleteID,
        setLastDeleteID,
        sortOptions,
        handleQuery,
        onRemoveDelivery,
      }}
    >
      <ApplicationLayout employee={employee}>
        <div className={styles.wrapper}>
          <span className={styles.title}>
            {t('views.features.delivery.title')}
          </span>
          <Search
            search={search}
            setSearch={setSearch}
            control={control}
            handleQuery={handleQuery}
            className={styles.search}
          />
          <Deliveries />
        </div>
      </ApplicationLayout>
    </DeliveryContext.Provider>
  )
}

export default DeliveryPage
