import React from 'react'

import styles from './CreateSuccessModal.module.scss'
import { Button, Modal } from '@/components/shared'

type Props = {
  show: boolean
  onClose: () => void
  createdBy: string
}

export const CreateSuccessModal = (props: Props) => {
  const { show, onClose, createdBy } = props

  return (
    <Modal show={show} onClose={onClose}>
      <div className={styles.createSuccessModal}>
        <img src="/assets/icons/paper-plane.svg" height={64} />
        <div className={styles.title}>Created Event & Sent Invitation</div>
        <div className={styles.description}>
          This event was created by {createdBy}.
        </div>
        <Button
          onClick={onClose}
          text={'Done'}
          size={'L'}
          className={styles.button}
        />
      </div>
    </Modal>
  )
}
