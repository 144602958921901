import consumer from './consumer'

export const InvitationsChannel = consumer.subscriptions.create(
  'InvitationsChannel',
  {
    connected() {},
    disconnected() {},
    received(_data) {},
  },
)
