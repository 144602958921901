import React from 'react'

import classnames from 'classnames'

import styles from './SortableTitle.module.scss'

export type SortOptions = {
  sort: string
  direction: string
}

type Props = {
  column: string
  title: string
  options?: SortOptions
  handleSort: (newSort?: string, newDirection?: string) => void
  showSortRightSide?: boolean
  className?: string
}

export const SortableTitle = (props: Props) => {
  const {
    column,
    title,
    options,
    handleSort,
    showSortRightSide = false,
    className,
  } = props

  const chevron_contents = () => {
    const style = column != options?.sort && styles.hoverChevrons

    const upFilename =
      options?.direction == 'asc' && column == options?.sort
        ? 'highlight-chevron-up.svg'
        : 'chevron-up.svg'
    const downFilename =
      options?.direction == 'desc' && column == options?.sort
        ? 'highlight-chevron-down.svg'
        : 'chevron-down.svg'
    return (
      <div
        className={classnames(
          styles.chevrons,
          { [styles.chevronsMarginLeft]: showSortRightSide },
          style,
        )}
      >
        <img src={`/assets/icons/${upFilename}`} className={styles.chevronUp} />
        <img
          src={`/assets/icons/${downFilename}`}
          className={styles.chevronDown}
        />
      </div>
    )
  }

  const toggleDirection =
    column == options?.sort && options.direction == 'asc' ? 'desc' : 'asc'
  return (
    <div
      className={classnames(styles.title, className)}
      onClick={() => handleSort(column, toggleDirection)}
    >
      {!showSortRightSide && title !== '' && chevron_contents()}
      <div className={styles.sortableTitle}>{title}</div>
      {showSortRightSide && title !== '' && chevron_contents()}
    </div>
  )
}
