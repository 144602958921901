import React, { createRef, useState } from 'react'

import classnames from 'classnames'
import { UseFormRegister, UseFormWatch } from 'react-hook-form'

import styles from './PasswordWithConfirm.module.scss'
import { CircleClose } from '@/components/icons'
import {
  TextField,
  validatePassword,
  PasswordComplexity,
  EyeSwitch,
} from '@/components/shared'

import { t } from '@/lib/helpers'

type Props = {
  register: UseFormRegister<any>
  watch: UseFormWatch<any>
  className?: string
}

type PasswordIconsProps = {
  password: string
  check: boolean
  showPassword: boolean
  onClickEyeSwitch: () => void
}

const PasswordIcons = (props: PasswordIconsProps) => {
  const { password, check, showPassword, onClickEyeSwitch } = props
  return (
    <div className={styles.passwordIcons}>
      <div
        className={classnames(styles.validate, {
          [styles.showValidate]: !!password,
        })}
      >
        {check ? (
          <img
            src={'/assets/icons/correct-circle.svg'}
            className={styles.icon}
          />
        ) : (
          <CircleClose className={styles.icon} />
        )}
      </div>
      <EyeSwitch checked={showPassword} onClick={onClickEyeSwitch} />
    </div>
  )
}

export const PasswordWithConfirm = (props: Props) => {
  const { register, watch, className } = props
  const inputRef = createRef<HTMLInputElement>()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false)
  const [showPasswordComplex, setShowPasswordComplex] = useState<boolean>(false)

  const password = watch('password')
  const confirmPassword = watch('confirmPassword')

  return (
    <div className={className}>
      <TextField
        ref={inputRef}
        legendTitle={t('views.features.devise.forgot_password.new_password')}
        type={showPassword ? 'text' : 'password'}
        name={'password'}
        register={register}
        required
        autoComplete={'new-password'}
        onFocus={() => setShowPasswordComplex(true)}
        onBlur={() => setShowPasswordComplex(false)}
        postDecoration={
          <PasswordIcons
            password={password}
            check={validatePassword(password)}
            showPassword={showPassword}
            onClickEyeSwitch={() => {
              inputRef.current?.focus()
              setShowPassword(!showPassword)
            }}
          />
        }
        className={classnames(styles.field)}
      />
      <TextField
        legendTitle={t(
          'views.features.devise.forgot_password.confirm_password',
        )}
        type={showConfirmPassword ? 'text' : 'password'}
        name={'confirmPassword'}
        register={register}
        required
        autoComplete={'new-password'}
        postDecoration={
          <PasswordIcons
            password={confirmPassword}
            check={password === confirmPassword}
            showPassword={showConfirmPassword}
            onClickEyeSwitch={() =>
              setShowConfirmPassword(!showConfirmPassword)
            }
          />
        }
        className={classnames(styles.field)}
      />
      <PasswordComplexity password={password} show={showPasswordComplex} />
    </div>
  )
}
