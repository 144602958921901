import React from 'react'

import classnames from 'classnames'

import styles from './EyeSwitch.module.scss'

type Props = {
  checked: boolean
  onClick: () => void
  className?: string
}

export const EyeSwitch = (props: Props) => {
  const { checked, onClick, className } = props

  return (
    <div onClick={onClick} className={classnames(styles.background, className)}>
      {checked ? (
        <img src="/assets/icons/eye.svg" />
      ) : (
        <img src="/assets/icons/eye-off.svg" />
      )}
    </div>
  )
}
