import consumer from './consumer'

export const RiskAssessmentsChannel = consumer.subscriptions.create(
  'RiskAssessmentsChannel',
  {
    connected() {},
    disconnected() {},
    received(_data) {},
  },
)
