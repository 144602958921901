import React, { useState } from 'react'

import styles from './WalkInVisitorPolicy.module.scss'
import { useWalkInContext } from '../../context'
import { Button, Link } from '@/components/shared'

export const WalkInVisitorPolicy = () => {
  const { setType, company, t } = useWalkInContext()
  const [disableBtn, setDisableBtn] = useState<boolean>(true)
  return (
    <div className={styles.wrapper}>
      <label className={styles.checkBox}>
        <input
          type="checkbox"
          onClick={() => setDisableBtn(!disableBtn)}
          className={styles.checkBoxInput}
        />
        <div className={styles.detail}>
          {t('views.misc.visitor_policy.text')}{' '}
          <Link
            url={company.visitor_policy_url}
            text={t('views.misc.visitor_policy.text_url')}
            noUnderLine
          />
        </div>
      </label>
      <Button
        text={t('views.misc.next')}
        onClick={() => setType('visitor')}
        disabled={disableBtn}
        className={styles.btn}
      />
    </div>
  )
}
