import React from 'react'

import { EventInput } from '@fullcalendar/react'
import classnames from 'classnames'
import { Control, UseFormRegister, UseFormWatch } from 'react-hook-form'
import { isEmail } from 'react-multi-email'

import styles from './InviteForm.module.scss'
import {
  CreatableSelect,
  DateTimeRangeField,
  ISelectOption,
  Select,
  TextField,
} from '@/components/shared'

import { Company, Employee, Invite } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  employee: Employee
  isEdit?: boolean
  invite?: Invite
  areaToAccessOptions: ISelectOption[]
  register: UseFormRegister<any>
  handleSubmit: () => void
  control: Control<any>
  watch: UseFormWatch<any>
  events: EventInput[]
  setEvents: (events: EventInput[]) => void
  duration: number | string
  setDuration: (duration: number | string) => void
  scheduleMode: boolean
  setScheduleMode: (scheduleMode: boolean) => void
  allVisitorEmails: ISelectOption[]
  company: Company
  employees: ISelectOption[]
}

const getOptionName = (name: string, disabled?: boolean) => {
  return `${name} ${
    disabled
      ? `(${t('views.features.invite.invite_form.connection_needed')})`
      : ''
  }`
}

export const InviteForm = (props: Props) => {
  const {
    invite,
    areaToAccessOptions,
    register,
    control,
    watch,
    handleSubmit,
    events,
    setEvents,
    duration,
    setDuration,
    scheduleMode,
    setScheduleMode,
    isEdit,
    allVisitorEmails,
    company,
    employees,
    employee,
  } = props

  const locationOptions: ISelectOption[] = [
    {
      label: t('views.features.invite.invite_form.on_site'),
      value: 'local',
    },
    {
      label: getOptionName('Zoom Meeting', employee.zoom_email === null),
      value: 'zoom',
      isDisabled: employee.zoom_email === null,
    },
    {
      label: getOptionName('Google Meet', employee.google_email === null),
      value: 'google',
      isDisabled: employee.google_email === null,
    },
  ]

  return (
    <form id="createInvite" onSubmit={handleSubmit} className={styles.form}>
      <div className={styles.title}>
        {isEdit
          ? t('views.features.calendar.calendar.edit_invitation')
          : t('views.features.calendar.calendar.create_invitation')}
      </div>
      <TextField
        title={t('views.features.invite.invite_form.event_name')}
        required
        name="title"
        defaultValue={invite?.title}
        register={register}
        placeholder={t(
          'views.features.invite.invite_form.event_name_placeholder',
        )}
        className={styles.inputEventName}
      />
      <TextField
        title={t('views.features.invite.invite_form.description')}
        type="textarea"
        name="comment"
        defaultValue={invite?.comment}
        register={register}
        placeholder={t(
          'views.features.invite.invite_form.description_placeholder',
        )}
        maxLength={420}
        className={styles.input}
      />
      <DateTimeRangeField
        required
        className={styles.input}
        control={control}
        register={register}
        watch={watch}
        name="dateTime"
        events={events}
        setEvents={setEvents}
        duration={duration}
        setDuration={setDuration}
        scheduleMode={scheduleMode}
        setScheduleMode={setScheduleMode}
        isEdit={isEdit}
        company={company}
      />
      <Select
        title={t('views.features.invite.invite_form.host')}
        name={'host_employee_id'}
        required
        options={employees}
        control={control}
        className={styles.input}
      />
      <CreatableSelect
        isMulti
        control={control}
        name="emails"
        title={t('views.features.invite.invite_form.invites')}
        placeholder={t('views.features.invite.invite_form.enter_emails')}
        required
        options={allVisitorEmails}
        validate={isEmail}
        formatCreateLabel={(inputValue: string) => inputValue}
        menuPlacement="top"
        hideDropdownIcon
        className={classnames(styles.input, styles.multiEmails)}
      />
      <Select
        control={control}
        name="category"
        options={locationOptions}
        title={t('views.features.invite.invite_form.location')}
        menuPlacement="top"
        className={styles.input}
      />
      {watch('category') === 'local' ? (
        <CreatableSelect
          control={control}
          name={'location'}
          options={areaToAccessOptions}
          title={t('views.features.invite.invite_form.access_area')}
          menuPlacement="top"
          required
          className={styles.input}
        />
      ) : (
        <TextField
          title={t('views.features.invite.invite_form.link')}
          name={'online_link'}
          register={register}
          required
          loading={!watch('online_link')}
          className={styles.onlineLink}
        />
      )}
    </form>
  )
}
