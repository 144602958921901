import React from 'react'

import styles from './CheckboxButtons.module.scss'

type Choice = {
  title: React.ReactNode
  value: string
}

type Props = {
  choices: Choice[]
  name: string
  register: any
  className?: string
}

export const CheckboxButtons = (props: Props) => {
  const { choices, register, name, className } = props
  return (
    <div className={className}>
      {choices.map((choice) => {
        const { title, value } = choice
        return (
          <label key={value} className={styles.checkboxButton}>
            <input
              type="checkbox"
              className={styles.checkbox}
              {...register(name)}
              value={value}
            />
            <span className={styles.checkboxLook}>
              <img src={'/assets/icons/check.svg'} />
            </span>
            <span className={styles.title}>{title}</span>
            <div className={styles.bg}></div>
          </label>
        )
      })}
    </div>
  )
}
