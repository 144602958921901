import React from 'react'

import classnames from 'classnames'

import styles from './WalkInLayout.module.scss'
import { CaretCircleLeft } from '@/components/icons'
import { ApplicationLayout, LocaleSwitch } from '@/components/shared'

import { CovidScreeningType } from '@/features/types'

import { useWalkInContext, WalkInStateType } from '../context'

type Props = {
  children?: React.ReactNode
  className?: string
}

export const WalkInLayout = (props: Props) => {
  const { children, className } = props
  const {
    company,
    state,
    setState,
    type,
    setType,
    locale,
    setLocale,
    t,
    isLoading,
  } = useWalkInContext()

  let title = ''
  switch (type) {
    case 'visitor-success': {
      title = t('views.features.walk_in.visitor_success.title')
      break
    }
    case 'delivery-success': {
      title = t('views.features.walk_in.delivery_success.title')
      break
    }
    case 'visitor': {
      if (state === 4) {
        title = t('views.features.walk_in.visitor.photo_title')
      } else {
        title = t('views.features.walk_in.visitor.title')
      }
      break
    }
    case 'delivery': {
      if (state === 1) {
        title = t('views.features.walk_in.delivery.title')
      } else {
        title = t('views.features.walk_in.delivery.photo_title')
      }
      break
    }
    case 'screening-form': {
      title = t('views.features.walk_in.screening_form.title')
      break
    }
    case 'atk': {
      if (state === 1) {
        title = t('views.features.walk_in.atk.title')
      } else {
        title = t('views.features.walk_in.atk.photo_title')
      }
      break
    }
    case 'screening-selection': {
      title = t('views.features.visitor.covid_screening.selection.title')
      break
    }
    default: {
      if (locale === 'th') {
        title = `${company?.name} ${t('views.features.walk_in.default.title')}`
      } else {
        title = `${t('views.features.walk_in.default.title')} ${company?.name}`
      }
    }
  }

  const screeningSelectionAvailable =
    company?.walk_in_setting.covid_screening_type === CovidScreeningType.either
  const handleBack = () => {
    switch (type) {
      case 'visitor-policy':
      case 'visitor':
      case 'delivery': {
        if (state === 1) {
          setState(0)
          setType('welcome')
        } else {
          setState((state - 1) as WalkInStateType)
        }
        break
      }
      case 'screening-selection':
      case 'screening-form':
      case 'atk': {
        switch (state) {
          case 0: {
            setState(4)
            setType('visitor')
            break
          }
          case 1: {
            if (screeningSelectionAvailable) {
              setState(0)
              setType('screening-selection')
            } else if (
              company?.walk_in_setting.covid_screening_type ===
                CovidScreeningType.both &&
              type === 'atk'
            ) {
              setState(7)
              setType('screening-form')
            } else {
              setState(4)
              setType('visitor')
            }
            break
          }
          default: {
            setState((state - 1) as WalkInStateType)
          }
        }
        break
      }
      default: {
        setState((state - 1) as WalkInStateType)
      }
    }
  }

  const handleChangeLocale = () => {
    setLocale(locale === 'th' ? 'en' : 'th')
  }

  return (
    <ApplicationLayout
      walkIn
      logoUrl={company?.logo_url}
      isLoading={isLoading}
      className={styles.layout}
    >
      <div className={classnames(styles.wrapper, className)}>
        {!['welcome', 'visitor-success', 'delivery-success'].includes(type) && (
          <>
            <div className={styles.header}>
              <div className={styles.headerButton}>
                <div className={styles.backButton} onClick={handleBack}>
                  <CaretCircleLeft />
                </div>
                <div>
                  <LocaleSwitch locale={locale} onClick={handleChangeLocale} />
                </div>
              </div>
            </div>
          </>
        )}
        <div className={classnames(styles.title, styles.mb24)}>{title}</div>
        <div className={styles.children}>{children}</div>
      </div>
    </ApplicationLayout>
  )
}
