import React from 'react'

import { FieldValues, UseFormRegister } from 'react-hook-form'

import { TextField } from '..'
import styles from './NumberField.module.scss'

export type NumberFieldDirection = 'up' | 'down'

type Props = {
  name: string
  register: UseFormRegister<FieldValues>
  onChange: (direction?: NumberFieldDirection) => void
  unit?: string
}

export const NumberField = (props: Props) => {
  const { name, register, onChange, unit } = props

  return (
    <>
      <TextField
        name={name}
        register={register}
        type={'number'}
        onBlur={onChange}
        className={styles.field}
        postDecoration={
          <div className={styles.caretBtns}>
            <div className={styles.boxCaretUp} onClick={() => onChange('up')}>
              <img src={'/assets/icons/caret-up.svg'} />
            </div>
            <div
              className={styles.boxCaretDown}
              onClick={() => onChange('down')}
            >
              <img src={'/assets/icons/caret-down.svg'} />
            </div>
          </div>
        }
      />
      {unit}
    </>
  )
}
