import React, { useState } from 'react'

import classnames from 'classnames'

import { Button, ImagePreview, showToast, Spinner } from '..'
import styles from './ImageField.module.scss'
import { CircleClose } from '@/components/icons'

import { acceptedImageFile, MAXIMUM_IMAGE_SIZE } from '@/features/constants'
import { blobToFile, t } from '@/lib/helpers'

type Props = {
  title?: string
  placeholder?: string
  required?: boolean
  defaultImagePath?: string
  onUpload: (file: File) => void
  onCancel: () => void
  className?: string
}

export const ImageField = (props: Props) => {
  const {
    title,
    placeholder = t('views.components.image_field.label'),
    required,
    defaultImagePath = '',
    onUpload,
    onCancel,
    className,
  } = props

  const [imagePath, setImagePath] = useState<string>(defaultImagePath)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const _onCancel = () => {
    setImagePath('')
    onCancel()
  }

  let previewImage
  if (imagePath) {
    previewImage = (
      <div className={styles.previewBox}>
        <Button
          text={''}
          iconComponent={<CircleClose />}
          className={styles.closeBtn}
          onClick={_onCancel}
        />
        <ImagePreview src={imagePath} width={140} height={140} />
      </div>
    )
  }

  const _onUpload = async (event: any) => {
    if (event.target.files[0].size >= MAXIMUM_IMAGE_SIZE) {
      showToast({
        type: 'alert',
        message: t('views.components.image_field.large_size'),
      })
    } else {
      setIsLoading(true)
      const file = event.target.files[0]
      switch (file.type) {
        case 'image/heic': {
          const jpegFile = await blobToFile(file)
          onUpload(jpegFile)
          setImagePath(URL.createObjectURL(jpegFile))
          break
        }
        default:
          onUpload(file)
          setImagePath(URL.createObjectURL(file))
      }
      setIsLoading(false)
    }
  }

  return (
    <div className={classnames(styles.background, className)}>
      <label className={styles.title}>
        {title}
        {required && title && <span className={styles.requiredTitle}>*</span>}
      </label>
      {isLoading ? (
        <Spinner height={160} width={160} />
      ) : (
        <>
          {previewImage}
          {!previewImage && (
            <>
              <label htmlFor={'visitorImage'} className={styles.dropArea}>
                <span className={styles.dropText}>{placeholder}</span>
                <div className={styles.dropBtn}>
                  {t('views.components.image_field.upload')}
                </div>
              </label>
              <div className={styles.inputWrapper}>
                <input
                  id={'visitorImage'}
                  type={'file'}
                  onChange={_onUpload}
                  required
                  accept={acceptedImageFile}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  )
}
