import React, { useState } from 'react'

import classnames from 'classnames'
import copy from 'copy-to-clipboard'
import { Control, Controller, UseFormRegister } from 'react-hook-form'

import { CovidScreeningSettingSection } from '..'
import styles from './Settings.module.scss'
import { Download } from '@/components/icons'
import { Button, NumberFieldDirection, showToast } from '@/components/shared'

import { companyServices } from '../../services'
import { companiesPath, walkInsNewPath } from '@/features/constants'
import {
  Company,
  CompanySettingNumberField,
  WalkInSettingFields,
} from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  defaultQRCode: string
  company: Company
  control: Control<any>
  handleUpdateFields: (value: WalkInSettingFields) => void
  register: UseFormRegister<any>
  handleNumberField: (
    field: CompanySettingNumberField,
    direction?: NumberFieldDirection,
  ) => void
}

export const WalkInSetting = (props: Props) => {
  const {
    defaultQRCode,
    company,
    control,
    handleUpdateFields,
    register,
    handleNumberField,
  } = props
  const [qrCode, setQRCode] = useState<any>(defaultQRCode)
  const [token, setToken] = useState<string>(company.walk_in_setting.token)

  const url = `${window.location.host}${walkInsNewPath}?token=${token}`

  const onRegenerate = async () => {
    const data = await companyServices().regenerateWalkInToken(company.slug)
    if (data) {
      setQRCode(data.walk_in_qr_code)
      setToken(data.walk_in_token)
    }
  }

  const onCopy = () => {
    copy(url)
    showToast({
      type: 'success',
      message: t('views.misc.copied'),
    })
  }

  const onDownload = () => {
    window.location.href = `${companiesPath}/${company.slug}/download_walk_in_token`
  }

  return (
    <Controller
      control={control}
      name="walk_in"
      render={({ field: { onChange, value } }) => {
        const _handleUpdateFields = (newValue: any) => {
          onChange(newValue)
          handleUpdateFields(newValue)
        }

        return (
          <div className={styles.wrapper}>
            <div className={styles.section}>
              <div className={styles.walkInSection}>
                <span className={styles.title}>
                  {t('views.features.company_setting.walk_in.request')}
                </span>
                <CovidScreeningSettingSection
                  register={register}
                  control={control}
                  value={value}
                  onChange={(k, v) => _handleUpdateFields({ ...value, [k]: v })}
                  handleNumberField={handleNumberField}
                  type={'walk_in'}
                />
              </div>
            </div>
            <div className={classnames(styles.section, styles.mt16)}>
              <div className={styles.walkInSection}>
                <span className={styles.title}>
                  {t('views.features.company_setting.walk_in.qr_title')}
                </span>
                <span className={styles.description}>
                  {t('views.features.company_setting.walk_in.description')}
                </span>
                <div className={styles.qrWrapper}>
                  <img
                    src={`data:image/jpeg;base64,${qrCode}`}
                    className={styles.qrCode}
                  />
                  <div className={styles.qrDescription}>
                    <span>
                      {t(
                        'views.features.company_setting.walk_in.regenerate_description',
                      )}
                    </span>
                    <Button
                      style="Link"
                      icon="/assets/icons/refresh.svg"
                      text={t(
                        'views.features.company_setting.walk_in.regenerate',
                      )}
                      onClick={onRegenerate}
                      className={styles.regenerateButton}
                    />
                  </div>
                </div>
                <div className={styles.mt16}>
                  <span>URL</span>
                  <div className={styles.walkInURLWrapper}>
                    <div className={styles.walkInURL}>{url}</div>
                    <Button
                      icon="/assets/icons/copy.svg"
                      style="Tertiary"
                      className={styles.copyButton}
                      onClick={onCopy}
                    />
                  </div>
                </div>
                <Button
                  iconComponent={<Download className={styles.downloadIcon} />}
                  text={t(
                    'views.features.company_setting.walk_in.download_qr_code',
                  )}
                  className={styles.downloadButton}
                  onClick={onDownload}
                />
              </div>
            </div>
          </div>
        )
      }}
    />
  )
}
