import React from 'react'

import { AtkCard } from '..'
import styles from './AtkReports.module.scss'
import { Button } from '@/components/shared'

import { AtkReport } from '@/features/types'
import { getLocaleDateString, t } from '@/lib/helpers'

import { useAtkReportContext } from '../../context'

export const AtkReports = () => {
  const { atkReports, selectMode, isIdsExist, handleSelect } =
    useAtkReportContext()

  let atkCards
  if (Object.values(atkReports).flat().length > 0) {
    atkCards = Object.keys(atkReports).map((date) => {
      const atkReportsDate = atkReports[date]
      if (atkReportsDate.length === 0) return null

      const atkReportsDateIds = atkReportsDate.map(
        (report: AtkReport) => report.slug,
      )
      return (
        <div key={date} className={styles.atkWithDate}>
          <div className={styles.titleWrapper}>
            {getLocaleDateString(date, {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
            {selectMode && atkReportsDate.length !== 0 && (
              <Button
                text={
                  isIdsExist(atkReportsDateIds)
                    ? t('views.misc.deselect_all')
                    : t('views.misc.select_all')
                }
                icon={'/assets/icons/checks.svg'}
                style={'Ghost'}
                onClick={() =>
                  handleSelect(
                    atkReportsDate.map((report: AtkReport) => report.slug),
                  )
                }
                className={styles.selectAll}
              />
            )}
          </div>
          <div className={styles.atkReports}>
            {atkReportsDate.map((atkReport: AtkReport) => (
              <AtkCard
                key={atkReport.slug}
                id={atkReport.slug}
                imgUrl={atkReport.atk_image_url}
                name={atkReport.visitor_name}
                date={getLocaleDateString(atkReport.test_atk_at, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })}
                invitationID={atkReport.invitation_slug}
              />
            ))}
          </div>
        </div>
      )
    })
  } else {
    atkCards = (
      <div className={styles.emptyState}>
        {t('views.features.atk_reports.index.no_reports')}
      </div>
    )
  }

  return <div className={styles.wrapper}>{atkCards}</div>
}
