import { confirmationPath, rootPath, signInPath } from '@/features/constants'
import {
  confirmationPath as systemAdminConfirmationPath,
  rootPath as systemAdminRootPath,
  signInPath as systemAdminSignInPath,
} from '@/features/constants/systemAdmin'
import { axios, t } from '@/lib/helpers'

import { showToast } from '../..'

type setUpAccountParams = {
  token: string
  email: string
  firstname: string
  lastname: string
  password: string
  isSystemAdmin: boolean
}

type signInParams = {
  email: string
  password: string
  remember: boolean
  isSystemAdmin: boolean
}

export const userServices = () => {
  return {
    setUpAccount: async (params: setUpAccountParams) => {
      try {
        const { token, email, firstname, lastname, password, isSystemAdmin } =
          params

        const path = isSystemAdmin
          ? systemAdminConfirmationPath
          : confirmationPath
        const mode = isSystemAdmin ? 'system_admin' : 'employee'
        await axios.patch(path, {
          [mode]: {
            confirmation_token: token,
            email,
            first_name: firstname,
            last_name: lastname,
            password,
          },
        })
        window.location.href = isSystemAdmin ? systemAdminRootPath : rootPath
      } catch {
        window.location.reload()
      }
    },
    signIn: async (params: signInParams) => {
      try {
        const { email, password, remember, isSystemAdmin } = params

        const path = isSystemAdmin ? systemAdminSignInPath : signInPath
        const mode = isSystemAdmin ? 'system_admin' : 'employee'
        await axios.post(path, {
          [mode]: { email, password, remember },
        })
        window.location.href = isSystemAdmin ? systemAdminRootPath : rootPath
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 401) {
          showToast({
            type: 'alert',
            message: t('views.features.devise.sign_in.failed_please_activate'),
          })
        } else {
          window.location.reload()
        }
      }
    },
  }
}
