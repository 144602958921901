import { showToast } from '@/components/shared'

import { invitationsPath, updateAtkReportsPath } from '@/features/constants'
import { CovidScreeningATKStatus } from '@/features/types'
import { axios, t } from '@/lib/helpers'

export const atkReportsService = () => {
  return {
    updateAtkStatus: async (
      atkReportIDs: string[],
      status: CovidScreeningATKStatus,
      reason?: string,
    ) => {
      try {
        const res = await axios.patch(updateAtkReportsPath, {
          atk_reports: {
            slugs: atkReportIDs,
            atk_status: status,
            atk_rejected_reason: reason,
          },
        })
        showToast({
          type: 'success',
          message: res.data['response'],
        })
      } catch (_err) {
        window.location.reload()
      }
    },
    getInvitation: async (invitationID: string) => {
      const res = await axios.get(`${invitationsPath}/${invitationID}`, {})
      return res.data
    },
    updateInvitationNote: async (id: string, internal_note: string) => {
      try {
        await axios.patch(`${invitationsPath}/${id}`, {
          invitation: { internal_note },
        })
        showToast({
          type: 'success',
          message: t(
            'views.features.invitations.event_detail_modal.successfully_update_note',
          ),
        })
      } catch (_err) {
        window.location.reload()
      }
    },
  }
}
