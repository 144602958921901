import React from 'react'

import classnames from 'classnames'

import styles from './Success.module.scss'

type Props = {
  text: string
  className?: string
}

export const Success = (props: Props) => {
  const { text, className } = props

  return (
    <div className={classnames(styles.wrapper, className)}>
      <img src={'/assets/images/complete.svg'} />
      <span className={styles.title}>{text}</span>
    </div>
  )
}
