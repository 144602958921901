import React from 'react'

import styles from './PolicyPage.module.scss'
import { ApplicationLayout, Link } from '@/components/shared'

const EmptyLine = () => {
  return (
    <>
      <br />
      <br />
    </>
  )
}

const LAST_UPDATED_DATE = '8 December 2021'

type Props = {
  vendorPolicyUrl?: string
}

const PolicyPage = (props: Props) => {
  const { vendorPolicyUrl } = props
  return (
    <ApplicationLayout>
      <div className={styles.background}>
        <h1 className={styles.title}>Privacy Policy</h1>
        <div className={styles.wrapper}>
          <div className={styles.fadeTextTop}></div>
          <div className={styles.policy}>
            This privacy policy (&quot;Policy&quot;) describes how nDataThoth
            Co., Ltd. (&quot;nDataThoth Co., Ltd.&quot;, &quot;nDataThoth&quot;,
            &quot;us&quot;, &quot;we&quot; or &quot;our&quot;) collects,
            protects and uses the personally identifiable information
            (&quot;Personal Information&quot;) that you (&quot;User&quot;,
            &quot;you&quot; or &quot;your&quot;) voluntarily provide for the use
            of the service called the &quot;AnyVisit&quot; and any of its
            related website, products or services (collectively,
            &quot;AnyVisit&quot;) to be in accordance with Personal Data
            Protection Act B.E. 2562 (2019) (&quot;PDPA&quot;).
            <EmptyLine />
            It also describes the choices available to you regarding our use of
            your Personal Information as our customers and the rights you have
            with your Personal Information under the PDPA. This Policy does not
            apply to the practices of companies that we do not own or control,
            or to individuals that we do not employ or manage.
            <div className={styles.section}>
              <h2 className={styles.label}>Data Processing Responsibility</h2>
              nDataThoth will act as the data controller only for the processing
              Personal Information of its customers for the purposes of
              providing the AnyVisit&apos;s service and other purposes as
              specified under this Policy, and its customers, a company that use
              the AnyVisit, will act as data controller regarding Personal
              Information of the visitor (&quot;Visitor&quot;) that they use for
              its own purposes.
              <EmptyLine />
              If you read this Policy as the Visitor, please note that the
              AnyVisit will be used and administered by nDataThoth&apos;s
              customers (which include its employees, administrator user). In
              this regard, nDataThoth&apos;s customers will be the data
              controller and nDataThoth will be acting as its data processor
              that processing your Personal Information on behalf of its
              customers in accordance with the terms and conditions or
              agreements had with its customers. However, nDataThoth will act as
              data controller regarding Personal Information of the Visitor that
              it collects for the purpose of facilitating the Visitor in filling
              the form on the AnyVisit next time.
              <EmptyLine />
              nDataThoth&apos;s customer, a company that send you the invitation
              link, is the one assuming responsibility for the processing of
              your Personal Information through the AnyVisit. Therefore,
              nDataThoth is not responsible for the privacy, security practices,
              or how nDataThoth&apos;s customer process Personal Information of
              its visitor. Please visit privacy policies of our customers that
              send you the invitation link to learn more about how they protect
              your privacy.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>
                Automatic collection of information
              </h2>
              When you visit the AnyVisit website, our servers automatically
              record information that your browser sends. This data may include
              information such as your device&apos;s IP address, browser type
              and version, operating system type and version, language
              preferences or the webpage you were visiting before you came to
              the AnyVisit website, pages of the AnyVisit website that you
              visit, the time spent on those pages, information you search for
              on the AnyVisit website, access times and dates, and other
              statistics.
              <EmptyLine />
              Information collected automatically is used only to identify
              potential cases of abuse and establish statistical information
              regarding the AnyVisit website usage. This statistical information
              is not otherwise aggregated in such a way that would identify any
              particular user of the system.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>
                Collection of Personal Information
              </h2>
              You can visit the AnyVisit website without telling us who you are
              or revealing any information by which someone could identify you
              as a specific, identifiable individual. If, however, you wish to
              use some of the AnyVisit website&apos;s features, you will be
              asked to provide certain Personal Information (for example, your
              name and e-mail address) or to register an account user with us.
              We receive and store any information you knowingly provide to us
              when you create an account, publish content, make a purchase, or
              fill any online forms on the AnyVisit website. When required, this
              information may include the following:
              <EmptyLine />
              <span className={styles.underline}>For our customers:</span>
              <ul>
                <li>Identity Information such as name, surname.</li>
                <li>
                  Contact information such as email address, address, telephone
                  number.
                </li>
                <li>
                  Account information such as username, unique user ID,
                  password.
                </li>
                <li>
                  Other information such as any information that you voluntarily
                  submitted to us that considered to be Personal Information
                  under the PDPA such as picture, motion picture, employee ID,
                  organization detail, Slack user ID, Zoom user ID or
                  information appeared on any submitted documents (if any).
                </li>
              </ul>
              Please note that if you, as our customers, do not provide us with
              your Personal Information that is necessary for providing access
              or use of the AnyVisit, we may not be able to provide you with
              some of our features or services that we provided on the AnyVisit
              exclusively for our registered members and our customers and we
              may not be able to manage or administer your account or provide
              you with any convenience during your use of the AnyVisit.
              <EmptyLine />
              <span className={styles.underline}>For the Visitor:</span>
              <ul>
                <li>Identity Information such as name, surname.</li>
                <li>
                  Contact information such as email address, telephone number.
                </li>
                <li>
                  Other information related to your health conditions such as
                  COVID-19 vaccination record, Antigen Test Kit (ATK) result
                  (The information concerning your health conditions is
                  considered as sensitive personal information under the PDPA
                  and we will process this type of Personal Information only if
                  we obtain your explicit consent or otherwise permissible by
                  the law).
                </li>
              </ul>
              If you are the Visitor, nDataThoth will collect and store your
              Personal Information as mentioned above only for the purpose of
              facilitating you in filling the form in the AnyVisit.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Managing Personal Information</h2>
              You are able to delete certain Personal Information we have about
              you. When you delete your Personal Information, we may maintain a
              copy of the unrevised Personal Information in our records for the
              duration necessary to comply with our obligations to our
              affiliates and partners, and for the purposes described below. If
              you would like to delete your Personal Information or permanently
              delete your account, you can do so on the settings page of your
              account on the Website or simply by contacting us.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Storing Personal Information</h2>
              We will retain and use your Personal Information for the period
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our agreements and terms and conditions unless a
              longer retention period is required or permitted by law. We may
              use any aggregated data derived from or incorporating your
              Personal Information after you update or delete it, but not in a
              manner that would identify you personally. Once the retention
              period expires, your Personal Information shall be deleted.
              Therefore, the right to access, the right to erasure, the right to
              rectification and the right to data portability cannot be enforced
              after the expiration of the retention period.
              <EmptyLine />
              Your Personal Information will be transferred out of Thailand as
              our infrastructures are located in: Singapore
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>
                Use and processing of collected Personal Information
              </h2>
              In order to make AnyVisit available to you, or to meet a legal
              obligation, we need to collect and use certain of your Personal
              Information. If you do not provide the information that we
              request, we may not be able to provide you with the requested
              products or services related to the AnyVisit. Some of the
              information we collect is directly from you via our website and
              the AnyVisit website. However, we may also collect your Personal
              Information from other available sources including, but not
              limited to social media, emails, etc. Any of Personal Information
              we collect from you may be used for the following purposes:
              <ul>
                <li>
                  Create and manage user accounts on the AnyVisit for the
                  customers.
                </li>
                <li>
                  Authenticate you to enable you to access your account on the
                  AnyVisit as our customers.
                </li>
                <li>
                  Operate, maintain, fulfill and manage our business and your
                  relationship with us.
                </li>
                <li>
                  Run and operate the AnyVisit and its related services and
                  feature.
                </li>
                <li>
                  Enhance, modify, personalize or otherwise improve AnyVisit and
                  other services for the benefit of our users.
                </li>
                <li>
                  Facilitate the Visitor when fill-in the form of the AnyVisit.
                </li>
                <li>
                  Send marketing, promotional, and offer communications via
                  invitation letters, e-mail or other means.
                </li>
                <li>
                  Provide customer service, support, and investigate issues on
                  the AnyVisit that you submit to us.
                </li>
                <li>Request user feedback.</li>
                <li>Improve user experience.</li>
                <li>Post customer testimonials.</li>
                <li>
                  Enforce terms and conditions and policies of the AnyVisit.
                </li>
                <li>Protect from abuse and malicious users.</li>
                <li>Respond to legal requests and prevent harm.</li>
                <li>
                  Participate in any other activities related to products or
                  services of nDataThoth Co., Ltd.
                </li>
              </ul>
              Processing your Personal Information depends on how you interact
              with the AnyVisit, where you are located in the world and if one
              of the following applies:
              <ol type={'i'}>
                <li>
                  You have given your consent or explicit consent for one or
                  more specific purposes;
                </li>
                <li>
                  Provision of information is necessary for the performance of
                  an agreement with you and/or for any pre-contractual
                  obligations thereof;
                </li>
                <li>
                  Processing is necessary for compliance with a legal obligation
                  to which you are subject;
                </li>
                <li>
                  Processing is related to a task that is carried out in the
                  public interest or in the exercise of official authority
                  vested in us;
                </li>
                <li>
                  Processing is necessary for the purposes of the legitimate
                  interests pursued by us or by a third party.
                </li>
              </ol>
              Note that under some circumstance we may be allowed to process
              your Personal Information until you object to such processing (by
              opting out), without having to rely on consent or any other of the
              following legal bases below. In any case, we will be happy to
              clarify the specific legal basis that applies to the processing,
              and whether the provision of Personal Information is a statutory
              or contractual requirement, or a requirement necessary to enter
              into a contract.
              <EmptyLine />
              We never sell your data to another party. In order to operate and
              manage the AnyVisit, we use the following external data
              processors:
              <ul>
                <li>
                  Amazon Web Services (Infrastructure and web hosting
                  services/email) [Singapore]
                </li>
                <li>
                  Google Workspace [United States of
                  America/Singapore/Worldwide]
                </li>
                <li>
                  Google Analytics [United States of
                  America/Singapore/Worldwide]
                </li>
                <li>Slack [United States of America]</li>
                <li>Microsoft Graph [United States of America]</li>
                <li>Zoom [United States of America]</li>
              </ul>
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Information transfer and storage</h2>
              Depending on your location, data transfers may involve
              transferring and storing your information in a country other than
              your own. If any such transfer takes place, you can find out more
              by checking the relevant sections of this document or inquire with
              us using the information provided in the contact section.
              <EmptyLine />
              The AnyVisit core infrastructure is hosted in Singapore.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>The rights of User</h2>
              You may exercise certain rights under the PDPA regarding your
              Personal Information processed by us. In particular, you have the
              right to do the following:
              <ol type={'i'}>
                <li>
                  You have the right to withdraw consent where you have
                  previously given your consent to the processing of your
                  information. However, the withdrawal of consent shall not
                  affect the collection, use or disclosure of Personal
                  Information you have already given consent legally;
                </li>
                <li>
                  You have the right to object to the processing of your
                  information if the processing is carried out on a legal basis
                  other than consent;
                </li>
                <li>
                  You have the right to learn if information is being processed
                  by us, obtain disclosure regarding certain aspects of the
                  processing and obtain a copy of the information undergoing
                  processing;
                </li>
                <li>
                  You have the right to verify the accuracy of your information
                  and ask for it to be updated or corrected;
                </li>
                <li>
                  You have the right, under certain circumstances, to restrict
                  the processing of your information, in which case, we will not
                  process your information for any purpose other than storing
                  it;
                </li>
                <li>
                  You have the right, under certain circumstances, to obtain the
                  erasure of your Personal Information from us;
                </li>
                <li>
                  You have the right to receive your information in a
                  structured, commonly used and machine readable format and, if
                  technically feasible, to have it transmitted to another
                  controller without any hindrance. This provision is applicable
                  provided that your information is processed by automated means
                  and that the processing is based on your consent, on a
                  contract which you are part of or on pre-contractual
                  obligations thereof; and
                </li>
                <li>
                  You have the right to lodge a complaint to the competent
                  authorities pursuant to relevant laws if you believe that the
                  collection, use or disclosure of your Personal Information by
                  us is violating or not in compliance with relevant laws.
                </li>
              </ol>
              You may exercise any of these rights by contacting us or our data
              protection officer using the &quot;Contacting us&quot; set out
              below. We will notify the result of your request within 30 days
              upon receipt of such request. If we deny the request, we will
              inform you of the reason via SMS, email address, telephone,
              registered mail, etc.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Billing and payments</h2>
              We use third-party payment processors to assist us in processing
              your payment information securely. Such third-party processors use
              of your Personal Information is governed by their respective
              privacy policies which may or may not contain privacy protections
              as protective as this Policy. We suggest that you review their
              respective privacy policies. nDataThoth&apos;s Payment Processors
              and Invoicing System:
              <ul>
                <li>Stripe</li>
              </ul>
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Disclose of Personal Information</h2>
              <h4 className={styles.underline}>
                Affiliates, group companies or subsidiaries
              </h4>
              We may disclose your Personal Information to our affiliates, group
              companies and subsidiaries for the purposes of business operation,
              internal administration or undertaking any activity related to
              this Privacy Policy.
              <EmptyLine />
              <h4 className={styles.underline}>Service providers</h4>
              We may contract with other companies to provide certain products
              and services. These service providers are not authorized to use or
              disclose your Personal Information except as necessary to perform
              services on our behalf or comply with legal requirements. We may
              share your Personal Information for these purposes only with third
              parties whose privacy policies are consistent with ours or who
              agree to abide by our policies with respect to Personal
              Information. Our service providers are given the information they
              need to perform their designated functions, and we do not
              authorize them to use or disclose Personal Information for their
              own marketing or other purposes.
              <EmptyLine />
              <h4 className={styles.underline}>Legal disclosure</h4>
              We will disclose any Personal Information we collect, use or
              receive if required or permitted by law, such as to comply with a
              subpoena, or similar legal process, and when we believe in good
              faith that disclosure is necessary to protect our rights, protect
              your safety or the safety of others, investigate fraud, or respond
              to a government request. In the event we go through a business
              transition, such as a merger or acquisition by another company, or
              sale of all or a portion of its assets, your user account, and
              Personal Information will likely be among the assets transferred.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Privacy of children</h2>
              We do not knowingly collect any Personal Information from children
              under the age of 20. If you are under the age of 20, please do not
              submit any Personal Information through the AnyVisit. We encourage
              parents and legal guardians to monitor their children&apos;s
              Internet usage and to help enforce this Policy by instructing
              their children never to provide Personal Information through the
              AnyVisit without their permission.
              <EmptyLine />
              If you have reason to believe that a child under the age of 20 has
              provided Personal Information to us through AnyVisit, please
              contact us. You must also be at least 20 years of age to consent
              to the processing of your Personal Information in your country (in
              some countries we may allow your parent or guardian to do so on
              your behalf).
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Newsletters</h2>
              We offer electronic newsletters to which you may voluntarily
              subscribe at any time. We are committed to keeping your e-mail
              address confidential and will not disclose your email address to
              any third parties except as allowed in the information use and
              processing section or for the purposes of utilizing a third-party
              provider to send such emails. We will maintain the information
              sent via e-mail in accordance with applicable laws and
              regulations.
              <EmptyLine />
              All e-mails sent from us will clearly state who the e-mail is from
              and provide clear information on how to contact the sender. You
              may choose to stop receiving our newsletter or marketing emails by
              following the unsubscribe instructions included in these emails or
              by contacting us. However, you will continue to receive essential
              transactional emails.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Cookies</h2>
              The AnyVisit website uses &quot;cookies&quot; to help personalize
              your online experience. A cookie is a text file that is placed on
              your hard disk by a web page server. Cookies cannot be used to run
              programs or deliver viruses to your computer. Cookies are uniquely
              assigned to you and can only be read by a web server in the domain
              that issued the cookie to you.
              <EmptyLine />
              We may use cookies to collect, store, and track information for
              statistical purposes to operate the AnyVisit website and other
              services. You have the ability to accept or decline cookies. Most
              web browsers automatically accept cookies, but you can usually
              modify your browser setting to decline cookies if you prefer. If
              you choose to decline cookies, you will not be able to use and
              experience the features of the Website and Services. To learn more
              about cookies and how to manage them, visit{' '}
              <Link
                url={'https://www.internetcookies.org'}
                text={'www.internetcookies.org'}
                noUnderLine
              />
              .
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>What are cookies?</h2>
              Cookies are small pieces of data stored in text files that are
              saved on your computer or other devices when websites are loaded
              in a browser. They are widely used to remember you and your
              preferences, either for a single visit (through a &quot;session
              cookie&quot;) or for multiple repeat visits (using a
              &quot;persistent cookie&quot;).
              <EmptyLine />
              Session cookies are temporary cookies that are used while your
              visit to the AnyVisit website, and they expire when you close the
              web browser.
              <EmptyLine />
              Persistent cookies are used to remember your preferences within
              the AnyVisit website and remain on your desktop or mobile device
              even after you close your browser or restart your computer. They
              ensure a consistent and efficient experience for you while
              visiting the AnyVisit website or using the AnyVisit.
              <EmptyLine />
              Cookies may be set by the AnyVisit website (&quot;first-party
              cookies&quot;), or by third parties, such as those who serve
              content or provide advertising or analytics services on the
              AnyVisit website (&quot;third party cookies&quot;). These third
              parties can recognize you when you visit our website and also when
              you visit certain other websites.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>What type of cookies do we use?</h2>
              1. Necessary cookies
              <br />
              Necessary cookies allow us to offer you the best possible
              experience when accessing and navigating through our website and
              using its features. For example, these cookies let us recognize
              that you have created an account and have logged into that account
              to access the content.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>What are your cookie options?</h2>
              If you don&apos;t like the idea of cookies or certain types of
              cookies, you can change your browser&apos;s settings to delete
              cookies that have already been set and to not accept new cookies.
              To learn more about how to do this or to learn more about cookies,
              visit internetcookies.org Please note, however, that if you delete
              cookies or do not accept them, you might not be able to use all
              the features on the AnyVisit.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Links to other websites</h2>
              The AnyVisit website contains links to other websites that are not
              owned or controlled by us. Please be aware that we are not
              responsible for the privacy practices of such other websites or
              third-parties. We encourage you to be aware when you leave our
              website and to read the privacy policies of each and every website
              that may collect Personal Information.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Information security</h2>
              We secure information you provide on computer servers in a
              controlled, secure environment, protected from unauthorized
              access, use, or disclosure. We maintain reasonable administrative,
              technical, and physical safeguards in an effort to protect against
              unauthorized access, use, modification, and disclosure of Personal
              Information in its control and custody. However, no data
              transmission over the Internet or wireless network can be
              guaranteed. Therefore, while we strive to protect your Personal
              Information, you acknowledge that (i) there are security and
              privacy limitations of the Internet which are beyond our control;
              (ii) the security, integrity, and privacy of any and all
              information and data exchanged between you and our Website cannot
              be guaranteed; and (iii) any such information and data may be
              viewed or tampered with in transit by a third-party, despite best
              efforts.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Data breach</h2>
              In the event we become aware that the security of the Website has
              been compromised or users Personal Information has been disclosed
              to unrelated third parties as a result of external activity,
              including, but not limited to, security attacks or fraud, we
              reserve the right to take reasonably appropriate measures,
              including, but not limited to, investigation and reporting, as
              well as notification to and cooperation with law enforcement
              authorities within 72 hours after having become aware of it. In
              the event of a data breach, we will make reasonable efforts to
              notify affected individuals if we believe that there is a
              reasonable risk of harm to the user as a result of the breach or
              if notice is otherwise required by law. When we do, we will post a
              notice on the AnyVisit website or send you an email directly.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Changes to this Policy</h2>
              We may update this Policy from time to time in our discretion and
              will notify you of any material changes to the way in which we
              treat Personal Information. When changes are made, we will revise
              the updated date at the bottom of this page. We may also provide
              notice to you in other ways in our discretion, such as through
              contact information you have provided. Any updated version of this
              Policy will be effective immediately upon the posting of the
              revised Policy unless otherwise specified. Your continued use of
              the AnyVisit website or our website after the effective date of
              the revised Policy (or such other act specified at that time) will
              constitute your consent to those changes. However, we will not,
              without your consent, use your Personal Information in a manner
              materially different than what was stated at the time your
              Personal Information was collected.
            </div>
            <div className={styles.section}>
              <h2 className={styles.label}>Contacting us</h2>
              If you would like to contact us to understand more about this
              Policy or wish to contact us concerning any matter relating to
              individual rights and your Personal Information, you may do so by
              submit your request via the contact form on our website, or submit
              your request by using the following information:
              <div className={styles.contactInfo}>
                <table>
                  <tr>
                    <td className={styles.infoLabel}>Address:</td>
                    <td>
                      No. 1778 Summer Hub Office 6th Floor, Sukhumvit Road, Phra
                      Khanong, Khlong Toei, Bangkok, Thailand 10110
                    </td>
                  </tr>
                  <tr>
                    <td>Tel:</td>
                    <td>
                      <Link
                        url={'tel:020249119'}
                        text={'02-024-9119'}
                        noUnderLine
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Email:</td>
                    <td>
                      <Link
                        url={'mailto:support@datawow.io'}
                        text={'support@datawow.io'}
                        noUnderLine
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Website:</td>
                    <td>
                      <Link
                        url={'https://datawow.io'}
                        text={'datawow.io'}
                        noUnderLine
                      />
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <EmptyLine />
            This document was last updated on {`${LAST_UPDATED_DATE}`}.
            {vendorPolicyUrl && (
              <>
                <EmptyLine />
                <div>
                  For vendor, please visit{' '}
                  <Link
                    url={vendorPolicyUrl}
                    text={'vendor privacy policy'}
                    noUnderLine
                  />
                </div>
              </>
            )}
          </div>
          <div className={styles.fadeTextBottom} />
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default PolicyPage
