import React from 'react'

import { EventInput } from '@fullcalendar/react'
import DatePicker from 'antd/lib/date-picker'
import thTH from 'antd/lib/locale/th_TH'
import classnames from 'classnames'
import moment from 'moment'
import Creatable from 'react-select/creatable'

import styles from './DateTimeRangeField.module.scss'

import { getTimeOptions } from '@/features/constants'
import { formatDate, getLocaleDateString, getNewTime } from '@/lib/helpers'

import { ISelectOption } from '../Select'

type Props = {
  event: EventInput
  onChange: (event: EventInput, oldDate?: string) => void
  onDelete: (event: EventInput) => void
  showDate?: boolean
  duration: number | string
  disabledDate?: (current: any) => boolean
}

const timeStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '100%',
    border: '1px solid #e8e8e8',
    borderRadius: '8px',
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    justifyContent: 'center',
    transform: 'translateY(1px)',
  }),
  dropdownIndicator: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  option: (provided: any) => ({
    ...provided,
    borderRadius: '4px',
    textAlign: 'center',
    padding: '8px 4px',
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingLeft: '8px',
    paddingRight: '8px',
  }),
}

const getStartOrEndDate = (date: any, newTime: string) => {
  const dateMoment = moment(date)
  return moment(`${dateMoment.format('L')} ${newTime}`).toDate()
}

export const DateWithTimeRange = (props: Props) => {
  const {
    event,
    onChange,
    onDelete,
    showDate = true,
    duration,
    disabledDate,
  } = props
  const startText = formatDate(event.start, 'hh:mm A')
  const endText = formatDate(event.end, 'hh:mm A')
  const timePickerOption: ISelectOption[] = getTimeOptions()
  const timePickerOptionTexts = timePickerOption.map((option) => option.value)
  const fromTimeIndex = timePickerOptionTexts.indexOf(startText)
  const toTimeIndex = timePickerOptionTexts.indexOf(endText)

  const dateFormat: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  }

  return (
    <div className={styles.dateRow}>
      {showDate && (
        <DatePicker
          locale={gon.currentLocale === 'th' ? thTH.DatePicker : undefined}
          value={moment(event.start)}
          onChange={(dateMoment) => {
            if (!dateMoment) return
            const dateString = dateMoment.format('D MMM YYYY')
            const oldDate = moment(event.start).format('L')
            event.start = new Date(`${dateString} ${startText}`)
            event.end = new Date(`${dateString} ${endText}`)
            onChange(event, oldDate)
          }}
          format={(value: any) =>
            getLocaleDateString(value.toDate(), dateFormat)
          }
          allowClear={false}
          disabledDate={disabledDate}
          className={styles.datePicker}
        />
      )}
      <Creatable
        options={timePickerOption}
        menuPlacement="top"
        formatCreateLabel={getNewTime}
        value={
          fromTimeIndex === -1
            ? { label: startText, value: startText }
            : timePickerOption[fromTimeIndex]
        }
        onCreateOption={(inputValue: string) => {
          const time = getNewTime(inputValue)
          event.start = getStartOrEndDate(event.start, time)
          event.end = new Date(event.start)
          event.end.setMinutes(event.start.getMinutes() + (duration as number))
          onChange(event)
        }}
        onChange={(newOption: any) => {
          event.start = getStartOrEndDate(event.start, newOption.value)
          event.end = new Date(event.start)
          event.end.setMinutes(event.start.getMinutes() + (duration as number))
          onChange(event)
        }}
        isDisabled={duration === 'allDay'}
        styles={timeStyles}
        className={classnames(styles.timePicker, styles.ml8)}
      />
      <span className={styles.dashText}>-</span>
      <Creatable
        options={timePickerOption}
        menuPlacement="top"
        formatCreateLabel={getNewTime}
        value={
          toTimeIndex === -1
            ? { label: endText, value: endText }
            : timePickerOption[toTimeIndex]
        }
        styles={timeStyles}
        isDisabled
        className={styles.timePicker}
      />
      <div onClick={() => onDelete(event)} className={styles.closeIcon}>
        <img src="/assets/icons/x-circle.svg" width={20} height={20} />
      </div>
    </div>
  )
}
