import React from 'react'

import classnames from 'classnames'

import styles from './RiskAssessments.module.scss'
import { Column, SortOptions, Table } from '@/components/shared'

import { riskAssessmentsPath } from '@/features/constants/systemAdmin'
import { RiskAssessment as RiskAssessmentType } from '@/features/types'
import { t } from '@/lib/helpers'

import { RiskAssessment } from './RiskAssessment'

type Props = {
  risk_assessments: RiskAssessmentType[]
  sortOptions: SortOptions
  handleSort: (newSort?: string, newDirection?: string) => void
  className?: string
}

const columns: Column[] = [
  {
    column: 'visitors.first_name',
    title: 'Name',
    className: styles.visitor,
  },
  {
    column: 'requested_at',
    title: 'Requested Date',
  },
  {
    column: 'status',
    title: 'Status',
  },
]

export const RiskAssessments = (props: Props) => {
  const { risk_assessments, sortOptions, handleSort, className } = props

  const onClickVerify = (id: number) => {
    window.location.href = `${riskAssessmentsPath}/${id}/edit`
  }

  const data = risk_assessments.map((risk_assessment, idx) => (
    <RiskAssessment
      key={idx}
      risk_assessment={risk_assessment}
      onClickVerify={onClickVerify}
    />
  ))

  return (
    <div className={classnames(styles.table, className)}>
      <Table
        columns={columns}
        data={data}
        sortOptions={sortOptions}
        handleSort={handleSort}
        emptyState={t(
          'views.features.risk_assessments.index.no_risk_assessments',
        )}
      />
    </div>
  )
}
