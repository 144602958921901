import React from 'react'

import ReactTooltip from 'react-tooltip'

import { t } from '@/lib/helpers'

type Props = {
  showToolTip?: boolean
}

export const Pencil = (props: Props) => {
  const { showToolTip = true } = props

  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        data-tip={showToolTip ? t('views.misc.edit') : undefined}
      >
        <rect width="24" height="24" fill="white" />
        <path d="M14.5421 6.5074C14.2492 6.2145 13.7743 6.21451 13.4814 6.50742L4.20077 15.7888C4.15133 15.8382 4.1158 15.9008 4.09881 15.968L3.01128 20.3328C2.97884 20.4641 3.01746 20.6039 3.11323 20.6997C3.18584 20.7723 3.28471 20.8124 3.38589 20.8124C3.41679 20.8124 3.44845 20.8086 3.47935 20.8009L7.84416 19.7133C7.91213 19.6963 7.97392 19.6608 8.02335 19.6114L17.3047 10.3307C17.5976 10.0378 17.5976 9.56296 17.3047 9.27006L14.5421 6.5074Z" />
        <path d="M20.2658 4.63931L19.1736 3.54715C18.4437 2.81723 17.1716 2.818 16.4424 3.54715L15.635 4.3546C15.3421 4.6475 15.3421 5.12237 15.635 5.41526L18.3977 8.17796C18.6906 8.47085 19.1654 8.47085 19.4583 8.17796L20.2658 7.3705C20.6304 7.00593 20.8312 6.52087 20.8312 6.00491C20.8312 5.48895 20.6304 5.00388 20.2658 4.63931Z" />
      </svg>

      <ReactTooltip effect="solid" />
    </>
  )
}
