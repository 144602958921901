import React, { useState } from 'react'

import { useForm } from 'react-hook-form'

import { Logs } from '../../components'
import styles from './LogsPage.module.scss'
import { SortOptions } from '@/components/shared'
import LogsLayout from '@/components/shared/LogsLayout/LogsLayout'

import { logService } from '../../services'
import { logsPath } from '@/features/constants'
import {
  Invitation,
  Employee,
  LogsPageTab,
  Delivery,
  SearchParams,
} from '@/features/types'
import { formatDate } from '@/lib/helpers'

type Props = {
  logs: Invitation[]
  defaultSearch: string
  defaultSort: string
  defaultSortDirection: string
  defaultStartDate?: string
  defaultEndDate?: string
  defaultTab?: LogsPageTab
  employee: Employee
}

const LogsPage = (props: Props) => {
  const {
    logs,
    defaultSearch,
    defaultSort,
    defaultSortDirection,
    defaultStartDate,
    defaultEndDate,
    defaultTab,
    employee,
  } = props
  const [search, setSearch] = useState<string>(defaultSearch || '')
  const [tab, setTab] = useState<LogsPageTab>(defaultTab || 'invites')
  const [data, setData] = useState<Invitation[] | Delivery[]>(logs)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const sortOptions: SortOptions = {
    sort: defaultSort || '',
    direction: defaultSortDirection || '',
  }
  const { control, watch } = useForm({
    defaultValues: {
      fromDate: defaultStartDate || undefined,
      toDate: defaultEndDate || undefined,
    },
  })

  const getSearchParams = (options: SearchParams) => {
    const fromDate = formatDate(watch('fromDate'))
    const toDate = formatDate(watch('toDate'))
    const dateQuery =
      fromDate !== '' &&
      toDate !== '' &&
      `&start_date=${fromDate}&end_date= ${toDate}`
    if (!!options.tab && tab !== options.tab) {
      const path = `tab=${options.tab}&search=${search}&sort=${
        options.sort || ''
      }&sort_direction=${options.direction || ''}`
      setTab(options.tab as LogsPageTab)
      return dateQuery ? path + dateQuery : path
    } else {
      const sort = options.sort || sortOptions.sort
      const direction = options.direction || sortOptions.direction
      const path = `search=${search}&sort=${sort}&sort_direction=${direction}&tab=${
        options.tab || tab
      }`
      return dateQuery ? path + dateQuery : path
    }
  }

  const handleQuery = (newSort?: string, newDirection?: string) => {
    window.location.href = `${logsPath}?${getSearchParams({
      sort: newSort,
      direction: newDirection,
    })}`
  }

  const onSetLogTab = async (logTab: LogsPageTab) => {
    if (tab === logTab) return

    setIsLoading(true)
    setData([])
    const newData = await logService().getLogs(getSearchParams({ tab: logTab }))
    setData(newData)
    setIsLoading(false)
  }

  return (
    <LogsLayout
      tab={tab}
      employee={employee}
      onSetLogTab={onSetLogTab}
      control={control}
      search={search}
      setSearch={setSearch}
      getSearchParams={getSearchParams}
      handleQuery={handleQuery}
      isLoading={isLoading}
    >
      <Logs
        logs={data}
        sortOptions={sortOptions}
        handleSort={handleQuery}
        tab={tab}
        className={styles.visitors}
      />
    </LogsLayout>
  )
}

export default LogsPage
