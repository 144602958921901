import React from 'react'

import styles from './ExportCSV.module.scss'
import { Download } from '@/components/icons'

import { t } from '@/lib/helpers'

import { Button, ButtonStyle } from '../Button'

type Props = {
  path: string
  buttonStyle?: ButtonStyle
  className?: string
}

export const ExportCSV = (props: Props) => {
  const { path, buttonStyle, className } = props
  const handleClick = () => {
    window.location.href = path
  }

  return (
    <Button
      iconComponent={<Download className={styles.downloadIcon} />}
      text={t('views.features.log.export_csv')}
      onClick={handleClick}
      style={buttonStyle}
      className={className}
    />
  )
}
