import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import { AtkActionPanel, AtkReports } from '../../components'
import styles from './AtkReportsPage.module.scss'
import {
  ApplicationLayout,
  Button,
  ConfirmModal,
  EventDetailModal,
  RejectForm,
  REJECT_FORM,
  Search,
  showToast,
} from '@/components/shared'

import { atkReportsService } from '../../services'
import { AtkReportsChannel } from '@/channels'
import { atkReportsPath, rejectAtkVerifyOptions } from '@/features/constants'
import {
  AtkReport,
  CovidScreeningATKStatus,
  Employee,
  Invitation,
  AtkReportActionType,
  AtkReportWithDate,
} from '@/features/types'
import { formatDate, t } from '@/lib/helpers'

import { AtkReportContext } from '../../context'

type Props = {
  employee: Employee
  defaultSearch: string
  defaultAtkReports: AtkReportWithDate
}

const AtkReportsPage = (props: Props) => {
  const { employee, defaultSearch, defaultAtkReports } = props
  const [atkReports, setAtkReports] =
    useState<AtkReportWithDate>(defaultAtkReports)
  const [search, setSearch] = useState<string>(defaultSearch || '')
  const [lastDeleteIDs, setLastDeleteIDs] = useState<string[]>([])
  const [selectMode, setSelectMode] = useState<boolean>(false)
  const [ids, setIDs] = useState<Set<string>>(new Set())
  const [typeModal, setTypeModal] = useState<AtkReportActionType>('none')
  const [invitation, setInvitation] = useState<Invitation>()
  const { register, control, watch, handleSubmit } = useForm()

  const handleQuery = () => {
    window.location.href = `${atkReportsPath}?search=${search}`
  }

  const onRemoveAtkReport = () => {
    const newAtkReports: AtkReportWithDate = {}
    for (const date in atkReports) {
      const atkReportsDate = atkReports[date].filter(
        (report) => !lastDeleteIDs.includes(report.slug),
      )
      if (atkReportsDate.length > 0) newAtkReports[date] = atkReportsDate
    }
    setAtkReports(newAtkReports)
    setLastDeleteIDs([])
  }

  useEffect(() => {
    AtkReportsChannel.received = (newAtkReports: AtkReport[]) => {
      switch (newAtkReports[0].atk_status) {
        case CovidScreeningATKStatus.not_verify: {
          const date = formatDate(newAtkReports[0].submit_atk_at)
          const todayReports = [...atkReports[date], ...newAtkReports]
          setAtkReports({ ...atkReports, [date]: todayReports })
          break
        }
        default:
          setLastDeleteIDs(newAtkReports.map((report) => report.slug))
      }
    }
  }, [])

  useEffect(() => {
    if (!selectMode) setIDs(new Set())
  }, [selectMode])

  const isIdsExist = (selectedIds: string[]) => {
    return selectedIds.filter((id) => ids.has(id)).length === selectedIds.length
  }

  const handleSelect = (selectedIds: string[]) => {
    if (isIdsExist(selectedIds)) {
      const newIds = ids
      selectedIds.forEach((id) => newIds.delete(id))
      setIDs(new Set(newIds))
    } else {
      setIDs(new Set([...ids, ...selectedIds]))
    }
  }

  const allIds = Object.values(atkReports)
    .flat()
    .map((report) => report.slug)

  const getModalType = () => {
    switch (typeModal) {
      case 'no_risk':
        return 'Emerald'
      case 'risk':
        return 'Delete'
      case 'reject':
        return 'Primary'
    }
  }

  const getAtkStatus = () => {
    switch (typeModal) {
      case 'no_risk':
        return CovidScreeningATKStatus.negative
      case 'risk':
        return CovidScreeningATKStatus.positive
      case 'reject':
        return CovidScreeningATKStatus.rejected
    }
  }

  const onConfirm = async (data?: any) => {
    await atkReportsService().updateAtkStatus(
      [...ids],
      getAtkStatus() as CovidScreeningATKStatus,
      data['reason'],
    )
    setLastDeleteIDs([...ids])
    setIDs(new Set())
    setTypeModal('none')
  }

  const getInvitation = async (invitationID: string) => {
    if (invitationID === null) {
      showToast({
        type: 'alert',
        message: t('views.features.atk_reports.no_invitation'),
      })
      return
    }
    const data: Invitation = await atkReportsService().getInvitation(
      invitationID,
    )
    setInvitation(data)
  }

  const onUpdateNote = async (id: string, note: string) => {
    await atkReportsService().updateInvitationNote(id, note)
  }

  const isRejectModal = typeModal === 'reject'
  const getDescription = () => {
    return (
      <>
        {t(`views.misc.${isRejectModal ? 'reject' : 'confirm'}`)}{' '}
        <strong>
          {t('views.features.atk_reports.confirm_modal.number_listing', {
            number: ids.size,
          })}
        </strong>
        {isRejectModal ? ', ' : ' '}
        {t(
          `views.features.atk_reports.confirm_modal.${
            isRejectModal ? 'reject' : 'approve'
          }_description`,
          { result: t(`views.misc.${typeModal}`).toLowerCase() },
        )}
      </>
    )
  }

  return (
    <AtkReportContext.Provider
      value={{
        atkReports,
        selectMode,
        ids,
        setIDs,
        setTypeModal,
        isIdsExist,
        handleSelect,
        onRemoveAtkReport,
        lastDeleteIDs,
        getInvitation,
      }}
    >
      <ApplicationLayout employee={employee}>
        <div className={styles.wrapper}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>
              {t('views.features.atk_reports.index.title')}
            </span>
            {selectMode ? (
              <div className={styles.rightSection}>
                <Button
                  text={t('views.misc.cancel')}
                  size={'L'}
                  style={'Ghost'}
                  onClick={() => setSelectMode(false)}
                />
                <Button
                  text={
                    isIdsExist(allIds)
                      ? t('views.misc.deselect_all')
                      : t('views.misc.select_all')
                  }
                  size={'L'}
                  onClick={() => handleSelect(allIds)}
                />
              </div>
            ) : (
              <Button
                text={t('views.features.atk_reports.index.select_for_approve')}
                size={'L'}
                onClick={() => setSelectMode(true)}
                disabled={Object.values(atkReports).flat().length === 0}
                className={styles.rightSection}
              />
            )}
          </div>
          <Search
            search={search}
            setSearch={setSearch}
            handleQuery={handleQuery}
            control={control}
            className={styles.search}
          />
          <AtkReports />
          <AtkActionPanel />
        </div>
        <ConfirmModal
          show={typeModal !== 'none'}
          type={getModalType()}
          title={t('views.features.atk_reports.confirm_modal.title', {
            action: isRejectModal
              ? t('views.misc.reject')
              : t('views.misc.approve'),
          })}
          description={getDescription()}
          onClose={() => setTypeModal('none')}
          confirmText={t(`views.misc.${typeModal}`)}
          onConfirm={!isRejectModal ? onConfirm : undefined}
          confirmBtnType={isRejectModal ? 'submit' : 'button'}
          form={REJECT_FORM}
          className={styles.modal}
        >
          {isRejectModal && (
            <RejectForm
              onSubmit={handleSubmit(onConfirm)}
              register={register}
              control={control}
              watch={watch}
              options={rejectAtkVerifyOptions}
              className={styles.form}
            />
          )}
        </ConfirmModal>
        {invitation && (
          <EventDetailModal
            show={!!invitation}
            onClose={() => setInvitation(undefined)}
            invitation={invitation}
            type={
              invitation.invite.category !== 'walk_in' ? 'visitor' : 'walk_in'
            }
            onUpdateNote={onUpdateNote}
          />
        )}
      </ApplicationLayout>
    </AtkReportContext.Provider>
  )
}

export default AtkReportsPage
