import React from 'react'

import spinnerData from '@/jsons/spinner.json'
import Lottie from 'react-lottie'

type Props = {
  height?: number
  width?: number
}

export const Spinner = (props: Props) => {
  const { height, width } = props
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: spinnerData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  return <Lottie height={height} width={width} options={defaultOptions} />
}
