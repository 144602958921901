import { ISelectOption } from '@/components/shared'

import { formatDate, getLocaleTimeString, t } from '@/lib/helpers'

export const rootPath = '/'
export const invitationsPath = '/invitations'
export const requestsPath = '/invitations/requests'
export const exportInvitationsPath = '/invitations/export_csv'
export const visitorsPath = '/visitors'
export const logsPath = '/logs'
export const calendarsPath = '/calendars'
export const vaccineOptions: ISelectOption[] = [
  'Pfizer–BioNTech',
  'AstraZeneca',
  'Sinopharm',
  'Sinovac',
  'Moderna',
  'Johnson & Johnson',
  'Sputnik V',
  'Sputnik Light',
  'Novavax',
  'Other..',
].map((vaccine: string) => ({
  label: vaccine,
  value: vaccine,
}))
export const employeesPath = '/employees'
export const signInPath = '/employees/sign_in'
export const signUpPath = '/employees/sign_up'
export const forgotPasswordPath = '/employees/password/new'
export const changePasswordPath = '/employees/password/edit'
export const signOutPath = '/employees/sign_out'
export const passwordPath = '/employees/password'
export const checkMailPath = '/employees/check_mail'
export const accountTemplatePath = '/employees/account_template'
export const importAccountPath = '/employees/import'
export const covidScreeningsPath = '/covid_screenings'
export const locationsPath = '/locations'
export const companiesPath = '/companies'
export const invitesPath = '/invites'
export const invitesNewPath = '/invites/new'
export const microsoftAuthPath = '/auth/microsoft_auth'
export const scanQRPath = '/app'
export const checkInOutPath = '/check_in_out'
export const connectionsPath = '/connections'
export const confirmCheckPath = `${scanQRPath}/confirm_check_in`
export const onlineMeetingsNewLinkPath = '/online_meetings/new_link'
export const walkInsPath = '/walk_ins'
export const walkInsNewPath = '/walk_ins/new'
export const deliveriesPath = '/deliveries'
export const employeesSearchPath = '/employees/search'
export const walkInLogsPath = '/logs/walk_in_logs'
export const deliveryLogsPath = '/logs/delivery_logs'
export const atkReportsPath = '/atk_reports'
export const atkLogsPath = '/atk_reports/logs'
export const updateAtkReportsPath = '/atk_reports/update_reports'
export const actionLogsPath = '/logs/action_logs'
export const confirmationPath = '/employees/confirmation'
export const resendConfirmationPath = '/employees/resend_confirmation'
export const vaccinesPath = '/vaccines'
export const covidScreeningsSearchPath = '/covid_screenings/search'

export const devisePaths = [
  signInPath,
  signUpPath,
  forgotPasswordPath,
  changePasswordPath,
  checkMailPath,
  confirmationPath,
]

export const allVisitorPaths = [visitorsPath, covidScreeningsPath]

export const termsPath = '/terms'
export const policyPath = '/policy'
export const contactPath = 'https://datawow.io/contact'

export const minimumPasswordLength = 8
export const getTimeOptions = () => {
  let date: Date = new Date()
  date.setHours(0, 0, 0, 0)
  const tomorrow: Date = new Date(date)
  tomorrow.setDate(date.getDate() + 1)
  const timeOptions: ISelectOption[] = []
  const MINUTES_IN_TIMESTAMP = 60 * 1000

  while (date < tomorrow) {
    timeOptions.push({
      label: getLocaleTimeString(date),
      value: formatDate(date, 'hh:mm A'),
    })
    date = new Date(date.getTime() + 15 * MINUTES_IN_TIMESTAMP)
  }
  return timeOptions
}

export const MINIMUM_SCHEDULE_SLOTS = 2
export const vaccineLogsTypeOptions = [
  'All',
  'Request',
  'Approve',
  'Reject',
].map((value: string) => ({ label: value, value }))

export const rejectAtkVerifyOptions: ISelectOption[] = [
  t('views.features.atk_reports.reject_modal.blur_image'),
  t('views.features.atk_reports.reject_modal.irrelevant_image'),
  t('views.misc.other'),
].map((reason: string) => ({
  label: reason,
  value: reason,
}))

export const covidScreeningChoiceLabels = [
  'high_fever',
  'cough',
  'runny_nose',
  'sore_throat',
  'difficult_to_breath',
  'cannot_smell',
  'cannot_taste',
  'rash',
  'conjunctivitis',
  'diarrhea',
]

export const errorsNotFoundPath = '/errors/not_found'
export const errorsUnprocessableEntityPath = '/errors/unprocessable_entity'
export const errorsInternalServerErrorPath = '/errors/internal_server_error'

export const maxScreeningEffectiveDate = 14
export const minScreeningEffectiveDate = 1
export const maxInvitationEffectiveHour = 72
export const minInvitationEffectiveHour = 0

export const acceptedImageFile = 'image/png, image/jpeg, image/heic'
export const MAXIMUM_IMAGE_SIZE = 10 * 2 ** 20 // 10 MB
