// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".RiskAssessments-module__visitor___3uB7q{width:70%}.RiskAssessments-module__visitor___3uB7q>div{justify-content:flex-start}.RiskAssessments-module__table___3vEcR{overflow-x:auto;height:calc(100% - 300px)}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/verifyVaccine/components/RiskAssessments/RiskAssessments.module.scss"],"names":[],"mappings":"AAEA,yCACE,SAAA,CACA,6CACE,0BAAA,CAIJ,uCACE,eAAA,CACA,yBAAA","file":"RiskAssessments.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.visitor) {\n  width: 70%;\n  > div {\n    justify-content: flex-start;\n  }\n}\n\n:local(.table) {\n  overflow-x: auto;\n  height: calc(100% - 300px);\n}\n"]}]);
// Exports
exports.locals = {
	"visitor": "RiskAssessments-module__visitor___3uB7q",
	"table": "RiskAssessments-module__table___3vEcR"
};
module.exports = exports;
