import { useCallback } from 'react'

import __axios from 'axios'
import dayjs from 'dayjs'
import heic2any from 'heic2any'
import { get, entries, nth, debounce } from 'lodash'
import moment from 'moment'

import { DateTimeObject } from '@/features/types'

const getCountOrdinalize = (count: number) => {
  switch (count) {
    case 1:
      return 'one'
    case 2:
      return 'two'
    case 3:
      return 'three'
    default:
      return 'other'
  }
}

/*
 * Usage: t('user.title', {name: get(this.props, 'user.data.nickname')})
 */
export const t = (key: string, args = {}, fallback?: string) => {
  // eslint-disable-next-line no-undef
  let localeKey = `${gon.currentLocale}.${key}`
  if ('count' in args && key === 'ordinalize') {
    localeKey += `.${getCountOrdinalize(get(args, 'count'))}`
  }
  let text = get(gon.locales, localeKey, fallback || key)
  try {
    const argsEntries = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current: any) => {
        const pattern = new RegExp(`%{${nth(current, 0)}}`, 'g')

        return result.replace(pattern, nth(current, 1) || '')
      }, text)
    }
  } catch (e) {
    console.error(e)
  }

  return text
}

export const formatDate = (date: any, pattern = 'YYYY-MM-DD') => {
  if (!date) return ''

  return dayjs(date).format(pattern)
}

const _axios = __axios.create({
  headers: {
    'X-CSRF-Token': document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content'),
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  responseType: 'json',
})

// We have our own lil' axios because it's easier
// to deal with `authenticity_token` in one-go.
export const axios = {
  get: (url: string, data: any) => {
    return _axios.get(url, data)
  },

  post: (url: string, data: any) => {
    return _axios.post(url, data)
  },

  patch: (url: string, data: any) => {
    return _axios.patch(url, data)
  },

  put: (url: string, data: any) => {
    return _axios.put(url, data)
  },

  delete: (url: string, data: any) => {
    return _axios.delete(url, {
      data,
    })
  },
  isAxiosError: __axios.isAxiosError,
}

// axios form data
const _axiosFormData = __axios.create({
  headers: {
    'X-CSRF-Token': document
      .querySelector('meta[name="csrf-token"]')
      ?.getAttribute('content'),
    'Content-Type': 'multipart/form-data',
  },
  responseType: 'json',
})

export const axiosFormData = {
  post: (url: string, data: any) => {
    return _axiosFormData.post(url, data)
  },

  patch: (url: string, data: any) => {
    return _axiosFormData.patch(url, data)
  },
}

export const firstLetterUpper = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const stringTo12Hour = (time: string) => {
  let timeNumber = time.replace(/\D/g, '')
  if (timeNumber.length < 4) {
    timeNumber = '0' + timeNumber + '000'
  }
  timeNumber = timeNumber.substring(0, 4)
  if (timeNumber[0] > '1' || (timeNumber[0] === '1' && timeNumber[1] > '2')) {
    timeNumber = '0' + timeNumber.substring(1, 4)
  }
  if (timeNumber[2] > '5') {
    timeNumber = timeNumber.substring(0, 2) + '0' + timeNumber[2]
  }
  const hour = timeNumber.substring(0, 2)
  const minute = timeNumber.substring(2, 4)
  const aIndex = time.toUpperCase().indexOf('A')
  const pIndex = time.toUpperCase().indexOf('P')
  const amPmText =
    aIndex === -1 || (pIndex >= 0 && aIndex < pIndex) ? 'PM' : 'AM'
  return `${hour}:${minute} ${amPmText}`
}

const stringTo24Hour = (time: string) => {
  let timeNumber = time.replace(/\D/g, '')
  if (timeNumber.length < 4) {
    timeNumber = '0' + timeNumber + '000'
  }
  timeNumber = timeNumber.substring(0, 4)
  if (timeNumber[0] > '2' || (timeNumber[0] === '2' && timeNumber[1] > '4')) {
    timeNumber = '0' + timeNumber.substring(1, 4)
  }
  if (timeNumber[2] > '5') {
    timeNumber = timeNumber.substring(0, 2) + '0' + timeNumber[2]
  }
  let hour = timeNumber.substring(0, 2)
  if (time.includes('PM') && hour !== '12') {
    hour = `${parseInt(hour) + 12}`
  }
  const minute = timeNumber.substring(2, 4)
  return `${hour}:${minute}`
}

export const getNewTime = (time: string) => {
  // eslint-disable-next-line no-undef
  if (gon.currentLocale === 'en') {
    return stringTo12Hour(time)
  } else {
    return stringTo24Hour(time)
  }
}

export const useDebounce = (
  callback: (...args: any[]) => void,
  delay = 600,
) => {
  return useCallback(debounce(callback, delay), [])
}

export const compareHourAndMinutes = (
  h1: number,
  m1: number,
  h2: number,
  m2: number,
) => {
  const time1 = h1 * 60 + m1
  const time2 = h2 * 60 + m2
  if (time1 < time2) return -1
  if (time1 > time2) return 1
  return 0
}

export const formatHourAndMinutes = (hour: number, minutes: number) => {
  return `${hour.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}:00`
}

export const getDuration = (dateTime: DateTimeObject) => {
  const { toDate, fromDate, toTime, fromTime } = dateTime
  return moment
    .duration(
      moment(`${toDate} ${toTime}`).diff(moment(`${fromDate} ${fromTime}`)),
    )
    .asMinutes()
}

export const checkSameDay = (d1: Date, d2: Date) => {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  )
}

export const eventOrder = (event1: any, event2: any) => {
  const sameDay1 = checkSameDay(new Date(event1.start), new Date(event1.end))
  const sameDay2 = checkSameDay(new Date(event2.start), new Date(event2.end))
  if (!sameDay1 && sameDay2) {
    return -1
  } else if (sameDay1 && !sameDay2) {
    return 1
  } else if (event1.start !== event2.start) {
    return event1.start < event2.start ? -1 : 1
  } else if (event1.title === '') {
    return 1
  }
  return event1.duration <= event2.duration ? -1 : 1
}

export const getTimeIndex = (time: Date) => {
  return (time.getHours() * 4 + Math.ceil(time.getMinutes() / 15)) % (24 * 4)
}

export const addTime = (time: Date, minute: number) => {
  return new Date(time.getTime() + minute * 60 * 1000)
}

export const getLocaleDateString = (
  date: Date | string,
  options?: Intl.DateTimeFormatOptions,
) => {
  const newOptions: Intl.DateTimeFormatOptions = {
    day: options?.day || 'numeric',
    month: options?.month || 'long',
    year: options?.year || 'numeric',
    hour: undefined,
    minute: undefined,
  }
  // eslint-disable-next-line no-undef
  const isEng = gon.currentLocale === 'en'
  newOptions['hour12'] = isEng
  // eslint-disable-next-line no-undef
  const locale = isEng ? 'en-GB' : 'th-TH'
  const dateString = new Date(date).toLocaleDateString(locale, newOptions)
  if (options?.hour && options?.minute) {
    return `${dateString}, ${getLocaleTimeString(date, options)}`
  } else {
    return dateString
  }
}

export const getLocaleTimeString = (
  date: Date | string,
  options?: Intl.DateTimeFormatOptions,
) => {
  const newOptions: Intl.DateTimeFormatOptions = {
    hour: options?.hour || '2-digit',
    minute: options?.minute || '2-digit',
    day: undefined,
    month: undefined,
    year: undefined,
  }
  // eslint-disable-next-line no-undef
  const isEng = gon.currentLocale === 'en'
  newOptions['hour12'] = isEng
  const locale = isEng ? 'en-US' : 'th-TH'
  return new Date(date).toLocaleTimeString(locale, newOptions)
}

export const maskText = (text: string, masked?: boolean) => {
  if (masked === false) return text

  return '*'.repeat(text.length)
}

export const blobToFile = async (file: File) => {
  const blob = await heic2any({
    blob: file,
    toType: 'image/jpeg',
    quality: 1.0,
  })
  const jpegFile = new File([blob as Blob], 'image.jpg', {
    type: 'image/jpeg',
  })
  return jpegFile
}

export const formatTelephone = (telephone: string) => {
  const tel = telephone.replaceAll('-', '').padStart(10, '0')
  return `${tel.slice(0, 3)}-${tel.slice(3, 6)}-${tel.slice(6, 10)}`
}
