import React, { useEffect, useState } from 'react'

import { EventInput } from '@fullcalendar/react'
import moment from 'moment'
import { useForm } from 'react-hook-form'

import { InviteCalendar, InviteForm } from '../../components'
import styles from './InvitePage.module.scss'
import { CaretLeft } from '@/components/icons'
import {
  ApplicationLayout,
  Button,
  ConfirmModal,
  ISelectOption,
  showToast,
} from '@/components/shared'

import { CreateSuccessModal } from '@/features/calendar/components'
import { inviteService } from '@/features/calendar/services'
import { getTimeOptions, MINIMUM_SCHEDULE_SLOTS } from '@/features/constants'
import {
  Company,
  DateTimeObject,
  Employee,
  IntegratedEvent,
  Invite,
} from '@/features/types'
import { formatDate, getDuration, getTimeIndex, t } from '@/lib/helpers'

type Props = {
  employee: Employee
  invite?: Invite
  areaToAccessOptions: ISelectOption[]
  previousPath: string
  invites: Invite[]
  integratedEvents: IntegratedEvent[]
  visitorEmails: string[]
  allVisitorEmails: ISelectOption[]
  company: Company
  employees: ISelectOption[]
  defaultArea: string
}

const MINUTE_IN_TIMESTAMP = 60 * 1000
const findDuration = (event: any) => {
  const durationInMinutes =
    (new Date(event.end).getTime() - new Date(event.start).getTime()) /
    MINUTE_IN_TIMESTAMP
  return durationInMinutes === 540 ? 'allDay' : durationInMinutes
}

const outsideCompanyTime = (start: Date, end: Date, company: Company) => {
  const openHour =
    start.getDay() === 0 || start.getDay() === 6
      ? company.open_hour_weekend
      : company.open_hour
  const openMinutes =
    start.getDay() === 0 || start.getDay() === 6
      ? company.open_minutes_weekend
      : company.open_minutes
  if (
    start.getHours() < openHour ||
    (start.getHours() === openHour && start.getMinutes() < openMinutes)
  )
    return true
  const closeHour =
    end.getDay() === 0 || end.getDay() === 6
      ? company.close_hour_weekend
      : company.close_hour
  const closeMinutes =
    end.getDay() === 0 || end.getDay() === 6
      ? company.close_minutes_weekend
      : company.close_minutes
  if (
    end.getHours() > closeHour ||
    (end.getHours() === closeHour && end.getMinutes() > closeMinutes)
  )
    return true
  return false
}

const InvitePage = (props: Props) => {
  const {
    employee,
    invite,
    areaToAccessOptions,
    previousPath,
    invites,
    integratedEvents,
    visitorEmails,
    allVisitorEmails,
    company,
    employees,
    defaultArea,
  } = props

  const isEdit = invite ? true : false
  const timeOptions: ISelectOption[] = getTimeOptions()
  const defaultTimeIndex: number = getTimeIndex(new Date())
  const tomorrow = new Date()
  tomorrow.setDate(tomorrow.getDate() + 1)
  const defaultDateTime: DateTimeObject = {
    fromDate: invite
      ? formatDate(invite.start_time, 'D MMM YYYY')
      : formatDate(new Date(), 'D MMM YYYY'),
    fromTime: invite
      ? formatDate(invite.start_time, 'hh:mm A')
      : timeOptions[defaultTimeIndex].value,
    toDate: invite
      ? formatDate(invite.end_time, 'D MMM YYYY')
      : formatDate(tomorrow, 'D MMM YYYY'),
    toTime: invite
      ? formatDate(invite.end_time, 'hh:mm A')
      : timeOptions[(defaultTimeIndex + 1) % (24 * 4)].value,
  }
  const defaultMoreOneDay = invite
    ? formatDate(invite.start_time) !== formatDate(invite.end_time)
    : false
  const defaultEmails = visitorEmails
    ? visitorEmails.map((email: string) => ({ label: email, value: email }))
    : []
  const { handleSubmit, register, control, watch, setValue } = useForm({
    defaultValues: {
      dateTime: defaultDateTime,
      emails: defaultEmails,
      location: {
        label: invite?.location || defaultArea,
        value: invite?.location || defaultArea,
      },
      host_employee_id: invite?.host_employee_id
        ? invite?.host_employee_id
        : employee.id,
      moreOneDay: defaultMoreOneDay,
      online_link: invite?.online_link,
      category: invite?.online_link
        ? invite?.online_link?.includes('meet.google')
          ? 'google'
          : 'zoom'
        : 'local',
    },
  })

  const [showCreateSuccessModal, setShowCreateSuccessModal] =
    useState<boolean>(false)
  const defaultNewEvents = invite
    ? invite.list_schedule_time.map((event: any, idx: number) => ({
        id: idx.toString(),
        start: event.start_time,
        end: event.end_time,
        className: 'new-invite',
      }))
    : []
  const defaultDuration =
    defaultNewEvents.length > 0 ? findDuration(defaultNewEvents[0]) : 15

  const inviteEventObjects = invites.map((invite: Invite) => ({
    title: invite.title,
    start: invite.start_time,
    end: invite.end_time,
    className: 'event-invite',
  }))
  const integratedEventObjects = integratedEvents.map(
    (integratedEvent: IntegratedEvent) => ({
      title: integratedEvent.title,
      start: integratedEvent.start_time,
      end: integratedEvent.end_time,
      className:
        integratedEvent.event_type === 'google'
          ? 'google-integrated'
          : 'microsoft-integrated',
    }),
  )
  const events: EventInput[] = [
    ...inviteEventObjects,
    ...integratedEventObjects,
  ]
  const [newEvents, setNewEvents] = useState<EventInput[]>(defaultNewEvents)
  const [duration, setDuration] = useState<number | string>(defaultDuration)
  const [scheduleMode, setScheduleMode] = useState<boolean>(
    invite?.start_time === null,
  )
  const [sendingData, setSendingData] = useState<any>({})
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false)

  const showToastScheduleError = (action: string) => {
    showToast({
      type: 'alert',
      message: t('views.features.invite.invite.failed_minimum_slots', {
        action: t(`views.misc.${action}`).toLowerCase(),
        minimum: MINIMUM_SCHEDULE_SLOTS,
      }),
    })
  }

  const showToastAreaError = (action: string) => {
    showToast({
      type: 'alert',
      message: t('views.features.invite.invite.failed_no_area_or_link', {
        action: t(`views.misc.${action}`).toLowerCase(),
      }),
    })
  }

  const createInvite = (data: any) => {
    if (!data.location && !data.online_link) {
      showToastAreaError('create')
      return
    }
    if (scheduleMode) {
      if (data.events.length < MINIMUM_SCHEDULE_SLOTS) {
        showToastScheduleError('create')
        return
      }
      inviteService().createScheduleInvite(data)
    } else {
      if (!data.moreOneDay) {
        data.dateTime.toDate = null
      }
      inviteService().createNormalInvite(data)
    }
    setShowCreateSuccessModal(true)
  }

  const editInvite = (data: any) => {
    if (!data.location && !data.online_link) {
      showToastAreaError('edit')
      return
    }
    data.id = invite?.id
    if (scheduleMode) {
      if (data.events.length < MINIMUM_SCHEDULE_SLOTS) {
        showToastScheduleError('edit')
        return
      }
      inviteService().editScheduleInvite(data)
    } else {
      inviteService().editNormalInvite(data)
    }
    window.location.href = previousPath
  }

  const validateNormalEvent = (dateTime: any, moreOneDay: boolean) => {
    let toDate = moreOneDay ? dateTime.toDate : dateTime.fromDate
    return !outsideCompanyTime(
      new Date(`${dateTime.fromDate} ${dateTime.fromTime}`),
      new Date(`${toDate} ${dateTime.toTime}`),
      company,
    )
  }

  const validateScheduleEvent = () => {
    let isOutsideCompanyTime = false
    newEvents.forEach((event: EventInput) => {
      if (
        outsideCompanyTime(
          new Date(event.start as string),
          new Date(event.end as string),
          company,
        )
      )
        isOutsideCompanyTime = true
    })
    return !isOutsideCompanyTime
  }

  const submitForm = (data: any) => {
    const submittedSendingData: any = {
      title: data.title,
      comment: data.comment,
      host_employee_id: data.host_employee_id,
      emails: data.emails.map(
        (emailOption: ISelectOption) => emailOption.value,
      ),
      dateTime: data.dateTime,
      events: newEvents,
      moreOneDay: data.moreOneDay,
    }
    if (data.category === 'local') {
      submittedSendingData['location'] = data.location.value
    } else {
      submittedSendingData['online_link'] = data.online_link
    }
    let validate
    if (scheduleMode) validate = validateScheduleEvent()
    else {
      validate = validateNormalEvent(data.dateTime, data.moreOneDay)
    }
    if (!validate) {
      setSendingData(submittedSendingData)
      setShowConfirmModal(true)
    } else {
      if (isEdit) {
        return editInvite(submittedSendingData)
      }
      return createInvite(submittedSendingData)
    }
  }

  const _setNewEvents = (events: EventInput[]) => {
    const sortedNewEvents = events.sort((left, right) =>
      moment(left.start).diff(moment(right.start)),
    )
    setNewEvents(sortedNewEvents)
  }

  const onConfirmConfirmModal = () => {
    setShowConfirmModal(false)
    if (isEdit) {
      return editInvite(sendingData)
    }
    return createInvite(sendingData)
  }

  const normalDateTime = watch('dateTime')
  const moreOneDay = watch('moreOneDay')
  const category = watch('category')

  const manageMoreOneDayDateTime = () => {
    if (normalDateTime.fromDate >= normalDateTime.toDate) {
      const toDate = new Date(normalDateTime.fromDate)
      toDate.setDate(toDate.getDate() + 1)
      const toDateStr = formatDate(toDate)
      setValue('dateTime', { ...normalDateTime, toDate: toDateStr })
      setDuration(getDuration({ ...normalDateTime, toDate: toDateStr }))
    } else {
      setDuration(getDuration(normalDateTime))
    }
  }

  const manageOneDayDateTime = () => {
    const fromDateFromTime = new Date(
      `${normalDateTime.fromDate} ${normalDateTime.fromTime}`,
    )
    const fromDateToTime = new Date(
      `${normalDateTime.fromDate} ${normalDateTime.toTime}`,
    )
    if (fromDateFromTime >= fromDateToTime) {
      const toTime =
        timeOptions[(getTimeIndex(fromDateFromTime) + 1) % (24 * 4)].value
      setValue('dateTime', { ...normalDateTime, toTime })
      setDuration(
        getDuration({
          ...normalDateTime,
          toDate: normalDateTime.fromDate,
          toTime,
        }),
      )
    } else {
      setDuration(
        getDuration({ ...normalDateTime, toDate: normalDateTime.fromDate }),
      )
    }
  }

  useEffect(() => {
    if (moreOneDay) manageMoreOneDayDateTime()
    else manageOneDayDateTime()
  }, [moreOneDay])

  const onlineLink = watch('online_link')
  useEffect(() => {
    if (category === 'local') return
    if (category === 'zoom' && onlineLink?.includes('zoom.us')) return
    if (category === 'google' && onlineLink?.includes('meet.google')) return

    setValue('online_link', undefined)
    inviteService()
      .getNewOnlineLink(category as 'google' | 'zoom')
      .then((link) => setValue('online_link', link))
  }, [category])

  return (
    <ApplicationLayout employee={employee}>
      <div className={styles.wrapper}>
        <Button
          text={t('views.misc.back')}
          iconComponent={<CaretLeft />}
          style={'Ghost'}
          onClick={() => (window.location.href = previousPath)}
          className={styles.backBtn}
        />
        <div className={styles.sectionWrapper}>
          <div className={styles.section}>
            <div className={styles.sectionForm}>
              <InviteForm
                employee={employee}
                invite={invite}
                areaToAccessOptions={areaToAccessOptions}
                register={register}
                control={control}
                watch={watch}
                handleSubmit={handleSubmit(submitForm)}
                events={newEvents}
                setEvents={_setNewEvents}
                duration={duration}
                setDuration={setDuration}
                scheduleMode={scheduleMode}
                setScheduleMode={setScheduleMode}
                isEdit={isEdit}
                allVisitorEmails={allVisitorEmails}
                company={company}
                employees={employees}
              />
            </div>
            <div className={styles.sectionCalendar}>
              <InviteCalendar
                events={events}
                newEvents={newEvents}
                setNewEvents={_setNewEvents}
                duration={duration}
                scheduleMode={scheduleMode}
                watch={watch}
                company={company}
                setValue={setValue}
              />
              <div className={styles.createButtonWrapper}>
                {scheduleMode && newEvents.length !== 0 && (
                  <div className={styles.slotNumber}>
                    {t('views.features.invite.invite.slots_selected', {
                      slot: newEvents.length,
                    })}
                  </div>
                )}
                <Button
                  text={
                    isEdit
                      ? t('views.features.invite.invite.edit_invitation')
                      : t('views.features.invite.invite.create_send_invitation')
                  }
                  type="submit"
                  size={'L'}
                  form="createInvite"
                  className={styles.createButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CreateSuccessModal
        show={showCreateSuccessModal}
        onClose={() => (window.location.href = previousPath)}
        createdBy={`${employee.first_name} ${employee.last_name || ''}`}
      />
      <ConfirmModal
        show={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        onConfirm={onConfirmConfirmModal}
        title={t('views.features.invite.invite.confirm_title')}
        description={t('views.features.invite.invite.confirm_description')}
        confirmText={t('views.misc.confirm')}
      />
    </ApplicationLayout>
  )
}

export default InvitePage
