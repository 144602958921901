import React, { useState } from 'react'

import classnames from 'classnames'

import styles from './Dropdown.module.scss'

export type DropdownMenu = {
  label: React.ReactNode
  onClick?: () => void
  className?: string
}

type Props = {
  menus: DropdownMenu[]
  children: React.ReactNode
  className?: string
}

export const Dropdown = (props: Props) => {
  const { menus, children, className } = props
  const [showDropdown, setShowDropdown] = useState<boolean>(false)
  const dropdownMenus = menus.map((menu: DropdownMenu, idx: number) => {
    const { label, onClick, className } = menu
    return (
      <div
        onClick={onClick}
        className={classnames(styles.menu, className)}
        key={idx}
      >
        {label}
      </div>
    )
  })

  return (
    <div className={classnames(styles.wrapper, className)}>
      <div onClick={() => setShowDropdown(true)} className={styles.menu}>
        {children}
      </div>
      <div
        onClick={() => setShowDropdown(false)}
        className={classnames(styles.popup, { [styles.show]: showDropdown })}
      >
        <div className={styles.bg} />
        <div className={styles.menus} role={'dialog'}>
          {dropdownMenus}
        </div>
      </div>
    </div>
  )
}
