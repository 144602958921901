import { createContext, Dispatch, SetStateAction, useContext } from 'react'

import { ISelectOption } from '@/components/shared'

import {
  Company,
  CovidScreening,
  WalkInDeliveryData,
  WalkInVisitorData,
} from '@/features/types'

export type WalkInType =
  | 'welcome'
  | 'visitor'
  | 'delivery'
  | 'visitor-success'
  | 'delivery-success'
  | 'screening-selection'
  | 'screening-form'
  | 'atk'
  | 'visitor-policy'
export type WalkInStateType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
export type WalkInScreeningFormStateType = 1 | 2 | 3 | 4 | 5 | 6

type WalkInContextProps = {
  company: Company | null
  type: WalkInType
  setType: Dispatch<SetStateAction<WalkInType>>
  state: WalkInStateType
  setState: Dispatch<SetStateAction<WalkInStateType>>
  locale: PlatformLanguage
  setLocale: Dispatch<SetStateAction<PlatformLanguage>>
  t: (key: string) => string
  visitor: WalkInVisitorData | null
  setVisitor: Dispatch<SetStateAction<WalkInVisitorData | null>>
  showConfirmModal: boolean
  setShowConfirmModal: Dispatch<SetStateAction<boolean>>
  covidScreening: CovidScreening | null
  setCovidScreening: Dispatch<SetStateAction<CovidScreening | null>>
  host: ISelectOption | null
  setHost: Dispatch<SetStateAction<ISelectOption | null>>
  imgSrc: string | undefined
  setImgSrc: Dispatch<SetStateAction<string | undefined>>
  isLoading: boolean
  setIsLoading: Dispatch<SetStateAction<boolean>>
  delivery: WalkInDeliveryData | null
  setDelivery: Dispatch<SetStateAction<WalkInDeliveryData | null>>
}

export const WalkInContext = createContext<WalkInContextProps>({
  company: null,
  type: 'welcome',
  setType: () => {},
  state: 0,
  setState: () => {},
  locale: 'th',
  setLocale: () => {},
  t: (_key: string) => '',
  visitor: null,
  setVisitor: () => {},
  showConfirmModal: false,
  setShowConfirmModal: () => {},
  covidScreening: null,
  setCovidScreening: () => {},
  host: null,
  setHost: () => {},
  imgSrc: undefined,
  setImgSrc: () => {},
  isLoading: false,
  setIsLoading: () => {},
  delivery: null,
  setDelivery: () => {},
})

export const useWalkInContext = () => {
  const context = useContext(WalkInContext)
  if (context === undefined) {
    throw new Error('useWalkInContext must be used within a Provider')
  }
  return context
}
