import React, { ChangeEvent, useState } from 'react'

import { useForm } from 'react-hook-form'

import { CreateModal, Users, createEditEmployeeForm } from '../../components'
import styles from './UserManagementPage.module.scss'
import {
  ApplicationLayout,
  Button,
  ImportFileButton,
  ISelectOption,
  Search,
  Select,
  SortOptions,
  ConfirmModal,
  ConFirmModalBtnType,
} from '@/components/shared'

import { accountTemplatePath } from '@/features/constants'
import { Employee, EmployeeStatus, SystemAdmin } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  isLoading?: boolean
  users: Employee[] | SystemAdmin[]
  user: Employee | SystemAdmin
  defaultSearch?: string
  defaultSort?: string
  defaultSortDirection?: string
  defaultType?: string
  onCreate: (data: any) => void
  onChangeStatus: (checked: boolean, id: string) => void
  onEdit: (id: string, data: any) => void
  onDelete: (id: string) => void
  handleQuery: (params: any) => void
  handleSelectRole?: (type: string) => void
  roleOptions?: ISelectOption[]
  isSystemAdmin?: boolean
  handleImportAccount?: (event: ChangeEvent<HTMLInputElement>) => void
  resendConfirmation?: (id: number) => void
}

type EditDeleteUser = {
  id: string | null
  name: string | null
  status: EmployeeStatus | null
  email: string | null
}

export const UserManagementPage = (props: Props) => {
  const {
    isLoading = false,
    users,
    user,
    defaultSearch,
    defaultSort,
    defaultSortDirection,
    defaultType,
    onCreate,
    onChangeStatus,
    onEdit,
    onDelete,
    handleQuery,
    handleSelectRole,
    roleOptions = [],
    isSystemAdmin,
    handleImportAccount,
    resendConfirmation,
  } = props

  const [search, setSearch] = useState<string>(defaultSearch || '')
  const [showCreateEmployeeModal, setShowCreateEmployeeModal] =
    useState<boolean>(false)
  const [showConfirmModal, setShowConfirmModal] = useState<
    'delete' | 'confirm' | false
  >(false)
  const [currentUser, setCurrentUser] = useState<EditDeleteUser>({
    id: null,
    name: null,
    status: null,
    email: null,
  })
  const [editingImageURL, setEditingImageURL] = useState<string>()
  const canCreate = isSystemAdmin
    ? true
    : ['Admin', 'Owner'].includes((user as Employee).type)

  const { handleSubmit, register, reset, control, watch } = useForm()

  const sortOptions: SortOptions = {
    sort: defaultSort || '',
    direction: defaultSortDirection || '',
  }

  const onClickEdit = (
    id: string,
    name: string,
    email: string,
    role: string,
    status: EmployeeStatus,
    imageURL?: string,
  ) => {
    reset({
      name: name.trim(),
      email: email,
      role: role,
    })
    setCurrentUser({ ...currentUser, id, status, email })
    setEditingImageURL(imageURL)
    setShowCreateEmployeeModal(true)
  }

  const onClickDelete = (id: string, name: string) => {
    setCurrentUser({ ...currentUser, id, name })
    setShowConfirmModal('delete')
  }

  const onSubmitCreateOrEdit = (data: any) => {
    if (currentUser.id) {
      if (
        user.id.toString() === currentUser.id &&
        currentUser.email !== watch('email') &&
        !showConfirmModal
      ) {
        return setShowConfirmModal('confirm')
      }

      return onEdit(currentUser.id, data)
    }
    return onCreate(data)
  }

  const onCloseCreateEmployeeModal = () => {
    reset({
      name: '',
      email: '',
      role: '',
    })
    setCurrentUser({ ...currentUser, id: null })
    setShowCreateEmployeeModal(false)
  }

  const onCloseConfirmModal = () => {
    showConfirmModal === 'delete' &&
      setCurrentUser({ ...currentUser, id: null, name: null })
    setShowConfirmModal(false)
  }

  const handleSearch = () => handleQuery({ newSearch: search })
  const handleSort = (newSort?: string, newDirection?: string) =>
    handleQuery({ newSort, newDirection })

  let confirmModalTitle, confirmModalDesc, confirmText, confirmBtnType, form
  if (showConfirmModal === 'confirm') {
    confirmModalTitle = t(
      'views.features.employee.index.confirm_change_email_title',
    )
    confirmModalDesc = t(
      'views.features.employee.index.confirm_change_email_description',
      {
        email: watch('email'),
      },
    )
    confirmText = t('views.misc.confirm')
    confirmBtnType = 'submit'
    form = createEditEmployeeForm
  } else {
    confirmModalTitle = t('views.features.employee.index.delete_title')
    confirmModalDesc = t('views.features.employee.index.delete_description', {
      name: currentUser.name,
    })
    confirmText = t('views.misc.delete')
    confirmBtnType = 'button'
  }

  return (
    <ApplicationLayout
      isLoading={isLoading}
      employee={isSystemAdmin ? undefined : (user as Employee)}
      systemAdmin={isSystemAdmin ? (user as SystemAdmin) : undefined}
    >
      <div className={styles.background}>
        <div className={styles.searchSection}>
          <Search
            search={search}
            setSearch={setSearch}
            handleQuery={handleSearch}
            withSearchButton={false}
            className={styles.search}
          />
          {!isSystemAdmin && (
            <Select
              name="filterRole"
              control={control}
              options={roleOptions}
              onChange={handleSelectRole}
              className={styles.selectRole}
              defaultValue={defaultType || 'All'}
            />
          )}
          {canCreate && (
            <Button
              icon="/assets/icons/add-user.svg"
              text={t('views.features.employee.index.create_account')}
              onClick={() => setShowCreateEmployeeModal(true)}
              size={'L'}
              className={styles.createAccountButton}
            />
          )}
        </div>
        {!isSystemAdmin && handleImportAccount && (
          <div className={styles.rightSection}>
            <ImportFileButton
              text={t('views.features.employee.index.import_account')}
              onUpload={handleImportAccount}
              accept={'.xlsx'}
            />
            <Button
              text={t('views.features.employee.index.download_template')}
              style={'Secondary'}
              onClick={() => (window.location.href = accountTemplatePath)}
              className={styles.templateButton}
            />
          </div>
        )}
        <Users
          users={users}
          sortOptions={sortOptions}
          handleSort={handleSort}
          currentUser={user}
          onChangeStatus={onChangeStatus}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
          isSystemAdmin={isSystemAdmin}
          resendConfirmation={resendConfirmation}
          className={styles.employees}
        />
      </div>
      <CreateModal
        show={showCreateEmployeeModal}
        onClose={onCloseCreateEmployeeModal}
        onSubmit={onSubmitCreateOrEdit}
        handleSubmit={handleSubmit}
        register={register}
        control={control}
        isEdit={currentUser.id != null}
        canEditProfile={
          currentUser.id === null ||
          (currentUser.status !== null &&
            currentUser.status === EmployeeStatus.pending) ||
          (currentUser.id != null && currentUser.id === user.id.toString())
        }
        imageURL={editingImageURL}
        currentRole={isSystemAdmin ? undefined : (user as Employee).type}
        isSystemAdmin={isSystemAdmin}
      />
      <ConfirmModal
        show={!!showConfirmModal}
        onClose={onCloseConfirmModal}
        onConfirm={() =>
          showConfirmModal === 'delete' &&
          currentUser.id &&
          onDelete(currentUser.id)
        }
        title={confirmModalTitle}
        description={confirmModalDesc}
        confirmText={confirmText}
        type={'Delete'}
        confirmBtnType={confirmBtnType as ConFirmModalBtnType}
        form={form}
      />
    </ApplicationLayout>
  )
}
