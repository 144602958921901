// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".RejectForm-module__textArea___1o6qi textarea{height:62px !important}", "",{"version":3,"sources":["/usr/src/app/app/javascript/components/shared/RejectForm/RejectForm.module.scss"],"names":[],"mappings":"AACE,8CACE,sBAAA","file":"RejectForm.module.scss","sourcesContent":[":local(.textArea) {\n  textarea {\n    height: 62px !important;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"textArea": "RejectForm-module__textArea___1o6qi"
};
module.exports = exports;
