import React from 'react'

import classnames from 'classnames'

import styles from './ConfirmCheckInPage.module.scss'
import { Button, MobileLayout } from '@/components/shared'

import { scanQRPath } from '@/features/constants'
import { Company, Employee } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  company: Company
  employee: Employee
  visitorName: string
}

export const CheckInSuccessPage = (props: Props) => {
  const { company, employee, visitorName } = props

  const footer = (
    <div className={styles.footer}>
      <Button
        text={t('views.features.app.check_in_success.scan_another')}
        style={'Secondary'}
        size={'L'}
        className={styles.footerButton}
        onClick={() => (window.location.href = `${scanQRPath}?scan=true`)}
      />
      <Button
        text={t('views.features.app.check_in_success.back_to_main')}
        size={'L'}
        className={classnames(styles.footerButton, styles.confirmButton)}
        onClick={() => (window.location.href = scanQRPath)}
      />
    </div>
  )

  return (
    <MobileLayout company={company} employee={employee} footer={footer}>
      <div className={styles.successPage}>
        <img
          src={'/assets/images/complete.svg'}
          width={120}
          height={120}
          className={styles.successImage}
        />
        <div className={styles.successVisitorName}>{visitorName}</div>
        <div>{t('views.features.app.check_in_success.has_checked_in')}</div>
      </div>
    </MobileLayout>
  )
}
