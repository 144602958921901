// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WalkInPage-module__confirmModal___3Jli8{position:fixed}.WalkInPage-module__confirmModal___3Jli8>div{min-width:unset !important;width:528px !important;max-width:95vw !important}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/walk_ins/pages/WalkInPage/WalkInPage.module.scss"],"names":[],"mappings":"AAAA,yCACE,cAAA,CAEA,6CACE,0BAAA,CACA,sBAAA,CACA,yBAAA","file":"WalkInPage.module.scss","sourcesContent":[":local(.confirmModal) {\n  position: fixed;\n\n  > div {\n    min-width: unset !important;\n    width: 528px !important;\n    max-width: 95vw !important;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"confirmModal": "WalkInPage-module__confirmModal___3Jli8"
};
module.exports = exports;
