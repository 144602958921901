import React from 'react'

import classnames from 'classnames'

import styles from './Logs.module.scss'
import { SortOptions, Table, Column } from '@/components/shared'

import {
  ActionLog as ActionLogType,
  AtkLog as AtkLogType,
  Delivery,
  Invitation,
  LogsPageTab,
} from '@/features/types'
import { t } from '@/lib/helpers'

import { ActionLog, DeliveryLog, InvitesLog, WalkInLog } from '.'
import { AtkLog } from './AtkLog'

type LogType = Invitation | Delivery | ActionLogType | AtkLogType

type Props = {
  logs: LogType[]
  sortOptions: SortOptions
  handleSort: (newSort?: string, newDirection?: string) => void
  tab?: LogsPageTab
  className?: string
}

const visitorColumn: Column = {
  column: 'visitors.first_name',
  title: t('views.features.log.invites_log.visitor'),
  className: styles.visitor,
}

const eventNameColumn: Column = {
  column: 'invites.title',
  title: t('views.features.log.invites_log.event_name'),
  className: styles.event,
}

const hostColumn: Column = {
  column: 'employees.first_name',
  title: t('views.features.log.invites_log.host'),
  className: styles.host,
}

const accessAreaColumn: Column = {
  column: 'locations.name',
  title: t('views.features.log.invites_log.access_area'),
  className: styles.area,
}

const assetColumn: Column = {
  column: 'asset',
  title: t('views.features.log.invites_log.asset'),
  className: styles.asset,
}

const checkInColumn: Column = {
  column: 'time_check_in_at',
  title: t('views.misc.check_in'),
  className: styles.check,
}

const walkInColumn: Column = {
  column: 'time_check_in_at',
  title: t('views.features.log.invites_log.walk_in'),
  className: styles.check,
}

const checkOutColumn: Column = {
  column: 'time_check_out_at',
  title: t('views.misc.check_out'),
  className: styles.check,
}

const deliveriesColumns: Column[] = [
  {
    title: t('views.features.log.delivery_log.parcels'),
    className: styles.parcels,
  },
  {
    column: 'host_employees.first_name',
    title: t('views.features.log.delivery_log.owner'),
    className: styles.owner,
  },
  {
    column: 'sender',
    title: t('views.features.log.delivery_log.sender'),
    className: styles.sender,
  },
  {
    column: 'created_at',
    title: t('views.features.log.delivery_log.arrive'),
    className: styles.arrive,
  },
  {
    column: 'updated_at',
    title: t('views.features.log.delivery_log.received'),
    className: styles.received,
  },
  {
    column: 'mark_as_received_employees.first_name',
    title: t('views.features.log.delivery_log.mark_as_received_by'),
    className: styles.markAsReceivedBy,
  },
]

const actionLogsColumns: Column[] = [
  {
    column: 'description',
    title: t('views.features.log.action_log.action'),
    className: styles.actionLog,
  },
  {
    column: 'email',
    title: t('views.misc.email'),
    className: styles.actionLogEmail,
  },
  {
    column: 'created_at',
    title: t('views.misc.time'),
    className: styles.actionLogTime,
  },
]

const atkColumns: Column[] = [
  {
    column: 'visitors.name',
    title: t('views.misc.visitor'),
    className: styles.visitorColumn,
  },
  {
    column: 'submit_at',
    title: t('views.misc.requested_date'),
    className: styles.requestResponseColumn,
  },
  {
    column: 'status',
    title: t('views.features.atk_reports.response'),
    className: styles.requestResponseColumn,
  },
  {
    column: 'verify_at',
    title: t('views.misc.verified'),
    className: styles.verifiedColumn,
  },
]

export const Logs = (props: Props) => {
  const { logs, sortOptions, handleSort, tab, className } = props
  const data = logs.map((log: LogType, idx: number) => {
    switch (tab) {
      case 'invites':
        return <InvitesLog invitation={log as Invitation} key={idx} />
      case 'walk_in':
        return <WalkInLog invitation={log as Invitation} key={idx} />
      case 'delivery':
        return <DeliveryLog delivery={log as Delivery} key={idx} />
      case 'atk':
        return <AtkLog atk={log as AtkLogType} key={idx} />
      default:
        return <ActionLog log={log as ActionLogType} key={idx} />
    }
  })

  const tableColumns = () => {
    switch (tab) {
      case 'invites':
        return [
          visitorColumn,
          eventNameColumn,
          hostColumn,
          accessAreaColumn,
          checkInColumn,
          checkOutColumn,
        ]
      case 'walk_in':
        return [
          visitorColumn,
          hostColumn,
          assetColumn,
          walkInColumn,
          checkOutColumn,
        ]
      case 'delivery':
        return deliveriesColumns
      case 'atk':
        return atkColumns
      default:
        return actionLogsColumns
    }
  }

  return (
    <div className={classnames(styles.background, className)}>
      <div className={styles.tableWrapper}>
        <Table
          columns={tableColumns()}
          data={data}
          sortOptions={sortOptions}
          handleSort={handleSort}
          emptyState={t('views.features.log.no_logs')}
          className={styles.table}
        />
      </div>
    </div>
  )
}
