// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WalkInATK-module__formArea___3hS5O{position:relative;display:flex;width:100%;max-width:480px;margin:0 auto;height:196px}.WalkInATK-module__formImageArea___32Cr-{height:376px;position:relative;display:flex;justify-content:center}.WalkInATK-module__atkRadioButtons___3qySE{margin-top:16px}.WalkInATK-module__atkRadioButtons___3qySE>label{font-weight:600;font-size:16px;line-height:24px}.WalkInATK-module__atkButton___38BUJ{margin-top:16px;width:100%}.WalkInATK-module__webcamArea___Z6g50{margin-bottom:16px;width:360px;height:360px;margin:auto}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/walk_ins/components/WalkInATK/WalkInATK.module.scss"],"names":[],"mappings":"AAEA,oCACE,iBAAA,CACA,YAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CACA,YAAA,CAGF,yCACE,YAAA,CACA,iBAAA,CACA,YAAA,CACA,sBAAA,CAGF,2CACE,eAAA,CAEA,iDACE,eAAA,CACA,cAAA,CACA,gBAAA,CAIJ,qCACE,eAAA,CACA,UAAA,CAGF,sCACE,kBAAA,CACA,WAAA,CACA,YAAA,CACA,WAAA","file":"WalkInATK.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.formArea) {\n  position: relative;\n  display: flex;\n  width: 100%;\n  max-width: 480px;\n  margin: 0 auto;\n  height: 196px;\n}\n\n:local(.formImageArea) {\n  height: 376px;\n  position: relative;\n  display: flex;\n  justify-content: center;\n}\n\n:local(.atkRadioButtons) {\n  margin-top: 16px;\n\n  > label {\n    font-weight: 600;\n    font-size: 16px;\n    line-height: 24px;\n  }\n}\n\n:local(.atkButton) {\n  margin-top: 16px;\n  width: 100%;\n}\n\n:local(.webcamArea) {\n  margin-bottom: 16px;\n  width: 360px;\n  height: 360px;\n  margin: auto;\n}\n"]}]);
// Exports
exports.locals = {
	"formArea": "WalkInATK-module__formArea___3hS5O",
	"formImageArea": "WalkInATK-module__formImageArea___32Cr-",
	"atkRadioButtons": "WalkInATK-module__atkRadioButtons___3qySE",
	"atkButton": "WalkInATK-module__atkButton___38BUJ",
	"webcamArea": "WalkInATK-module__webcamArea___Z6g50"
};
module.exports = exports;
