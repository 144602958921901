import { showToast } from '@/components/shared'

import {
  covidScreeningsPath,
  errorsNotFoundPath,
  vaccinesPath,
  visitorsPath,
} from '@/features/constants'
import { VaccineFormData, VisitorFormData } from '@/features/types'
import { axios, axiosFormData, formatTelephone, t } from '@/lib/helpers'

export const visitorService = () => {
  return {
    submitVisitorForm: async (
      visitorSlug: string,
      visitorFormData: VisitorFormData,
    ) => {
      const form = new FormData()
      form.append('visitor[first_name]', visitorFormData.firstName)
      form.append('visitor[last_name]', visitorFormData.lastName)
      form.append('visitor[invitation_id]', visitorFormData.invitationSlug)
      form.append('visitor[email]', visitorFormData.email)
      form.append(
        'visitor[telephone]',
        formatTelephone(visitorFormData.telephone.toString()),
      )
      form.append('visitor[asset]', visitorFormData.asset)
      form.append('visitor[visitor_note]', visitorFormData.visitor_note)
      visitorFormData.image &&
        form.append('visitor[image]', visitorFormData.image)
      return axiosFormData.patch(`${visitorsPath}/${visitorSlug}`, form)
    },
    submitVaccinationForm: async (
      visitorSlug: string,
      vaccineFormData: VaccineFormData,
    ) => {
      const form = new FormData()
      form.append(
        'risk_assessment[vaccines]',
        JSON.stringify(vaccineFormData.vaccines),
      )
      vaccineFormData.invitationSlug &&
        form.append(
          'risk_assessment[invitation_id]',
          vaccineFormData.invitationSlug,
        )
      if (vaccineFormData.image || vaccineFormData.image === '') {
        form.append('risk_assessment[image]', vaccineFormData.image)
      }
      return axiosFormData.patch(`${vaccinesPath}/${visitorSlug}`, form)
    },
    submitScheduleDateForm: async (
      visitorSlug: string,
      invitationSlug: string,
      datetime: string,
    ) => {
      try {
        const [start_datetime, finish_datetime] = datetime.split(' - ')
        const url = new URL(window.location.href)
        const locale = url.searchParams.get('locale') || 'en'
        await axios.patch(`${visitorsPath}/${visitorSlug}?schedule=true`, {
          visitor: {
            start_datetime,
            finish_datetime,
            invitation_id: invitationSlug,
          },
        })
        window.location.href = `${visitorsPath}?invitation_id=${invitationSlug}&locale=${locale}`
      } catch (err) {
        showToast({
          type: 'alert',
          message: t('views.features.visitor.schedule.invalid_date_time'),
        })
      }
    },
    submitScreeningForm: async (
      covidScreeningSlug: string,
      data: any,
      invitationSlug: string,
      redirectedAtk?: boolean,
    ) => {
      try {
        const res = await axios.patch(
          `${covidScreeningsPath}/${covidScreeningSlug}?invitation_id=${invitationSlug}`,
          {
            covid_screening: data,
            type: 'form',
          },
        )
        const { invitation_id } = res.data
        const invitationId = invitationSlug || invitation_id
        let path = `${visitorsPath}?invitation_id=${invitationId}`
        if (redirectedAtk) {
          path = `${covidScreeningsPath}/${covidScreeningSlug}/edit?type=atk&invitation_id=${invitationId}`
        }
        window.location.href = path
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          window.location.href = errorsNotFoundPath
        } else {
          window.location.reload()
        }
      }
    },
    submitAtkForm: async (
      covidScreeningSlug: string,
      data: any,
      invitationSlug: string,
    ) => {
      try {
        const form = new FormData()
        form.append('type', 'atk')
        data.atk_image &&
          form.append('covid_screening[atk_image]', data.atk_image)
        data.test_atk_at &&
          form.append('covid_screening[test_atk_at]', data.test_atk_at)
        const res = await axiosFormData.patch(
          `${covidScreeningsPath}/${covidScreeningSlug}?invitation_id=${invitationSlug}`,
          form,
        )
        const { invitation_id } = res.data
        window.location.href = `${visitorsPath}?invitation_id=${
          invitationSlug || invitation_id
        }`
      } catch (err) {
        if (axios.isAxiosError(err) && err.response?.status === 404) {
          window.location.href = errorsNotFoundPath
        } else {
          window.location.reload()
        }
      }
    },
  }
}
