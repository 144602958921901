// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WalkInSuccess-module__wrapper___25pz0{width:480px;display:flex;flex-direction:column;margin:0 auto;border-bottom:16px}.WalkInSuccess-module__img___1UAlN{display:flex;margin:0 auto}.WalkInSuccess-module__btn___1XOxz{width:100%;margin-top:16px}@media screen and (max-width: 480px){.WalkInSuccess-module__wrapper___25pz0{width:100%}}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/walk_ins/components/WalkInSuccess/WalkInSuccess.module.scss"],"names":[],"mappings":"AAAA,uCACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,aAAA,CACA,kBAAA,CAGF,mCACE,YAAA,CACA,aAAA,CAGF,mCACE,UAAA,CACA,eAAA,CAGF,qCACE,uCACE,UAAA,CAAA","file":"WalkInSuccess.module.scss","sourcesContent":[":local(.wrapper) {\n  width: 480px;\n  display: flex;\n  flex-direction: column;\n  margin: 0 auto;\n  border-bottom: 16px;\n}\n\n:local(.img) {\n  display: flex;\n  margin: 0 auto;\n}\n\n:local(.btn) {\n  width: 100%;\n  margin-top: 16px;\n}\n\n@media screen and (max-width: 480px) {\n  :local(.wrapper) {\n    width: 100%;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "WalkInSuccess-module__wrapper___25pz0",
	"img": "WalkInSuccess-module__img___1UAlN",
	"btn": "WalkInSuccess-module__btn___1XOxz"
};
module.exports = exports;
