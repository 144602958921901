// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".ScreeningSelection-module__background___VchIC{max-width:480px;margin:0 auto}.ScreeningSelection-module__button___2Z8Uq{width:100%;margin-top:16px}.ScreeningSelection-module__radioButtons___3F3Xu{height:232px}.ScreeningSelection-module__radioButtons___3F3Xu>div{grid-column-gap:16px !important;-moz-column-gap:16px !important;column-gap:16px !important}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/walk_ins/components/ScreeningSelection/ScreeningSelection.module.scss"],"names":[],"mappings":"AAEA,+CACE,eAAA,CACA,aAAA,CAGF,2CACE,UAAA,CACA,eAAA,CAGF,iDACE,YAAA,CAEA,qDACE,+BAAA,CAAA,+BAAA,CAAA,0BAAA","file":"ScreeningSelection.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.background) {\n  max-width: 480px;\n  margin: 0 auto;\n}\n\n:local(.button) {\n  width: 100%;\n  margin-top: 16px;\n}\n\n:local(.radioButtons) {\n  height: 232px;\n\n  > div {\n    column-gap: 16px !important;\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"background": "ScreeningSelection-module__background___VchIC",
	"button": "ScreeningSelection-module__button___2Z8Uq",
	"radioButtons": "ScreeningSelection-module__radioButtons___3F3Xu"
};
module.exports = exports;
