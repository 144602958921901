import React from 'react'

import { Toaster as ReactToaster } from 'react-hot-toast'

import styles from './Toaster.module.scss'

type Props = {}

const Toaster = (props: Props) => {
  return (
    <ReactToaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        className: styles.toast,
      }}
    />
  )
}

export default Toaster
