import React from 'react'

import styles from './WalkInWelcome.module.scss'
import { Button } from '@/components/shared'

import { useWalkInContext } from '../../context'

export const WalkInWelcome = () => {
  const { setType, setState, company, t } = useWalkInContext()
  return (
    <div className={styles.btns}>
      <div
        onClick={() => {
          setType(company?.visitor_policy_url ? 'visitor-policy' : 'visitor')
          setState(1)
        }}
        className={styles.visitorBtn}
      >
        <img
          src={'/assets/images/walk-in-visitor.svg'}
          className={styles.img}
        />
        <Button
          text={t('views.misc.visitor')}
          size={'L'}
          className={styles.btn}
        />
      </div>
      <div
        onClick={() => {
          setType('delivery')
          setState(1)
        }}
        className={styles.deliveryBtn}
      >
        <img
          src={'/assets/images/walk-in-delivery.svg'}
          className={styles.img}
        />
        <Button
          text={t('views.misc.delivery')}
          size={'L'}
          className={styles.btn}
        />
      </div>
    </div>
  )
}
