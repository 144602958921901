import React from 'react'

import classnames from 'classnames'
import { Control, UseFormRegister } from 'react-hook-form'

import { Label } from '..'
import styles from './SettingSections.module.scss'
import {
  NumberField,
  NumberFieldDirection,
  Select,
  Switch,
} from '@/components/shared'

import {
  InvitationWalkInType,
  CompanySettingNumberField,
} from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  register: UseFormRegister<any>
  control: Control<any>
  value: any
  onChange: (key: string, value: any) => void
  handleNumberField: (
    field: CompanySettingNumberField,
    direction?: NumberFieldDirection,
  ) => void
  type: InvitationWalkInType
}

export const CovidScreeningSettingSection = (props: Props) => {
  const { register, control, value, onChange, handleNumberField, type } = props
  return (
    <div className={styles.halfWidth}>
      <div className={styles.box}>
        <Label
          title={t('views.features.company_setting.covid_screening.form')}
          description={t(
            'views.features.company_setting.covid_screening.form_description',
          )}
        />
        <Switch
          checked={value.required_screening_form}
          onChange={() =>
            onChange('required_screening_form', !value.required_screening_form)
          }
          width={50}
          height={30}
          className={styles.floatRight}
        />
      </div>
      <div
        className={classnames(styles.box, styles.switchDetail, styles.ml24, {
          [styles.switchActive]: value.required_screening_form,
        })}
      >
        <Label
          title={t(
            'views.features.company_setting.covid_screening.effective_date',
          )}
          description={t(
            'views.features.company_setting.covid_screening.effective_date_description',
          )}
        />
        <NumberField
          name={`${type}.effective_date_screening`}
          register={register}
          onChange={(direction?: NumberFieldDirection) =>
            handleNumberField(`${type}.effective_date_screening`, direction)
          }
          unit={t('views.misc.day')}
        />
      </div>
      <div className={styles.box}>
        <Label
          title={t('views.features.company_setting.covid_screening.atk')}
          description={t(
            'views.features.company_setting.covid_screening.atk_description',
          )}
        />
        <Switch
          checked={value.required_atk}
          onChange={() => onChange('required_atk', !value.required_atk)}
          width={50}
          height={30}
          className={styles.floatRight}
        />
      </div>
      <div
        className={classnames(styles.box, styles.switchDetail, {
          [styles.switchActive]:
            value.required_screening_form && value.required_atk,
        })}
      >
        <Label
          title={t('views.features.company_setting.covid_screening.flow')}
          description={t(
            'views.features.company_setting.covid_screening.flow_description',
          )}
        />
        <Select
          name={`${type}.required_covid_screening`}
          control={control}
          options={[
            {
              label: t('views.features.company_setting.covid_screening.either'),
              value: 'either',
            },
            {
              label: t('views.features.company_setting.covid_screening.both'),
              value: 'both',
            },
          ]}
          onChange={(value: string) =>
            onChange(`${type}.required_covid_screening`, value)
          }
          className={styles.covidScreeningSelect}
        />
      </div>
    </div>
  )
}
