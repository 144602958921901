import React, { useState } from 'react'

import classnames from 'classnames'

import styles from './ColorPicker.module.scss'

type Props = {
  defaultColor?: string
  handleChange?: (colorCode: string) => void
  className?: string
}

export const ColorPicker = (props: Props) => {
  const { defaultColor, handleChange, className } = props
  const [color, setColor] = useState<string>(defaultColor || '#3873e4')
  return (
    <div className={classnames(styles.wrapper, className)}>
      <input
        id={'color'}
        type={'color'}
        onBlur={(e) => {
          const colorCode = e.target.value
          handleChange?.(colorCode)
          setColor(colorCode)
        }}
        defaultValue={defaultColor}
        className={styles.emailColor}
      />
      <label htmlFor={'color'} className={styles.box}>
        <div
          style={{ backgroundColor: color }}
          className={styles.circleColor}
        />
        <span className={styles.colorCode}>{color}</span>
      </label>
    </div>
  )
}
