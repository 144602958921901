import React from 'react'

import { useForm } from 'react-hook-form'

import styles from './ScreeningSelection.module.scss'
import {
  Button,
  RadioButtons,
  RadioButtonsRegisterOption,
} from '@/components/shared'

import { useWalkInContext } from '../../context'

const screeningSelectionForm = 'screeningSelectionForm'

export const ScreeningSelection = () => {
  const { setState, setType, t } = useWalkInContext()
  const { register, handleSubmit, watch } = useForm()

  const registerOptions: RadioButtonsRegisterOption[] = [
    {
      icon: 'diagnosis',
      label: t('views.features.visitor.covid_screening.selection.form'),
      value: 'screening-form',
    },
    {
      icon: 'rapid-test',
      label: t('views.features.visitor.covid_screening.selection.atk'),
      value: 'atk',
    },
  ]

  const _handleSubmit = (data: any) => {
    if (data.screening) {
      setType(data.screening)
      setState(1)
    }
  }

  return (
    <div className={styles.background}>
      <form id={screeningSelectionForm} onSubmit={handleSubmit(_handleSubmit)}>
        <RadioButtons
          name={'screening'}
          register={register}
          registerOptions={registerOptions}
          horizontal
          className={styles.radioButtons}
        />
        <Button
          text={t('views.misc.next')}
          disabled={!watch('screening')}
          type="submit"
          size="L"
          className={styles.button}
        />
      </form>
    </div>
  )
}
