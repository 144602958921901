import React, { useRef, useState } from 'react'

import styles from './Calendar.module.scss'
import { Button, ISelectOption, Select, SelectSize } from '@/components/shared'
import { ConfirmModal } from '@/components/shared/ConfirmModal'

import { inviteService } from '../../services'
import {
  Invite,
  Invitation,
  IntegratedEvent,
  Company,
  Employee,
  VisitorWithCovidScreeningStatus,
  CovidScreeningFormStatus,
  CovidScreeningATKStatus,
} from '@/features/types'
import { eventOrder, checkSameDay, t, getLocaleDateString } from '@/lib/helpers'

/* eslint-disable */
import FullCalendar, {
  EventClickArg,
  EventSourceInput,
} from '@fullcalendar/react'
import thLocale from '@fullcalendar/core/locales/th'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import momentPlugin from '@fullcalendar/moment'
import './Calendar.scss'
/* eslint-disable */

import { InviteDetail } from '../InviteDetail'
import { CaretLeft, CaretRight } from '@/components/icons'
import { invitesNewPath, invitesPath } from '@/features/constants'
import { useForm } from 'react-hook-form'
import { getCovidScreeningStatus } from '@/lib/covidScreeningHelpers'

type Calendar = {
  invites: Invite[]
  defaultInvite?: Invite
  defaultInvitations?: Invitation[]
  integratedEvents: IntegratedEvent[]
  company: Company
  typeOptions: ISelectOption[]
  personOptions: ISelectOption[]
  employee: Employee
}

const oneDayTime = 24 * 60 * 60 * 1000
const formatTitle = (date: any) => {
  const start = date.start.marker
  const end = new Date(date.end.marker.getTime() - oneDayTime)
  const longDate: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  }
  const longDateWithWeekdayOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    ...longDate,
  }
  if (end.getTime() - start.getTime() > 7 * oneDayTime) {
    return `${getLocaleDateString(start, { month: 'long', year: 'numeric' })}`
  } else if (end.getTime() - start.getTime() > oneDayTime) {
    return `${getLocaleDateString(start, longDate)} ${
      date.defaultSeparator
    } ${getLocaleDateString(end, longDate)}`
  } else if (checkSameDay(new Date(), start)) {
    return `${t('views.misc.today')}, ${getLocaleDateString(new Date(), {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })}`
  }
  return getLocaleDateString(start, longDateWithWeekdayOptions)
}

const getDuration = (event: any) => {
  const end = new Date(event.end_time).getTime()
  const start = new Date(event.start_time).getTime()
  return (end - start) / 1000 / 60
}

const invitationToVisitor = (
  invitation: Invitation,
): VisitorWithCovidScreeningStatus => ({
  id: 0,
  slug: '',
  email: invitation.visitor_email || '',
  first_name: invitation?.visitor_name?.split(' ')[0] || '',
  last_name: invitation?.visitor_name?.split(' ').slice(1).join(' ') || '',
  image: {
    url: invitation.visitor_image_url || '',
  },
  telephone: invitation.visitor_telephone || '',
  invitation_status: invitation.status,
  vaccine_slot_verified: invitation.vaccine_slot_verified,
  covid_screening_status: getCovidScreeningStatus(
    invitation.covid_screening_type,
    invitation.covid_screening_form_status as CovidScreeningFormStatus,
    invitation.covid_screening_atk_status as CovidScreeningATKStatus,
  ),
})

export const Calendar = (props: Calendar) => {
  const {
    invites,
    defaultInvite,
    defaultInvitations = [],
    integratedEvents,
    company,
    typeOptions,
    personOptions,
    employee,
  } = props
  const [title, setTitle] = useState<string>(
    `${t('views.misc.today')}, ${getLocaleDateString(new Date(), {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })}`,
  )
  const [invite, setInvite] = useState<Invite | undefined>(defaultInvite)
  const defaultVisitors = defaultInvitations
    ? defaultInvitations.map((invitation: Invitation) =>
        invitationToVisitor(invitation),
      )
    : []
  const [visitors, setVisitors] =
    useState<VisitorWithCovidScreeningStatus[]>(defaultVisitors)
  const [showDeleteInviteModal, setShowDeleteInviteModal] =
    useState<boolean>(false)
  const calendarRef: any = useRef()
  const [isIntegratedEvent, setIsIntegratedEvent] = useState<boolean>(false)

  const getEvents = () => {
    return [
      ...invites.reduce((sum: any[], invite: Invite) => {
        if (invite.list_schedule_time.length > 0) {
          invite.list_schedule_time.forEach((schedule) => {
            sum.push({
              id: invite.id.toString(),
              title: invite.title,
              start: schedule.start_time,
              end: schedule.end_time,
              type: 'invite',
              classNames: [
                schedule.selected ? 'event-invite' : 'schedule-invite',
                getDuration(schedule) <= 30 && 'hideEventTime',
              ],
            })
          })
        } else {
          sum.push({
            id: invite.id.toString(),
            title: invite.title,
            start: invite.start_time,
            end: invite.end_time,
            type: 'invite',
            classNames: [
              'event-invite',
              getDuration(invite) <= 30 && 'hideEventTime',
            ],
          })
        }
        return sum
      }, []),
      ...integratedEvents.map((integrated: IntegratedEvent) => ({
        id: integrated.id,
        title: integrated.title,
        start: new Date(integrated.start_time),
        end: new Date(integrated.end_time),
        type: 'integrated_event',
        classNames: [
          integrated.event_type === 'google'
            ? 'google-integrated'
            : 'microsoft-integrated',
          getDuration(integrated) <= 30 && 'hideEventTime',
        ],
      })),
    ] as EventSourceInput
  }

  const prev = () => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.prev()
  }

  const next = () => {
    const calendarApi = calendarRef.current.getApi()
    calendarApi.next()
  }

  const _setTitle = () => {
    if (!calendarRef.current) return
    const calendarApi = calendarRef.current.getApi()
    setTitle(calendarApi.view.title)
  }

  const onSetInvite = async (inviteID: string, start: any, end: any) => {
    const { invitations, selected_schedule_mode } = (
      await inviteService().getInviteDetailFromID(inviteID, start, end)
    ).data
    const invite = {
      ...invitations[0].invite,
      location: invitations[0].area_to_access,
      host_employee_name: invitations[0].host_employee_name,
      online_link: invitations[0].online_link,
    }
    if (selected_schedule_mode) {
      invite.start_time = invitations[0].start_datetime
      invite.end_time = invitations[0].start_datetime
    }
    const visitors = invitations.map((invitation: Invitation) =>
      invitationToVisitor(invitation),
    )
    setInvite(invite)
    setVisitors(visitors)
    setIsIntegratedEvent(false)
  }

  const onSetIntegratedEvent = async (integrated_event_id: string) => {
    const { integrated_event, attendees } = (
      await inviteService().getIntegratedEventFromID(integrated_event_id)
    ).data
    const visitors: VisitorWithCovidScreeningStatus[] = attendees.map(
      (attendee: any) => {
        if (attendee.id) return attendee
        return {
          ...attendee,
          image: {
            url: null,
          },
        }
      },
    )
    setInvite({
      ...integrated_event,
      comment: integrated_event.description,
      host_employee_name: integrated_event.organizer,
    })
    setVisitors(visitors)
    setIsIntegratedEvent(true)
  }

  const onClickEvent = (info: EventClickArg) => {
    if (info.event._def.extendedProps.type === 'invite') {
      return onSetInvite(info.event.id, info.event.start, info.event.end)
    } else return onSetIntegratedEvent(info.event.id)
  }

  const setQueryToCurrentPath = (key: string, value: string) => {
    const params = new URLSearchParams(window.location.search)
    params.set(key, value)
    return `${window.location.pathname}?${params.toString()}`
  }

  const onDeleteInvite = async () => {
    if (!invite) return

    const calendarApi = calendarRef.current.getApi()
    const type =
      calendarApi.view.type === 'timeGridDay'
        ? 'day'
        : calendarApi.view.type === 'timeGridWeek'
        ? 'week'
        : 'month'
    await inviteService().deleteInvite(invite.id.toString())
    window.location.href = setQueryToCurrentPath('view', type)
  }

  const initialView = () => {
    const params = new URLSearchParams(window.location.search)
    const type =
      params.get('view') === 'day'
        ? 'timeGridDay'
        : params.get('view') === 'week'
        ? 'timeGridWeek'
        : 'dayGridMonth'
    return type
  }

  const { control } = useForm()
  const url = new URL(window.location.href)

  return (
    <>
      <div className={styles.header}>
        <Button
          iconComponent={<CaretLeft />}
          className={styles.previousButton}
          onClick={prev}
        />
        <Button
          iconComponent={<CaretRight />}
          className={styles.nextButton}
          onClick={next}
        />
        <div className={styles.todayText}>{title}</div>
        <div className={styles.rightSection}>
          <Button
            onClick={() => (window.location.href = invitesNewPath)}
            icon={'/assets/icons/calendar-plus-out.svg'}
            text={t('views.features.calendar.calendar.create_invitation')}
            className={styles.createInvitationButton}
            size={'L'}
          />
        </div>
      </div>
      <div className={styles.calendarWrapper}>
        <div className={styles.calendarBox}>
          <FullCalendar
            ref={calendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, momentPlugin]}
            initialView={initialView()}
            headerToolbar={{
              left: 'timeGridDay,timeGridWeek,dayGridMonth',
              center: '',
              right: '',
            }}
            buttonText={{
              today: t('views.misc.today'),
              month: t('views.features.calendar.calendar.month'),
              week: t('views.features.calendar.calendar.week'),
              day: t('views.features.calendar.calendar.day'),
            }}
            events={getEvents()}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: true,
            }}
            titleFormat={(date: any) => formatTitle(date)}
            allDaySlot={false}
            scrollTime={'08:00:00'}
            nowIndicator
            dayHeaders
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              meridiem: 'short',
            }}
            views={{
              day: {
                dayHeaders: false,
              },
              week: {
                dayHeaderFormat: 'ddd D',
              },
              month: {
                dayHeaderFormat: 'ddd',
              },
            }}
            datesSet={_setTitle}
            eventClick={onClickEvent}
            dayMaxEventRows
            eventMaxStack={3}
            fixedWeekCount={false}
            weekends={company.open_on_weekend}
            slotDuration="00:30:00"
            eventOrder={eventOrder}
            locale={gon.currentLocale === 'th' ? thLocale : undefined}
          />
          <div className={styles.filterSelects}>
            {employee.type !== 'Staff' && (
              <Select
                control={control}
                name="filterPerson"
                options={personOptions}
                onChange={(person: string) => {
                  url.searchParams.set('person', person)
                  window.location.href = url.toString()
                }}
                defaultValue={url.searchParams.get('person') || 'all'}
                size={SelectSize.Small}
                isSearchable={false}
                className={styles.filterSelect}
              />
            )}
            <Select
              control={control}
              name="filterType"
              options={typeOptions}
              onChange={(type: string) => {
                url.searchParams.set('type', type)
                window.location.href = url.toString()
              }}
              defaultValue={url.searchParams.get('type') || 'all'}
              size={SelectSize.Small}
              isSearchable={false}
              className={styles.filterSelect}
            />
          </div>
        </div>
        <InviteDetail
          invite={invite}
          visitors={visitors}
          isIntegratedEvent={isIntegratedEvent}
          onEditInvite={() =>
            (window.location.href = `${invitesPath}/${invite?.id}/edit`)
          }
          onDeleteInvite={() => setShowDeleteInviteModal(true)}
        />
      </div>
      {invite && (
        <>
          <ConfirmModal
            show={showDeleteInviteModal}
            title={t('views.features.calendar.calendar.delete_event_title')}
            description={t(
              'views.features.calendar.calendar.delete_event_description',
              { invite_title: invite.title },
            )}
            confirmText={t('views.misc.delete')}
            onClose={() => setShowDeleteInviteModal(false)}
            onConfirm={onDeleteInvite}
            type={'Delete'}
          />
        </>
      )}
    </>
  )
}
