import React from 'react'

import classnames from 'classnames'

import styles from './ImagePreview.module.scss'

type Props = {
  src?: string
  width: number
  height: number
  borderRadius?: number
  vaccineSlotVerified?: number
  showNewIcon?: boolean
  className?: string
}

export const ImagePreview = (props: Props) => {
  const {
    src,
    width,
    height,
    borderRadius = 12,
    showNewIcon = false,
    vaccineSlotVerified = 0,
    className,
  } = props
  if (!src) {
    const style = { width, height, borderRadius }
    return (
      <div className={classnames(styles.emptyImage, className)} style={style} />
    )
  }

  return (
    <div className={styles.previewBox} style={{ width, height }}>
      {vaccineSlotVerified > 0 && (
        <img src={'/assets/icons/vaccine.svg'} className={styles.vaccine} />
      )}
      {showNewIcon && (
        <img
          src={'/assets/icons/camera-plus.svg'}
          width={28}
          height={28}
          className={styles.cameraPlus}
        />
      )}
      <img
        src={src}
        width={width}
        height={height}
        style={{ borderRadius }}
        className={classnames(styles.image, className)}
      />
    </div>
  )
}
