import React from 'react'

type Props = {
  className?: string
}

export const Vaccinated = (props: Props) => {
  const { className } = props

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path d="M0.138065 15.8626C-0.0451536 15.6794 -0.0451536 15.3829 0.138065 15.1997L2.90522 12.4325C2.722 12.2493 2.722 11.9528 2.90522 11.7696L3.23669 11.4382L4.5625 12.764L4.23103 13.0954C4.04781 13.2786 3.75131 13.2786 3.56813 13.0954L0.80097 15.8626C0.617783 16.0458 0.321283 16.0458 0.138065 15.8626Z" />
        <path d="M14.5055 6.79747L13.5111 5.80309L12.1853 7.12891L8.87075 3.81438L10.1966 2.48857L9.20218 1.49419C9.01896 1.31097 9.01896 1.01447 9.20218 0.831284C9.3854 0.648066 9.6819 0.648066 9.86509 0.831284L11.8538 2.82003L13.2109 1.46297L12.5436 0.800066C12.3604 0.616847 12.3604 0.320347 12.5436 0.137159C12.7268 -0.046059 13.0233 -0.046059 13.2065 0.137159C14.7885 1.71531 12.6467 -0.427121 15.8626 2.78875C16.0458 2.97197 16.0458 3.26847 15.8626 3.45166C15.6794 3.63488 15.3829 3.63488 15.1997 3.45166L14.5368 2.78875L13.1797 4.14581L15.1684 6.13456C15.3516 6.31778 15.3516 6.61428 15.1684 6.79747C14.9852 6.98069 14.6887 6.98069 14.5055 6.79747Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.95534 12.3597C6.94444 12.2306 6.93888 12.1 6.93888 11.9681C6.93888 9.56841 8.77888 7.59847 11.125 7.39219L8.20938 4.47654L7.21501 5.47092L8.20938 6.46529C8.3926 6.64851 8.3926 6.94501 8.20935 7.12826C8.02617 7.31148 7.72967 7.31148 7.54645 7.12826L6.55207 6.13389L5.88917 6.79679L7.54645 8.45407C7.72967 8.63726 7.72967 8.93376 7.54645 9.11698C7.36326 9.3002 7.06676 9.3002 6.88354 9.11698L5.22626 7.4597L4.56335 8.1226L5.55773 9.11698C5.74095 9.30017 5.74095 9.59667 5.55773 9.77988C5.37454 9.9631 5.07804 9.9631 4.89482 9.77988L3.90045 8.78551L3.23754 9.44842C3.05432 9.6316 3.05432 9.9281 3.23754 10.1113L5.8892 12.763C6.07239 12.9462 6.36889 12.9462 6.5521 12.763L6.95534 12.3597Z"
        />
        <path d="M11.5312 8.4375C10.8328 8.4375 10.1501 8.6446 9.56939 9.03262C8.98868 9.42064 8.53607 9.97215 8.2688 10.6174C8.00153 11.2626 7.9316 11.9727 8.06785 12.6577C8.20411 13.3427 8.54043 13.9719 9.03428 14.4657C9.52813 14.9596 10.1573 15.2959 10.8423 15.4321C11.5273 15.5684 12.2373 15.4985 12.8826 15.2312C13.5279 14.9639 14.0794 14.5113 14.4674 13.9306C14.8554 13.3499 15.0625 12.6672 15.0625 11.9687C15.0625 11.505 14.9712 11.0458 14.7937 10.6174C14.6162 10.189 14.3561 9.79968 14.0282 9.47178C13.7003 9.14387 13.311 8.88376 12.8826 8.7063C12.4542 8.52884 11.995 8.4375 11.5312 8.4375ZM13.0973 11.475L11.3329 13.2403C11.2751 13.2978 11.2053 13.3418 11.1284 13.3692C11.0516 13.3966 10.9697 13.4066 10.8885 13.3985C10.8074 13.3905 10.729 13.3646 10.659 13.3227C10.5891 13.2808 10.5292 13.2239 10.4839 13.1562L9.89551 12.2737C9.8253 12.1521 9.80401 12.0083 9.83597 11.8715C9.86792 11.7348 9.95072 11.6153 10.0676 11.5374C10.1844 11.4595 10.3265 11.429 10.465 11.4521C10.6035 11.4752 10.7281 11.5501 10.8133 11.6617L11.0283 11.9844L12.3179 10.6947C12.4234 10.6018 12.5603 10.5525 12.7007 10.557C12.8412 10.5614 12.9747 10.6191 13.0741 10.7185C13.1735 10.8179 13.2314 10.9514 13.2358 11.0918C13.2403 11.2323 13.1911 11.3692 13.0982 11.4747L13.0973 11.475Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="matrix(-4.37114e-08 -1 -1 4.37114e-08 16 16)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
