import React, { useState } from 'react'

import classnames from 'classnames'
import { useForm } from 'react-hook-form'

import { AssetsCard } from '../../components'
import styles from './ConfirmCheckInPage.module.scss'
import {
  CovidDetail,
  Button,
  MobileLayout,
  VisitorDetail,
} from '@/components/shared'

import { invitationService } from '../../services'
import { Invitation, Company, Employee, Visitor } from '@/features/types'
import { t } from '@/lib/helpers'

import { CheckInSuccessPage } from './CheckInSuccessPage'

type Props = {
  invitation: Invitation
  company: Company
  employee: Employee
}

const ConfirmCheckInPage = (props: Props) => {
  const { invitation, company, employee } = props
  const [editAsset, setEditAsset] = useState<boolean>(false)
  const [confirm, setConfirm] = useState<boolean>(false)

  const { register, reset, handleSubmit } = useForm({
    defaultValues: {
      asset: invitation.asset,
    },
  })

  if (confirm) {
    return (
      <CheckInSuccessPage
        company={company}
        employee={employee}
        visitorName={invitation.visitor_name || ''}
      />
    )
  }

  const visitor: Visitor = {
    id: 0,
    first_name: invitation?.visitor_name || '',
    last_name: '',
    email: invitation?.visitor_email || '',
    image: {
      url: invitation?.visitor_image_url || '',
    },
    telephone: invitation?.visitor_telephone || '',
    slug: invitation?.visitor_slug || '',
  }

  const onCancelEditAsset = () => {
    setEditAsset(false)
    reset({ asset: invitation.asset })
  }

  const onSaveAsset = async (data: any) => {
    await invitationService().updateInvitation(invitation.id as string, {
      asset: data.asset,
    })
    window.location.reload()
  }

  const onConfirmCheckIn = async () => {
    if (!invitation.check_in_at) {
      await invitationService().checkIn(invitation.id as string)
      setConfirm(true)
    }
  }

  const footer = !editAsset && (
    <div className={styles.footer}>
      <Button
        text={t('views.features.app.confirm_check_in.edit')}
        style={'Secondary'}
        size={'L'}
        className={styles.footerButton}
        onClick={() => setEditAsset(true)}
      />
      <Button
        text={t('views.features.app.confirm_check_in.check_in')}
        size={'L'}
        className={classnames(styles.footerButton, styles.confirmButton)}
        onClick={onConfirmCheckIn}
      />
    </div>
  )

  return (
    <MobileLayout company={company} employee={employee} footer={footer}>
      <div className={styles.header}>
        {t('views.features.app.confirm_check_in.title')}
      </div>
      <VisitorDetail visitor={visitor} imageSize={80} />
      <CovidDetail invitation={invitation} className={styles.covidDetail} />
      <AssetsCard
        assets={invitation.asset}
        isEdit={editAsset}
        register={register}
        handleSubmit={handleSubmit}
        onCancelEditAsset={onCancelEditAsset}
        onSaveAsset={onSaveAsset}
        className={styles.assetsCard}
      />
    </MobileLayout>
  )
}

export default ConfirmCheckInPage
