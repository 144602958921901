import { walkInsPath } from '@/features/constants'
import { axios } from '@/lib/helpers'

export const walkInService = () => {
  return {
    walkInInvitations: async () => {
      return axios.get(`${walkInsPath}/walk_in_invitation`, {})
    },
  }
}
