import React from 'react'

type Props = {
  className?: string
}

export const Shield = (props: Props) => {
  const { className } = props
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0)">
        <path d="M14.1243 10.6847C13.6942 11.8502 13.0438 12.8637 12.1909 13.6965C11.2201 14.6445 9.94885 15.3978 8.41248 15.9352C8.36206 15.9528 8.30945 15.9672 8.25659 15.9778C8.18677 15.9916 8.11572 15.999 8.04529 16H8.03149C7.95642 16 7.88098 15.9924 7.80615 15.9778C7.7533 15.9672 7.70142 15.9528 7.65125 15.9356C6.11304 15.3991 4.84021 14.6461 3.86841 13.6981C3.01513 12.8652 2.36486 11.8524 1.93542 10.6869C1.15454 8.56775 1.19897 6.23327 1.23474 4.3573L1.23535 4.32849C1.24255 4.17358 1.24719 4.01086 1.24975 3.83117C1.26281 2.94897 1.96423 2.21911 2.84655 2.16992C4.68616 2.06725 6.10925 1.46728 7.3252 0.282096L7.33582 0.272331C7.53772 0.0871497 7.79211 -0.00367067 8.04529 0.000113528C8.28943 0.00328737 8.53259 0.0939857 8.7273 0.272331L8.73767 0.282096C9.95386 1.46728 11.377 2.06725 13.2166 2.16992C14.0989 2.21911 14.8003 2.94897 14.8134 3.83117C14.8159 4.01208 14.8206 4.17456 14.8278 4.32849L14.8281 4.34069C14.8638 6.22021 14.908 8.55933 14.1243 10.6847Z" />
      </g>
      <g opacity="0.45">
        <path
          d="M12.352 9.91763C12.0448 10.7501 11.5803 11.474 10.9711 12.0689C10.2776 12.7461 9.36958 13.2842 8.27216 13.668C8.23615 13.6805 8.19857 13.6908 8.16082 13.6984C8.11094 13.7083 8.0602 13.7136 8.00989 13.7143H8.00003C7.94641 13.7143 7.89252 13.7089 7.83908 13.6984C7.80132 13.6908 7.76426 13.6805 7.72843 13.6682C6.62971 13.285 5.72054 12.7472 5.0264 12.0701C4.41692 11.4752 3.95244 10.7517 3.6457 9.9192C3.08792 8.40552 3.11966 6.73804 3.14521 5.39806L3.14564 5.37748C3.15079 5.26683 3.1541 5.1506 3.15593 5.02226C3.16526 4.39211 3.66627 3.87078 4.29651 3.83564C5.61051 3.76232 6.627 3.33376 7.49553 2.4872L7.50312 2.48023C7.64734 2.34796 7.82905 2.28308 8.00989 2.28579C8.18427 2.28805 8.35796 2.35284 8.49703 2.48023L8.50445 2.4872C9.37315 3.33376 10.3896 3.76232 11.7036 3.83564C12.3339 3.87078 12.8349 4.39211 12.8442 5.02226C12.8461 5.15148 12.8494 5.26753 12.8545 5.37748L12.8548 5.3862C12.8802 6.72871 12.9118 8.39951 12.352 9.91763Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
