import React, { useState } from 'react'

import { useForm } from 'react-hook-form'

import { VisitorLayout } from '../../layout'

import { visitorService } from '../../services'
import {
  vaccinesPath,
  visitorsPath,
  errorsNotFoundPath,
} from '@/features/constants'
import {
  Invitation,
  Visitor,
  InvitationStatus,
  VisitorFormData,
} from '@/features/types'
import { axios, t } from '@/lib/helpers'

import { VisitorForm } from '../../components/VisitorForm'

type Props = {
  invitation: Invitation
  visitor: Visitor
  required_vaccine_record: boolean
}

const RegistrationPage = (props: Props) => {
  const { invitation, visitor, required_vaccine_record = true } = props

  const [image, setImage] = useState<File>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { register, handleSubmit } = useForm()

  const _handleSubmit = async (data: any) => {
    setIsLoading(true)
    const visitorFormData: VisitorFormData = {
      firstName: data.first_name,
      lastName: data.last_name,
      invitationSlug: invitation.slug,
      email: data.email,
      telephone: data.telephone,
      asset: data.asset,
      visitor_note: data.visitor_note,
      image: image,
    }
    try {
      await visitorService().submitVisitorForm(visitor.slug, visitorFormData)
      setIsLoading(false)
      if (
        invitation.status === InvitationStatus.not_confirm &&
        required_vaccine_record &&
        !invitation.online_link
      ) {
        window.location.href = `${vaccinesPath}/${visitor.slug}?invitation_id=${invitation.slug}`
      } else {
        window.location.href = `${visitorsPath}/${visitor.slug}?invitation_id=${invitation.slug}`
      }
    } catch (err) {
      if (axios.isAxiosError(err) && err.response) {
        setIsLoading(false)
        if (err.response.status === 404) {
          window.location.href = errorsNotFoundPath
        } else {
          window.location.reload()
        }
      }
    }
  }

  return (
    <VisitorLayout
      textNextBtn={t('views.components.layout.visitor.next')}
      isLoading={isLoading}
      form={'visitorRegistrationForm'}
      logoUrl={invitation.logo_url}
    >
      <VisitorForm
        onSubmit={handleSubmit(_handleSubmit)}
        register={register}
        visitor={visitor}
        invitation={invitation}
        handleUpload={(file: File) => setImage(file)}
        handleCancelUpload={() => setImage(undefined)}
      />
    </VisitorLayout>
  )
}

export default RegistrationPage
