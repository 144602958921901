// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".LogsPage-module__visitors___2Pzjm{padding-bottom:32px}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/logs/pages/LogsPage/LogsPage.module.scss"],"names":[],"mappings":"AAAA,mCACE,mBAAA","file":"LogsPage.module.scss","sourcesContent":[":local(.visitors) {\n  padding-bottom: 32px;\n}\n"]}]);
// Exports
exports.locals = {
	"visitors": "LogsPage-module__visitors___2Pzjm"
};
module.exports = exports;
