import React from 'react'

import ReactTooltip from 'react-tooltip'

type Props = {
  tooltip?: string
  onClick?: (e?: React.MouseEvent) => void
  className?: string
}

export const CircleClose = (props: Props) => {
  const { tooltip, onClick, className } = props
  return (
    <>
      <svg
        width="28"
        height="28"
        viewBox="0 0 29 28"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        data-tip={tooltip}
        onClick={onClick}
        className={className}
      >
        <circle cx="14" cy="14" r="10" fill="white" />
        <path d="M14.5 2.625C12.2502 2.625 10.051 3.29213 8.18039 4.54203C6.30978 5.79193 4.85182 7.56847 3.99088 9.64698C3.12993 11.7255 2.90467 14.0126 3.34357 16.2192C3.78248 18.4257 4.86584 20.4525 6.45667 22.0433C8.04749 23.6342 10.0743 24.7175 12.2809 25.1564C14.4874 25.5953 16.7745 25.3701 18.853 24.5091C20.9315 23.6482 22.7081 22.1902 23.958 20.3196C25.2079 18.449 25.875 16.2498 25.875 14C25.8715 10.9842 24.672 8.09298 22.5395 5.96051C20.407 3.82804 17.5158 2.62849 14.5 2.625ZM18.6187 16.8813C18.7 16.9626 18.7646 17.059 18.8087 17.1652C18.8528 17.2714 18.8755 17.3852 18.8756 17.5002C18.8756 17.6152 18.853 17.729 18.8091 17.8353C18.7651 17.9415 18.7006 18.038 18.6193 18.1193C18.538 18.2006 18.4415 18.2651 18.3353 18.3091C18.229 18.353 18.1152 18.3756 18.0002 18.3756C17.8852 18.3755 17.7714 18.3528 17.6652 18.3087C17.559 18.2646 17.4626 18.2 17.3814 18.1187L14.5 15.2373L11.6187 18.1187C11.4545 18.2824 11.2321 18.3744 11.0002 18.3742C10.7683 18.3741 10.546 18.282 10.382 18.118C10.218 17.954 10.1259 17.7317 10.1258 17.4998C10.1256 17.2679 10.2176 17.0455 10.3814 16.8813L13.2627 14L10.3814 11.1187C10.2176 10.9545 10.1256 10.7321 10.1258 10.5002C10.1259 10.2683 10.218 10.046 10.382 9.88201C10.546 9.71804 10.7683 9.62588 11.0002 9.62575C11.2321 9.62563 11.4545 9.71756 11.6187 9.88135L14.5 12.7627L17.3814 9.88135C17.5455 9.71756 17.7679 9.62563 17.9998 9.62575C18.2317 9.62588 18.454 9.71804 18.618 9.88201C18.782 10.046 18.8741 10.2683 18.8743 10.5002C18.8744 10.7321 18.7824 10.9545 18.6187 11.1187L15.7373 14L18.6187 16.8813Z" />
      </svg>
      <ReactTooltip effect="solid" />
    </>
  )
}
