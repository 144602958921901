import React, { useState } from 'react'

import classnames from 'classnames'

import { NumberCard, ScanQR } from '../../components'
import styles from './ScanQRPage.module.scss'
import { Button, ImagePreview, MobileLayout } from '@/components/shared'

import { invitationService } from '../../services'
import { scanQRPath } from '@/features/constants'
import { Invitation, Employee, Company } from '@/features/types'
import { getLocaleTimeString, t } from '@/lib/helpers'

type Props = {
  totalVisitor: number
  totalCheckIn: number
  totalCheckOut: number
  invitations: Invitation[]
  employee: Employee
  company: Company
  defaultScan?: boolean
}

const ScanQRPage = (props: Props) => {
  const {
    company,
    employee,
    invitations,
    defaultScan = false,
    totalVisitor,
    totalCheckIn,
    totalCheckOut,
  } = props
  const [showQRReaderModal, setShowQRReaderModal] =
    useState<boolean>(defaultScan)
  const [selectedIndex, setSelectedIndex] = useState<number>()

  const footer = (
    <div className={styles.footer}>
      <Button
        text={t('views.features.app.scan_qr.scan_qr_title')}
        icon="/assets/icons/qr-code.svg"
        onClick={() => setShowQRReaderModal(true)}
        size={'L'}
        className={styles.scanQRButton}
      />
    </div>
  )

  const handleCheckOut = async (invitation: Invitation) => {
    await invitationService().checkOut(invitation.id as string)
    window.location.href = scanQRPath
  }

  return (
    <MobileLayout
      company={company}
      employee={employee}
      footer={footer}
      className={styles.layout}
    >
      <div className={styles.numberCards}>
        <NumberCard
          number={totalVisitor}
          text={t('views.features.app.scan_qr.expected')}
          style="first"
          className={styles.numberCard}
        />
        <NumberCard
          number={totalCheckIn}
          text={t('views.features.app.scan_qr.checking_in')}
          style="second"
          className={styles.numberCard}
        />
        <NumberCard
          number={totalCheckOut}
          text={t('views.features.app.scan_qr.check_out')}
          style="third"
          className={styles.numberCard}
        />
      </div>
      <div className={styles.visitorsText}>
        {t('views.features.app.scan_qr.visitors')}
      </div>
      {invitations.map((invitation: Invitation, idx: number) => {
        const firstName = invitation.visitor_name?.split(' ')[0]
        const lastName = invitation.visitor_name?.split(' ').slice(1).join(' ')
        return (
          <div
            key={idx}
            onClick={() => invitation.check_in_at && setSelectedIndex(idx)}
            className={classnames(styles.invitation, {
              [styles.selectable]: invitation.check_in_at,
            })}
          >
            <ImagePreview
              src={
                invitation.visitor_image_url ||
                '/assets/icons/empty-display-image.svg'
              }
              width={48}
              height={48}
            />
            <div className={styles.visitorName}>
              <div>{firstName}</div>
              <div className={styles.lastName}>{lastName}</div>
            </div>
            <div className={styles.checkInAt}>
              {idx === selectedIndex ? (
                <Button
                  text={t('views.misc.check_out')}
                  style={'Secondary'}
                  onClick={() => handleCheckOut(invitation)}
                  className={styles.checkOutButton}
                />
              ) : invitation.check_in_at ? (
                getLocaleTimeString(invitation.check_in_at)
              ) : (
                <span className={styles.awaiting}>
                  {t('views.features.app.scan_qr.awaiting')}
                </span>
              )}
            </div>
          </div>
        )
      })}
      {showQRReaderModal && (
        <ScanQR
          onClose={() => setShowQRReaderModal(false)}
          handleCheckOut={handleCheckOut}
        />
      )}
    </MobileLayout>
  )
}

export default ScanQRPage
