import React from 'react'

import classnames from 'classnames'

import styles from './AtkActionPanel.module.scss'
import { Button } from '@/components/shared'

import { t } from '@/lib/helpers'

import { useAtkReportContext } from '../../context'

export const AtkActionPanel = () => {
  const { ids, selectMode, setTypeModal } = useAtkReportContext()
  const disabled = ids.size === 0
  return (
    <div
      className={classnames(styles.actionPanel, {
        [styles.showPanel]: selectMode && !disabled,
      })}
    >
      <Button
        text={t('views.misc.reject')}
        style={'Secondary'}
        size={'L'}
        onClick={() => setTypeModal('reject')}
        className={styles.rejectBtn}
      />
      <div className={styles.btnWrapper}>
        <Button
          text={t('views.misc.risk')}
          style={'Delete'}
          size={'L'}
          onClick={() => setTypeModal('risk')}
          className={styles.btn}
        />
        <Button
          text={t('views.misc.no_risk')}
          style={'Emerald'}
          size={'L'}
          onClick={() => setTypeModal('no_risk')}
          className={styles.btn}
        />
      </div>
    </div>
  )
}
