import React from 'react'

import styles from './Area.module.scss'
import { Pencil, Trash } from '@/components/icons'
import { Switch } from '@/components/shared'

import { Location, LocationStatus } from '@/features/types'

type Props = {
  idx: number
  area: Location
  handleUpdateStatus: (id: number, status: string, areaIdx: number) => void
  onEdit: (id: number, name: string, areaIndex: number) => void
  onDelete: (id: number, areaIndex: number) => void
}

export const Area = (props: Props) => {
  const { idx, area, handleUpdateStatus, onEdit, onDelete } = props
  const updatedStatus =
    area.status === LocationStatus.disable
      ? LocationStatus.enable
      : LocationStatus.disable
  return (
    <tr key={area.name} className={styles.rowArea}>
      <td className={styles.areaName}>{area.name}</td>
      <td className={styles.areaStatus}>
        <div className={styles.areaStatusBox}>
          <Switch
            onChange={() => handleUpdateStatus(area.id, updatedStatus, idx)}
            checked={area.status === LocationStatus.enable}
            width={42}
            height={26}
          />
        </div>
      </td>
      <td className={styles.areaTools}>
        {!area.default_location && (
          <div className={styles.areaToolsBox}>
            <div className={styles.areaPencilBox}>
              <div
                onClick={() => onEdit(area.id, area.name, idx)}
                className={styles.areaPencil}
              >
                <Pencil />
              </div>
            </div>
            <div className={styles.areaTrashBox}>
              <div
                onClick={() => onDelete(area.id, idx)}
                className={styles.areaTrash}
              >
                <Trash />
              </div>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}
