import React from 'react'

import classnames from 'classnames'

import { ImagePreview, ScreeningShield } from '..'
import styles from './VisitorDetail.module.scss'

import {
  CovidScreeningATKStatus,
  CovidScreeningFormStatus,
  Visitor,
} from '@/features/types'
import { maskText } from '@/lib/helpers'

export type VisitorInfo = {
  name: string
  email: string
  telephone: string
  image_url: string
}

type Props = {
  visitor?: Visitor
  visitorInfo?: VisitorInfo
  imageSize?: number
  showTelephone?: boolean
  borderRadius?: number
  masked?: boolean
  covidScreeningStatus?: CovidScreeningATKStatus | CovidScreeningFormStatus
  className?: string
}

export const VisitorDetail = (props: Props) => {
  const {
    visitor,
    visitorInfo,
    imageSize = 40,
    showTelephone = true,
    borderRadius = 10,
    masked = false,
    covidScreeningStatus,
    className,
  } = props

  let visitorDetail
  if (visitor?.first_name || visitorInfo?.name) {
    visitorDetail = (
      <div className={styles.visitorDetail}>
        <div className={styles.visitorNameSection}>
          <ScreeningShield screeningStatus={covidScreeningStatus} />
          <div
            className={classnames(styles.visitorName, {
              [styles.maskedName]: masked,
            })}
          >
            {maskText(
              visitorInfo?.name ||
                `${visitor?.first_name || ''} ${visitor?.last_name || ''}`,
              masked,
            )}
          </div>
        </div>
        <div className={styles.visitorEmailTel}>
          {maskText(visitor?.email || visitorInfo?.email || '', masked)}
        </div>
        {showTelephone && (
          <div className={styles.visitorEmailTel}>
            {maskText(
              visitor?.telephone || visitorInfo?.telephone || '',
              masked,
            )}
          </div>
        )}
      </div>
    )
  } else {
    visitorDetail = (
      <div className={styles.visitorDetail}>
        <div className={styles.visitorNameSection}>
          <div
            className={classnames(styles.visitorName, {
              [styles.maskedName]: masked,
            })}
          >
            {maskText(visitor?.email || visitorInfo?.email || '', masked)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={classnames(styles.background, className)}>
      <ImagePreview
        src={
          visitor?.image.url ||
          visitorInfo?.image_url ||
          '/assets/icons/empty-display-image.svg'
        }
        width={imageSize}
        height={imageSize}
        vaccineSlotVerified={visitor?.vaccine_slot_verified}
        borderRadius={borderRadius}
        className={styles.ImagePreview}
      />
      {visitorDetail}
    </div>
  )
}
