import React, { useEffect } from 'react'

import { toast } from 'react-hot-toast'

import { ToastProps, Toast as ToastDetail } from '@/components/shared'

const Toast = (props: ToastProps) => {
  const { message } = props
  useEffect(() => {
    if (message) toast(<ToastDetail {...props} />)
  }, [message])
  return null
}

export default Toast
