import React, { useEffect, useState } from 'react'

import { useForm } from 'react-hook-form'

import styles from './UserSignInPage.module.scss'
import {
  Button,
  CheckboxButtons,
  MobileLayout,
  TextField,
  UserLayout,
  userLayoutStyles,
} from '@/components/shared'

import { userServices } from '../../services'
import { forgotPasswordPath, policyPath, termsPath } from '@/features/constants'
import { t } from '@/lib/helpers'

type Props = {
  isSystemAdmin?: boolean
}

const USER_SIGN_IN_FORM = 'userSignInForm'

export const UserSignInPage = (props: Props) => {
  const { isSystemAdmin = false } = props
  const { handleSubmit, register } = useForm()
  const [width, setWindowWidth] = useState(0)
  const responsive = width > 690

  const _handleSubmit = async (data: any) => {
    await userServices().signIn({ ...data, isSystemAdmin })
  }

  useEffect(() => {
    updateDimensions()

    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const updateDimensions = () => {
    const width = window.innerWidth
    setWindowWidth(width)
  }

  const component = (
    <>
      <div className={userLayoutStyles.title}>
        {t('views.features.devise.sign_in.sign_in_header')}
      </div>
      <form id={USER_SIGN_IN_FORM} onSubmit={handleSubmit(_handleSubmit)}>
        <TextField
          legendTitle={t('views.features.devise.sign_in.email_title')}
          type={'email'}
          name={'email'}
          register={register}
          required
          autoComplete={'email'}
          className={userLayoutStyles.field}
        />
        <TextField
          legendTitle={t('views.features.devise.sign_in.password_title')}
          type={'password'}
          name={'password'}
          register={register}
          required
          autoComplete={'current-password'}
          className={userLayoutStyles.field}
        />
        <CheckboxButtons
          choices={[
            {
              title: t('views.features.devise.sign_in.remember_me'),
              value: 'remember',
            },
          ]}
          register={register}
          name={'remember'}
          className={styles.remember}
        />
      </form>
    </>
  )

  const mobileFooter = (
    <div className={styles.mobileFooter}>
      <Button
        text={t('views.features.devise.sign_in.sign_in_button')}
        size={'L'}
        form={USER_SIGN_IN_FORM}
        type="submit"
        className={styles.mobileLogInButton}
      />
      <div>
        <a href={termsPath} className={styles.footerLink}>
          {t('views.components.footer.terms')}
        </a>
        <span className={styles.andText}>{t('views.misc.and')}</span>
        <a href={policyPath} className={styles.footerLink}>
          {t('views.components.footer.policy')}
        </a>
      </div>
    </div>
  )

  return responsive ? (
    <UserLayout
      form={USER_SIGN_IN_FORM}
      textBtn={t('views.features.devise.sign_in.sign_in_button')}
      isSystemAdmin={isSystemAdmin}
      showSignUpPath
      showForgotPasswordPath
    >
      {component}
    </UserLayout>
  ) : (
    <MobileLayout footer={mobileFooter} className={styles.mobileLayout}>
      {component}
      <div className={styles.forgotPassword}>
        <a href={forgotPasswordPath} className={styles.forgotPasswordText}>
          {t('views.features.devise.user_layout.forgot_password_link')}
        </a>
      </div>
    </MobileLayout>
  )
}
