import { showToast } from '@/components/shared'

import { axios } from '@/lib/helpers'

export const connectionsServices = () => {
  return {
    disconnect: async (type: string) => {
      try {
        await axios.delete(`/connections/disconnect?type=${type}`, {})
        window.location.reload()
      } catch (err) {
        showToast({ type: 'alert', message: 'Fail to disconnect calendar' })
      }
    },
  }
}
