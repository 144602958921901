import React, { useEffect, useState } from 'react'

import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import { ImagePreview, EyeSwitch } from '..'
import styles from './CovidDetail.module.scss'

import {
  CovidScreeningATKStatus,
  CovidScreeningFormStatus,
  CovidScreeningType,
  Invitation,
  CovidScreeningStatus,
} from '@/features/types'
import { getLocaleDateString, t } from '@/lib/helpers'

type VaccinationSlotProps = {
  receivedSlot: number
}

const VaccinationSlot = (props: VaccinationSlotProps) => {
  const { receivedSlot = 0 } = props
  const defaultSlots = Math.max(3, receivedSlot)

  return (
    <div className={styles.vaccineSlots}>
      <div className={styles.slotTooltip}>
        {Array(receivedSlot)
          .fill(null)
          .map((_, idx) => (
            <img
              key={`received-${idx}`}
              src={'/assets/icons/vaccination-receive-slot.svg'}
              data-tip={t('views.features.visitor.edit.vaccinated.dose_order', {
                order: t('ordinalize', { count: idx + 1 }),
              })}
              className={styles.vaccineSlot}
            />
          ))}
        {Array(defaultSlots - receivedSlot)
          .fill(null)
          .map((_, idx) => (
            <img
              key={`empty-${idx}`}
              src={'/assets/icons/vaccination-empty-slot.svg'}
              className={styles.vaccineSlot}
            />
          ))}
      </div>
      <ReactTooltip effect="solid" />
    </div>
  )
}

const getStylesFromStatus = (screeningStatus?: CovidScreeningStatus) => {
  let colorStyle, bgColorStyle
  switch (screeningStatus) {
    case CovidScreeningATKStatus.negative:
    case CovidScreeningFormStatus.low_risk:
      colorStyle = styles.colorLowRisk
      bgColorStyle = styles.bgLowRisk
      break
    case CovidScreeningFormStatus.moderate_risk:
      colorStyle = styles.colorModerateRisk
      bgColorStyle = styles.bgModerateRisk
      break
    case CovidScreeningATKStatus.positive:
    case CovidScreeningFormStatus.high_risk:
      colorStyle = styles.colorHighRisk
      bgColorStyle = styles.bgHighRisk
      break
    case CovidScreeningATKStatus.not_verify:
      colorStyle = styles.colorNotVerify
      bgColorStyle = styles.bgNotVerify
      break
  }
  return [colorStyle, bgColorStyle]
}

type ScreeningResultProps = {
  invitation: Invitation
  showImage: boolean
  setShowImage: (showImage: boolean) => void
  covidScreeningType: CovidScreeningType
  className?: string
}

const ScreeningResult = (props: ScreeningResultProps) => {
  const {
    invitation,
    showImage = false,
    setShowImage,
    covidScreeningType,
    className,
  } = props

  const {
    covid_screening_image_url,
    covid_screening_form_status,
    covid_screening_atk_status,
    covid_screening_form_expired_at,
    covid_screening_atk_expired_at,
  } = invitation

  const [status, setStatus] = useState<CovidScreeningStatus | undefined>(
    covid_screening_form_status,
  )
  const [expiredAt, setExpiredAt] = useState<string | undefined>(
    covid_screening_form_expired_at,
  )

  useEffect(() => {
    if (covidScreeningType === 'atk') {
      setStatus(covid_screening_atk_status)
      setExpiredAt(covid_screening_atk_expired_at)
    } else {
      setStatus(covid_screening_form_status)
      setExpiredAt(covid_screening_form_expired_at)
    }
  }, [covidScreeningType])

  const [colorStyle, bgColorStyle] = getStylesFromStatus(status)
  const showEyeIcon =
    covidScreeningType === CovidScreeningType.atk && covid_screening_image_url
  return (
    <div className={classnames(styles.screeningResult, className)}>
      <div className={classnames(styles.screeningCircle, bgColorStyle)} />
      <span className={classnames(styles.screeningStatus, colorStyle)}>
        {status &&
          t(`views.features.invitations.components.covid_detail.${status}`)}
      </span>
      <span className={styles.screeningDate}>
        {expiredAt
          ? `(${t(
              'views.features.invitations.components.covid_detail.expired_at',
            )} ${getLocaleDateString(expiredAt, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })})`
          : '(-)'}
      </span>
      {showEyeIcon && (
        <EyeSwitch
          checked={!showImage}
          onClick={() => setShowImage(!showImage)}
          className={styles.ml8}
        />
      )}
    </div>
  )
}

type CovidDetailProps = {
  invitation: Invitation
  showModal?: boolean
  className?: string
}

export const CovidDetail = (props: CovidDetailProps) => {
  const { invitation, showModal = true, className } = props
  const {
    covid_screening_type,
    covid_screening_image_url,
    vaccine_slot_verified,
  } = invitation
  const [showImage, setShowImage] = useState<boolean>(false)
  const isScreeningBoth = covid_screening_type === CovidScreeningType.both
  const [selectedType, setSelectedType] = useState<CovidScreeningType>(() =>
    isScreeningBoth ? CovidScreeningType.form : covid_screening_type,
  )

  const getScreeningTitle = () => {
    switch (covid_screening_type) {
      case 'form':
        return t(
          'views.features.invitations.components.covid_detail.screening_form',
        )
      case 'atk':
        return t(
          'views.features.invitations.components.covid_detail.screening_atk',
        )
      case 'not_require':
      case 'either':
      case 'both':
        return (
          <span className={styles.links}>
            <span
              onClick={
                isScreeningBoth
                  ? () => {
                      setSelectedType(CovidScreeningType.form)
                      setShowImage(false)
                    }
                  : undefined
              }
              className={classnames({
                [styles.link]:
                  isScreeningBoth && selectedType === CovidScreeningType.atk,
              })}
            >
              {t(
                'views.features.invitations.components.covid_detail.screening_form',
              )}
            </span>
            /
            <span
              onClick={
                isScreeningBoth
                  ? () => setSelectedType(CovidScreeningType.atk)
                  : undefined
              }
              className={classnames({
                [styles.link]:
                  isScreeningBoth && selectedType === CovidScreeningType.form,
              })}
            >
              {t(
                'views.features.invitations.components.covid_detail.screening_atk',
              )}
            </span>
          </span>
        )
    }
  }

  useEffect(() => {
    if (!showModal) setShowImage(false)
  }, [showModal])

  return (
    <div className={classnames(styles.wrapper, className)}>
      <div className={styles.rowWrapper}>
        <div className={styles.section}>
          <span className={styles.title}>
            {t(
              'views.features.invitations.components.covid_detail.vaccine_record',
            )}
          </span>
          <VaccinationSlot receivedSlot={vaccine_slot_verified} />
        </div>
        <div className={classnames(styles.section, styles.screeningSection)}>
          <span className={styles.title}>{getScreeningTitle()}</span>
          <ScreeningResult
            showImage={showImage}
            setShowImage={setShowImage}
            covidScreeningType={
              isScreeningBoth ? selectedType : covid_screening_type
            }
            invitation={invitation}
            className={styles.mt4}
          />
        </div>
      </div>
      {showImage && (
        <div className={styles.imageWrapper}>
          <div className={styles.imageTitle}>
            {t(
              'views.features.invitations.components.covid_detail.screening_atk',
            )}
          </div>
          <div className={styles.imagePreview}>
            <ImagePreview
              src={covid_screening_image_url}
              width={464}
              height={464}
            />
          </div>
        </div>
      )}
    </div>
  )
}
