import React, { useState } from 'react'

import classnames from 'classnames'

import styles from './VisitorLayout.module.scss'
import { ApplicationLayout, Button, LocaleSwitch } from '@/components/shared'

import { t } from '@/lib/helpers'

type Props = {
  children?: React.ReactNode
  textNextBtn?: string
  onClickNextBtn?: () => void
  showEditBtn?: boolean
  onClickEditBtn?: () => void
  form?: string
  isLoading?: boolean
  className?: string
  applicationClassName?: string
  showBannerImage?: boolean
  disabledNextButton?: boolean
  showLocaleSwitch?: boolean
  logoUrl?: string
}

export const VisitorLayout = (props: Props) => {
  const {
    children,
    textNextBtn,
    onClickNextBtn,
    showEditBtn,
    onClickEditBtn,
    form = '',
    isLoading,
    className,
    applicationClassName,
    showBannerImage = true,
    disabledNextButton = false,
    showLocaleSwitch = true,
    logoUrl,
  } = props

  let editBtn, nextBtn
  if (showEditBtn && onClickEditBtn) {
    editBtn = (
      <Button
        text={t('views.components.layout.visitor.edit')}
        style={'Secondary'}
        className={classnames(styles.btn, styles.editBtn)}
        onClick={onClickEditBtn}
      />
    )
  }

  if (textNextBtn && (onClickNextBtn || form)) {
    nextBtn = (
      <Button
        type={form === '' ? 'button' : 'submit'}
        text={textNextBtn}
        style={'Primary'}
        className={styles.btn}
        onClick={onClickNextBtn}
        form={form}
        disabled={disabledNextButton}
      />
    )
  }

  const getLocale = () => {
    const localeIdx = document.cookie.indexOf('locale')
    if (localeIdx === -1) return 'th'

    return document.cookie.substring(
      localeIdx + 7,
      localeIdx + 9,
    ) as PlatformLanguage
  }

  const [locale, setLocale] = useState<PlatformLanguage>(getLocale())

  const handleChangeLocale = () => {
    const updateLang = locale === 'th' ? 'en' : 'th'
    const url = new URL(window.location.href)
    const invitation_id = url.searchParams.get('invitation_id')
    setLocale(updateLang)
    setTimeout(() => {
      window.location.href = `${window.location.pathname}?${
        invitation_id ? `invitation_id=${invitation_id}&` : ''
      }locale=${updateLang}`
    }, 500)
  }

  return (
    <ApplicationLayout
      isLoading={isLoading}
      logoUrl={logoUrl}
      className={applicationClassName}
    >
      <div className={classnames(styles.wrapper, className)}>
        <div className={styles.box}>
          {showBannerImage && (
            <img
              src={'/assets/images/visitor-banner.jpg'}
              className={styles.banner}
            />
          )}
          <div className={styles.content}>
            {showLocaleSwitch && (
              <LocaleSwitch
                locale={locale}
                onClick={handleChangeLocale}
                className={styles.localeSwitch}
              />
            )}
            {children}
          </div>
        </div>
        <div
          className={classnames(styles.btns, {
            [styles.hidden]: !editBtn && !nextBtn,
          })}
        >
          {editBtn}
          {nextBtn}
        </div>
      </div>
    </ApplicationLayout>
  )
}
