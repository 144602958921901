// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WalkInVisitorPolicy-module__wrapper___1yoKu{display:flex;flex-direction:column;max-width:480px;margin:0 auto;min-height:150px}.WalkInVisitorPolicy-module__checkBox___1y4w9{width:100%;display:flex;margin:auto;grid-column-gap:8px;-moz-column-gap:8px;column-gap:8px;margin-bottom:44px}.WalkInVisitorPolicy-module__checkBoxInput___2PIcZ{width:20px;height:20px}.WalkInVisitorPolicy-module__detail___169Pn{width:100%}.WalkInVisitorPolicy-module__btn___Xu-iH{width:100%;margin-top:auto}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/walk_ins/components/WalkInVisitorPolicy/WalkInVisitorPolicy.module.scss"],"names":[],"mappings":"AAAA,6CACE,YAAA,CACA,qBAAA,CACA,eAAA,CACA,aAAA,CACA,gBAAA,CAGF,8CACE,UAAA,CACA,YAAA,CACA,WAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,cAAA,CACA,kBAAA,CAGF,mDACE,UAAA,CACA,WAAA,CAGF,4CACE,UAAA,CAGF,yCACE,UAAA,CACA,eAAA","file":"WalkInVisitorPolicy.module.scss","sourcesContent":[":local(.wrapper) {\n  display: flex;\n  flex-direction: column;\n  max-width: 480px;\n  margin: 0 auto;\n  min-height: 150px;\n}\n\n:local(.checkBox) {\n  width: 100%;\n  display: flex;\n  margin: auto;\n  column-gap: 8px;\n  margin-bottom: 44px;\n}\n\n:local(.checkBoxInput) {\n  width: 20px;\n  height: 20px;\n}\n\n:local(.detail) {\n  width: 100%;\n}\n\n:local(.btn) {\n  width: 100%;\n  margin-top: auto;\n}\n"]}]);
// Exports
exports.locals = {
	"wrapper": "WalkInVisitorPolicy-module__wrapper___1yoKu",
	"checkBox": "WalkInVisitorPolicy-module__checkBox___1y4w9",
	"checkBoxInput": "WalkInVisitorPolicy-module__checkBoxInput___2PIcZ",
	"detail": "WalkInVisitorPolicy-module__detail___169Pn",
	"btn": "WalkInVisitorPolicy-module__btn___Xu-iH"
};
module.exports = exports;
