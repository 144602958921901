import React from 'react'

import classnames from 'classnames'

import styles from './Modal.module.scss'

interface IModal {
  show: boolean
  onClose?: () => void
  title?: string
  showCloseButton?: string
  children?: React.ReactNode
  className?: string
}

export const Modal = (props: IModal) => {
  const { show, onClose, title, children, className } = props
  return (
    <div
      onClick={onClose}
      className={classnames(className, styles.background, {
        [styles.notShow]: !show,
      })}
    >
      <div onClick={(e) => e.stopPropagation()} className={styles.content}>
        <div className={styles.titleSection}>
          <div className={styles.title}>{title}</div>
          <div onClick={onClose} className={styles.closeButton}>
            <img src={'/assets/icons/close.svg'} />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
