import React from 'react'

import classnames from 'classnames'

import { SortableTitle, SortOptions } from '..'
import styles from './Table.module.scss'

export type Column = {
  column?: string
  title: string
  className?: string
}

type Props = {
  columns: Column[]
  data: React.ReactNode[]
  sortOptions?: SortOptions
  handleSort: (newSort?: string, newDirection?: string) => void
  emptyState?: string
  clickableRow?: boolean
  className?: string
}

export const Table = (props: Props) => {
  const {
    columns,
    data,
    sortOptions,
    handleSort,
    emptyState,
    clickableRow = false,
    className,
  } = props
  const tableHeads = columns.map((column: Column, idx: number) => {
    if (column.column) {
      return (
        <th
          className={classnames(
            { [styles.firstColumn]: idx === 0 },
            column.className,
          )}
          key={idx}
        >
          <SortableTitle
            options={sortOptions}
            handleSort={handleSort}
            column={column.column}
            title={column.title}
            showSortRightSide={idx === 0}
          />
        </th>
      )
    } else {
      const { className, title } = column
      return (
        <th className={className} key={idx}>
          <div className={styles.title}>{title}</div>
        </th>
      )
    }
  })

  const isEmptyState = data.length === 0
  const emptyStateUI = (
    <tr className={styles.emptyState}>
      <td colSpan={columns.length}>{emptyState}</td>
    </tr>
  )

  return (
    <div className={styles.background}>
      <table
        className={classnames(
          styles.table,
          { [styles.h100]: isEmptyState },
          className,
        )}
      >
        <thead>
          <tr className={styles.columnName}>{tableHeads}</tr>
        </thead>
        <tbody
          className={classnames({
            [styles.clickableRow]: clickableRow,
            [styles.h100]: isEmptyState,
          })}
        >
          {isEmptyState ? emptyStateUI : data}
        </tbody>
      </table>
    </div>
  )
}
