import React from 'react'

import classnames from 'classnames'

import styles from './NumberCard.module.scss'

export type NumberCardStyle = 'first' | 'second' | 'third'

type Props = {
  number: number
  text: string
  style: NumberCardStyle
  className?: string
}

export const NumberCard = (props: Props) => {
  const { number, text, style, className } = props

  const numberStyle = (style: NumberCardStyle) => {
    switch (style) {
      case 'first':
        return styles.first
      case 'second':
        return styles.second
      default:
        return styles.third
    }
  }

  return (
    <div className={classnames(styles.background, className)}>
      <div className={classnames(styles.number, numberStyle(style))}>
        {number}
      </div>
      <div className={styles.text}>{text}</div>
    </div>
  )
}
