import React from 'react'

import classnames from 'classnames'

import styles from './Logs.module.scss'

import { ActionLog as ActionLogType } from '@/features/types'
import { getLocaleDateString, getLocaleTimeString } from '@/lib/helpers'

type Props = {
  log: ActionLogType
}

export const ActionLog = (props: Props) => {
  const { log } = props

  const { description, email, created_at } = log
  return (
    <tr className={styles.deliveryLogText}>
      <td>
        <div className={classnames(styles.textEllipsis, styles.actionText)}>
          {description}
        </div>
      </td>
      <td className={styles.textEllipsis}>{email}</td>
      <td>
        <div>{getLocaleDateString(created_at)}</div>
        <div className={classnames(styles.timeText)}>
          {getLocaleTimeString(created_at)}
        </div>
      </td>
    </tr>
  )
}
