import React, { useState } from 'react'

import { useForm } from 'react-hook-form'

import { VaccineLogs, VaccineLogsFilter } from '../../components'
import styles from './VaccineLogsPage.module.scss'
import { ApplicationLayout, Search, SortOptions } from '@/components/shared'

import { vaccineLogsPath } from '@/features/constants/systemAdmin'
import { SystemAdmin } from '@/features/types'
import { formatDate } from '@/lib/helpers'

type Props = {
  systemAdmin: SystemAdmin
  vaccine_logs: any
  defaultSearch?: string
  defaultSort?: string
  defaultSortDirection?: string
  defaultType?: string
  defaultFromDate?: string
  defaultToDate?: string
}

const VaccineLogsPage = (props: Props) => {
  const {
    systemAdmin,
    vaccine_logs,
    defaultSearch = '',
    defaultSort = '',
    defaultSortDirection = '',
    defaultType = '',
    defaultFromDate,
    defaultToDate,
  } = props
  const [search, setSearch] = useState<string>(defaultSearch || '')
  const { control, watch } = useForm({
    defaultValues: {
      fromDate: defaultFromDate || undefined,
      toDate: defaultToDate || undefined,
    },
  })

  const sortOptions: SortOptions = {
    sort: defaultSort,
    direction: defaultSortDirection,
  }

  const getSearchParams = (params: any) => {
    const { newSort, newDirection, newType } = params
    const sort = newSort || sortOptions.sort || ''
    const direction = newDirection || sortOptions.direction || ''
    const type = newType || defaultType || 'All'
    const fromDate = formatDate(watch('fromDate'))
    const toDate = formatDate(watch('toDate'))
    const paramsObj = {
      from_date: fromDate,
      to_date: toDate,
      search,
      sort,
      sort_direction: direction,
      type,
    }
    const searchParams = new URLSearchParams(paramsObj)
    return searchParams.toString()
  }

  const handleQuery = (params?: any) => {
    window.location.href = `${vaccineLogsPath}?${getSearchParams(params || {})}`
  }

  const handleSort = (newSort?: string, newDirection?: string) => {
    return handleQuery({ newSort, newDirection })
  }

  return (
    <ApplicationLayout systemAdmin={systemAdmin}>
      <Search
        search={search}
        setSearch={setSearch}
        date
        control={control}
        handleQuery={handleQuery}
        extraFilterComponent={
          <VaccineLogsFilter
            control={control}
            defaultType={defaultType}
            handleQuery={handleQuery}
          />
        }
      />
      <VaccineLogs
        vaccineLogs={vaccine_logs}
        sortOptions={sortOptions}
        handleSort={handleSort}
        className={styles.visitors}
      />
    </ApplicationLayout>
  )
}

export default VaccineLogsPage
