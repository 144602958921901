import React, { useState } from 'react'

import {
  IntegrationConnectionProps,
  IntegrationLayout,
} from '@/components/shared'

import { connectionsServices } from '../services'
import { microsoftAuthPath } from '@/features/constants'
import { Employee, ConnectionsType } from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  employee: Employee
  urlGoogleAuth: string
  urlZoomAuth: string
  urlSlackAuth: string
}

const CalendarConnectionPage = (props: Props) => {
  const { employee, urlGoogleAuth, urlZoomAuth, urlSlackAuth } = props
  const [showModal, setShowModal] = useState<boolean>(false)
  const [connectedEmail, setConnectedEmail] = useState<string>('')
  const [type, setType] = useState<ConnectionsType>()

  const onClickDisconnect = (email: string) => {
    setConnectedEmail(email)
    setShowModal(true)
  }

  const integrationConnections: IntegrationConnectionProps[] = [
    {
      logoName: 'google-logo',
      title: 'Google',
      description: t('views.features.connections.index.google_description'),
      email: employee.google_email,
      onConnect: () => {
        window.location.href = urlGoogleAuth
      },
      onDisconnect: () => {
        setType('google')
        onClickDisconnect(employee.google_email)
      },
    },
    {
      logoName: 'microsoft-logo',
      title: 'Office 365',
      description: t('views.features.connections.index.microsoft_description'),
      email: employee.microsoft_email,
      onConnect: () => {
        window.location.href = microsoftAuthPath
      },
      onDisconnect: () => {
        setType('microsoft')
        onClickDisconnect(employee.microsoft_email)
      },
    },
    {
      logoName: 'zoom-logo',
      title: 'Zoom',
      description: t('views.features.connections.index.zoom_description'),
      email: employee.zoom_email,
      onConnect: () => {
        window.location.href = urlZoomAuth
      },
      onDisconnect: () => {
        setType('zoom')
        onClickDisconnect(employee.zoom_email)
      },
    },
    {
      logoName: 'slack-logo',
      title: 'Slack',
      description: t('views.features.connections.index.slack_description'),
      email: employee.slack_email,
      onConnect: () => {
        window.location.href = urlSlackAuth
      },
      onDisconnect: () => {
        setType('slack')
        onClickDisconnect(employee.slack_email)
      },
    },
  ]

  return (
    <IntegrationLayout
      employee={employee}
      title={t('views.features.connections.index.title')}
      sectionHeader={t('views.features.connections.index.integrations')}
      sectionDescription={t('views.features.connections.index.description')}
      titleModal={t('views.features.connections.index.disconnect_title')}
      descriptionModal={t(
        'views.features.connections.index.disconnect_description',
        { email: connectedEmail },
      )}
      showModal={showModal}
      onCloseModal={() => setShowModal(false)}
      onConfirmModal={() => {
        type && connectionsServices().disconnect(type)
        setShowModal(false)
      }}
      integrationConnections={integrationConnections}
    ></IntegrationLayout>
  )
}

export default CalendarConnectionPage
