import React, { useState } from 'react'

import classnames from 'classnames'

import styles from './Invitations.module.scss'
import { Button, showToast, Spinner, VisitorDetail } from '@/components/shared'

import { invitationService } from '../../services'
import {
  CovidScreeningFormStatus,
  CovidScreeningStatus,
  Invitation as InvitationType,
  Visitor,
} from '@/features/types'
import { getLocaleTimeString, t } from '@/lib/helpers'

type Props = {
  invitation: InvitationType
  onClick: (invitationID: string) => void
  showPending?: boolean
  onCheckIn?: () => void
  onCheckOut?: () => void
  covidScreeningStatus?: CovidScreeningStatus
}

export const Invitation = (props: Props) => {
  const {
    invitation,
    onClick,
    showPending,
    onCheckIn,
    onCheckOut,
    covidScreeningStatus = CovidScreeningFormStatus.not_screening,
  } = props

  const {
    id,
    asset,
    check_in_at,
    check_out_at,
    start_datetime,
    finish_datetime,
    visitor_image_url,
    visitor_name,
    visitor_email,
    invite,
    host_employee_name,
    area_to_access,
    vaccine_slot_verified,
    visitor_slug,
    online_link,
  } = invitation

  const eventTime = `${getLocaleTimeString(
    start_datetime,
  )} - ${getLocaleTimeString(finish_datetime)}`
  const [checkInAt, setCheckInAt] = useState<Date | undefined>(check_in_at)
  const [checkOutAt, setCheckOutAt] = useState<Date | undefined>(check_out_at)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleInvitation = (
    e: React.MouseEvent<HTMLButtonElement>,
    option?: any,
  ) => {
    e.stopPropagation()
    const { status, isCheckIn } = option
    if (status && status !== '') {
      invitationService()
        .updateInvitationStatus(id.toString(), status)
        .then(() => window.location.reload())
    } else if (isCheckIn) {
      setIsLoading(true)
      invitationService()
        .checkIn(id.toString())
        .then(({ data: { check_in_at } }) => {
          setCheckInAt(check_in_at)
          showToast({
            type: 'success',
            message: t(
              'views.features.invitations.dashboard.successfully_checked_in',
            ),
          })
          setIsLoading(false)
        })
    } else {
      setIsLoading(true)
      invitationService()
        .checkOut(id.toString())
        .then(({ data: { check_out_at } }) => {
          setCheckOutAt(check_out_at)
          showToast({
            type: 'success',
            message: t(
              'views.features.invitations.dashboard.successfully_checked_out',
            ),
          })
          setIsLoading(false)
        })
    }
  }

  const checkInAtOrAwaiting = () => {
    if (checkInAt)
      return (
        <div className={styles.checkInOut}>
          <img src="/assets/icons/location.svg" height={20} />
          <div className={styles.checkInOutTime}>
            {getLocaleTimeString(checkInAt)}
          </div>
        </div>
      )

    return <div className={styles.awaiting}>{t('views.misc.awaiting')}</div>
  }

  const handleCheckIn = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleInvitation(e, {
      status: showPending ? 'approve' : '',
      isCheckIn: true,
    })
    onCheckIn && onCheckIn()
  }

  const handleCheckOut = (e: React.MouseEvent<HTMLButtonElement>) => {
    handleInvitation(e, {
      status: showPending ? 'reject' : '',
      isCheckIn: false,
    })
    onCheckOut && onCheckOut()
  }

  const checkOutAtOrButton = () => {
    if (isLoading) {
      return <Spinner height={40} width={40} />
    }
    if (checkOutAt)
      return (
        <div className={styles.checkInOut}>
          <img src="/assets/icons/exit.svg" height={20} />
          <div className={styles.checkInOutTime}>
            {getLocaleTimeString(checkOutAt)}
          </div>
        </div>
      )
    if (checkInAt || showPending)
      return (
        <Button
          text={
            showPending ? t('views.misc.decline') : t('views.misc.check_out')
          }
          style={'Secondary'}
          className={styles.checkInOutButton}
          onClick={handleCheckOut}
        />
      )

    return (
      <Button
        text={showPending ? t('views.misc.approve') : t('views.misc.check_in')}
        className={styles.checkInOutButton}
        onClick={handleCheckIn}
      />
    )
  }

  const visitor: Visitor = {
    id: 0,
    first_name: visitor_name || '',
    last_name: '',
    email: visitor_email || '',
    image: {
      url: visitor_image_url || '',
    },
    telephone: '',
    slug: visitor_slug || '',
    vaccine_slot_verified: vaccine_slot_verified,
  }

  return (
    <tr onClick={() => onClick(id.toString())} className={styles.invitation}>
      <td className={styles.visitorInfo}>
        <VisitorDetail
          visitor={visitor}
          imageSize={60}
          showTelephone={false}
          borderRadius={6}
          covidScreeningStatus={covidScreeningStatus}
          className={styles.visitorDetail}
        />
      </td>
      <td className={styles.eventAndHost}>
        <div className={styles.textEllipsis}>{invite.title}</div>
        <div className={classnames(styles.textEllipsis, styles.hostText)}>
          {host_employee_name || '-'}
        </div>
      </td>
      <td className={classnames(styles.textEllipsis, styles.eventTime)}>
        {eventTime}
      </td>
      <td className={styles.areaAndAsset}>
        <div className={styles.textEllipsis}>
          {area_to_access || online_link}
        </div>
        <div className={classnames(styles.textEllipsis, styles.assetText)}>
          {asset || '-'}
        </div>
      </td>
      <td className={styles.centerColumn}>{checkInAtOrAwaiting()}</td>
      <td className={styles.centerColumn}>{checkOutAtOrButton()}</td>
    </tr>
  )
}
