import React from 'react'

type Props = {
  src?: string
  className?: string
}

export const GoogleMapIframe = (props: Props) => {
  const { src, className } = props

  return (
    <>
      {src && (
        <iframe
          src={src}
          width="100%"
          height="100%"
          loading="lazy"
          style={{ border: 'none' }}
          className={className}
        ></iframe>
      )}
    </>
  )
}

export const getGoogleMapEmbeddedLink = (
  latitude: number,
  longitude: number,
) => {
  return `https://www.google.com/maps?q=${latitude},${longitude}&hl=es;z%3D14&amp&output=embed`
}

export const googleMapURLToLatLng = (googleMapURL: any) => {
  const matchedLatitude = googleMapURL.match(/!3d[-]?\d+.\d+/)
  const matchedLongitude = googleMapURL.match(/!4d[-]?\d+.\d+/)
  if (matchedLatitude && matchedLongitude) {
    const latitude = matchedLatitude[0].replace('!3d', '')
    const longitude = matchedLongitude[0].replace('!4d', '')
    return [latitude, longitude]
  }

  try {
    const url = new URL(googleMapURL)
    const paramsQ = url.searchParams.get('q')
    if (paramsQ) {
      const [latitude, longitude] = paramsQ.split(',')
      return [latitude, longitude]
    }
  } catch (e) {
    return false
  }
  return false
}

export const validateLatLng = (lat: number, lng: number) => {
  const validateLat = -90 <= lat && lat <= 90
  const validateLng = -180 <= lng && lng <= 180
  return validateLat && validateLng
}
