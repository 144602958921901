import React from 'react'

import QrReader from 'react-qr-reader'

import styles from './ScanQR.module.scss'
import { Button, showToast } from '@/components/shared'

import { invitationService } from '../../services'
import { confirmCheckPath } from '@/features/constants'
import { Invitation } from '@/features/types'
import { t, useDebounce } from '@/lib/helpers'

type Props = {
  onClose: () => void
  handleCheckOut: (invitation: Invitation) => void
}

export const ScanQR = (props: Props) => {
  const { onClose, handleCheckOut } = props
  const onScan = (data: string | null) => {
    if (!data) return

    invitationService()
      .scanQR(data)
      .then((response: any) => {
        const invitation = response.data
        if (invitation.check_out_at) {
          onInvalidQRCode()
        } else if (invitation.check_in_at) {
          handleCheckOut(invitation)
        } else {
          window.location.href = `${confirmCheckPath}/${invitation.slug}`
        }
      })
      .catch(() => {
        onInvalidQRCode()
      })
  }

  const onError = (err: any) => {
    console.error(err)
  }

  const onInvalidQRCode = useDebounce(() => {
    showToast({ type: 'alert', message: t('views.features.app.invalid_qr') })
  })

  return (
    <div className={styles.wrapper}>
      <div className={styles.qrReader}>
        <QrReader
          onError={onError}
          onScan={onScan}
          showViewFinder={false}
          className={styles.qrReader}
        />
      </div>
      <div className={styles.background}>
        <div className={styles.scanText}>
          {t('views.features.app.scan_qr.scan_qr_title')}
        </div>
        <div className={styles.qrBoxWrapper}>
          <img src="/assets/images/qr-border.svg" className={styles.qrBorder} />
          <div className={styles.qrBox} />
        </div>
        <Button
          text={t('views.features.app.scan_qr.scan_qr_cancel')}
          style={'Secondary'}
          onClick={onClose}
          size={'L'}
          className={styles.cancelButton}
        />
      </div>
    </div>
  )
}
