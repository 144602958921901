import React from 'react'

import { Control } from 'react-hook-form'

import styles from './VaccineLogsFilter.module.scss'
import { Select } from '@/components/shared'

import { vaccineLogsTypeOptions } from '@/features/constants'

type Props = {
  control: Control<any>
  defaultType?: string
  handleQuery: (params: any) => void
}

export const VaccineLogsFilter = (props: Props) => {
  const { control, defaultType, handleQuery } = props

  return (
    <Select
      name="filterType"
      control={control}
      options={vaccineLogsTypeOptions}
      onChange={(value: string) => handleQuery({ newType: value })}
      className={styles.select}
      defaultValue={defaultType || 'All'}
    />
  )
}
