// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".WalkInScreeningForm-module__formArea___3kcWk{position:relative;display:flex;width:100%;max-width:480px;margin:0 auto;height:196px}.WalkInScreeningForm-module__mr16___WRf8J{margin-right:16px}@keyframes WalkInScreeningForm-module__capture___1eFKU{0%{opacity:0}1%{opacity:1}100%{opacity:1}}.WalkInScreeningForm-module__checkboxes___3g7L1{display:flex}.WalkInScreeningForm-module__checkbox___3PJV-{width:50%}.WalkInScreeningForm-module__submitButton___3vuj8{margin-top:16px;width:100%}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/walk_ins/components/WalkInScreeningForm/WalkInScreeningForm.module.scss"],"names":[],"mappings":"AAEA,8CACE,iBAAA,CACA,YAAA,CACA,UAAA,CACA,eAAA,CACA,aAAA,CACA,YAAA,CAGF,0CACE,iBAAA,CAGF,uDACE,GACE,SAAA,CAEF,GACE,SAAA,CAEF,KACE,SAAA,CAAA,CAIJ,gDACE,YAAA,CAGF,8CACE,SAAA,CAGF,kDACE,eAAA,CACA,UAAA","file":"WalkInScreeningForm.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.formArea) {\n  position: relative;\n  display: flex;\n  width: 100%;\n  max-width: 480px;\n  margin: 0 auto;\n  height: 196px;\n}\n\n:local(.mr16) {\n  margin-right: 16px;\n}\n\n@keyframes capture {\n  0% {\n    opacity: 0;\n  }\n  1% {\n    opacity: 1;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n:local(.checkboxes) {\n  display: flex;\n}\n\n:local(.checkbox) {\n  width: 50%;\n}\n\n:local(.submitButton) {\n  margin-top: 16px;\n  width: 100%;\n}\n"]}]);
// Exports
exports.locals = {
	"formArea": "WalkInScreeningForm-module__formArea___3kcWk",
	"mr16": "WalkInScreeningForm-module__mr16___WRf8J",
	"checkboxes": "WalkInScreeningForm-module__checkboxes___3g7L1",
	"checkbox": "WalkInScreeningForm-module__checkbox___3PJV-",
	"submitButton": "WalkInScreeningForm-module__submitButton___3vuj8",
	"capture": "WalkInScreeningForm-module__capture___1eFKU"
};
module.exports = exports;
