import React, { ReactNode } from 'react'

import classnames from 'classnames'
import { Control } from 'react-hook-form'

import { Button, DateField, TimeField } from '..'
import styles from './Search.module.scss'

import { t } from '@/lib/helpers'

type Props = {
  search: string
  setSearch: (search: string) => void
  handleQuery: () => void
  date?: boolean
  control?: Control<any>
  withSearchButton?: boolean
  className?: string
  extraFilterComponent?: ReactNode
  time?: boolean
  defaultFromTime?: string
  defaultToTime?: string
  withClearButton?: boolean
}

export const Search = (props: Props) => {
  const {
    search,
    setSearch,
    handleQuery,
    date = false,
    control,
    withSearchButton = true,
    className,
    extraFilterComponent,
    time,
    defaultFromTime,
    defaultToTime,
    withClearButton = false,
  } = props

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == 'Enter') handleQuery()
  }

  const disabledDate = (current: any) => {
    // Can not select days after today
    return current && new Date(current).getTime() > new Date().getTime()
  }

  const filterDateComponent = date && control && (
    <div className={styles.dateWrapper}>
      <DateField
        control={control}
        name="fromDate"
        disabledDate={disabledDate}
        className={styles.datetime}
      />
      <span className={styles.dateTo}>{t('views.components.search.to')}</span>
      <DateField
        control={control}
        name="toDate"
        disabledDate={disabledDate}
        className={styles.datetime}
      />
    </div>
  )

  const filterTimeComponent = time && control && !date && (
    <div className={styles.dateWrapper}>
      <TimeField
        control={control}
        name="fromTime"
        defaultValue={defaultFromTime}
        className={styles.time}
      />
      <span className={styles.dateTo}>to</span>
      <TimeField
        control={control}
        name="toTime"
        defaultValue={defaultToTime}
        className={styles.time}
      />
    </div>
  )

  const isOneLine = !date && !extraFilterComponent

  const searchButton = withSearchButton && (
    <Button
      text={t('views.components.search.search')}
      size={'L'}
      className={styles.searchSubmit}
      onClick={() => handleQuery()}
    />
  )

  const clearButton = withClearButton && (
    <Button
      text={t('views.components.search.clear_all')}
      size={'L'}
      style={'Link'}
      onClick={() => (window.location.href = window.location.pathname)}
    />
  )

  return (
    <div
      className={classnames(
        styles.wrapper,
        { [styles.flexColumn]: !isOneLine },
        className,
      )}
    >
      <div
        className={classnames(styles.search, {
          [styles.fullWidth]: !date,
          [styles.searchWithTime]: time,
        })}
      >
        <img src="/assets/icons/search.svg" className={styles.searchIcon} />
        <input
          value={search}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
          placeholder={t('views.components.search.search_keyword')}
          onKeyDown={handleSearchKeyDown}
          className={styles.searchText}
        />
      </div>
      {(extraFilterComponent ||
        filterDateComponent ||
        filterTimeComponent ||
        clearButton ||
        searchButton) && (
        <div
          className={classnames({
            [styles.filter]: !isOneLine,
            [styles.timeFilter]: time,
          })}
        >
          {extraFilterComponent}
          {filterDateComponent}
          {filterTimeComponent}
          {clearButton}
          {searchButton}
        </div>
      )}
    </div>
  )
}
