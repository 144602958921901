import { createContext, Dispatch, SetStateAction, useContext } from 'react'

import { AtkReport, AtkReportActionType } from '@/features/types'

type AtkReportContextProps = {
  atkReports: { [key: string]: AtkReport[] }
  selectMode: boolean
  ids: Set<string>
  setIDs: Dispatch<SetStateAction<Set<string>>>
  setTypeModal: Dispatch<SetStateAction<AtkReportActionType>>
  isIdsExist: (ids: string[]) => boolean
  handleSelect: (ids: string[]) => void
  onRemoveAtkReport: () => void
  lastDeleteIDs: string[]
  getInvitation: (invitationID: string) => void
}

export const AtkReportContext = createContext<AtkReportContextProps>({
  atkReports: {},
  selectMode: false,
  ids: new Set(),
  setIDs: () => {},
  setTypeModal: () => {},
  isIdsExist: () => false,
  handleSelect: () => {},
  onRemoveAtkReport: () => {},
  lastDeleteIDs: [],
  getInvitation: () => {},
})

export const useAtkReportContext = () => {
  const context = useContext(AtkReportContext)
  if (context === undefined) {
    throw new Error('useAtkReportContext must be used within a Provider')
  }
  return context
}
