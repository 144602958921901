import React from 'react'

import classnames from 'classnames'

import styles from './Header.module.scss'

import { headerService } from '../services'
import {
  rootPath,
  signInPath,
  signOutPath,
  systemAdminsPath,
  vaccineLogsPath,
} from '@/features/constants/systemAdmin'
import { SystemAdmin } from '@/features/types'

import { Dropdown, ImagePreview } from '../..'

type Props = {
  systemAdmin?: SystemAdmin
}

export const SystemAdminHeader = (props: Props) => {
  const { systemAdmin } = props
  const current_path = window.location.pathname

  let rightSection
  let navbar

  if (systemAdmin && !current_path.includes(signInPath)) {
    const dropDownMenus = [
      {
        label: 'Sign out',
        onClick: () => headerService().signOut(signOutPath),
        className: styles.signOut,
      },
    ]
    rightSection = (
      <Dropdown menus={dropDownMenus} className={styles.userDropdown}>
        <div className={classnames(styles.navbarUser)}>
          <div className={classnames(styles.profile, styles.adminProfile)}>
            <span>
              {systemAdmin.first_name} {systemAdmin.last_name}
            </span>
            <span className={classnames(styles.role, styles.adminRole)}>
              Admin
            </span>
          </div>
          <ImagePreview
            src={
              systemAdmin.image?.url || '/assets/icons/empty-display-image.svg'
            }
            width={40}
            height={40}
            className={styles.image}
          />
        </div>
      </Dropdown>
    )

    const menus = [
      {
        label: 'User Management',
        onClick: () => (window.location.href = systemAdminsPath),
      },
      {
        label: 'Vaccine log',
        onClick: () => (window.location.href = vaccineLogsPath),
      },
    ]

    navbar = (
      <div className={styles.navbar}>
        <div
          onClick={() => (window.location.href = rootPath)}
          className={classnames(styles.navbarButton, styles.adminNavbarButton, {
            [styles.highlightNavbarButton]: [rootPath].some(
              (path) => current_path === path,
            ),
          })}
        >
          Home
        </div>
        <Dropdown
          menus={menus}
          className={classnames(
            styles.navbarButton,
            styles.adminNavbarButton,
            styles.settings,
          )}
        >
          Settings
        </Dropdown>
      </div>
    )
  }

  return (
    <div className={classnames(styles.bg, styles.systemAdminHeader)}>
      <div className={styles.wrapper}>
        <div
          className={styles.adminLogo}
          onClick={() => (window.location.href = rootPath)}
        >
          <img src="/assets/icons/logo-color.svg" height={46} />
        </div>
        <div className={styles.adminLogoText}>Admin</div>
        {navbar}
        <div className={styles.rightSection}>{rightSection}</div>
      </div>
    </div>
  )
}
