// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".AreaModal-module__modal___1N8rh>div{width:80%;max-width:464px;min-width:-moz-fit-content;min-width:fit-content;max-height:224px}.AreaModal-module__description___2a6nv{color:gray;font-weight:500;font-size:14px;line-height:24px}.AreaModal-module__form___3pGJG{margin:24px 0px}.AreaModal-module__btns___3-11x{margin-top:24px;width:100%;display:flex;justify-content:flex-end}.AreaModal-module__btn___RQkad{width:100px}.AreaModal-module__ml8___3TP7f{margin-left:8px}.AreaModal-module__mt48___25LH0{margin-top:48px}", "",{"version":3,"sources":["/usr/src/app/app/javascript/features/companies/components/AreaModal/AreaModal.module.scss"],"names":[],"mappings":"AAGE,qCACE,SAAA,CACA,eAAA,CACA,0BAAA,CAAA,qBAAA,CACA,gBAAA,CAIJ,uCACE,UAAA,CACA,eAAA,CACA,cAAA,CACA,gBAAA,CAGF,gCACE,eAAA,CAGF,gCACE,eAAA,CACA,UAAA,CACA,YAAA,CACA,wBAAA,CAGF,+BACE,WAAA,CAGF,+BACE,eAAA,CAGF,gCACE,eAAA","file":"AreaModal.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.modal) {\n  > div {\n    width: 80%;\n    max-width: 464px;\n    min-width: fit-content;\n    max-height: 224px;\n  }\n}\n\n:local(.description) {\n  color: $anyvisit-dark-ii-v;\n  font-weight: 500;\n  font-size: 14px;\n  line-height: 24px;\n}\n\n:local(.form) {\n  margin: 24px 0px;\n}\n\n:local(.btns) {\n  margin-top: 24px;\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n}\n\n:local(.btn) {\n  width: 100px;\n}\n\n:local(.ml8) {\n  margin-left: 8px;\n}\n\n:local(.mt48) {\n  margin-top: 48px;\n}\n"]}]);
// Exports
exports.locals = {
	"modal": "AreaModal-module__modal___1N8rh",
	"description": "AreaModal-module__description___2a6nv",
	"form": "AreaModal-module__form___3pGJG",
	"btns": "AreaModal-module__btns___3-11x",
	"btn": "AreaModal-module__btn___RQkad",
	"ml8": "AreaModal-module__ml8___3TP7f",
	"mt48": "AreaModal-module__mt48___25LH0"
};
module.exports = exports;
