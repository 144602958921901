import React, { useEffect, useState } from 'react'

import classnames from 'classnames'

import { RiskAssessments } from '../../components'
import styles from './VerifyDashboardPage.module.scss'
import {
  ApplicationLayout,
  Button,
  Search,
  SortOptions,
} from '@/components/shared'

import { RiskAssessmentsChannel } from '@/channels'
import { riskAssessmentsPath, rootPath } from '@/features/constants/systemAdmin'
import {
  RiskAssessment,
  RiskAssessmentStatus,
  SystemAdmin,
} from '@/features/types'
import { getLocaleDateString } from '@/lib/helpers'

type Props = {
  systemAdmin: SystemAdmin
  risk_assessments: RiskAssessment[]
  defaultSearch?: string
  defaultSort?: string
  defaultSortDirection?: string
  totalWaitingRequest: number
  totalRejected: number
  totalApproved: number
}

const VerifyDashboardPage = (props: Props) => {
  const {
    systemAdmin,
    risk_assessments: defaultRiskAssessments,
    defaultSearch = '',
    defaultSort = '',
    defaultSortDirection = '',
    totalWaitingRequest,
    totalRejected,
    totalApproved,
  } = props
  const [riskAssessments, setRiskAssessments] = useState<RiskAssessment[]>(
    defaultRiskAssessments,
  )
  const [search, setSearch] = useState<string>(defaultSearch || '')

  const sortOptions: SortOptions = {
    sort: defaultSort,
    direction: defaultSortDirection,
  }

  const getSearchParams = (newSort?: string, newDirection?: string) => {
    const sort = newSort || sortOptions.sort || ''
    const direction = newDirection || sortOptions.direction || ''
    return `search=${search}&sort=${sort}&sort_direction=${direction}`
  }

  const handleQuery = (newSort?: string, newDirection?: string) => {
    window.location.href = `${rootPath}?${getSearchParams(
      newSort,
      newDirection,
    )}`
  }

  const today = getLocaleDateString(new Date())

  const onStartApprove = () => {
    const firstPending = firstPendingRiskAssessment(riskAssessments)
    if (firstPending) {
      window.location.href = `${riskAssessmentsPath}/${firstPending.id}/edit`
    }
  }

  useEffect(() => {
    RiskAssessmentsChannel.received = (newRiskAssessment: RiskAssessment) => {
      if (systemAdmin) {
        const duplicateIdx = riskAssessments.findIndex(
          (riskAssessment) =>
            riskAssessment.visitor_email === newRiskAssessment.visitor_email,
        )
        if (duplicateIdx === -1) {
          setRiskAssessments([...riskAssessments, newRiskAssessment])
        } else {
          riskAssessments.splice(duplicateIdx, 1)
          setRiskAssessments([...riskAssessments, newRiskAssessment])
        }
      }
    }
  }, [riskAssessments])

  return (
    <ApplicationLayout systemAdmin={systemAdmin}>
      <div className={styles.background}>
        <div className={styles.todayWrapper}>
          <span className={styles.todayText}>Today, {today}</span>
          <Button
            text="Start Approve"
            icon="/assets/icons/play-circle.svg"
            size={'L'}
            onClick={onStartApprove}
            disabled={!firstPendingRiskAssessment(riskAssessments)}
            className={styles.startApprove}
          />
        </div>
        <div className={styles.totalNumberSection}>
          <div className={styles.totalCard}>
            <div>Waiting request</div>
            <div
              className={classnames(styles.totalNumber, styles.waitingRequest)}
            >
              {totalWaitingRequest}
            </div>
          </div>
          <div className={styles.totalCard}>
            <div>Approved</div>
            <div className={classnames(styles.totalNumber, styles.approved)}>
              {totalApproved}
            </div>
          </div>
          <div className={styles.totalCard}>
            <div>Rejected</div>
            <div className={classnames(styles.totalNumber, styles.rejected)}>
              {totalRejected}
            </div>
          </div>
        </div>
        <Search
          search={search}
          setSearch={setSearch}
          handleQuery={handleQuery}
          className={styles.search}
        />
        <RiskAssessments
          risk_assessments={riskAssessments}
          sortOptions={sortOptions}
          handleSort={handleQuery}
          className={styles.riskAssessments}
        />
      </div>
    </ApplicationLayout>
  )
}

const firstPendingRiskAssessment = (risk_assessments: RiskAssessment[]) => {
  return risk_assessments.find(
    (risk_assessment: RiskAssessment) =>
      risk_assessment.request_status === RiskAssessmentStatus.pending,
  )
}

export default VerifyDashboardPage
