import React from 'react'

import { EventInput } from '@fullcalendar/core'
import classnames from 'classnames'
import {
  Control,
  Controller,
  FieldValues,
  UseFormRegister,
  UseFormWatch,
} from 'react-hook-form'

import styles from './DateTimeRangeField.module.scss'
import { Switch } from '@/components/shared'

import { getTimeOptions } from '@/features/constants'
import { Company } from '@/features/types'
import { formatDate, getTimeIndex, t } from '@/lib/helpers'

import { ISelectOption } from '../Select'
import { NormalMode } from './NormalMode'
import { ScheduleMode } from './ScheduleMode'

interface Props {
  required?: boolean
  className?: string
  control: Control<FieldValues>
  register: UseFormRegister<FieldValues>
  watch: UseFormWatch<FieldValues>
  name: `${string}`
  events: EventInput[]
  setEvents: (events: EventInput[]) => void
  duration: number | string
  setDuration: (duration: number | string) => void
  scheduleMode: boolean
  setScheduleMode: (scheduleMode: boolean) => void
  isEdit?: boolean
  company: Company
}

export const DateTimeRangeField = (props: Props) => {
  const {
    required = false,
    className,
    control,
    register,
    watch,
    name,
    events,
    setEvents,
    duration,
    setDuration,
    scheduleMode,
    setScheduleMode,
    isEdit = false,
    company,
  } = props

  const onChangeScheduleMode = () => {
    if (scheduleMode) {
      setEvents([])
      setDuration(15)
    } else {
      const timeOptions: ISelectOption[] = getTimeOptions()
      let fromTimeIndex = getTimeIndex(new Date())
      let defaultDate = new Date()
      if (fromTimeIndex === 0 || fromTimeIndex === timeOptions.length - 1) {
        fromTimeIndex = 0
        defaultDate.setDate(defaultDate.getDate() + 1)
      }
      const fromTime = timeOptions[fromTimeIndex].value
      const toTime = timeOptions[fromTimeIndex + 1].value
      const defaultScheduleModeEvent: EventInput = {
        id: 'defaultScheduleEvent',
        start: new Date(`${formatDate(defaultDate, 'D MMM YYYY')} ${fromTime}`),
        end: new Date(`${formatDate(defaultDate, 'D MMM YYYY')} ${toTime}`),
        className: 'new-invite select-slot',
      }
      setDuration(15)
      setEvents([defaultScheduleModeEvent])
    }
    setScheduleMode(!scheduleMode)
  }

  return (
    <div className={classnames(styles.background, className)}>
      <label className={styles.title}>
        {t('views.components.date_time_range_field.title')}
        {required && <span className={styles.requiredTitle}>*</span>}
      </label>
      <div className={styles.selectionMode}>
        <div className={styles.selectionModeLabel}>
          <span className={styles.selectionModeTitle}>
            {t('views.components.date_time_range_field.schedule_mode')}
          </span>
          <span className={styles.selectionModeDescription}>
            {t('views.components.date_time_range_field.mode_description')}
          </span>
        </div>
        <div className={styles.selectionModeSwitch}>
          <Switch
            checked={scheduleMode}
            onChange={onChangeScheduleMode}
            width={50}
            height={30}
            disabled={isEdit}
          />
        </div>
      </div>
      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange, ref } }) =>
          scheduleMode ? (
            <ScheduleMode
              duration={duration}
              setDuration={setDuration}
              events={events}
              setEvents={setEvents}
              company={company}
            />
          ) : (
            <NormalMode
              value={value}
              onChange={onChange}
              dateTimeRef={ref}
              watch={watch}
              register={register}
              setDuration={setDuration}
            />
          )
        }
      />
    </div>
  )
}
