import React from 'react'

import { VaccineInformation } from '../../components'
import styles from './VerifyVaccinePage.module.scss'
import { VerifyButton, VerifyLayout, VisitorInfo } from '@/components/shared'

import { verifyVaccineServices } from '../../services'
import { rejectOptions, rootPath } from '@/features/constants/systemAdmin'
import {
  RiskAssessment,
  RiskAssessmentStatus,
  SystemAdmin,
} from '@/features/types'
import { t } from '@/lib/helpers'

type Props = {
  systemAdmin: SystemAdmin
  riskAssessment: RiskAssessment
  hasNextRiskAssessment?: boolean
}

const VerifyVaccinePage = (props: Props) => {
  const { systemAdmin, riskAssessment, hasNextRiskAssessment = false } = props

  const onBack = () => {
    window.location.href = rootPath
  }

  const onReject = async (data: any) => {
    const otherText = rejectOptions.slice(-1).pop()?.value
    let reason = data.reason
    if (data.reason === otherText && data.reason_other) {
      reason = data.reason_other
    }
    await verifyVaccineServices().reject(riskAssessment.id.toString(), reason)
    onSkip()
  }

  const onApprove = async () => {
    await verifyVaccineServices().approve(riskAssessment.id.toString())
    onSkip()
  }

  const onSkip = async () => {
    const nextID = await nextRiskAssessment()
    if (nextID) {
      window.location.href = `/risk_assessments/${nextID}/edit`
    } else {
      window.location.href = rootPath
    }
  }

  const nextRiskAssessment = async () => {
    try {
      const response = await verifyVaccineServices().skip(
        riskAssessment.id.toString(),
      )
      return response.data.id
    } catch {
      return false
    }
  }

  const visitorInfo: VisitorInfo = {
    name: riskAssessment.visitor_name,
    email: riskAssessment.visitor_email,
    telephone: riskAssessment.visitor_telephone,
    image_url: riskAssessment.visitor_image_url,
  }

  const firstVerifyBtn: VerifyButton = {
    text: t('views.misc.approve'),
    onClick: onApprove,
  }

  const secondVerifyBtn: VerifyButton = {
    text: t('views.misc.reject'),
  }

  return (
    <VerifyLayout
      systemAdmin={systemAdmin}
      showSkip={hasNextRiskAssessment}
      onBack={onBack}
      onSkip={onSkip}
      firstVerifyBtn={firstVerifyBtn}
      secondVerifyBtn={secondVerifyBtn}
      rejectOptions={rejectOptions}
      onReject={onReject}
      verifyImageUrl={riskAssessment.image.url}
      visitorInfo={visitorInfo}
      extraInfo={
        <div className={styles.vaccination}>
          <div className={styles.vaccineRecord}>
            {t('views.features.risk_assessments.edit.title')}
          </div>
          <div>
            {riskAssessment.vaccines.map((vaccine, idx) => (
              <VaccineInformation
                key={idx}
                dose={idx + 1}
                name={vaccine.name}
                date={vaccine.date as Date}
                approved={vaccine.status === RiskAssessmentStatus.verified}
              />
            ))}
          </div>
        </div>
      }
    />
  )
}

export default VerifyVaccinePage
