import React from 'react'

import classnames from 'classnames'

import checkMailStyles from './CheckMailPage.module.scss'
import { UserLayout, userLayoutStyles } from '@/components/shared'

import { contactPath, signInPath } from '@/features/constants'

const createAccount = 'create_account'

const CheckMailPage = () => {
  const url = new URL(window.location.href)
  const email = url.searchParams.get('email')
  const type = url.searchParams.get('type')
  const handleClickBtn = () => (window.location.href = signInPath)
  const isCreateAccount = type === createAccount
  const title = isCreateAccount
    ? 'Account successfully created!'
    : 'Sent New Password Link'
  const method = isCreateAccount
    ? 'how to activate account.'
    : 'how to reset your password.'
  return (
    <UserLayout textBtn={'BACK TO SIGN IN'} onClickBtn={handleClickBtn}>
      <div className={userLayoutStyles.center}>
        <img
          src={'/assets/icons/flying-email.svg'}
          className={userLayoutStyles.emailIcon}
        />
        <div className={userLayoutStyles.title}>{title}</div>
        <div
          className={classnames(userLayoutStyles.detail, checkMailStyles.mt20)}
        >
          Check your <span>{email}</span> inbox
          <br /> for instructions from us on
          {` ${method}`}
        </div>

        <div
          className={classnames(
            userLayoutStyles.detail,
            checkMailStyles.mt20,
            checkMailStyles.mb40,
          )}
        >
          Unsure if that an email address was correct?{' '}
          <a href={contactPath}>Help Support</a>
        </div>
      </div>
    </UserLayout>
  )
}

export default CheckMailPage
