// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".CreatableSelectTime-module__timePicker___3Nhye{width:92px;max-width:90px;height:48px}.CreatableSelectTime-module__timePicker___3Nhye>div:first-child:hover{border:1px solid #3873e4}", "",{"version":3,"sources":["/usr/src/app/app/javascript/components/shared/CreatableSelectTime/CreatableSelectTime.module.scss"],"names":[],"mappings":"AAEA,gDACE,UAAA,CACA,cAAA,CACA,WAAA,CAGE,sEACE,wBAAA","file":"CreatableSelectTime.module.scss","sourcesContent":["@import '/app/assets/stylesheets/colors.scss';\n\n:local(.timePicker) {\n  width: 92px;\n  max-width: 90px;\n  height: 48px;\n\n  > div:first-child {\n    &:hover {\n      border: 1px solid $anyvisit-primary;\n    }\n  }\n}\n"]}]);
// Exports
exports.locals = {
	"timePicker": "CreatableSelectTime-module__timePicker___3Nhye"
};
module.exports = exports;
