import React from 'react'

import { UserSetUpPage } from '@/components/shared'

import { Employee } from '@/features/types'

type Props = {
  employee: Employee
}

const StaffSetUpPage = (props: Props) => {
  const { employee } = props

  return <UserSetUpPage user={employee} />
}

export default StaffSetUpPage
