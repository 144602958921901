import React from 'react'

import ReactTooltip from 'react-tooltip'

import { t } from '@/lib/helpers'

type Props = {
  showToolTip?: boolean
  onClick?: () => void
  className?: string
}

export const Trash = (props: Props) => {
  const { showToolTip = true, onClick, className } = props

  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        data-tip={showToolTip ? t('views.misc.delete') : undefined}
        onClick={onClick}
        className={className}
      >
        <rect width="24" height="24" fill="white" />
        <path d="M3.23042 6.39705C2.96834 6.65208 2.8125 7.00617 2.8125 7.39577C2.8125 8.17497 3.44995 8.81242 4.22914 8.81242H4.48418L5.47586 19.6996C5.57506 20.755 6.4605 21.5625 7.52306 21.5625H16.5189C17.5815 21.5625 18.4669 20.755 18.5661 19.6996L19.5578 8.81242H19.8128C20.2024 8.81242 20.5565 8.65658 20.8116 8.3945C21.0736 8.1396 21.2295 7.78537 21.2295 7.39577C21.2295 6.61657 20.592 5.97913 19.8128 5.97913H4.22914C3.83955 5.97913 3.48546 6.13482 3.23042 6.39705Z" />
        <path d="M15.2477 4.56232C15.8128 4.56232 16.271 4.10419 16.271 3.53924V3.46058C16.271 2.89548 15.8128 2.4375 15.2477 2.4375H8.79402C8.22892 2.4375 7.77094 2.89563 7.77094 3.46058V3.53924C7.77094 4.10434 8.22906 4.56232 8.79402 4.56232H15.2477Z" />
      </svg>
      <ReactTooltip effect="solid" />
    </>
  )
}
