import React from 'react'

import classnames from 'classnames'
import Switch from 'react-switch'

import styles from './LocaleSwitch.module.scss'

type Props = {
  locale: PlatformLanguage
  onClick: () => void
  className?: string
}

const getLocaleELement = (
  locale: PlatformLanguage,
  selected: PlatformLanguage,
) => {
  return (
    <div
      className={classnames(styles.locale, {
        [styles.selected]: locale === selected,
      })}
    >
      {locale.toUpperCase()}
    </div>
  )
}

export const LocaleSwitch = (props: Props) => {
  const { locale, onClick, className } = props

  return (
    <Switch
      checked={locale === 'en'}
      onChange={onClick}
      checkedIcon={getLocaleELement('th', locale)}
      uncheckedIcon={getLocaleELement('en', locale)}
      checkedHandleIcon={getLocaleELement('en', locale)}
      uncheckedHandleIcon={getLocaleELement('th', locale)}
      width={86}
      height={32}
      className={classnames(
        styles.switch,
        { [styles.checkSwitch]: locale === 'en' },
        className,
      )}
    />
  )
}
